import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { RectangleLink } from '../../styles/components/Links';
import ExternalLinkModal from '../ExternalLinkModal';

type ResourceLinkType = {
  imgSrc: string,
  text: string,
  link: string,
  isExternal: boolean,
  action?: () => void,
}

export default function ResourceLink({
  imgSrc, text, link, isExternal, action,
}: ResourceLinkType) {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);

  const handleButtonTouch = () => {
    if (!action) return;
    action();
  };

  const renderLinkTextAndImage = () => (
    <div>
      <img alt="" src={imgSrc} />
      <span>{text}</span>
    </div>
  );

  if (isExternal) {
    return (
      <>
        {showModal && (
          <ExternalLinkModal
            linkTitle={text}
            linkPath={link}
            handleModalClose={() => setShowModal(false)}
          />
        )}
        <RectangleLink
          colors={theme.colors.buttons.resources}
          onClick={() => setShowModal(true)} // using onClick to handle bug in Chrome with touch events
          as="button"
        >
          {renderLinkTextAndImage()}
        </RectangleLink>
      </>
    );
  }

  return (
    <RectangleLink
      colors={theme.colors.buttons.resources}
      onTouchStart={handleButtonTouch}
      to={link}
    >
      {renderLinkTextAndImage()}
    </RectangleLink>
  );
}

ResourceLink.defaultProps = {
  action: () => null,
};
