import React, { useMemo } from 'react';
import {
  Character, ActivityType, DifficultyLevel, LineType,
} from '../../data/types';
import {
  MODEL_LINE_HEIGHT_NORMAL,
  MODEL_LINE_HEIGHT_NORMAL_SCALE,
  MODEL_LINE_HEIGHT_SMALL,
  MODEL_LINE_HEIGHT_SMALL_SCALE,
} from '../../shared/constants';
import { ModelLetterArea } from '../../styles/components/ModelAreas';
import scaffolds from '../../assets/scaffolds';

interface ModelLetterProps {
  currentCharacter: Character;
  showModelLetter: boolean;
  difficultyLevel: DifficultyLevel;
  lineType: LineType,
}

export default function ModelLetter({
  currentCharacter,
  showModelLetter,
  difficultyLevel,
  lineType,
}: ModelLetterProps) {
  const scale = useMemo(() => (difficultyLevel === DifficultyLevel.EASY
    ? MODEL_LINE_HEIGHT_SMALL_SCALE
    : MODEL_LINE_HEIGHT_NORMAL_SCALE), [difficultyLevel]);

  const positionY = useMemo(() => {
    const charModelPositionY = currentCharacter.positioning[difficultyLevel]?.model?.yOffset || null;
    if (charModelPositionY) return charModelPositionY;
    const charPositionY = currentCharacter.positioning[difficultyLevel]?.scaffold?.yOffset || null;
    if (charPositionY) return charPositionY;
    return 0;
  }, [currentCharacter, difficultyLevel]);

  const charHeight = useMemo(() => {
    const modelHeight = currentCharacter.positioning[difficultyLevel]?.model?.height;
    if (modelHeight) return modelHeight;

    switch (difficultyLevel) {
      case DifficultyLevel.EASY:
        return MODEL_LINE_HEIGHT_SMALL;
      default:
        // eslint-disable-next-line no-case-declarations
        const characterHeight = currentCharacter.positioning[difficultyLevel]?.scaffold?.height || null;
        if (characterHeight) return characterHeight;
        return MODEL_LINE_HEIGHT_NORMAL;
    }
  }, [currentCharacter, difficultyLevel]);

  const imageSrc = useMemo(() => {
    const scaffold = scaffolds[difficultyLevel];
    if (!scaffold) return '';
    let activityType = ActivityType.SOLID;
    if (lineType === LineType.FADED) {
      activityType = ActivityType.FADED;
    } else if (lineType === LineType.DOTTED) {
      activityType = ActivityType.DOTTED;
    }
    // @ts-expect-error
    return scaffold[currentCharacter.type][activityType] || '';
  }, [currentCharacter, lineType]);

  if (!currentCharacter) return null;

  return (
    <ModelLetterArea
      hide={!showModelLetter}
      difficultyLevel={difficultyLevel}
    >
      <img
        alt={`practice character ${currentCharacter.display}`}
        src={imageSrc}
        height={charHeight * scale}
        style={{ marginTop: positionY * scale }}
      />
    </ModelLetterArea>
  );
}
