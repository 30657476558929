import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import arrowIcon from '../../assets/images/icons/arrow-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';
import { Paths } from '../../data/types';

type BackButtonProps = {
  path: string,
  icon?:any,
  placedLeft: boolean,
}

export default function BackButton({ path, icon, placedLeft }: BackButtonProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const goBackPath = Object.values(Paths).find((p) => p === path);

  const handleBackButtonTouch = () => {
    navigate(`/${goBackPath}`);
  };

  return (
    <CircleButton
      colors={theme.colors.buttons.back}
      aria-label="go back"
      onTouchStart={handleBackButtonTouch}
      role="link"
      width="64px"
      height="64px"
      style={placedLeft
        ? { position: 'absolute', top: '5%', left: '2%' }
        : { position: 'absolute', top: '5%', right: '2%' }}
    >
      <img
        alt=""
        src={icon || arrowIcon}
        style={{ transform: 'rotate(180deg)', width: '70%' }}
      />
    </CircleButton>
  );
}

BackButton.defaultProps = {
  icon: null,
};
