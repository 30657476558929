/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Links from '../data/links';
import PolicyLinkContainer from './PolicyLinkContainer';
import { Paths } from '../data/types';
import VersionDisplay from './Version Display/VersionDisplay';

export default function InformationContent() {
  return (
    <div className="about-content">
      <h2>About Starwriter</h2>
      <p>
        Starwriter is a digital handwriting instruction and practice program that emphasizes automaticity,
        efficiency and legibility for early learners. The program provides explicit formation instruction
        and scaffolded practice for all 26 letters (upper and lower case) and digits (0-9), using a carefully
        developed font and formation approach that prioritizes efficiency. The instructional progression is based
        on visual motor development, grouping similarly formed letters and beginning with easier stroke patterns.
      </p>
      <p>
        Starwriter is being developed by the
        {' '}
        <a href={Links.CAMBIAR_EDUCATION} target="_blank" rel="noreferrer">Cambiar Education</a>
        {' '}
        Illuminate Literacy program, in partnership with
        {' '}
        <a href={Links.DR_NELL_DUKE} target="_blank" rel="noreferrer">Dr. Nell Duke</a>.
        {' '}
        We are an early stage nonprofit focused on unlocking the great reader in every child to end illiteracy in our
        country, centering the experiences of Black, Latino, and Native American students and students
        living in poverty.
      </p>
      <p>
        Starwriter is currently in the prototyping stage, with a full release planned for SY23-24.
      </p>
      <p className="small">
        All songs used in Starwriter are written and performed by DraeTown.
        {' '}
        &copy; 2021 Regents of the University of Michigan. All Rights Reserved.
      </p>
      <h2>Additional Resources</h2>
      <p>
        Please see our
        {' '}
        <a href={Links.RESOURCES} target="_blank" rel="noreferrer">resource folder</a>
        {' '}
        which includes guides for how to use the program, printable worksheets, rubrics, and other resources.
      </p>
      <h2>Get in touch</h2>
      <p>
        Please don’t hesitate to get in touch with questions, issues, ideas and general feedback.
        You can reach us at
        {' '}
        <a href={Links.EMAIL_CAMBIAR}>Illuminate@cambiareducation.org</a>.
      </p>
      <PolicyLinkContainer returnPage={Paths.ABOUT} />
      <VersionDisplay isLanding={false} />
    </div>
  );
}
