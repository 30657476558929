import React from 'react';
import { useTheme } from 'styled-components';
import { PillButton } from '../../styles/components/Buttons';

type TeacherConfirmButtonProps = {
  buttonTouch: () => void,
  isDisabled?: boolean,
  autoWidth?: boolean,
  buttonText: string,
}

export default function TeacherConfirmButton({
  buttonTouch,
  isDisabled,
  autoWidth,
  buttonText,
}: TeacherConfirmButtonProps) {
  const theme = useTheme();

  return (
    <PillButton
      disabled={isDisabled}
      aria-disabled={isDisabled}
      colors={isDisabled
        ? theme.colors.buttons.access.disabled
        : theme.colors.buttons.access.active}
      aria-label={buttonText}
      onTouchStart={buttonTouch}
      onClick={buttonTouch}
      role="link"
      autoWidth={autoWidth}
    >
      <span className="access-button-text">{buttonText}</span>
    </PillButton>
  );
}

TeacherConfirmButton.defaultProps = {
  isDisabled: false,
  autoWidth: false,
};
