/* Several characters have strokes that require an overlap on itself
** (the start or end point of a stroke touches another area of that stroke) for proper formation.
** These characters are: O Q b o p 0 6 8 (end point), a d g q 9 (start point)
**
** To check for intersection, we must first make two lines from the one drawn stroke so the intersection library
** can do a proper calculation. The first line is just one coordinate point (x,y) and is taken from the
** very beginning or very end of a drawn stroke. The second line is every other coordinate point in
** that drawn stroke.
**
** One issue with only calculating points is illustrated in this graphic: docs/close-the-loop.png.
** You will see that if a stroke width is not accounted for, then Starwriter will mark strokes as
** not intersecting/touching, when visually, they will appear to be doing so.
**
** That means, that we will turn our one coordinate point into a circlular line using stroke width and an allowed
** margin of error as the radius. See illustration here: docs/close-the-loop-2.png
**
** We can get false positives if the library finds an intersection with the coordinate point
** and the points directly in front of or behind it in the drawn stroke array.
** See illusration here: docs/close-the-loop-3.png
*/

import { CharacterType, StrokeIntersectionType } from '../../data/types/writing/characters';
import { pointsToCircle, pointsToPath } from '../../shared/pointConversions';
import distanceBetweenTwoPoints from '../../shared/distanceBetweenTwoPoints';
import usePathIntersect from '../usePathIntersect.js';
import envSettings from '../../data/envSettings';

// Allow for a margin of error with gaps.
const getMarginOfError = (characterType: CharacterType) => {
  switch (characterType) {
    case CharacterType.NUMBER_6:
    case CharacterType.LOWER_G:
      return 6;
    case CharacterType.NUMBER_9:
    case CharacterType.LOWER_Q:
    case CharacterType.LOWER_A:
      return 7;
    case CharacterType.LOWER_P:
      return 8;
    case CharacterType.LOWER_B:
    case CharacterType.LOWER_D:
      return 9;
    case CharacterType.UPPER_O:
    case CharacterType.UPPER_Q:
    case CharacterType.NUMBER_0:
    case CharacterType.NUMBER_8:
      return 10;
    case CharacterType.LOWER_O:
      return 14;
    default:
      return 12;
  }
};

export default function useSingleStrokeIntersection(strokeWidth: number) {
  const intersect = usePathIntersect();

  const getPointToIntersect = (drawnStrokePoints: number[], requiredIntersection: StrokeIntersectionType): number[] => {
    if (requiredIntersection === StrokeIntersectionType.END_POINT_INTERSECTION) {
      return [drawnStrokePoints[drawnStrokePoints.length - 2], drawnStrokePoints[drawnStrokePoints.length - 1]];
    } if (requiredIntersection === StrokeIntersectionType.START_POINT_INTERSECTION) {
      return [drawnStrokePoints[0], drawnStrokePoints[1]];
    }
    return [];
  };

  const getRemainingPoints = (
    drawnStrokePoints: number[],
    requiredIntersection: StrokeIntersectionType,
    pointToIntersect: number[],
    marginOfError: number,
  ): number[] => {
    if (requiredIntersection === StrokeIntersectionType.END_POINT_INTERSECTION) {
      /* Has the end of stroke closed the loop (intersected)?
      ** Check if end of the drawn stroke intersects with the drawn stroke.
      **
      ** NOTE: Comments below assume an understanding of the comments at the top of the file.
      **
      ** Start from the back of the drawnStrokePoints array and iterate through each coordinate point (x, y)
      ** finding the distance between them and the pointToIntersect. Throw away any coordinate points that are closer to
      ** pointToIntersect than the stroke width and margin of error.
      ** Once we hit a distance that's greater than this, we return the remaining length of drawnStrokePoints.
      */
      const unacceptableDistance = strokeWidth + marginOfError;
      let newDrawnStrokePointsLength;
      for (let x = drawnStrokePoints.length; x >= 0; x -= 2) {
        const distance = distanceBetweenTwoPoints(
          [drawnStrokePoints[x - 2], drawnStrokePoints[x - 1]],
          pointToIntersect,
        );
        if (distance > unacceptableDistance) {
          newDrawnStrokePointsLength = x - 2;
          break;
        }
      }
      return drawnStrokePoints.slice(0, newDrawnStrokePointsLength);
    } if (requiredIntersection === StrokeIntersectionType.START_POINT_INTERSECTION) {
      /* Has the beginning of stroke closed the loop (intersected)?
      ** Check if beginning of the drawn stroke intersects with the drawn stroke.
      **
      ** NOTE: Comments below assume an understanding of the comments at the top of the file.
      **
      ** Start from the begginning of the drawnStrokePoints array,
      ** find the distance between coordinate points (x, y) and pointToIntersect.
      ** Once the distance of a point equals the stroke width plus the margin of error, we have the new
      ** start point of drawnStrokePoints and we can throw away any points before that.
      */
      const closeDistance = strokeWidth + marginOfError;
      let newStartPoint;
      for (let x = 0; x < drawnStrokePoints.length; x += 2) {
        const distance = distanceBetweenTwoPoints(
          [drawnStrokePoints[x], drawnStrokePoints[x + 1]],
          pointToIntersect,
        );
        if (distance > closeDistance) {
          newStartPoint = x;
          break;
        }
      }
      return drawnStrokePoints.slice(newStartPoint);
    }
    return [];
  };

  const doesStrokeIntersect = (
    drawnStrokePoints: number[],
    requiredIntersection: StrokeIntersectionType,
    characterType: CharacterType,
  ): boolean => {
    const pointToIntersect = getPointToIntersect(drawnStrokePoints, requiredIntersection);
    const allOtherPoints = getRemainingPoints(
      drawnStrokePoints,
      requiredIntersection,
      pointToIntersect,
      getMarginOfError(characterType),
    );
    // Turn the point to intersect into a circle to accommodate drawn stroke's width.
    const pointToIntersectPath = pointsToCircle(pointToIntersect, strokeWidth + getMarginOfError(characterType));
    const allOtherPointsPath = pointsToPath(allOtherPoints);
    if (envSettings.devMode) {
      /* eslint-disable no-console */
      console.group('TEMPORARY: Copy the below and send to Atomic.');
      console.log(characterType);
      console.log(`[${drawnStrokePoints}]`);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    const intersection = intersect(pointToIntersectPath, allOtherPointsPath);
    return intersection.length > 0;
  };

  return doesStrokeIntersect;
}
