import { SpacingActivityType, SpacingItemType } from '../../data/types';

import amModel from './MODEL/AM.svg';
import amFaded from './TRACE_FADED/AM.svg';
import amShootingStar from './TRACE_SHOOTING_STAR/AM.svg';
import amClose from './MULTI_CHOICE_CLOSE/AM.svg';
import amWide from './MULTI_CHOICE_WIDE/AM.svg';
import amCorrect from './MULTI_CHOICE_CORRECT/AM.svg';
import amInde from './INDEPENDENT/AM.svg';

import atModel from './MODEL/AT.svg';
import atFaded from './TRACE_FADED/AT.svg';
import atShootingStar from './TRACE_SHOOTING_STAR/AT.svg';
import atClose from './MULTI_CHOICE_CLOSE/AT.svg';
import atWide from './MULTI_CHOICE_WIDE/AT.svg';
import atCorrect from './MULTI_CHOICE_CORRECT/AT.svg';
import atInde from './INDEPENDENT/AT.svg';

import bagModel from './MODEL/BAG.svg';
import bagFaded from './TRACE_FADED/BAG.svg';
import bagShootingStar from './TRACE_SHOOTING_STAR/BAG.svg';
import bagClose from './MULTI_CHOICE_CLOSE/BAG.svg';
import bagWide from './MULTI_CHOICE_WIDE/BAG.svg';
import bagCorrect from './MULTI_CHOICE_CORRECT/BAG.svg';
import bagInde from './INDEPENDENT/BAG.svg';

import chModel from './MODEL/CH.svg';
import chFaded from './TRACE_FADED/CH.svg';
import chShootingStar from './TRACE_SHOOTING_STAR/CH.svg';
import chClose from './MULTI_CHOICE_CLOSE/CH.svg';
import chWide from './MULTI_CHOICE_WIDE/CH.svg';
import chCorrect from './MULTI_CHOICE_CORRECT/CH.svg';
import chInde from './INDEPENDENT/CH.svg';

import ifModel from './MODEL/IF.svg';
import ifFaded from './TRACE_FADED/IF.svg';
import ifShootingStar from './TRACE_SHOOTING_STAR/IF.svg';
import ifClose from './MULTI_CHOICE_CLOSE/IF.svg';
import ifWide from './MULTI_CHOICE_WIDE/IF.svg';
import ifCorrect from './MULTI_CHOICE_CORRECT/IF.svg';
import ifInde from './INDEPENDENT/IF.svg';

import inModel from './MODEL/IN.svg';
import inFaded from './TRACE_FADED/IN.svg';
import inShootingStar from './TRACE_SHOOTING_STAR/IN.svg';
import inClose from './MULTI_CHOICE_CLOSE/IN.svg';
import inWide from './MULTI_CHOICE_WIDE/IN.svg';
import inCorrect from './MULTI_CHOICE_CORRECT/IN.svg';
import inInde from './INDEPENDENT/IN.svg';

import itModel from './MODEL/IT.svg';
import itFaded from './TRACE_FADED/IT.svg';
import itShootingStar from './TRACE_SHOOTING_STAR/IT.svg';
import itClose from './MULTI_CHOICE_CLOSE/IT.svg';
import itWide from './MULTI_CHOICE_WIDE/IT.svg';
import itCorrect from './MULTI_CHOICE_CORRECT/IT.svg';
import itInde from './INDEPENDENT/IT.svg';

import noModel from './MODEL/NO.svg';
import noFaded from './TRACE_FADED/NO.svg';
import noShootingStar from './TRACE_SHOOTING_STAR/NO.svg';
import noClose from './MULTI_CHOICE_CLOSE/NO.svg';
import noWide from './MULTI_CHOICE_WIDE/NO.svg';
import noCorrect from './MULTI_CHOICE_CORRECT/NO.svg';
import noInde from './INDEPENDENT/NO.svg';

import redModel from './MODEL/RED.svg';
import redFaded from './TRACE_FADED/RED.svg';
import redShootingStar from './TRACE_SHOOTING_STAR/RED.svg';
import redClose from './MULTI_CHOICE_CLOSE/RED.svg';
import redWide from './MULTI_CHOICE_WIDE/RED.svg';
import redCorrect from './MULTI_CHOICE_CORRECT/RED.svg';
import redInde from './INDEPENDENT/RED.svg';

import shModel from './MODEL/SH.svg';
import shFaded from './TRACE_FADED/SH.svg';
import shShootingStar from './TRACE_SHOOTING_STAR/SH.svg';
import shClose from './MULTI_CHOICE_CLOSE/SH.svg';
import shWide from './MULTI_CHOICE_WIDE/SH.svg';
import shCorrect from './MULTI_CHOICE_CORRECT/SH.svg';
import shInde from './INDEPENDENT/SH.svg';

import thModel from './MODEL/TH.svg';
import thFaded from './TRACE_FADED/TH.svg';
import thShootingStar from './TRACE_SHOOTING_STAR/TH.svg';
import thClose from './MULTI_CHOICE_CLOSE/TH.svg';
import thWide from './MULTI_CHOICE_WIDE/TH.svg';
import thCorrect from './MULTI_CHOICE_CORRECT/TH.svg';
import thInde from './INDEPENDENT/TH.svg';

import upModel from './MODEL/UP.svg';
import upFaded from './TRACE_FADED/UP.svg';
import upShootingStar from './TRACE_SHOOTING_STAR/UP.svg';
import upClose from './MULTI_CHOICE_CLOSE/UP.svg';
import upWide from './MULTI_CHOICE_WIDE/UP.svg';
import upCorrect from './MULTI_CHOICE_CORRECT/UP.svg';
import upInde from './INDEPENDENT/UP.svg';

import usModel from './MODEL/US.svg';
import usFaded from './TRACE_FADED/US.svg';
import usShootingStar from './TRACE_SHOOTING_STAR/US.svg';
import usClose from './MULTI_CHOICE_CLOSE/US.svg';
import usWide from './MULTI_CHOICE_WIDE/US.svg';
import usCorrect from './MULTI_CHOICE_CORRECT/US.svg';
import usInde from './INDEPENDENT/US.svg';

import yesModel from './MODEL/YES.svg';
import yesFaded from './TRACE_FADED/YES.svg';
import yesShootingStar from './TRACE_SHOOTING_STAR/YES.svg';
import yesClose from './MULTI_CHOICE_CLOSE/YES.svg';
import yesWide from './MULTI_CHOICE_WIDE/YES.svg';
import yesCorrect from './MULTI_CHOICE_CORRECT/YES.svg';
import yesInde from './INDEPENDENT/YES.svg';

type SpacingScaffolds = {
  [spaceKey in SpacingItemType]: {
    ['MODEL']: string;
    [SpacingActivityType.TRACE_FADED]: string;
    [SpacingActivityType.TRACE_SHOOTING_STAR]: string;
    [SpacingActivityType.SPACE_INDEPENDENT]: string;
    [SpacingActivityType.DRAG_AND_DROP]: string;
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: string;
  }
}

const spacingScaffolds: SpacingScaffolds = {
  [SpacingItemType.AM]: {
    MODEL: amModel,
    [SpacingActivityType.TRACE_FADED]: amFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: amShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: amInde,
    [SpacingActivityType.DRAG_AND_DROP]: amFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: amInde,
  },
  [SpacingItemType.AT]: {
    MODEL: atModel,
    [SpacingActivityType.TRACE_FADED]: atFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: atShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: atInde,
    [SpacingActivityType.DRAG_AND_DROP]: atFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: atInde,
  },
  [SpacingItemType.BAG]: {
    MODEL: bagModel,
    [SpacingActivityType.TRACE_FADED]: bagFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: bagShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: bagInde,
    [SpacingActivityType.DRAG_AND_DROP]: bagFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: bagInde,
  },
  [SpacingItemType.CH]: {
    MODEL: chModel,
    [SpacingActivityType.TRACE_FADED]: chFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: chShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: chInde,
    [SpacingActivityType.DRAG_AND_DROP]: chFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: chInde,
  },
  [SpacingItemType.IF]: {
    MODEL: ifModel,
    [SpacingActivityType.TRACE_FADED]: ifFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: ifShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: ifInde,
    [SpacingActivityType.DRAG_AND_DROP]: ifFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: ifInde,
  },
  [SpacingItemType.IN]: {
    MODEL: inModel,
    [SpacingActivityType.TRACE_FADED]: inFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: inShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: inInde,
    [SpacingActivityType.DRAG_AND_DROP]: inFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: inInde,
  },
  [SpacingItemType.IT]: {
    MODEL: itModel,
    [SpacingActivityType.TRACE_FADED]: itFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: itShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: itInde,
    [SpacingActivityType.DRAG_AND_DROP]: itFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: itInde,
  },
  [SpacingItemType.NO]: {
    MODEL: noModel,
    [SpacingActivityType.TRACE_FADED]: noFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: noShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: noInde,
    [SpacingActivityType.DRAG_AND_DROP]: noFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: noInde,
  },
  [SpacingItemType.RED]: {
    MODEL: redModel,
    [SpacingActivityType.TRACE_FADED]: redFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: redShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: redInde,
    [SpacingActivityType.DRAG_AND_DROP]: redFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: redInde,
  },
  [SpacingItemType.SH]: {
    MODEL: shModel,
    [SpacingActivityType.TRACE_FADED]: shFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: shShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: shInde,
    [SpacingActivityType.DRAG_AND_DROP]: shFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: shInde,
  },
  [SpacingItemType.TH]: {
    MODEL: thModel,
    [SpacingActivityType.TRACE_FADED]: thFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: thShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: thInde,
    [SpacingActivityType.DRAG_AND_DROP]: thFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: thInde,
  },
  [SpacingItemType.UP]: {
    MODEL: upModel,
    [SpacingActivityType.TRACE_FADED]: upFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: upShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: upInde,
    [SpacingActivityType.DRAG_AND_DROP]: upFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: upInde,
  },
  [SpacingItemType.US]: {
    MODEL: usModel,
    [SpacingActivityType.TRACE_FADED]: usFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: usShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: usInde,
    [SpacingActivityType.DRAG_AND_DROP]: usFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: usInde,
  },
  [SpacingItemType.YES]: {
    MODEL: yesModel,
    [SpacingActivityType.TRACE_FADED]: yesFaded,
    [SpacingActivityType.TRACE_SHOOTING_STAR]: yesShootingStar,
    [SpacingActivityType.SPACE_INDEPENDENT]: yesInde,
    [SpacingActivityType.DRAG_AND_DROP]: yesFaded,
    [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: yesInde,
  },
};

export default spacingScaffolds;

export const chooseSpacingScaffolds = {
  [SpacingItemType.AM]: {
    TOO_CLOSE: amClose,
    TOO_WIDE: amWide,
    CORRECT: amCorrect,
  },
  [SpacingItemType.AT]: {
    TOO_CLOSE: atClose,
    TOO_WIDE: atWide,
    CORRECT: atCorrect,
  },
  [SpacingItemType.BAG]: {
    TOO_CLOSE: bagClose,
    TOO_WIDE: bagWide,
    CORRECT: bagCorrect,
  },
  [SpacingItemType.CH]: {
    TOO_CLOSE: chClose,
    TOO_WIDE: chWide,
    CORRECT: chCorrect,
  },
  [SpacingItemType.IF]: {
    TOO_CLOSE: ifClose,
    TOO_WIDE: ifWide,
    CORRECT: ifCorrect,
  },
  [SpacingItemType.IN]: {
    TOO_CLOSE: inClose,
    TOO_WIDE: inWide,
    CORRECT: inCorrect,
  },
  [SpacingItemType.IT]: {
    TOO_CLOSE: itClose,
    TOO_WIDE: itWide,
    CORRECT: itCorrect,
  },
  [SpacingItemType.NO]: {
    TOO_CLOSE: noClose,
    TOO_WIDE: noWide,
    CORRECT: noCorrect,
  },
  [SpacingItemType.RED]: {
    TOO_CLOSE: redClose,
    TOO_WIDE: redWide,
    CORRECT: redCorrect,
  },
  [SpacingItemType.SH]: {
    TOO_CLOSE: shClose,
    TOO_WIDE: shWide,
    CORRECT: shCorrect,
  },
  [SpacingItemType.TH]: {
    TOO_CLOSE: thClose,
    TOO_WIDE: thWide,
    CORRECT: thCorrect,
  },
  [SpacingItemType.UP]: {
    TOO_CLOSE: upClose,
    TOO_WIDE: upWide,
    CORRECT: upCorrect,
  },
  [SpacingItemType.US]: {
    TOO_CLOSE: usClose,
    TOO_WIDE: usWide,
    CORRECT: usCorrect,
  },
  [SpacingItemType.YES]: {
    TOO_CLOSE: yesClose,
    TOO_WIDE: yesWide,
    CORRECT: yesCorrect,
  },
};
