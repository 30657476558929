import React from 'react';
import LottiePlayer from '../LottiePlayer';
import correctSpaceStar550 from '../../assets/lotties/correct-space-star-550.json';
import correctSpaceStar1100 from '../../assets/lotties/correct-space-star-1100.json';
import tooCloseStar550 from '../../assets/lotties/too-close-star-550.json';
import tooCloseStar1100 from '../../assets/lotties/too-close-star-1100.json';
import tooWideStar550 from '../../assets/lotties/too-wide-star-550.json';
import tooWideStar1100 from '../../assets/lotties/too-wide-star-1100.json';
import { AbsolutePositionWrapper } from '../../styles/components/PositionWrappers';
import { SpacingFeedbackType } from '../../data/types';
import ZIndexWrapper from '../../styles/components/ZIndexWrapper';

type SpacingStarTypes = {
  [key in SpacingFeedbackType]: {
    regular: {
      lottie: any;
      width: number;
      top: number;
    },
    tall: {
      lottie: any;
      width: number;
      top: number;
    }
  }
}

const spacingStarTypes: SpacingStarTypes = {
  [SpacingFeedbackType.TOO_CLOSE]: {
    regular: {
      lottie: tooCloseStar550,
      width: 110,
      top: 134,
    },
    tall: {
      lottie: tooCloseStar1100,
      width: 110,
      top: 140,
    },
  },
  [SpacingFeedbackType.TOO_WIDE]: {
    regular: {
      lottie: tooWideStar550,
      width: 40,
      top: 75,
    },
    tall: {
      lottie: tooWideStar1100,
      width: 40,
      top: 70,
    },
  },
  [SpacingFeedbackType.CORRECT]: {
    regular: {
      lottie: correctSpaceStar550,
      width: 20,
      top: 6,
    },
    tall: {
      lottie: correctSpaceStar1100,
      width: 20,
      top: 31,
    },
  },
};

interface SpacingStarAnimationProps {
  spacingFeedbackType: SpacingFeedbackType|undefined,
  right: number,
  isTall?: boolean,
  isVisible: boolean,
  handleComplete: () => void,
}

export default function SpacingStarAnimation({
  spacingFeedbackType,
  right,
  isTall,
  isVisible,
  handleComplete,
}: SpacingStarAnimationProps) {
  if (!isVisible || !spacingFeedbackType) return null;
  const {
    lottie, width, top,
  } = isTall
    ? spacingStarTypes[spacingFeedbackType].tall
    : spacingStarTypes[spacingFeedbackType].regular;

  return (
    <AbsolutePositionWrapper
      top={top}
      right={right}
    >
      <ZIndexWrapper value={2} style={{ position: 'relative' }}>
        <LottiePlayer
          data={lottie}
          width={width}
          shouldAutoplay
          speed={1.5}
          handleComplete={handleComplete}
        />
      </ZIndexWrapper>
    </AbsolutePositionWrapper>
  );
}

SpacingStarAnimation.defaultProps = {
  isTall: false,
};
