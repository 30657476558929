import styled from 'styled-components';
import { FlexRowCenter } from './FlexSpacers';
import colorNames from '../colors';

export const DraggableLetterWrapper = styled(FlexRowCenter)`
  background-color: ${colorNames.lightBlue};
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  height: 204px;
  position: relative;
  width: 104px;
`;
