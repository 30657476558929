import React from 'react';
import { useTheme } from 'styled-components';
import { CharacterCategory, CharacterType } from '../../data/types';
import { characters } from '../../data/characters/characters';
import HexagonWithBorderSvg from '../SVG/HexagonWithBorderSvg';
import HexagonWithDoubleBorderSvg from '../SVG/HexagonWithDoubleBorderSvg';
import CharacterButtonBadgeImg from './CharacterButtonBadgeImg';
import { ButtonWithSvg } from '../../styles/components/Buttons';
import { HexagonButtonSizes } from '../SVG/types';

type CharacterButtonProps = {
  charType: CharacterType,
  charCategory: CharacterCategory,
  isDisabled?: boolean,
  isCurrentCharacter?: boolean,
  isCharacterInCurrentSequence?: boolean,
  isCharacterInUnlockedSet?: boolean,
  isDeleteable?: boolean,
  size?: HexagonButtonSizes,
  buttonTouch: (event: any) => void,
}

export default function CharacterButton({
  charType,
  charCategory,
  isDisabled,
  isCurrentCharacter,
  isCharacterInCurrentSequence,
  isCharacterInUnlockedSet,
  isDeleteable,
  size,
  buttonTouch,
}: CharacterButtonProps) {
  const theme = useTheme();

  const getHexagonButtonColorsKey = () => {
    if (isCurrentCharacter || isCharacterInCurrentSequence
      || (isCharacterInUnlockedSet && !isDisabled)) return 'selected';
    if (isDisabled && isCharacterInUnlockedSet) return 'disabled';
    return charCategory;
  };

  const shouldShowDoubleBorder = size !== HexagonButtonSizes.SM
    && (isCurrentCharacter || isCharacterInCurrentSequence || isCharacterInUnlockedSet);

  return (
    <ButtonWithSvg
      disabled={isDisabled}
      aria-disabled={isDisabled}
      type="button"
      aria-label={`start drawing the character ${charCategory} ${charType}`}
      className={isCurrentCharacter ? 'bounce' : ''}
      onTouchStart={buttonTouch}
    >
      {shouldShowDoubleBorder
        ? (
          <HexagonWithDoubleBorderSvg
            colors={theme.colors.svgs.hexagonButton[getHexagonButtonColorsKey()]}
            size={size}
          />
        ) : (
          <HexagonWithBorderSvg
            colors={theme.colors.svgs.hexagonButton[getHexagonButtonColorsKey()]}
            size={size}
          />
        )}

      <CharacterButtonBadgeImg
        isNext={isCurrentCharacter}
        isUnlockedAfterSequence={isCharacterInUnlockedSet && !isCharacterInCurrentSequence && isDisabled}
        isDeleteable={isDeleteable}
      />

      <span
        style={{
          color: theme.colors.svgs.hexagonButton[getHexagonButtonColorsKey()].text,
        }}
      >
        {characters[charType].display}
      </span>
    </ButtonWithSvg>
  );
}

const height = window.innerHeight;

CharacterButton.defaultProps = {
  isDisabled: false,
  isCurrentCharacter: false,
  isCharacterInCurrentSequence: false,
  isCharacterInUnlockedSet: false,
  isDeleteable: false,
  size: height <= 768 ? HexagonButtonSizes.SM : HexagonButtonSizes.MD,
};
