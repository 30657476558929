import React, { useEffect } from 'react';
import { RotateScreenContainer } from '../styles/components/ScreenContainers';
import rotateScreen from '../assets/images/rotate_screen.svg';
import rotateYourScreen from '../assets/audio/rotateScreen/VO36.mp3';
import useScreenOrientation from '../hooks/useScreenOrientation';
import { useAudioContext } from '../context/AudioContext';

export default function RotateScreen() {
  const isPortrait = useScreenOrientation();
  const audioContext = useAudioContext();

  useEffect(() => {
    if (isPortrait) {
      audioContext?.handlePlay({ src: rotateYourScreen });
    }
    return () => audioContext?.handleComplete();
  }, [isPortrait]);

  if (isPortrait) {
    return (
      <RotateScreenContainer>
        <img src={rotateScreen} alt="rotate the screen to landscape" />
      </RotateScreenContainer>
    );
  }
  return null;
}
