export enum ActivityType {
  SOLID = 'SOLID',
  FADED = 'FADED',
  DOTTED = 'DOTTED',
  CHASE_STAR = 'CHASE_STAR',
  INDEPENDENT = 'INDEPENDENT',
  STAR_GAME = 'STAR_GAME',
}

export type Activity = {
  type: ActivityType,
  currentIteration: number,
  requiredIterations: number,
  nextActivityType: ActivityType | null,
  // each child array represents an iteration of the activity
  starMeterFrames?: [number, number][],
}

// [SW-983]: add new drag and drop activity type
export enum SpacingActivityType {
  // [SW-983] TODO: Remove TRACE_FADED activity after testing and confirming everything works ok.
  TRACE_FADED = 'TRACE_FADED',
  DRAG_AND_DROP = 'DRAG_AND_DROP',
  DRAG_AND_DROP_INDEPENDENT = 'DRAG_AND_DROP_INDEPENDENT',
  MULTI_CHOICE_CLOSE = 'MULTI_CHOICE_CLOSE',
  MULTI_CHOICE_WIDE = 'MULTI_CHOICE_WIDE',
  MULTI_CHOICE_ALL = 'MULTI_CHOICE_ALL',
  TRACE_SHOOTING_STAR = 'TRACE_SHOOTING_STAR',
  SPACE_INDEPENDENT = 'SPACE_INDEPENDENT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  PLANET_1_GAME = 'PLANET_1_GAME',
  PLANET_2_GAME = 'PLANET_2_GAME'
}

// [SW-983]: add new drag and drop activity category
export enum SpacingActivityCategory {
  WRITING = 'WRITING',
  MULTI_CHOICE = 'MULTI_CHOICE',
  PLANET_GAME = 'PLANET_GAME',
  DRAG_AND_DROP = 'DRAG_AND_DROP',
}

export type SpacingActivity = {
  type: SpacingActivityType,
  requiredIterations: number,
  category: SpacingActivityCategory,
  nextActivityType: SpacingActivityType | null,
  title: string,
  // each child array represents an iteration of the activity
  starMeterFrames?: [number, number][],
}
