import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { AudioCue } from '../components/AudioPlayer';

interface AudioContextInterface {
  audioCue: AudioCue|null,
  isPlaying: boolean,
  handlePlay: (audioCue: AudioCue) => void,
  handleComplete: () => void,
}

const AudioContext = createContext<AudioContextInterface | null>(null);

type AudioContextProviderProps = {
  children: React.ReactNode,
}

export default function AudioContextProvider({ children }: AudioContextProviderProps) {
  const [audioCue, setAudioCue] = useState<AudioCue|null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleComplete = () => {
    setAudioCue(null);
    setIsPlaying(false);
  };

  const handlePlay = (newAudioCue: AudioCue) => {
    if (!newAudioCue.onEnd) {
      setAudioCue({ ...newAudioCue, onEnd: () => handleComplete() });
    } else {
      setAudioCue(newAudioCue);
    }
    setIsPlaying(true);
  };

  const providerValue = useMemo(
    () => ({
      audioCue, isPlaying, handleComplete, handlePlay,
    }),
    [audioCue, isPlaying],
  );

  return (
    <AudioContext.Provider value={providerValue}>
      {children}
    </AudioContext.Provider>
  );
}

export function useAudioContext() {
  const context = useContext(AudioContext);

  if (context === undefined) {
    throw new Error('useAudioContext must be used within AudioContextProvider');
  }

  return context;
}
