import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}

  html,
  body {
    overflow: hidden;
    overscroll-behavior: none;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
  }

  body {
    font-family: ${(props) => props.theme.fonts.families.primary};
    background: url(${(props) => props.theme.images.mainBackground}) repeat-y center center fixed;
    background-size: cover;

    /*
      Disable pointer-events so the context menu is not triggered.
        NOTE: As of iOS 13, the contextmenu event is not
        triggered on Chrome and cannot be used to prevent context menus from appearing.
        See comment here:
        https://github.com/mapbox/mapbox-gl-js/issues/8728#issuecomment-748073057

        Safari on iOS does not support the event: https://caniuse.com/mdn-api_element_contextmenu_event

        If ever fixed on Chrome, the below could potentially be replaced with an event listener for contextmenu:
        https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event

        Additionally, the property webkit-touch-callout fixed this issue well, except it's only supported on
        iOS Safari and MDN warns against using it in production:
        https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout

        If that property ever gains more support, it should be used here instead of pointer-events, since pointer-events
        still allows a small, translucent pop up on Chrome.
    */
    pointer-events: none;
  }

  /* can be removed if body pointer-events: none is ever removed */
  button, button:not(:disabled), a, form, input, canvas, g.star, :active, :focus, :enabled {
    pointer-events: auto;
    cursor: pointer;
  }

  #root {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .squiggles {
    z-index: -1;
  }

  img {
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
  }
`;

export default GlobalStyles;
