import React, { useEffect } from 'react';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import Theme from './styles/Theme';
import PageWrapper from './styles/components/PageWrapper';
import Information from './pages/Information';
import TouchDeviceRequired from './pages/TouchDeviceRequired';
import RotateScreen from './components/RotateScreen';
import envSettings from './data/envSettings';
import AudioPlayer from './components/AudioPlayer';
import useTouchDevice from './hooks/useTouchDevice';
import EducatorResources from './pages/EducatorResources';
import Landing from './pages/Landing';
import GameScreens from './components/GameScreens';
import { Paths } from './data/types';
import getDocumentTitle from './shared/getDocumentTitle';
import Policies from './pages/Policies';
import ActivitySelection from './pages/ActivitySetSelection';
import PreWritingSelection from './pages/PreWritingSelection';
import PWHandwritingActivity from './pages/PreWritingHandwritingActivity';

// prevents screen bounce, drag, scroll triggered by gestures
// This function must stay out of the component so it doesn't get
// replicated by repeated renders. If it does, removeEventListener
// will not work becuase the stopScrolling function being passed to it
// may not be the original stopScrolling function passed to addEventListener
const stopScrolling = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

function App() {
  const isTouchDevice = useTouchDevice();
  const location = useLocation();

  useEffect(() => {
    if (envSettings?.analytics === 'matomo' && document && document.head) {
      const analytics = document.head.querySelector('#starwriter-analytics');
      if (!analytics) {
        const analyticsScript = document.createElement('script');
        analyticsScript.src = `${window.location.origin}/scripts/analytics.js`;
        analyticsScript.type = 'text/javascript';
        analyticsScript.id = 'starwriter-analytics';
        document.head.appendChild(analyticsScript);
      }
    }
  }, []);

  useEffect(() => {
    if (!location.pathname) return;
    const pathName = location.pathname.replace('/', '');

    // Update page titles as user navigates app
    const pageTitle = getDocumentTitle(pathName as Paths);
    document.title = pageTitle;

    if (pathName === Paths.DIRECT_NOTICE
      || pathName === Paths.TOS
      || pathName === Paths.PRIVACY) {
      document.removeEventListener('touchmove', stopScrolling, false);
    } else {
      document.addEventListener('touchmove', stopScrolling, { passive: false });
    }
  }, [location]);

  return (
    <Theme>
      <AudioPlayer />
      <RotateScreen />
      <PageWrapper>
        <Routes>
          <Route
            path={Paths.EDUCATOR_RESOURCES}
            element={!isTouchDevice
              ? <TouchDeviceRequired />
              : <EducatorResources />}
          />
          <Route path={Paths.ABOUT} element={<Information />} />
          <Route path={Paths.ACTIVITY_SET_SELECTION} element={<ActivitySelection />} />
          <Route
            path={Paths.WRITING}
            element={!isTouchDevice
              ? <TouchDeviceRequired />
              : <GameScreens />}
          />
          <Route path={Paths.PRE_WRITING} element={<PreWritingSelection />} />
          <Route path={Paths.PRE_WRITING_HANDWRITING} element={<PWHandwritingActivity />} />
          <Route path="/" element={!isTouchDevice ? <TouchDeviceRequired /> : <Landing />} />
          <Route
            path={Paths.TOS}
            element={<Policies policyPath={Paths.TOS} />}
          />
          <Route
            path={Paths.PRIVACY}
            element={<Policies policyPath={Paths.PRIVACY} />}
          />
          <Route
            path={Paths.DIRECT_NOTICE}
            element={<Policies policyPath={Paths.DIRECT_NOTICE} />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </PageWrapper>
    </Theme>
  );
}

export default App;
