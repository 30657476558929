import { useState } from 'react';

type Timeout = {
  delay: number,
  func: () => void,
}

export default function useTimeout() {
  const [allTimeoutIds, setAllTimeoutIds] = useState<any[]>([]);

  const createTimeouts = (timeouts: Timeout[]): void => {
    timeouts.forEach((timeout) => {
      const timerId = setTimeout(() => {
        timeout.func();
      }, timeout.delay);
      setAllTimeoutIds((currentTimeoutIds) => [...currentTimeoutIds, timerId]);
    });
  };

  const createTimeout = (timeout: Timeout) => {
    const timerId = setTimeout(() => {
      timeout.func();
    }, timeout.delay);
    setAllTimeoutIds((currentTimeoutIds) => [...currentTimeoutIds, timerId]);
    return timerId;
  };

  const cancelTimeouts = (skippedTimeoutIds: number[] = []) => {
    allTimeoutIds.forEach((timeoutId) => {
      if (!skippedTimeoutIds.includes(timeoutId)) {
        clearTimeout(timeoutId);
      }
    });
  };

  return { createTimeouts, cancelTimeouts, createTimeout };
}
