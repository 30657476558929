import styled from 'styled-components';

export const ThreeGroupHexagonButtonLayout = styled.div`
  display: grid;
  grid-row-gap: 42px;
  padding: 2em 2.5em;
  width: 100%;
  height: 100%;
`;

export const SingleGroupHexagonButtonLayout = styled.div`
  display: grid;
  width: 100%;
  min-width: 800px;
  height: 100%;
  padding: 2.3125em 2.5em;
`;

export const HexagonButtonGroup = styled.div`
  .row {
    display: grid;
    margin: -12px 0px;
    justify-content: center;
    align-content: center;
    grid-column-gap: 12px;

    &.small {
      margin: -6px 0px;
    }
  }

  &.number {
    .row {
      grid-template-columns: repeat(10, 0fr);
    }
  }

  &.symbol {
    .row {
      grid-template-columns: repeat(10, 0fr);
      margin-top: -40px;
      margin-left: -15px;

      &.custom-set {
        margin-left: 0px;
      }
    }
  }

  &.symbolSm {
    .row {
      grid-template-columns: repeat(10, 0fr);
      margin-top: -40px;
      margin-left: 0px;

      &.custom-set {
        margin-left: 0px;
      }
    }
  }

  &.upper-letter, &.lower-letter {
    .row.one {
      grid-template-columns: repeat(10, 0fr);

      &.small {
        grid-template-columns: repeat(14, 0fr);
      }
    }

    .row.two {
      grid-template-columns: repeat(11, 0fr);

      &.small {
        grid-template-columns: repeat(13, 0fr);
      }
    }

    .row.three {
      grid-template-columns: repeat(6, 0fr);
    }
  }

  &.spacing {
    margin: 6em 0;

    .row {
      display: flex;
      margin-top: 8px;
      column-gap: 20px;
    }
  }
`;
