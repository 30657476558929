import { ActivityType, CharacterCategory, CharacterType } from '../../../data/types';
import successSolid from './VO10.mp3';
import successFaded from './VO15.mp3';
import successDotted from './VO17.mp3';

import speedyAndNeat from './VO23.mp3';
import greatJobLetters from './VO40-letters.mp3';
import greatJobNumbers from './VO41-numbers.mp3';
import greatJobSymbols from './VO42-symbols.mp3';

import lowerA from './VO25-LOWER_A.mp3';
import lowerB from './VO25-LOWER_B.mp3';
import lowerC from './VO25-LOWER_C.mp3';
import lowerD from './VO25-LOWER_D.mp3';
import lowerE from './VO25-LOWER_E.mp3';
import lowerF from './VO25-LOWER_F.mp3';
import lowerG from './VO25-LOWER_G.mp3';
import lowerH from './VO25-LOWER_H.mp3';
import lowerI from './VO25-LOWER_I.mp3';
import lowerJ from './VO25-LOWER_J.mp3';
import lowerK from './VO25-LOWER_K.mp3';
import lowerL from './VO25-LOWER_L.mp3';
import lowerM from './VO25-LOWER_M.mp3';
import lowerN from './VO25-LOWER_N.mp3';
import lowerO from './VO25-LOWER_O.mp3';
import lowerP from './VO25-LOWER_P.mp3';
import lowerQ from './VO25-LOWER_Q.mp3';
import lowerR from './VO25-LOWER_R.mp3';
import lowerS from './VO25-LOWER_S.mp3';
import lowerT from './VO25-LOWER_T.mp3';
import lowerU from './VO25-LOWER_U.mp3';
import lowerV from './VO25-LOWER_V.mp3';
import lowerW from './VO25-LOWER_W.mp3';
import lowerX from './VO25-LOWER_X.mp3';
import lowerY from './VO25-LOWER_Y.mp3';
import lowerZ from './VO25-LOWER_Z.mp3';

import number0 from './VO25-NUMBER_0.mp3';
import number1 from './VO25-NUMBER_1.mp3';
import number2 from './VO25-NUMBER_2.mp3';
import number3 from './VO25-NUMBER_3.mp3';
import number4 from './VO25-NUMBER_4.mp3';
import number5 from './VO25-NUMBER_5.mp3';
import number6 from './VO25-NUMBER_6.mp3';
import number7 from './VO25-NUMBER_7.mp3';
import number8 from './VO25-NUMBER_8.mp3';
import number9 from './VO25-NUMBER_9.mp3';

import upperA from './VO25-UPPER_A.mp3';
import upperB from './VO25-UPPER_B.mp3';
import upperC from './VO25-UPPER_C.mp3';
import upperD from './VO25-UPPER_D.mp3';
import upperE from './VO25-UPPER_E.mp3';
import upperF from './VO25-UPPER_F.mp3';
import upperG from './VO25-UPPER_G.mp3';
import upperH from './VO25-UPPER_H.mp3';
import upperI from './VO25-UPPER_I.mp3';
import upperJ from './VO25-UPPER_J.mp3';
import upperK from './VO25-UPPER_K.mp3';
import upperL from './VO25-UPPER_L.mp3';
import upperM from './VO25-UPPER_M.mp3';
import upperN from './VO25-UPPER_N.mp3';
import upperO from './VO25-UPPER_O.mp3';
import upperP from './VO25-UPPER_P.mp3';
import upperQ from './VO25-UPPER_Q.mp3';
import upperR from './VO25-UPPER_R.mp3';
import upperS from './VO25-UPPER_S.mp3';
import upperT from './VO25-UPPER_T.mp3';
import upperU from './VO25-UPPER_U.mp3';
import upperV from './VO25-UPPER_V.mp3';
import upperW from './VO25-UPPER_W.mp3';
import upperX from './VO25-UPPER_X.mp3';
import upperY from './VO25-UPPER_Y.mp3';
import upperZ from './VO25-UPPER_Z.mp3';

import symbolPlus from './VO25-SYMBOL_PLUS.mp3';
import symbolEqual from './VO25-SYMBOL_EQUAL.mp3';
import symbolQuestion from './VO25-SYMBOL_QUESTION.mp3';
import symbolExclamation from './VO25-SYMBOL_EXCLAMATION.mp3';

const activitySuccessCues = {
  [ActivityType.SOLID]: successSolid,
  [ActivityType.FADED]: successFaded,
  [ActivityType.DOTTED]: successDotted,
  [ActivityType.CHASE_STAR]: speedyAndNeat,
  [ActivityType.INDEPENDENT]: {
    [CharacterType.LOWER_A]: lowerA,
    [CharacterType.LOWER_B]: lowerB,
    [CharacterType.LOWER_C]: lowerC,
    [CharacterType.LOWER_D]: lowerD,
    [CharacterType.LOWER_E]: lowerE,
    [CharacterType.LOWER_F]: lowerF,
    [CharacterType.LOWER_G]: lowerG,
    [CharacterType.LOWER_H]: lowerH,
    [CharacterType.LOWER_I]: lowerI,
    [CharacterType.LOWER_J]: lowerJ,
    [CharacterType.LOWER_K]: lowerK,
    [CharacterType.LOWER_L]: lowerL,
    [CharacterType.LOWER_M]: lowerM,
    [CharacterType.LOWER_N]: lowerN,
    [CharacterType.LOWER_O]: lowerO,
    [CharacterType.LOWER_P]: lowerP,
    [CharacterType.LOWER_Q]: lowerQ,
    [CharacterType.LOWER_R]: lowerR,
    [CharacterType.LOWER_S]: lowerS,
    [CharacterType.LOWER_T]: lowerT,
    [CharacterType.LOWER_U]: lowerU,
    [CharacterType.LOWER_V]: lowerV,
    [CharacterType.LOWER_W]: lowerW,
    [CharacterType.LOWER_X]: lowerX,
    [CharacterType.LOWER_Y]: lowerY,
    [CharacterType.LOWER_Z]: lowerZ,

    [CharacterType.NUMBER_0]: number0,
    [CharacterType.NUMBER_1]: number1,
    [CharacterType.NUMBER_2]: number2,
    [CharacterType.NUMBER_3]: number3,
    [CharacterType.NUMBER_4]: number4,
    [CharacterType.NUMBER_5]: number5,
    [CharacterType.NUMBER_6]: number6,
    [CharacterType.NUMBER_7]: number7,
    [CharacterType.NUMBER_8]: number8,
    [CharacterType.NUMBER_9]: number9,

    [CharacterType.UPPER_A]: upperA,
    [CharacterType.UPPER_B]: upperB,
    [CharacterType.UPPER_C]: upperC,
    [CharacterType.UPPER_D]: upperD,
    [CharacterType.UPPER_E]: upperE,
    [CharacterType.UPPER_F]: upperF,
    [CharacterType.UPPER_G]: upperG,
    [CharacterType.UPPER_H]: upperH,
    [CharacterType.UPPER_I]: upperI,
    [CharacterType.UPPER_J]: upperJ,
    [CharacterType.UPPER_K]: upperK,
    [CharacterType.UPPER_L]: upperL,
    [CharacterType.UPPER_M]: upperM,
    [CharacterType.UPPER_N]: upperN,
    [CharacterType.UPPER_O]: upperO,
    [CharacterType.UPPER_P]: upperP,
    [CharacterType.UPPER_Q]: upperQ,
    [CharacterType.UPPER_R]: upperR,
    [CharacterType.UPPER_S]: upperS,
    [CharacterType.UPPER_T]: upperT,
    [CharacterType.UPPER_U]: upperU,
    [CharacterType.UPPER_V]: upperV,
    [CharacterType.UPPER_W]: upperW,
    [CharacterType.UPPER_X]: upperX,
    [CharacterType.UPPER_Y]: upperY,
    [CharacterType.UPPER_Z]: upperZ,

    [CharacterType.SYMBOL_PLUS]: symbolPlus,
    [CharacterType.SYMBOL_EQUAL]: symbolEqual,
    [CharacterType.SYMBOL_QUESTION]: symbolQuestion,
    [CharacterType.SYMBOL_EXCLAMATION]: symbolExclamation,
  },
  speedyAndNeat,
  greatJob: {
    [CharacterCategory.LETTER_LOWER]: greatJobLetters,
    [CharacterCategory.LETTER_UPPER]: greatJobLetters,
    [CharacterCategory.NUMBER]: greatJobNumbers,
    [CharacterCategory.SYMBOL]: greatJobSymbols,
  },
};

export default activitySuccessCues;
