import React, {
  createContext, useContext, useState, useMemo,
} from 'react';
import envSettings from '../data/envSettings';

// lowercase matters here to match to JS event touchType
export enum TouchInput {
  DIRECT = 'direct',
  STYLUS = 'stylus',
  ANY = 'any'
}

interface UserSettingsContextInterface {
  acceptedTouchInputType: TouchInput,
  setAcceptedTouchInputType: (touchType: TouchInput) => void,
}

const UserSettingsContext = createContext<UserSettingsContextInterface | null>(null);

type UserSettingsContextProviderProps = {
  children: React.ReactNode,
}

export default function UserSettingsContextProvider({ children }: UserSettingsContextProviderProps) {
  const [acceptedTouchInputType, _setAcceptedTouchInputType] = useState<TouchInput>(TouchInput.ANY);

  const setAcceptedTouchInputType = (touchType: TouchInput) => {
    if (envSettings?.enableStylusSettings) {
      _setAcceptedTouchInputType(touchType);
    }
  };

  const providerValue = useMemo(
    () => ({
      acceptedTouchInputType,
      setAcceptedTouchInputType,
    }),
    [acceptedTouchInputType],
  );

  return (
    <UserSettingsContext.Provider value={providerValue}>
      {children}
    </UserSettingsContext.Provider>
  );
}

export function useUserSettingsContext() {
  const context = useContext(UserSettingsContext);

  if (context === undefined) {
    throw new Error('useUserSettingsContext must be used within UserSettingsContextProvider');
  }

  return context;
}
