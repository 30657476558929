import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Stage, Layer, Text } from 'react-konva';
import colors from '../../styles/colors';
import { characters } from '../../data/characters/characters';
import { SpacingActivityType } from '../../data/types';
import { useSpacingGameContext } from '../../context/SpacingGameContext';
import { DraggableLetterWrapper } from '../../styles/components/DraggableLetter';
import arrowIcon from '../../assets/images/icons/plain-arrow.svg';
import { FlexColumn, FlexRowCenter } from '../../styles/components/FlexSpacers';
import {
  DragAndDropState,
  SpacingState,
  DragAndDropActionOutcome,
} from '../../stateMachines/spacingMachine/spacingMachine';
import { getLetterWidth } from '../../shared/dragAndDropHelpers';
import useKonvaGrading from '../../hooks/useKonvaGrading';

type DraggableLetterProps = {
  canDragAndDrop: boolean
  handleSpacingOutcome: (outcome: DragAndDropActionOutcome, dragAndDropValue: DragAndDropState) => void
}

const hookCharacters = ['g', 'j', 'p', 'q', 'y'];

export function DraggableLetter({ canDragAndDrop, handleSpacingOutcome }: DraggableLetterProps) {
  const { state } = useSpacingGameContext();
  const { dragAndDrop, spacingErrorCount, currentActivity } = state.context;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [outcome, setOutcome] = useState<DragAndDropActionOutcome | undefined>(undefined);
  const [doReset, setDoReset] = useState<boolean>(false);

  const { setDrop, calculateDragAndDropOutcome } = useKonvaGrading(windowSize);

  const [dragAndDropState, setDragAndDropState] = useState(dragAndDrop);

  const defaultLetterPosition = useMemo(() => {
    const currentCharacter = state.context.currentItem
      ? characters[state.context.currentItem.traceCharacterType].display
      : '';

    if (wrapperRef.current) {
      const letterContainerRect = wrapperRef.current.getBoundingClientRect();
      const letterWidth = getLetterWidth(currentCharacter);

      return {
        // x coordinate will make the letter be centered in its container.
        x: letterContainerRect.left + (letterContainerRect.width - letterWidth) / 2,
        y: hookCharacters.includes(currentCharacter)
          ? letterContainerRect.top + letterContainerRect.height * 0.25
          : letterContainerRect.top + letterContainerRect.height * 0.35,
      };
    }
    return { x: 0, y: 0 };
  }, [wrapperRef.current, windowSize.width, windowSize.height, doReset]);

  const dropStagePosition = useMemo(() => {
    const stage = document.getElementById('SpacingDropAreaStage')?.firstElementChild;
    if (stage) {
      const stageRect = stage.getBoundingClientRect();

      return {
        left: stageRect.left,
        right: stageRect.right,
        top: stageRect.top,
        bottom: stageRect.bottom,
        bottomLine: stageRect.y + 37,
      };
    }
    return {
      left: 0, right: 0, top: 0, bottom: 0, bottomLine: 0,
    };
  }, [wrapperRef.current, windowSize.width, windowSize.height, doReset]);

  useEffect(() => {
    setDragAndDropState({
      ...dragAndDropState,
      x: defaultLetterPosition.x,
      y: defaultLetterPosition.y,
    });
  }, [defaultLetterPosition]);

  useEffect(() => {
    /*
    Return the letter to the original position after an unseccessful attemtp and clicking on next button
    */
    if (state.event.type === 'NEXT') {
      setDragAndDropState({
        ...dragAndDropState,
        x: defaultLetterPosition.x,
        y: defaultLetterPosition.y,
      });
      setOutcome(undefined);
    }

    /*
    Set reset state for the letter placement and the drop area on continuing to the DRAG_AND_DROP_INDEPENDENT activity
    */
    if (
      state.matches(SpacingState.CUEING_ACTION)
      && spacingErrorCount === 0
      && currentActivity?.type === SpacingActivityType.DRAG_AND_DROP_INDEPENDENT
    ) {
      setDoReset(true);
    }
  }, [state.event]);

  useEffect(() => {
    // Adding an event listener so the initialLetterPosition can be redefined
    // in the case of a window resize (not-likely to happen on a full screen game-like website)
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDrop = (data: DragAndDropState) => {
    setDrop(data);

    const currentCharacter = state.context.currentItem
      ? characters[state.context.currentItem.traceCharacterType].display
      : '';

    const wordModel = document.getElementById('drag-and-drop-word-model');
    const wordModelRect = wordModel?.getBoundingClientRect();
    const letterWidth = getLetterWidth(currentCharacter);

    const dragAndDropOutcome = calculateDragAndDropOutcome(data, letterWidth, wordModelRect, currentCharacter);
    setOutcome(dragAndDropOutcome);

    return dragAndDropOutcome;
  };

  const handleLetterColor = () => {
    let color;

    // [SW-1024] TODO: Logic improvement needed here
    if (dragAndDropState.isDragging) {
      color = colors.penOrange;
    } else if (canDragAndDrop) {
      color = colors.vividDarkBlue;
    } else if (!canDragAndDrop && !outcome) {
      color = colors.paleBlue;
    } else if (
      !canDragAndDrop && (outcome && [DragAndDropActionOutcome.TOO_CLOSE, DragAndDropActionOutcome.TOO_WIDE].includes(
        outcome,
      ))
    ) {
      color = colors.penOrange;
    } else if (!canDragAndDrop && outcome === DragAndDropActionOutcome.CORRECT) {
      color = colors.vividDarkBlue;
    }

    return color;
  };

  return (
    <FlexColumn>
      <DraggableLetterWrapper ref={wrapperRef} id="DraggableLetterWrapperComponent">
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ position: 'fixed', top: 0, left: 0 }}
          onTouchStart={() => {
            if (canDragAndDrop) {
              setDragAndDropState({
                ...dragAndDropState,
                isDragging: true,
              });
            }
          }}
          onTouchMove={(e) => {
            const pos = e.target.getStage()?.getRelativePointerPosition();
            if (canDragAndDrop) {
              setDragAndDropState({
                isDragging: true,
                x: pos?.x ? pos.x - 30 : 975,
                y: pos?.y ? pos.y - 65 : 420,
              });
            }
          }}
          onTouchEnd={() => {
            if (canDragAndDrop) {
              /*
                If the user drops the letter outside of the drop area, it goes back to the original position
                and no grading action is done.
              */
              if (
                (dragAndDropState.x < dropStagePosition.left
                || dragAndDropState.x > dropStagePosition.right)
                || (dragAndDropState.y < dropStagePosition.top || dragAndDropState.y > dropStagePosition.bottom)
              ) {
                // [SW-1024] TODO: Not sure if we need to grade the drop outside drop area
                // const dragAndDropValue = {
                //   ...defaultLetterPosition,
                //   isDragging: false,
                // };
                // handleSpacingOutcome(handleDrop(dragAndDropValue), dragAndDropValue);
                setDragAndDropState({
                  ...defaultLetterPosition,
                  isDragging: false,
                });
              } else {
                const dragAndDropValue = {
                  ...dragAndDropState,
                  y: dropStagePosition.bottomLine,
                  isDragging: false,
                };
                setDragAndDropState(dragAndDropValue);
                handleSpacingOutcome(handleDrop(dragAndDropValue), dragAndDropValue);
              }
            }
          }}
        >
          <Layer draggable={false}>
            <Text
              text={
                state.context.currentItem ? characters[state.context.currentItem.traceCharacterType].display : ''
              }
              fontFamily="StarWriter"
              fontSize={116}
              fontStyle="300"
              wrap="char"
              x={dragAndDropState.x}
              y={dragAndDropState.y}
              fill={handleLetterColor()}
              draggable={false}
            />
          </Layer>
        </Stage>
      </DraggableLetterWrapper>
      <FlexRowCenter style={{ columnGap: '8px', marginTop: '8px' }}>
        <img
          alt=""
          style={{ width: '16px' }}
          src={arrowIcon}
        />
        <img
          alt=""
          style={{ width: '16px', rotate: '180deg' }}
          src={arrowIcon}
        />
      </FlexRowCenter>
    </FlexColumn>
  );
}
