const colors: { [key: string]: string } = {
  paleBlue: '#42acef66',
  lightBlue: '#CFF3FF',
  darkLightBlue: '#B4C7D9',
  blue: '#87E1FE',
  darkBlue: '#00519B',
  darkModerateBlue: '#4374A0',
  veryDarkBlue: '#180D49',
  brightBlue: '#42ACEF',
  softBlue: '#99b9d7',
  verySoftBlue: '#B3DEF9',
  navyBlue: '#1F3E5A',
  blueGray: '#355C80',
  lightCyan: '#DEFBFF',
  cyan: '#5ED1BD',
  darkCyan: '#147A88',
  gray100: '#4C4C4C',
  gray200: '#202020',
  gray300: '#3D3D3D',
  gray400: '#D9D9D9',
  paleYellow: '#FFF4BE',
  lightYellow: '#FFE9AF',
  yellow: '#FFE45C',
  whiteOrange: '#FFFBF0',
  dullOrange: '#e5c299',
  lightOrange: '#FFC93F',
  orange: '#FEC736',
  mediumOrange: '#FFBB0B',
  darkOrange: '#BD6600',
  darkBrownOrange: '#71250A',
  vividOrange: '#FFB82F',
  lightBrown: '#884900',
  brown: '#61492C',
  white: '#FFFFFF',
  black: '#000000',
  vividDarkBlue: '#1023DB',
  purple: '#4503B0',
  vividPurple: '#9A5DFC',
  palePurple: '#DCC4F7',
  darkPurple: '#502D7E',
  veryDarkPurple: '#281B5D',
  brightMagenta: '#AD03B0',
  magenta: '#B00487',
  mediumPink: '#E80041',
  lightPink: '#EEB5C6',
  red: '#EE2B00',
  penOrange: '#FF630B',
  teal: '#00B876',
  vividLightBlue: '#1CF1FF',
  vividMediumBlue: '#2E29FF',
  darkMagenta: '#D000D5',
  darkerBlue: '#2400FF',
  green: '#00B876',
  limeGreen: '#DDFF0B',
  mediumRed: '#F50000',
  mediumYellow: '#FFD029',
  vividGreen: '#2FA136',
  lightGreen: '#AAE9AD',

};

export default colors;
