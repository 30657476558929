import React from 'react';
import Aa from '../../assets/images/icons/activitySetSelectionDraftIcons/Aa.svg';
import Ellipse from '../../assets/images/icons/activitySetSelectionDraftIcons/Ellipse.svg';
import Line from '../../assets/images/icons/activitySetSelectionDraftIcons/Line.svg';
import Polygon from '../../assets/images/icons/activitySetSelectionDraftIcons/Polygon.svg';
import { ActivitySetButton } from '../../styles/components/Buttons';
import { ActivitySetType } from '../../data/types/writing/activitySet';
import colors from '../../styles/colors';

type ActivitySetSelectionButtonProps = {
  isDisabled: boolean,
  buttonTouch: () => void,
  activitySetType: string
}

export default function ActivitySetSelectionButton({
  isDisabled,
  buttonTouch,
  activitySetType,
}: ActivitySetSelectionButtonProps) {
  const handleColoring = () => {
    if (isDisabled) return colors.lightBlue;
    if (activitySetType === ActivitySetType.PRE_WRITING) return colors.blue;
    if (activitySetType === ActivitySetType.WRITING) return colors.orange;
    return colors.blue;
  };

  const handleBorderColoring = () => {
    if (isDisabled) return colors.paleBlue;
    if (activitySetType === ActivitySetType.PRE_WRITING) return colors.darkModerateBlue;
    if (activitySetType === ActivitySetType.WRITING) return colors.darkOrange;
    return colors.darkModerateBlue;
  };

  return (
    <ActivitySetButton
      disabled={isDisabled}
      width="292px"
      height="292px"
      aria-disabled={isDisabled}
      color={handleColoring()}
      aria-label="go to next activity"
      onTouchStart={buttonTouch}
      borderColor={handleBorderColoring()}
      role="link"
      isCircle={activitySetType === ActivitySetType.PRE_WRITING}
    >
      {/* TODO : Ask the design team to condense the ellipse, line and polygon svgs into just one
      to refactor the following code. */}
      {activitySetType === ActivitySetType.PRE_WRITING ? (
        <div>
          <img alt="" className="pre-writing-icons" src={Ellipse} />
          <img alt="" className="pre-writing-icons" src={Line} />
          <img alt="" className="pre-writing-icons" src={Polygon} />
        </div>
      ) : (
        <img alt="" src={Aa} />
      )}
    </ActivitySetButton>
  );
}
