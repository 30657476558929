import React from 'react';
import { SpacingActivityType } from '../../../../data/types';
import planet1 from '../../../../assets/lotties/planet_1.json';
import planet2 from '../../../../assets/lotties/planet_2.json';
import LottiePlayer from '../../../LottiePlayer';

type PlanetLottieProps = {
  planetType: SpacingActivityType|null,
  shouldAnimate: boolean,
}

export default function PlanetLottie({ planetType, shouldAnimate }: PlanetLottieProps) {
  const getPlanetLottie = (currentActivityType: SpacingActivityType | null) => {
    switch (currentActivityType) {
      case SpacingActivityType.PLANET_2_GAME:
        return planet2;
      case SpacingActivityType.PLANET_1_GAME:
      default:
        return planet1;
    }
  };
  return (
    <LottiePlayer
      data={getPlanetLottie(planetType)}
      width={450}
      segmentFrames={shouldAnimate ? [250, 300] : [0, 1]}
      shouldLoop
    />
  );
}
