import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div>
      <h1>Illuminate Literacy: Privacy Policy</h1>
      <p><i>Last Updated: April 18, 2023</i></p>

      <p>
        Illuminate Literacy is committed to protecting your privacy. This Privacy Policy
        explains the types of information we collect when you use our services, located at
        {' '}
        <a href="https://starwriter.org/">https://starwriter.org/</a>
        {' '}
        . It also explains how we will use, disclose, and protect your information. By visiting our website and
        using our programs, you agree to the terms of this Privacy Policy. If you do not agree to the terms of this
        Privacy Policy, please do not use our website or programs.
      </p>
      <p>
        By using our website or services, you represent and warrant that you are authorized to provide such information.
      </p>
      <ol>
        <li>
          <h2>Who is Illuminate Literacy?</h2>
          <ol>
            <li>
              Illuminate Literacy (&ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) is an early stage
              nonprofit focused, in partnership with our fiscal sponsor Cambiar Education
              and our sister R&amp;D organization AERDF Reading Reimagined, on unlocking the great reader in every
              child to end illiteracy in our country, centering the experiences of Black, Latino, and Native American
              children and children living in poverty.
            </li>
          </ol>
        </li>
        <li>
          <h2>What is covered by this policy?</h2>
          <ol>
            <li>
              We currently operate the following website:
              http://starwriter.org/ (&ldquo;Site&rdquo;) as well as ancillary services and applications (collectively,
              &ldquo;Services&rdquo;). This Privacy Policy applies to all content on the Site and the Services we offer.
            </li>
            <li>
              From time to time, we may add new programs or Services
              that may not be listed above, but they will provide a link to this Privacy Policy and will be governed by
              its terms.
            </li>
          </ol>
        </li>
        <li>
          <h2>Information Collected by Illuminate Literacy</h2>
          <ol>
            <li>
              Illuminate Literacy does not collect any personal information
              from you unless you provide it to us in one of the ways discussed in this Privacy Policy.
            </li>
            <li>
              Information You Provide
              <ol>
                <li>
                  The prototype version of our Starwriter handwriting program (used in SY22-23) will not
                  collect any personally identifiable information from students, parents or teacher users.
                  There will be no individual user logins, and at no point will student, parent or teacher users have an
                  opportunity to input any personally identifiable information. The prototype will collect only
                  activity-level data (e.g., number of sessions, session duration, device IP addresses, instructional
                  videos viewed, letters/numbers practiced). We can collect (and share) data at the device level,
                  but will not have the ability to know which users used a particular device.
                </li>
              </ol>
            </li>
            <li>
              Information Collected Automatically
              <ol>
                <li>
                  When a user uses our Site, we collect certain information, such as the number of sessions a user
                  attempted, the responses users provide to questions asked, the
                  duration of the sessions, and the IP address of the device the user used to complete the session.
                </li>
                <li>
                  When using our Site, our servers automatically collect &ldquo;cookies.&rdquo; A cookie is a piece of
                  data that is sent to your browser, which will store the cookie on your computer if your browser is
                  enabled to accept cookies. Cookies allow us to recognize your browser when you return and to remember
                  your preferences. You may block or reject cookies, but if you do, you may not be able to use our
                  Services.
                </li>
                <li>
                  Cookies, or site usage information, are non-personal and include browser type, operating system, IP
                  address, the domain name from which you accessed our Site, and possibly the date and time you accessed
                  our Site, how much time you spent on our Site, and other &ldquo;click-stream&rdquo; data.
                </li>
                <li>
                  We do not track our users across third-party websites.
                  Third-party websites are not able to collect personally identifiable information about your
                  activity on our Site.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>How We Use Your Information</h2>
          <ol>
            <li>
              Personal information that we collect for a particular purpose
              will be saved and used only for that purpose unless you have agreed to allow us to use it for some other
              purpose. Except as discussed in this Privacy Policy, we will not sell, rent, or otherwise use your
              personal information for a commercial purpose unless approved by you.
            </li>
            <li>
              Information You Provide
              <ol>
                <li>
                  We may use the information that you provide when you
                  register for our Services to create your account.
                </li>
                <li>
                  We may use your email address to send you information about your account. We may also use your email
                  address to send you other communications relating to Illuminate
                  Literacy and our Services. You may unsubscribe at any time.
                </li>
                <li>
                  We may use the information we collect from you for the
                  purpose of providing you with technical support, customer service, and account maintenance.
                </li>
                <li>
                  When users provide feedback or report a concern, we use their information to respond to their request
                  or fix the issue.
                </li>
                <li>
                  You are in control of the personal information that you
                  provide to us. You can review, revise, or delete your personal information as described below.
                </li>
                <li>
                  We use users&rsquo; responses to help us understand and
                  improve the effectiveness of our Services.
                </li>
                <li>
                  Non-personally identifiable information, such as grade level and other demographic information,
                  may be collected and used in aggregated, anonymized reports to help us better understand
                  how our Services are used and how to improve the Services.
                </li>
                <li>
                  We do not use your personal information to engage in
                  targeted advertising on our Site.
                </li>
              </ol>
            </li>
            <li>
              Information Collected Automatically
              <ol>
                <li>
                  We may use information about the use of our Site or Services to facilitate sequential and progressive
                  lessons to users.
                </li>
                <li>
                  We may use aggregated, anonymized program information to
                  improve and demonstrate the efficacy of our Site and Services.
                </li>
                <li>
                  We may use Site use information to test new applications or services. This may mean that we partner
                  with third-party researchers or application developers. We will not share your personal information
                  with such researchers unless provided for under this Privacy Policy and our Terms of Use.
                </li>
                <li>
                  We use Site usage information to administer our Site and
                  Services, including to monitor and diagnose any problems and to improve the Services.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>How We May Share Your Information</h2>
          <ol>
            <li>
              We do not ever sell or rent your personal information.
            </li>
            <li>
              When you have provided personal information to us for a
              particular purpose, we may disclose your information to other companies that we have engaged with to
              perform business-related functions for us. This may include database management companies, software
              engineers, and hosting services. When we employ another company to provide services to support us, we
              provide them only with the information that they need to perform their specific function. Our partners
              and third-party providers are required to maintain reasonable security practices, to use information only
              for our stated purpose, to prevent disclosure
              of the information to unauthorized parties, and to abide by applicable laws and regulations.
            </li>
            <li>
              We may share aggregated, de-identified information with
              partners or third-party providers to help us analyze that information.
            </li>
            <li>
              We may disclose your personal information if we have a good faith belief that doing so is necessary to
              comply with the law, such as complying with a subpoena or other legal process. We may need to disclose
              personal information where, in good faith, we think it is necessary to protect the rights, property,
              or safety of Illuminate Literacy, our employees, our community, or others, or to prevent violations
              of our Terms of Use or other agreements. This includes, without limitation, exchanging information
              with other companies and organizations for fraud protection or responding to law enforcement
              and government requests. Where appropriate, we may notify users about the legal requests,
              unless (i) providing notice is prohibited by the legal process itself, by court order we receive,
              or by applicable law; (ii) we believe that providing notice would be futile, ineffective, create a
              risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon
              Illuminate Literacy, or its users. In instances where we comply with legal requests without notice
              for these reasons, we will attempt to notify that user about the request after the fact where
              appropriate and where we determine in good faith that we are no longer prevented from doing so.
            </li>
            <li>
              In the event that all or a portion of Illuminate Literacy or its assets are acquired by or merged with
              a third-party, personal information that we have collected from users would be one of the assets
              transferred to or acquired by that third-party. This Privacy Policy will continue to apply to your
              information, and any acquirer would only be able to handle your personal information as per this policy
              (unless you give consent to a new policy). We will provide you with notice of an acquisition within thirty
              (30) days following the completion of such a transaction, by posting on our homepage, or by email to your
              email address that you provided to us. If you do not consent to the use of your personal information by
              such a successor organization, you may request its deletion.
            </li>
          </ol>
        </li>
        <li>
          <h2>Your Rights</h2>
          <ol>
            <li>
              The Site and Services do not collect personally identifiable information relating to children. If you are
              a parent or legal guardian of a minor child and have questions about the information collected about your
              child, contact
              {' '}
              <a href="mailto:Illuminate@cambiareducation.org">
                Illuminate@cambiareducation.org
              </a>
              .
            </li>
            <li>
              We comply with the Family Educational Rights and Privacy Act, the Children&rsquo;s Online Privacy
              Protection Act, and all other applicable laws and regulations concerning
              children and the internet and the protection of students&rsquo; personally identifiable information.
            </li>
          </ol>
        </li>
        <li>
          <h2>Data Retention</h2>
          <ol>
            <li>
              We store, retain, and delete personal information as required
              by applicable laws and regulations.
            </li>
            <li>
              If you would like to modify any personal information you have
              provided to us, or have us no longer use this information, please contact
              {' '}
              <a href="mailto:Illuminate@cambiareducation.org">
                Illuminate@cambiareducation.org
              </a>
              .
            </li>
            <li>
              We retain aggregated, anonymized information for some of the
              purposes described above.
            </li>
          </ol>
        </li>
        <li>
          <h2>Security</h2>
          <ol>
            <li>
              We take the protection of personal information seriously. We use commercially reasonable information
              security safeguards to protect our Site and Services against risks of loss, unauthorized access,
              destruction, misuse, modification, or inadvertent or improper disclosure of data.
            </li>
            <p>
              In circumstances where personal data is collected, we restrict access to personal data to authorized
              Illuminate Literacy administrators, agents or independent contractors who need to know that information
              in order to process it for us, and who are subject to strict confidentiality obligations and may be
              disciplined or terminated if they fail to meet these obligations.
            </p>
            <li>
              While we take precautions against possible data breaches, no web site or internet transmission is
              completely secure. We cannot guarantee that unauthorized access, hacking,
              data loss, or other breaches will never occur. You use our Site and our Services at your own risk.
            </li>
            <li>
              Our Site and Services do not have the capability to respond to &ldquo;Do Not Track&ldquo; signals
              received from various web browsers.
            </li>
          </ol>
        </li>
        <li>
          <h2>Other Agreements</h2>
          <ol>
            <li>
              In addition to this Privacy Policy, your rights and obligations concerning our Site and Services are
              governed by the Terms of Use and other applicable policies, guidelines, and requirements. When you
              use our Site or Services, please review each agreement carefully, as you must agree to be bound by each
              such agreement before you may use the Site or Services.
            </li>
          </ol>

        </li>
        <li>
          <h2>Notice of Changes to Policy</h2>
          <ol>
            <li>
              We reserve the right to change this Privacy Policy at any time. Changes become effective when posted.
              When we do, we will notify you by updating the date of this Privacy Policy and posting it on this page
              of our website.
            </li>
          </ol>
        </li>
        <li>
          <h2>Contact Us</h2>
          If you have any questions about this Privacy Policy, please
          contact us:
          {' '}
          <a href="mailto:Illuminate@cambiareducation.org">
            Illuminate@cambiareducation.org
          </a>
        </li>
      </ol>
    </div>
  );
}
