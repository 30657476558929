import React, {
  lazy,
  Suspense,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { StarGameAreaWrapper } from '../styles/components/ContentWrappers';
import { AudioCue } from '../components/AudioPlayer';
import LottiePlayer from '../components/LottiePlayer';
import NextButton from '../components/Buttons/NextButton';
import starTimer from '../assets/lotties/timer.json';
import animatedStar from '../assets/lotties/animated-star.json';
import dashedSquiggles1 from '../assets/images/backgrounds/dashed-squiggles-1.svg';
import dashedSquiggles2 from '../assets/images/backgrounds/dashed-squiggles-2.svg';
import StarGameSuccess from '../components/StarGameSuccess';
import { StarGameContainer } from '../styles/components/ScreenContainers';
import gameInstructionsForLetter from '../assets/audio/rewards/VO29-letters.mp3';
import gameInstructionsForNumber from '../assets/audio/rewards/VO29-numbers.mp3';
import gameInstructionsForSymbol from '../assets/audio/rewards/VO29-symbols.mp3';
import gameGetStarted from '../assets/audio/rewards/VO30.mp3';
import phonicsSongs from '../assets/audio/phonicsSongs';
import {
  ActivityType, CharacterCategory, SequenceCategory,
} from '../data/types';
import successMessage from '../assets/audio/rewards/VO31.mp3';
import moveOn from '../assets/audio/tapArrowActions/VO3.mp3';
import newPen from '../assets/audio/rewards/VO28.mp3';
import { AnalyticsEventCategory } from '../data/types/analytics';
import { useAudioContext } from '../context/AudioContext';
import { ActionType, useGameContext } from '../context/WritingContext/GameContext';
import {
  useActivityProgressContext,
  ActionType as ProgressActionType,
} from '../context/ActivityProgressContext/ActivityProgressContext';
import ActivityRewardsAndNavigation from '../components/ActivityRewardsAndNavigation';
import { RewardEventTypes, RewardState } from '../stateMachines/rewardsMachine/rewardsMachine';
import { useRewardsContext } from '../context/RewardsContext';
import starLoader from '../assets/lotties/loader-star.json';
import starGameComponents from '../components/SVG/StarGame';

enum GameState {
  INTRO = 'INTRO',
  PLAY = 'PLAY',
  END_SUCCESS = 'END_SUCCESS',
  END_FAIL = 'END_FAIL',
}

type AnimatedStar = {
  id: string,
  top: number,
  left: number,
  show: boolean,
}

export default function CharacterStarGameActivity() {
  const { trackEvent } = useMatomo();
  const [gameState, setGameState] = useState<GameState>(GameState.INTRO);
  const [shouldStartTimer, setShouldStartTimer] = useState(false);
  const [shouldGoToNextActivity, setShouldGoToNextActivity] = useState(false);
  const [animatedStarDictionary, setAnimatedStarDictionary] = useState<{ [id: string]: AnimatedStar }>({});
  const [starElements, setStarElements] = useState<Element[]>([]);
  const [activeAudioCue, setActiveAudioCue] = useState<AudioCue|null>(null);
  const svgRef = useRef(null);
  let onFirstRender = true;
  const audioContext = useAudioContext();
  const ctx = useGameContext();
  const rewards = useRewardsContext();
  const progress = useActivityProgressContext();
  const previousActivity = useMemo(() => {
    if (!progress || !progress?.redirectedFrom) return null;
    return progress.activities?.[progress.redirectedFrom as ActivityType];
  }, [progress, progress?.redirectedFrom]);

  const CharacterComponent = useMemo(() => {
    if (!ctx || !ctx.currentCharacter) return null;
    const characterComponentName = starGameComponents[ctx.currentCharacter.type];
    return lazy(() => import(`../components/SVG/StarGame/${characterComponentName}`));
  }, [ctx?.currentCharacter?.type]);

  const handleAnalytics = (): void => {
    if (!ctx || !ctx.currentSequence) return;
    if (ctx.currentSequence.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS
        && ctx.currentSequence?.characters.length === 0
        && !ctx.hasCompletedSequence) {
      trackEvent({
        category: AnalyticsEventCategory.SET,
        action: 'Completed',
        name: `${ctx.currentSequence.display}`,
      });
    }
  };

  const getGameInstructionForCharacter = (characterCategory: CharacterCategory) => {
    switch (characterCategory) {
      case CharacterCategory.NUMBER:
        return gameInstructionsForNumber;
      case CharacterCategory.SYMBOL:
        return gameInstructionsForSymbol;
      default:
        return gameInstructionsForLetter;
    }
  };

  useEffect(() => {
    if (activeAudioCue) {
      audioContext?.handlePlay(activeAudioCue);
    }
    return () => audioContext?.handleComplete();
  }, [activeAudioCue]);

  useEffect(() => {
    if (!ctx || !ctx.currentCharacter) return;
    setActiveAudioCue({
      src: getGameInstructionForCharacter(ctx.currentCharacter.category),
      onEnd: () => setActiveAudioCue({ src: gameGetStarted }),
    });
  }, [ctx?.currentCharacter]);

  useEffect(() => {
    // TO DO: StarGame is mounting 2x from DrawingActivity which is causing this
    // method to be called twice (it should only be called once
    // this happens on other components too so it's not just this).
    // Temporarily added onFirstRender boolean as a way to stop the code from running multiple times
    // and messing up state.
    if (svgRef && svgRef.current && onFirstRender) {
      onFirstRender = false;
      const svg: Element = svgRef.current;
      // Imported Svg needs to have all <g> elements set with `class="star"` for this to work
      // instructions are located in src/assets/images/star-game/index.tsx
      svg.querySelectorAll('.star').forEach((star, index) => {
        // For each found star (<g> element) in the svg,
        // 1) Add it to the animated star map to track locations of animated stars and if they're showing
        // 2) Add an id
        // 3) add an event listener so we can respond when a student touches a star
        setStarElements((arr) => [...arr, star]);
        const id = `animated-star-${index + 1}`;
        setAnimatedStarDictionary((dictionary) => ({
          ...dictionary,
          [id]: {
            id: `animated-star-${index + 1}`,
            top: star.getBoundingClientRect().top,
            left: star.getBoundingClientRect().left,
            show: false,
          },
        }));
        star.setAttribute('id', id);
      });
    }
  }, [svgRef?.current]);

  const hasTouchedAllStars = () => {
    const touchedStars = Object.keys(animatedStarDictionary).filter((starId) => animatedStarDictionary[starId].show);
    return touchedStars.length === starElements.length;
  };

  const showSuccessScreen = () => {
    // set game state as a success
    // reward a pen if applicable
    // and allow student to go to the next activity
    setGameState(GameState.END_SUCCESS);
    if (!ctx) return;
    if (ctx.currentSequence?.category === SequenceCategory.PRACTICE || ctx.hasCompletedSequence) {
      rewards.send({
        type: RewardEventTypes.REWARD_PEN,
        payload: {
          itemType: ctx?.currentCharacter?.type,
          activityType: ctx?.currentActivity?.type,
        },
      });
    }

    if (!ctx.currentCharacter) return;
    trackEvent({
      category: AnalyticsEventCategory.STAR_GAME,
      action: 'Won',
      name: ctx.currentCharacter.type,
    });
  };

  const handleStart = () => {
    setGameState(GameState.PLAY);
    if (!ctx || !ctx.currentCharacter) return;
    setActiveAudioCue({ src: phonicsSongs[ctx.currentCharacter.type] || null });
  };

  const handleEarlyEnd = () => {
    if (Object.keys(animatedStarDictionary).length === 0) return;
    // If student has touched all stars before end of timer, allow them to move on early
    if (hasTouchedAllStars()) {
      showSuccessScreen();
    }
  };

  const handleEnd = () => {
    if (!ctx || !ctx.currentSequence) return;

    if (GameState.END_SUCCESS) {
      setShouldGoToNextActivity(false);
    }

    ctx.dispatch({ type: ActionType.COMPLETE_ACTIVITY_LEVEL });
    progress?.dispatch({ type: ProgressActionType.RESET_ALL_PROGRESS });
    rewards.send(RewardEventTypes.NEXT);
    handleAnalytics();
  };

  const handleTimerFinished = () => {
    if (hasTouchedAllStars()) {
      // If student successfully touched all the stars,
      // show them the reward animation,
      showSuccessScreen();
    } else {
      if (ctx && ctx.currentCharacter) {
        trackEvent({
          category: AnalyticsEventCategory.STAR_GAME,
          action: 'Lost',
          name: ctx.currentCharacter.type,
        });
      }
      // If student did not successfully touch all the stars, show the next button to go
      // to next activity.
      setGameState(GameState.END_FAIL);
      setShouldGoToNextActivity(true);
    }
  };

  const handleStarTouch = (evt: any) => {
    // must use evt.currentTarget and not evt.target because we want the grouped path elements
    // (<g class="star">....</g>) not the individual path elements
    if (evt && evt.currentTarget) {
      // find touched star in animatedStarDictionary and set show = true
      const starId = evt.currentTarget.getAttribute('id');
      setAnimatedStarDictionary((dictionary) => ({
        ...dictionary,
        [starId]: { ...dictionary[starId], show: true },
      }));
      // hide the touched star, and remove its listener
      evt.currentTarget.classList.add('hide');
      evt.currentTarget.removeEventListener('touchstart', handleStarTouch);
    }
  };

  useEffect(() => {
    if (gameState !== GameState.END_SUCCESS) return;

    setActiveAudioCue({
      src: successMessage,
      onEnd: () => {
        setShouldGoToNextActivity(true);
        if (rewards.state.matches(RewardState.REWARDING_PEN)) {
          setActiveAudioCue({ src: newPen, onEnd: () => setActiveAudioCue({ src: moveOn }) });
        } else {
          setActiveAudioCue({ src: moveOn });
        }
      },
    });
  }, [gameState, rewards.state.value]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (gameState === GameState.PLAY && starElements.length > 0) {
      setShouldStartTimer(true);
      // Wait until starting game to add touch events so student cannot tap beforehand.
      starElements.forEach((star) => {
        star.addEventListener('touchstart', handleStarTouch);
      });
      return () => {
        starElements.forEach((star) => {
          star.removeEventListener('touchstart', handleStarTouch);
        });
      };
    }
  }, [gameState]);

  if (!ctx || !ctx.currentCharacter) return null;

  if (gameState === GameState.END_SUCCESS) {
    return (
      <StarGameSuccess character={ctx.currentCharacter} showNavigation={shouldGoToNextActivity}>
        {rewards.state.matches(RewardState.REWARDING_PEN)
          ? (
            <ActivityRewardsAndNavigation
              starMeterFrames={previousActivity?.starMeterFrames || [0, 1]}
              hasNewPen
              showNextButton
              onNextButtonTouch={handleEnd}
              canChangePenColor
            />
          )
          : (
            <div className="next-activity-navigation">
              <NextButton isDisabled={false} buttonTouch={handleEnd} />
            </div>
          )}
      </StarGameSuccess>
    );
  }

  return (
    <StarGameContainer onTouchEnd={() => handleEarlyEnd()}>
      <h1 className="visually-hidden">
        Play the star game with
        {' '}
        {ctx.currentCharacter.display}
      </h1>
      {Object.keys(animatedStarDictionary).map((starId: string) => {
        // Needs to be placed as a child of StarGameContainer because the getBoundingClientRect() method
        // used to obtain star position provides coordinates relative to the viewport
        const star = animatedStarDictionary[starId];
        return (
          <div
            key={star.id}
            className="animated-star-container"
            style={{ top: star.top, left: star.left, visibility: star.show ? 'visible' : 'hidden' }}
          >
            <LottiePlayer
              data={animatedStar}
              width={50}
              handleComplete={() => {}}
              shouldAutoplay
              shouldLoop
            />
          </div>
        );
      })}
      <StarGameAreaWrapper>
        <img className="squiggles top" src={dashedSquiggles1} alt="" />
        {ctx.currentCharacter.type ? (
          <Suspense fallback={(
            <div className="loading-container">
              <LottiePlayer
                data={starLoader}
                width={290}
                shouldAutoplay
                shouldLoop
              />
            </div>
          )}
          >
            <div className="star-game" ref={svgRef}>
              {CharacterComponent && <CharacterComponent />}
            </div>
          </Suspense>
        ) : null}
        <img className="squiggles bottom" src={dashedSquiggles2} alt="" />
        {(!shouldStartTimer && svgRef?.current) && <NextButton isDisabled={false} buttonTouch={handleStart} />}
        {shouldGoToNextActivity && <NextButton isDisabled={false} buttonTouch={handleEnd} />}
      </StarGameAreaWrapper>
      <div className="star-timer">
        {shouldStartTimer && (
          <LottiePlayer
            data={starTimer}
            width={240}
            handleComplete={handleTimerFinished}
            shouldAutoplay
            speed={3.1}
          />
        )}
      </div>
    </StarGameContainer>
  );
}
