import React from 'react';
import { HexagonSvgColors } from '../../styles/styled';
import { HexagonButtonSizes } from './types';

interface HexagonWithBorderSvgProps {
  colors: HexagonSvgColors,
  size?: HexagonButtonSizes,
}

export default function HexagonWithBorderSvg({ colors, size }: HexagonWithBorderSvgProps) {
  switch (size) {
    case HexagonButtonSizes.SM:
      return (
        <svg width="58" height="65" viewBox="0 0 58 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M33.25 1.87639C30.6201 0.35801 27.3799 0.35801 24.75 1.87639L4.75 13.4234C2.12009 
              14.9418 0.5 17.7479 0.5 20.7846V43.8786C0.5 46.9154 2.12009 49.7215 4.75 51.2398L24.75 
              62.7868C27.3799 64.3052 30.6201 64.3052 33.25 62.7868L53.25 51.2398C55.8799 49.7215 57.5 
              46.9154 57.5 43.8786V20.7846C57.5 17.7479 55.8799 14.9418 53.25 13.4234L33.25 1.87639Z`}
            fill={colors.outerStroke}
            stroke={colors.outerStroke}
          />
          <path
            d={`M25.25 2.74241C27.5705 1.40267 30.4295 1.40267 32.75 2.74241L52.75 14.2894C55.0705 
              15.6292 56.5 18.1051 56.5 20.7846V43.8786C56.5 46.5581 55.0705 49.0341 52.75 50.3738L32.75 
              61.9208C30.4295 63.2606 27.5705 63.2606 25.25 61.9208L5.25 50.3738C2.92949 49.0341 1.5 46.5581 1.5 
              43.8786V20.7846C1.5 18.1051 2.92949 15.6292 5.25 14.2894L25.25 2.74241Z`}
            fill={colors.fill}
            stroke={colors.innerStroke}
          />
        </svg>
      );
    case HexagonButtonSizes.MD:
      return (
        <svg width="70" height="78" viewBox="0 0 70 78" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M41.2441 3.15757C37.241 0.846395 32.309 0.846394 28.3059 3.15757L6.96908 15.4764C2.966 
              17.7876 0.5 22.0588 0.5 26.6812V51.3188C0.5 55.9412 2.966 60.2124 6.96908 62.5236L28.3059 
              74.8424C32.309 77.1536 37.241 77.1536 41.2441 74.8424L62.5809 62.5236C66.584 60.2124 69.05 
              55.9412 69.05 51.3188V26.6812C69.05 22.0588 66.584 17.7876 62.5809 15.4764L41.2441 3.15757Z`}
            fill={colors.outerStroke}
            stroke={colors.outerStroke}
          />
          <path
            d={`M28.8059 4.0236C32.4996 1.89105 37.0504 1.89105 40.7441 4.0236L62.0809 16.3424C65.7746 
              18.475 68.05 22.4161 68.05 26.6812V51.3188C68.05 55.5839 65.7746 59.525 62.0809 61.6576L40.7441 
              73.9764C37.0504 76.1089 32.4996 76.1089 28.8059 73.9764L7.46908 61.6576C3.7754 59.525 1.5 55.5839 
              1.5 51.3188V26.6812C1.5 22.4161 3.7754 18.475 7.46908 16.3424L28.8059 4.0236Z`}
            fill={colors.fill}
            stroke={colors.innerStroke}
          />
        </svg>
      );
    case HexagonButtonSizes.LG:
      return (
        <svg width="93" height="100" viewBox="0 0 93 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M85.7922 21.0186L52.7304 2.85277C48.8503 0.720892 44.1497 0.720894 40.2696 
              2.85277L7.2078 21.0186C3.07076 23.2917 0.5 27.6375 0.5 32.3579V67.6421C0.5 
              72.3625 3.07077 76.7083 7.20781 78.9814L40.2696 97.1472C44.1497 99.2791 48.8503 
              99.2791 52.7304 97.1472L85.7922 78.9814C89.9292 76.7083 92.5 72.3625 92.5 
              67.6421V32.3579C92.5 27.6375 89.9292 23.2917 85.7922 21.0186Z`}
            fill={colors.outerStroke}
            stroke={colors.outerStroke}
          />
          <path
            d={`M52.2488 3.72919L85.3106 21.895C89.1279 23.9924 91.5 28.0023 91.5 
              32.3579V67.6421C91.5 71.9977 89.1279 76.0076 85.3106 78.105L52.2488 
              96.2708C48.6687 98.2379 44.3313 98.2379 40.7512 96.2708L7.68935 78.105C3.87207 
              76.0076 1.5 71.9977 1.5 67.6421V32.3579C1.5 28.0023 3.87207 23.9924 
              7.68935 21.895L40.7512 3.72919C44.3313 1.76209 48.6687 1.76209 52.2488 3.72919Z`}
            fill={colors.fill}
            stroke={colors.innerStroke}
          />
        </svg>
      );
    default:
      return null;
  }
}

HexagonWithBorderSvg.defaultProps = {
  size: HexagonButtonSizes.MD,
};
