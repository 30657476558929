import React from 'react';
import { useTheme } from 'styled-components';
import { PillButton } from '../../styles/components/Buttons';
import { TextButtonWithAdditionalColors } from '../../styles/styled';

type SequenceButtonProps = {
  colors: TextButtonWithAdditionalColors,
  buttonTouch: () => void,
  isDisabled?: boolean,
  ariaLabel: string,
  sequenceOrder: number,
  children: any,
}

export default function SequenceButton({
  colors, buttonTouch, isDisabled, ariaLabel, sequenceOrder, children,
}: SequenceButtonProps) {
  const theme = useTheme();
  const circleText = sequenceOrder.toString();
  return (
    <PillButton
      className="sequence-button"
      disabled={isDisabled}
      aria-disabled={isDisabled}
      colors={colors}
      aria-label={ariaLabel}
      onTouchStart={buttonTouch}
      role="link"
    >
      <div>
        {children}
        {circleText && (
          <div className="circle-container">
            <div
              className="circle-with-text"
              style={{
                borderColor: colors.tertiary,
                backgroundColor: isDisabled ? theme.colors.white : colors.tertiary,
              }}
            >
              <span style={{ color: isDisabled ? colors.tertiary : theme.colors.white }}>{circleText}</span>
            </div>
          </div>
        )}
      </div>
    </PillButton>
  );
}

SequenceButton.defaultProps = {
  isDisabled: false,
};
