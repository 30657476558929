import React from 'react';
import spacingScaffolds from '../../assets/spacing-scaffolds';
import { SpacingActivityType, SpacingItem } from '../../data/types';
import ShootingStarAnimation from '../Animations/ShootingStarAnimation';
import shootingStar from '../../assets/images/shooting-star-spacing.png';
import ChaseTheShootingStarLottieLoader from '../ChaseTheStarLottieLoader';
import { DRAWABLE_WIDTH_NORMAL } from '../../shared/constants';
import ActivityScaffoldPositionWrapper from '../../styles/components/ActivityScaffoldWrapper';
import { characters } from '../../data/characters/characters';

type ActivityScaffoldProps = {
  currentActivityType: SpacingActivityType,
  currentItem: SpacingItem,
  showIntroSequence: boolean,
  playLottie?: boolean,
}

export default function SpacingActivityScaffold({
  currentActivityType,
  currentItem,
  showIntroSequence,
  playLottie,
}: ActivityScaffoldProps) {
  switch (currentActivityType) {
    case (SpacingActivityType.TRACE_FADED):
      return (
        <>
          <ShootingStarAnimation
            start={showIntroSequence}
            animationData={{ left: currentItem.positioning.shootingStar.xOffset || 0 }}
          />
          <img
            alt=""
            src={(showIntroSequence
              ? spacingScaffolds[currentItem.type]?.MODEL
              : spacingScaffolds[currentItem.type]?.TRACE_FADED) || ''}
            height={currentItem.positioning.scaffold.height || 0}
            style={{ position: 'absolute', top: `${currentItem.positioning.scaffold.yOffset || 0}px` }}
          />
        </>
      );
    case (SpacingActivityType.SPACE_INDEPENDENT):
      return (
        <img
          alt=""
          src={spacingScaffolds[currentItem.type]?.SPACE_INDEPENDENT}
          height={currentItem.positioning.scaffold.height || 0}
          style={{ position: 'absolute', top: `${currentItem.positioning.scaffold.yOffset || 0}px` }}
        />
      );
    case (SpacingActivityType.TRACE_SHOOTING_STAR): {
      const character = characters[currentItem.traceCharacterType];
      return (
        <>
          <img
            alt=""
            src={shootingStar}
            style={{
              position: 'absolute',
              left: currentItem.positioning.shootingStar.xOffset,
            }}
          />
          <img
            alt=""
            src={spacingScaffolds[currentItem.type]?.TRACE_SHOOTING_STAR}
            height={currentItem.positioning.scaffold.height || 0}
            style={{ position: 'absolute', top: `${currentItem.positioning.scaffold.yOffset || 0}px` }}
          />
          <ActivityScaffoldPositionWrapper
            top={character.lottieSettings?.CHASE_STAR?.positionY}
            left={currentItem.positioning.scaffold.lottiePositionX}
            isVisible
          >
            <ChaseTheShootingStarLottieLoader
              shouldStart={playLottie || false}
              width={DRAWABLE_WIDTH_NORMAL + 10}
              characterType={currentItem.traceCharacterType}
              lottieFrames={character.lottieSettings?.CHASE_STAR?.frames}
            />
          </ActivityScaffoldPositionWrapper>
        </>
      );
    }
    case (SpacingActivityType.DRAG_AND_DROP):
      return (
        <img
          id="drag-and-drop-word-model"
          alt=""
          src={(showIntroSequence
            ? spacingScaffolds[currentItem.type]?.MODEL
            : spacingScaffolds[currentItem.type]?.DRAG_AND_DROP_INDEPENDENT) || ''}
          height={currentItem.positioning.scaffold.height || 0}
          style={{ position: 'absolute', top: `${currentItem.positioning.scaffold.yOffset || 0}px` }}
        />
      );
    case (SpacingActivityType.DRAG_AND_DROP_INDEPENDENT):
      return (
        <img
          id="drag-and-drop-word-model"
          alt=""
          src={(showIntroSequence
            ? spacingScaffolds[currentItem.type]?.MODEL
            : spacingScaffolds[currentItem.type]?.DRAG_AND_DROP_INDEPENDENT) || ''}
          height={currentItem.positioning.scaffold.height || 0}
          style={{ position: 'absolute', top: `${currentItem.positioning.scaffold.yOffset || 0}px` }}
        />
      );
    default:
      return null;
  }
}

SpacingActivityScaffold.defaultProps = {
  playLottie: false,
};
