/* eslint-disable react/require-default-props */
import React from 'react';
import { FlexRowSpaceBetween, FlexRowLeftCenter } from '../styles/components/FlexSpacers';
import LottiePlayer from './LottiePlayer';
import NextButton from './Buttons/NextButton';
import { Pen } from '../data/types';
import DarkBackdrop from '../styles/components/DarkBackdrop';
import ZIndexWrapper from '../styles/components/ZIndexWrapper';
import starMeter from '../assets/lotties/star-meter.json';
import { PenColorButton } from '../styles/components/Buttons';
import { useRewardsContext } from '../context/RewardsContext';
import { RewardEventTypes } from '../stateMachines/rewardsMachine/rewardsMachine';

interface ActivityRewardsAndNavigationProps {
  starMeterFrames: [number, number],
  hasNewPen: boolean,
  showRewards?: boolean,
  showNextButton?: boolean,
  nextButtonDisabled?: boolean,
  onNextButtonTouch: () => void,
  canChangePenColor: boolean,
}

export default function ActivityRewardsAndNavigation({
  starMeterFrames,
  hasNewPen,
  showRewards,
  showNextButton,
  nextButtonDisabled = false,
  onNextButtonTouch,
  canChangePenColor,
}: ActivityRewardsAndNavigationProps) {
  const rewards = useRewardsContext();
  const pens: Pen[] = rewards.state.context.unlockedPens;
  const { activePen } = rewards.state.context;
  const isButtonDisabled = (penId: string): boolean => !canChangePenColor && activePen.id !== penId;

  return (
    <FlexRowSpaceBetween style={{ height: 120 }}>
      {hasNewPen ? (
        <DarkBackdrop />
      ) : null}
      <ZIndexWrapper value={2}>
        <FlexRowLeftCenter>
          {showRewards && (
            <>
              <LottiePlayer
                width={80}
                data={starMeter}
                segmentFrames={starMeterFrames}
              />
              <div role="group" aria-labelledby="pen-colors">
                <span id="pen-colors" className="visually-hidden" aria-hidden="true">Select a pencil color</span>
                {pens.map((pen: Pen) => (
                  <PenColorButton
                    key={pen.id}
                    pen={pen}
                    onTouchStart={() => rewards.send({ type: RewardEventTypes.CHANGE_ACTIVE_PEN, payload: pen })}
                    isSelected={activePen.id === pen.id}
                    disabled={isButtonDisabled(pen.id)}
                    aria-disabled={isButtonDisabled(pen.id)}
                    aria-current={activePen.id === pen.id}
                    aria-label={`${pen.displayName} pencil color`}
                  />
                ))}
              </div>
            </>
          )}
        </FlexRowLeftCenter>
      </ZIndexWrapper>
      {showNextButton || hasNewPen ? (
        <ZIndexWrapper value={2}>
          <NextButton
            isDisabled={!!nextButtonDisabled}
            buttonTouch={onNextButtonTouch}
          />
        </ZIndexWrapper>
      ) : null}
    </FlexRowSpaceBetween>
  );
}

ActivityRewardsAndNavigation.defaultProps = {
  showNextButton: false,
  showRewards: true,
};
