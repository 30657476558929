import styled from 'styled-components';

const ActivityWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .no-internet-container {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 1%;
  }
`;

export default ActivityWrapper;
