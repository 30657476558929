/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import starSuccess from '../../../../assets/lotties/star-success.json';
import LottiePlayer from '../../../LottiePlayer';
import { useSpacingGameContext } from '../../../../context/SpacingGameContext';

type StarSuccessProps = {
  // eslint-disable-next-line react/require-default-props
  yAxis: number;
  animateStar: boolean,
};

export default function StarSuccessLottie({
  yAxis,
  animateStar,
}: StarSuccessProps) {
  const { state } = useSpacingGameContext();
  const leftPosition = state.context?.currentItem?.positioning.planetGame.xOffset || 0;
  const animationStyle: React.CSSProperties = {
    position: 'absolute',
    left: `${leftPosition - 10}px`,
    top: `${Math.floor(yAxis) - 100}px`,
  };

  return (
    <div style={animationStyle}>
      { animateStar
      && <LottiePlayer data={starSuccess} width={100} shouldAutoplay shouldLoop />}
    </div>
  );
}
