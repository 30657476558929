import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import redPlanetLottie from '../../assets/lotties/PreWriting/PW2/redplanet.json';
import bluePlanetLottie from '../../assets/lotties/PreWriting/PW2/blueplanet.json';
import yellowPlanetLottie from '../../assets/lotties/PreWriting/PW2/yellowplanet.json';
import stars from '../../assets/lotties/PreWriting/PW2/stars.json';
import shootingStar from '../../assets/lotties/PreWriting/PW2/shooting-star-right.json';
import { PreWritingHandwritingRewardWrapper } from '../../styles/components/PreWritingWrappers';
import LottiePlayer from '../LottiePlayer';
import NextButton from '../Buttons/NextButton';
import { Paths } from '../../data/types/routes';
import { useAudioContext } from '../../context/AudioContext';
import shapesAndLinesReward from '../../assets/audio/preWriting/PW2/VO116.m4a';
import nextButtonVO from '../../assets/audio/tapArrowActions/VO3.mp3';

export default function PW2Reward() {
  const navigate = useNavigate();
  const audioContext = useAudioContext();

  useEffect(() => {
    const playNextAudio = () => {
      audioContext?.handlePlay({ src: nextButtonVO });
    };
    const playShapesAndLinesReward = () => {
      audioContext?.handlePlay({ src: shapesAndLinesReward });
    };
    playShapesAndLinesReward();
    // Set a timeout to play the nextButtonVO audio after a delay
    const delay = 9000;
    const timeoutId = setTimeout(playNextAudio, delay);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleNext = () => {
    navigate(`/${Paths.PRE_WRITING}`);
  };
  return (
    <PreWritingHandwritingRewardWrapper>
      <div className="red-planet">
        <LottiePlayer
          data={redPlanetLottie}
          shouldLoop
          width="220px"
          shouldAutoplay
        />
      </div>
      <div className="blue-planet">
        <LottiePlayer
          data={bluePlanetLottie}
          shouldLoop
          width="280px"
          shouldAutoplay
        />
      </div>
      <div className="yellow-planet">
        <LottiePlayer
          data={yellowPlanetLottie}
          shouldLoop
          width="600px"
          shouldAutoplay
        />
      </div>
      <div className="stars-1">
        <LottiePlayer
          data={stars}
          shouldLoop
          width="100px"
          scale={3}
          shouldAutoplay
        />
      </div>
      <div className="stars-2">
        <LottiePlayer
          data={stars}
          shouldLoop
          width="100px"
          scale={3}
          shouldAutoplay
        />
      </div>
      <div className="stars-3">
        <LottiePlayer
          data={stars}
          shouldLoop
          width="100px"
          scale={3}
          shouldAutoplay
        />
      </div>
      <div className="shooting-stars-1">
        <LottiePlayer
          data={shootingStar}
          shouldLoop
          width="348px"
          shouldAutoplay
        />
      </div>

      <div className="shooting-stars-2">
        <LottiePlayer
          data={shootingStar}
          shouldLoop
          width="348px"
          shouldAutoplay
        />
      </div>

      <div className="next-button-wrapper">
        <NextButton
          buttonTouch={handleNext}
          isDisabled={false}
        />
      </div>
    </PreWritingHandwritingRewardWrapper>
  );
}
