import React, { useEffect, useState } from 'react';
import { Layer } from 'react-konva';
import URLImage from './URLImage';
import {
  DifficultyLevel,
  Point,
  ThresholdRestrictedPoint,
  StrokeOutcome,
  Character,
  CharacterCategory,
} from '../../data/types';
import endPointDot from '../../assets/images/end-point.svg';
import closeGapCue from '../../assets/images/close-gap-cue.svg';
import startArrow from '../../assets/images/start-arrow.svg';
import startPointDot from '../../assets/images/start-point.svg';
import midArrow from '../../assets/images/mid-arrow.svg';
import { getIntersectionNodeName, IntersectionType } from '../../shared/getIntersectionNodeName';
import { VISIBLE_POINT_DIAMETER_LARGE, VISIBLE_POINT_DIAMETER_NORMAL } from '../../shared/constants';

interface GuidePointsLayerProps {
  lastStrokeOutcome?: StrokeOutcome|null,
  showGuideDots: boolean,
  showStartDotOnLoad: boolean,
  layerPositionX: number,
  layerPositionY: number,
  visibleMidPoints: Point[],
  startPoint?: ThresholdRestrictedPoint,
  isDirectionalStart: boolean,
  endPoint?: ThresholdRestrictedPoint,
  intersectedPoints: Set<string>,
  strokeEndedAtEndPoint: boolean,
  difficultyLevel: DifficultyLevel,
  beginStartDotAnimation: boolean,
  removeStartDotAfterAnimation: boolean,
  beginStopDotAnimation: boolean,
  currentActivityIteration: number,
  currentCharacter?: Character
}

export default function GuidePointsLayer({
  lastStrokeOutcome,
  showGuideDots,
  showStartDotOnLoad,
  layerPositionX,
  layerPositionY,
  visibleMidPoints,
  startPoint,
  isDirectionalStart,
  endPoint,
  intersectedPoints,
  strokeEndedAtEndPoint,
  difficultyLevel,
  beginStartDotAnimation,
  removeStartDotAfterAnimation,
  beginStopDotAnimation,
  currentActivityIteration,
  currentCharacter,
}: GuidePointsLayerProps) {
  const [showStaticStartDotAfterAnimation, setShowStaticStartDotAfterAnimation] = useState(false);
  const [showAndBeginStartDotAnimation, setShowAndBeginStartDotAnimation] = useState(false);
  const endPointId = getIntersectionNodeName(IntersectionType.REQUIRED, endPoint?.orderId);
  const startPointId = getIntersectionNodeName(IntersectionType.REQUIRED, startPoint?.orderId);
  const startPointCoordinates = startPoint?.coordinates[difficultyLevel] || { x: 0, y: 0 };
  const endPointCoordinates = endPoint?.coordinates[difficultyLevel] || { x: 0, y: 0 };
  const visiblePointWidthHeight = difficultyLevel === DifficultyLevel.NORMAL
    ? VISIBLE_POINT_DIAMETER_NORMAL
    : VISIBLE_POINT_DIAMETER_LARGE;
  const shouldShowPoint = (point: Point): boolean => {
    // Some order ids are 0
    if (point?.visibleAfterOrderId === undefined) return true;
    const intersectionNodeName = getIntersectionNodeName(IntersectionType.REQUIRED, point?.visibleAfterOrderId);
    return intersectedPoints.has(intersectionNodeName);
  };

  const getUpdatedPointOffset = (currentPointOffset: number | undefined) => {
    if (!currentPointOffset) return 0;
    if (difficultyLevel === DifficultyLevel.EASY) {
      return currentPointOffset * 2;
    }
    return currentPointOffset;
  };

  const onAnimationStart = () => {
    setShowStaticStartDotAfterAnimation(true);
  };

  const onAnimationEnd = () => {
    setShowAndBeginStartDotAnimation(false);
  };

  useEffect(() => {
    setShowStaticStartDotAfterAnimation(false);
  }, [startPoint, currentActivityIteration]);

  useEffect(() => {
    setShowAndBeginStartDotAnimation(beginStartDotAnimation);
  }, [beginStartDotAnimation]);

  const renderStartPoint = (shouldAnimate: boolean) => {
    if (!startPoint || intersectedPoints.has(startPointId)) return null;
    return (
      <URLImage
        url={isDirectionalStart ? startArrow : startPointDot}
        x={startPointCoordinates.x}
        y={startPointCoordinates.y}
        rotation={startPoint?.rotationDegrees || 0}
        rotationOffsetX={getUpdatedPointOffset(startPoint?.rotationOffsetX)}
        rotationOffsetY={getUpdatedPointOffset(startPoint?.rotationOffsetY)}
        animateEntrance={shouldAnimate}
        handleAnimationStart={(shouldAnimate && !showGuideDots) ? onAnimationStart : undefined}
        handleAnimationEnd={shouldAnimate ? onAnimationEnd : undefined}
        width={visiblePointWidthHeight}
        height={visiblePointWidthHeight}
      />
    );
  };

  const renderCloseGapCue = () => (
    <URLImage
      url={closeGapCue}
      x={currentCharacter?.category === CharacterCategory.LETTER_UPPER ? 40 : 20}
      y={-5}
      width={visiblePointWidthHeight}
      height={visiblePointWidthHeight}
      animateEntrance
    />
  );

  if (showGuideDots || showAndBeginStartDotAnimation || showStaticStartDotAfterAnimation || showStartDotOnLoad) {
    return (
      <Layer
        id="points"
        x={layerPositionX}
        y={layerPositionY}
        listening={false}
      >
        {showGuideDots ? (
          <>
            {endPoint && (!intersectedPoints.has(endPointId) || !strokeEndedAtEndPoint) && shouldShowPoint(endPoint) ? (
              <URLImage
                url={endPointDot}
                x={endPointCoordinates.x}
                y={endPointCoordinates.y}
                width={visiblePointWidthHeight}
                height={visiblePointWidthHeight}
                animateEntrance={beginStopDotAnimation}
              />
            ) : null}
            {visibleMidPoints.map((point) => {
              if (intersectedPoints.has(getIntersectionNodeName(IntersectionType.REQUIRED, point.orderId))) return null;
              if (!shouldShowPoint(point)) return null;
              const coordinates = point?.coordinates[difficultyLevel] || { x: 0, y: 0 };
              return (
                <URLImage
                  url={midArrow}
                  x={coordinates.x}
                  y={coordinates.y}
                  rotation={point.rotationDegrees || 0}
                  rotationOffsetX={getUpdatedPointOffset(point.rotationOffsetX)}
                  rotationOffsetY={getUpdatedPointOffset(point.rotationOffsetY)}
                  id={`midpoint-${point.orderId}`}
                  key={`midpoint-${point.orderId}`}
                  width={visiblePointWidthHeight}
                  height={visiblePointWidthHeight}
                />
              );
            })}
            {/* This handles all tracing + chase the shooting star activities start dots.
            *** We always want to show a static start dot with these activities. */}
            {renderStartPoint(showAndBeginStartDotAnimation)}
          </>
        ) : null}
        {/* This shows the close gap cue image on solid/faded/dotted activity */}
        {lastStrokeOutcome === StrokeOutcome.WARNING_INTERSECTION ? renderCloseGapCue() : null}
        {/* showStartDotOnLoad:
        *** This is only for independent activity first iteration. Display static start dot on load. */}
        {/* (showStaticStartDotAfterAnimation && !removeStartDotAfterAnimation):
        *** This is only for the independent activity second and third iterations.
        *** It displays a static start dot only after the start dot hint has been
        *** triggered and its animation has ended. It disappears when a stroke has been
        *** completed and the activity moves on to another stroke or iteration */}
        {(showStartDotOnLoad || (showStaticStartDotAfterAnimation && !removeStartDotAfterAnimation))
          ? renderStartPoint(false) : null}
        {/* This is only for the independent activity all iterations. It shows and animates a start dot
        *** when triggered. */}
        {showAndBeginStartDotAnimation && renderStartPoint(true)}
      </Layer>
    );
  }

  return null;
}

GuidePointsLayer.defaultProps = {
  startPoint: null,
  endPoint: null,
  lastStrokeOutcome: null,
  currentCharacter: null,
};
