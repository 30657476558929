import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Paths } from '../../data/types';
import { LargePillButton } from '../../styles/components/Buttons';

export default function SetsLink() {
  const theme = useTheme();

  return (
    <Link to={`/${Paths.WRITING}`} className="launch-starwriter">
      <LargePillButton
        colors={theme.colors.buttons.launch}
        aria-label="launch Starwriter student experience"
      >
        <span className="launch-text">Launch Starwriter</span>
      </LargePillButton>
    </Link>
  );
}
