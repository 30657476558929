import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../data/types';
import useTouchDevice from '../hooks/useTouchDevice';
import EducatorResourcesLink from '../components/Links/EducatorResourcesLink';
import { DocumentAreaWrapper } from '../styles/components/ContentWrappers';
import ClosePageButton from '../components/Buttons/ClosePageButton';
import DocumentContainer from '../styles/components/DocumentContainer';
import TermsOfUse from '../components/Documents/TermsOfUse';
import DirectNotice from '../components/Documents/DirectNotice';
import PrivacyPolicy from '../components/Documents/PrivacyPolicy';

type PoliciesProps = {
  policyPath: Paths,
}

export default function Policies({ policyPath }: PoliciesProps) {
  const isTouchDevice = useTouchDevice();
  const navigate = useNavigate();
  const location = useLocation();
  const [returnPage, setReturnPage] = useState<Paths|null>(null);

  useEffect(() => {
    const state = location.state as { returnPage: Paths };
    setReturnPage(state?.returnPage);
  }, [location]);

  const getButtonLabel = () => (
    `Close and go back to ${(returnPage === Paths.EDUCATOR_RESOURCES) ? 'educator experience' : 'about'} page.`
  );

  const handleClosePageButtonTouch = (): void => {
    if (isTouchDevice) {
      if (returnPage === Paths.EDUCATOR_RESOURCES) {
        navigate(`/${Paths.EDUCATOR_RESOURCES}`, { state: { shouldCheckAccess: false } });
        return;
      }
      navigate(`/${returnPage}`);
    } else {
      navigate(`/${Paths.ABOUT}`);
    }
  };

  const getPolicyHtml = () => {
    switch (policyPath) {
      case Paths.TOS:
        return <TermsOfUse />;
      case Paths.PRIVACY:
        return <PrivacyPolicy />;
      case Paths.DIRECT_NOTICE:
        return <DirectNotice />;
      default:
        return null;
    }
  };

  return (
    <DocumentAreaWrapper>
      <ClosePageButton buttonTouch={() => handleClosePageButtonTouch()} ariaLabel={getButtonLabel()} />
      <DocumentContainer>
        {getPolicyHtml()}
      </DocumentContainer>
      { isTouchDevice && <EducatorResourcesLink /> }
    </DocumentAreaWrapper>
  );
}
