import styled from 'styled-components';

type SlideRightOutAnimationProps = {
  rightPosition: number,
}

const SlideRightOutAnimation = styled.div<SlideRightOutAnimationProps>`
  position: absolute;
  width: 75px;
  height: auto;
  right: ${(props) => props.rightPosition}px;
  visibility: hidden;
  opacity: 0;

  &.slide-right-enter {
    opacity: 1;
    visibility: visible;
  }

  &.slide-right-enter-active {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 1500ms ease-in,
                opacity 200ms ease-in 1300ms;
  }

  &.slide-right-enter-done {
    visibility: hidden;
  }
`;

export default SlideRightOutAnimation;
