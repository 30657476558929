import { spacingItems } from './spacing-items';
import { SpacingSet, SpacingSetType, SpacingItemType } from '../types';

export const spacingSets: {
  [key in SpacingSetType]: SpacingSet;
} = {
  [SpacingSetType.DIGRAPH]: {
    type: SpacingSetType.DIGRAPH,
    sequenceItems: [
      spacingItems.CH,
      spacingItems.SH,
      spacingItems.TH,
    ],
    practiceItems: {
      [SpacingItemType.CH]: true,
      [SpacingItemType.SH]: true,
      [SpacingItemType.TH]: true,
    },
    display: 'ch sh th',
    displayOrder: 24,
  },
  [SpacingSetType.WORDS_1]: {
    type: SpacingSetType.WORDS_1,
    sequenceItems: [
      spacingItems.IN,
      spacingItems.AM,
      spacingItems.UP,
    ],
    practiceItems: {
      [SpacingItemType.CH]: true,
      [SpacingItemType.SH]: true,
      [SpacingItemType.TH]: true,
      [SpacingItemType.IN]: true,
      [SpacingItemType.AM]: true,
      [SpacingItemType.UP]: true,
    },
    display: 'Words 1',
    displayOrder: 25,
  },
  [SpacingSetType.WORDS_2]: {
    type: SpacingSetType.WORDS_2,
    sequenceItems: [
      spacingItems.IT,
      spacingItems.AT,
    ],
    practiceItems: {
      [SpacingItemType.CH]: true,
      [SpacingItemType.SH]: true,
      [SpacingItemType.TH]: true,
      [SpacingItemType.IN]: true,
      [SpacingItemType.AM]: true,
      [SpacingItemType.UP]: true,
      [SpacingItemType.IT]: true,
      [SpacingItemType.AT]: true,
    },
    display: 'Words 2',
    displayOrder: 26,
  },
  [SpacingSetType.WORDS_3]: {
    type: SpacingSetType.WORDS_3,
    sequenceItems: [
      spacingItems.IF,
      spacingItems.US,
      spacingItems.NO,
      spacingItems.YES,
      spacingItems.RED,
      spacingItems.BAG,
    ],
    practiceItems: {
      [SpacingItemType.CH]: true,
      [SpacingItemType.SH]: true,
      [SpacingItemType.TH]: true,
      [SpacingItemType.IN]: true,
      [SpacingItemType.AM]: true,
      [SpacingItemType.UP]: true,
      [SpacingItemType.IT]: true,
      [SpacingItemType.AT]: true,
      [SpacingItemType.IF]: true,
      [SpacingItemType.US]: true,
      [SpacingItemType.NO]: true,
      [SpacingItemType.YES]: true,
      [SpacingItemType.RED]: true,
      [SpacingItemType.BAG]: true,
    },
    display: 'Words 3',
    displayOrder: 27,
  },
};

export const spacingSetTypeList = (
  Object.keys(SpacingSetType) as (keyof typeof SpacingSetType)[]
);

const getAllSetsByOrder = (): SpacingSet[] => (
  Object.values(spacingSets).sort((a, b) => a.displayOrder - b.displayOrder)
);

export function getNextSpacingSetInOrder(currentSet: SpacingSet) {
  const nextOrder = currentSet.displayOrder + 1;
  const nextSet = getAllSetsByOrder().find((set) => set.displayOrder === nextOrder);
  return nextSet || null;
}
