import distanceBetweenTwoPoints from './distanceBetweenTwoPoints';

const pointIsWithinAcceptableDistance = (
  [drawnStrokeX, drawnStrokeY]: [number, number],
  [charX, charY]: [number, number],
  acceptableDistance: number,
): boolean => {
  const distanceBetweenPoints = distanceBetweenTwoPoints([drawnStrokeX, drawnStrokeY], [charX, charY]);
  return distanceBetweenPoints <= acceptableDistance;
};

export default pointIsWithinAcceptableDistance;
