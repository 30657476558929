import React from 'react';
import { useTheme } from 'styled-components';
import playIcon from '../../assets/images/icons/play-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';

type DemoButtonProps = {
  ariaItemLabel: string,
  buttonTouch: () => void,
}

export default function DemoButton({ ariaItemLabel, buttonTouch }: DemoButtonProps) {
  const theme = useTheme();

  return (
    <CircleButton
      colors={theme.colors.buttons.demo}
      aria-label={`go to demo video page for ${ariaItemLabel}`}
      onTouchStart={buttonTouch}
      role="link"
    >
      <img
        alt=""
        src={playIcon}
        style={{ width: '39px', height: '42px' }}
      />
    </CircleButton>
  );
}
