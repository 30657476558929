import React from 'react';
import { Line } from 'react-konva';
import { DifficultyLevel, RestrictedAreaShape } from '../../data/types';
import { getIntersectionNodeName, IntersectionType } from '../../shared/getIntersectionNodeName';
import envSettings from '../../data/envSettings';
import { SCALE_LARGE } from '../../shared/constants';

type RestrictedAreasType = {
  restrictedAreas: RestrictedAreaShape[] | [],
  difficultyLevel: DifficultyLevel,
}

export default function RestrictedAreas({ restrictedAreas, difficultyLevel }: RestrictedAreasType) {
  return (
    <>
      {restrictedAreas.map((shape) => {
        const coordinates = shape.coordinates[difficultyLevel] || { x: 0, y: 0 };
        const restrictedAreaPoints = shape.points.map((point) => {
          if (difficultyLevel === DifficultyLevel.EASY) return point * SCALE_LARGE;
          return point;
        });
        return (
          <Line
            key={`restricted-area-${shape.id}`}
            id={getIntersectionNodeName(IntersectionType.RESTRICTED, shape.id)}
            x={coordinates.x}
            y={coordinates.y}
            points={restrictedAreaPoints}
            fill={envSettings?.devMode ? 'red' : 'transparent'}
            opacity={envSettings?.devMode ? 0.5 : 0}
            closed
            tension={shape.tension || 0}
          />
        );
      })}
    </>
  );
}
