import React from 'react';
import { useTheme } from 'styled-components';
import closeIcon from '../../assets/images/icons/x-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';
import CloseButtonContainer from '../../styles/components/CloseButtonContainer';

type ClosePageButtonProps = {
  buttonTouch: () => void,
  ariaLabel: string,
}

export default function ClosePageButton({ buttonTouch, ariaLabel }: ClosePageButtonProps) {
  const theme = useTheme();

  return (
    <CloseButtonContainer>
      <CircleButton
        width="44px"
        height="44px"
        colors={theme.colors.buttons.ok}
        aria-label={ariaLabel}
        onClick={buttonTouch} /* onClick event for non touch device users */
        onTouchStart={buttonTouch}
        role="link"
      >
        <img
          className="close-icon"
          alt=""
          src={closeIcon}
        />
      </CircleButton>
    </CloseButtonContainer>
  );
}
