import { captureMessage } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { CharacterType } from '../data/types';
import LottiePlayer from './LottiePlayer';

type LottieLoadProps = {
  shouldStart: boolean,
  width: number,
  characterType: CharacterType,
  lottieFrames?: [number, number],
}

export default function ChaseTheShootingStarLottieLoader({
  shouldStart, width, characterType, lottieFrames,
}: LottieLoadProps) {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    import(`../assets/scaffolds/NORMAL/CHASE_STAR/${characterType}.json`)
      .then(setAnimationData)
      .catch((e) => {
        captureMessage(`Chase the Star lottie failed to load for ${characterType}, ${e}`);
      });
  }, []);

  if (!animationData || !shouldStart) return null;

  return (
    <LottiePlayer
      data={animationData}
      width={width}
      segmentFrames={lottieFrames}
    />
  );
}

ChaseTheShootingStarLottieLoader.defaultProps = {
  lottieFrames: undefined,
};
