import styled from 'styled-components';
import { FlexRowCenter } from './FlexSpacers';

const VideoContainer = styled(FlexRowCenter)`
  height: 400px;
  width: 600px;
  overflow: hidden;
  position: relative;

  .loading-container {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  button {
    z-index: 5;
    position: absolute;
  }

  video {
    object-fit: cover;
    height: auto;
    max-width: 800px;
  }
`;

export default VideoContainer;
