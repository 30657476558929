const shuffle = (
  items: any[],
  copyFn?: (items: any[]) => any[],
) => {
  const copiedItems = copyFn ? copyFn(items) : items;
  for (let i = copiedItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copiedItems[i], copiedItems[j]] = [copiedItems[j], copiedItems[i]];
  }
  return copiedItems;
};

export default shuffle;
