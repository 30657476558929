import React from 'react';
import { CustomSetConfirmAreaWrapper } from '../styles/components/ContentWrappers';
import { FlexWrapper, FlexRowSpaceBetween } from '../styles/components/FlexSpacers';
import { SelectCustomSetContainer } from '../styles/components/ScreenContainers';
import SequencePageButton from './Buttons/SequencePageButton';
import CharacterButton from './Buttons/CharacterButton';
import TeacherConfirmButton from './Buttons/TeacherConfirmButton';
import { ButtonWithSvg } from '../styles/components/Buttons';
import backArrow from '../assets/images/back-arrow.svg';
import { HexagonButtonSizes } from './SVG/types';
import CharacterPlaceholderButton from './Buttons/CharacterPlaceholderButton';
import SortableHorizontalListWrapper from './DragAndDrop/SortableHorizontalListWrapper';
import SortableItem from './DragAndDrop/SortableItem';
import { SelectedChar } from '../pages/CreateCustomCharacterSet';

interface ConfirmCustomSetProps {
  selectedChars: SelectedChar[],
  setSelectedChars: React.Dispatch<React.SetStateAction<SelectedChar[]>>,
  goToSelectScreen: () => void,
  goToLaunchScreen: () => void,
  showConfirmLeavingModal: () => void,
}

export default function ConfirmCustomSet({
  selectedChars,
  setSelectedChars,
  goToSelectScreen,
  goToLaunchScreen,
  showConfirmLeavingModal,
}: ConfirmCustomSetProps) {
  return (
    <SelectCustomSetContainer>
      <FlexRowSpaceBetween className="text-wrapper">
        <ButtonWithSvg
          type="button"
          onClick={goToSelectScreen}
        >
          <img src={backArrow} alt="Go back" />
        </ButtonWithSvg>
        <SequencePageButton buttonTouch={showConfirmLeavingModal} />
      </FlexRowSpaceBetween>
      <CustomSetConfirmAreaWrapper>
        <div className="text">
          <h1>
            One more step!
            <span className="visually-hidden">Confirm Custom Set</span>
          </h1>
          <p>Drag characters to change their orders.</p>
        </div>
        <FlexWrapper className="character-buttons">
          <SortableHorizontalListWrapper
            items={selectedChars}
            setItems={setSelectedChars}
          >
            {selectedChars.map((char: SelectedChar) => (
              <SortableItem key={char.id} id={char.id}>
                <CharacterButton
                  charType={char.type}
                  charCategory={char.category}
                  buttonTouch={() => {}}
                  isCharacterInCurrentSequence
                  size={HexagonButtonSizes.LG}
                />
              </SortableItem>
            ))}
          </SortableHorizontalListWrapper>
          {Array(5 - selectedChars.length).fill('').map((_, i) => (
            <CharacterPlaceholderButton
              size={HexagonButtonSizes.LG}
              // eslint-disable-next-line react/no-array-index-key
              key={`placeholder-${i}`}
              label="Add another character to custom set"
              buttonTouch={goToSelectScreen}
            />
          ))}
        </FlexWrapper>
        <TeacherConfirmButton
          buttonText="COMPLETE & START SET"
          buttonTouch={goToLaunchScreen}
          autoWidth
        />
      </CustomSetConfirmAreaWrapper>
    </SelectCustomSetContainer>
  );
}
