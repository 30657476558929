import { SpacingItemType } from '../../../data/types';
import amGame from './AM.svg';
import atGame from './AT.svg';
import bagGame from './BAG.svg';
import chGame from './CH.svg';
import ifGame from './IF.svg';
import inGame from './IN.svg';
import itGame from './IT.svg';
import noGame from './NO.svg';
import redGame from './RED.svg';
import shGame from './SH.svg';
import thGame from './TH.svg';
import upGame from './UP.svg';
import usGame from './US.svg';
import yesGame from './YES.svg';

type PlanetGameComponents = {
  [key in SpacingItemType]: any;
}

const planetGameWords: PlanetGameComponents = {
  [SpacingItemType.AM]: amGame,
  [SpacingItemType.AT]: atGame,
  [SpacingItemType.BAG]: bagGame,
  [SpacingItemType.CH]: chGame,
  [SpacingItemType.IF]: ifGame,
  [SpacingItemType.IN]: inGame,
  [SpacingItemType.IT]: itGame,
  [SpacingItemType.NO]: noGame,
  [SpacingItemType.RED]: redGame,
  [SpacingItemType.SH]: shGame,
  [SpacingItemType.TH]: thGame,
  [SpacingItemType.UP]: upGame,
  [SpacingItemType.US]: usGame,
  [SpacingItemType.YES]: yesGame,
};

export default planetGameWords;
