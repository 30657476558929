import {
  createMachine, assign, MachineConfig, StateMachine, MachineOptions,
} from 'xstate';
import {
  DragAndDropIteration,
  Iteration,
  SpacingActivity,
  SpacingActivityCategory,
  SpacingActivityType,
  SpacingItem,
  SpacingItemType,
  SpacingSet,
  SpacingSetType,
  StrokeOutcome,
  StrokeOutcomeType,
} from '../../data/types';
import { spacingActivities } from '../../data/activities';
import deepCopy from '../../shared/deepCopy';
import { DrawingStroke } from '../../components/Konva/types';
import { strokeOutcomes } from '../../data/stroke-outcomes';
import { getNextSpacingSetInOrder } from '../../data/spacing/spacing-sets';

export type DrawnSpacingCharacter = {
  order: number,
  spacingItemType: SpacingItemType,
  setType: SpacingSetType | undefined,
  drawingStrokes: DrawingStroke[],
  isSelected: boolean,
}

export type DroppedSpacingCharacter = {
  order: number,
  spacingItemType: SpacingItemType,
  setType: SpacingSetType | undefined,
  isSelected: boolean,
}

export enum SpacingActionOutcome {
  'PASS' = 'PASS',
  'PASS_WITH_FEEDBACK' = 'PASS_WITH_FEEDBACK',
  'FAIL' = 'FAIL',
}

export enum DragAndDropActionOutcome {
  'CORRECT' = 'CORRECT',
  'TOO_CLOSE' = 'TOO_CLOSE',
  'TOO_WIDE' = 'TOO_WIDE',
}

export enum SpacingState {
  'INIT' = 'INIT',
  // show character/item button page
  'SELECTING_NEXT_ITEM' = 'SELECTING_NEXT_ITEM',
  // play demo video
  'SHOWING_DEMO' = 'SHOWING_DEMO',
  // play intro audio and/or demo animations
  'CUEING_ACTION' = 'CUEING_ACTION',
  // user can write or do the activity
  'ACTIVE' = 'ACTIVE',
  // user gets success message or feedback message
  'SHOWING_SUCCESS_FEEDBACK' = 'SHOWING_SUCCESS_FEEDBACK',
  // user gets error message
  'SHOWING_ERROR_FEEDBACK' = 'SHOWING_ERROR_FEEDBACK',
}

export type SpacingActivityProgressState = {
  dragAndDropIterations?: DragAndDropIteration,
  iterations?: Iteration[],
  currentIteration?: number,
  outcome?: SpacingActionOutcome,
  dropOutcome?: DragAndDropActionOutcome,
  spacingErrorCount?: number,
}

type ActivitiesProgressState = { [key: string]: SpacingActivityProgressState };

export interface SpacingMachineSchema {
  states: {
    [key in SpacingState]: {};
  }
}

export type DragAndDropState = {
  isDragging: boolean,
  x: number,
  y: number
}

export type SpacingMachineContext = {
  spacingErrorCount: number,
  charFormationErrorCount: number,
  lastStrokeOutcome: StrokeOutcome | null,
  completedIterations: number,
  hasWatchedDemo: boolean,
  drawnSpacingChars: DrawnSpacingCharacter[],
  droppedSpacingChars: DroppedSpacingCharacter[],
  currentSet: SpacingSet | null,
  currentItem: SpacingItem | null,
  currentActivity: SpacingActivity | null,
  previousState: SpacingState | null,
  isPracticeMode: boolean,
  activitiesProgress: ActivitiesProgressState,
  dragAndDrop: DragAndDropState
}

export const initialActivitiesProgress = {
  [SpacingActivityType.DRAG_AND_DROP]: {},
  [SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]: {},
  [SpacingActivityType.MULTI_CHOICE_CLOSE]: {},
  [SpacingActivityType.MULTI_CHOICE_WIDE]: {},
  [SpacingActivityType.MULTI_CHOICE_ALL]: {},
};

export const initialDragAndDropProgress = {
  isDragging: false,
  x: 0,
  y: 0,
};

export const initialContext: SpacingMachineContext = {
  spacingErrorCount: 0,
  charFormationErrorCount: 0,
  lastStrokeOutcome: null,
  completedIterations: 0,
  hasWatchedDemo: false,
  drawnSpacingChars: [],
  droppedSpacingChars: [],
  currentSet: null,
  currentItem: null,
  currentActivity: null,
  previousState: null,
  isPracticeMode: false,
  activitiesProgress: { ...initialActivitiesProgress },
  dragAndDrop: { ...initialDragAndDropProgress },
};

export enum SpacingEventTypes {
  // user selects a set from the home page
  'LAUNCH_SET' = 'LAUNCH_SET',
  // user selects active item for activity sequence
  'SELECT_ITEM' = 'SELECT_ITEM',
  // only when user has done an action in the activity
  'ACTION_COMPLETED' = 'ACTION_COMPLETED',
  // all other 'next' cases, either when user clicks arrow button or audio/animation cue ends
  'NEXT' = 'NEXT',
  // rewatch the demo video from a later point in the activity loop
  'REWATCH_DEMO' = 'REWATCH_DEMO',
  // go back to item selection page in practice mode
  'RESET_ITEM' = 'RESET_ITEM',
  // full reset to init - goes back to home screen
  'RESET' = 'RESET'
}

export type SpacingTransition = {
  type: SpacingEventTypes,
  payload?: any,
  drawnSpacingChar?: DrawnSpacingCharacter,
  droppedSpacingChar?: DroppedSpacingCharacter,
  activityProgress?: SpacingActivityProgressState,
  dragAndDrop?: DragAndDropState
}

// guards
const isCueingActionCompletedAllowed = (context: SpacingMachineContext) => (
  (context.currentActivity && [
    SpacingActivityType.MULTI_CHOICE_CLOSE,
    SpacingActivityType.MULTI_CHOICE_WIDE,
    SpacingActivityType.MULTI_CHOICE_ALL,
    SpacingActivityType.DRAG_AND_DROP,
    SpacingActivityType.DRAG_AND_DROP_INDEPENDENT,
  ].includes(context.currentActivity?.type)) || false
);

const isSpacingFail = (context: SpacingMachineContext, event: SpacingTransition) => (
  [SpacingActionOutcome.FAIL, DragAndDropActionOutcome.TOO_CLOSE, DragAndDropActionOutcome.TOO_WIDE].includes(
    event.payload?.spacingOutcome,
  )
);

const isCharFormationFail = (context: SpacingMachineContext, event: SpacingTransition) => {
  if (!event.payload.charStrokeOutcome) return false;
  return strokeOutcomes[event.payload.charStrokeOutcome as StrokeOutcome].type === StrokeOutcomeType.ERROR;
};

const isSuccess = (context: SpacingMachineContext, event: SpacingTransition) => (
  [SpacingActionOutcome.PASS, SpacingActionOutcome.PASS_WITH_FEEDBACK, DragAndDropActionOutcome.CORRECT].includes(
    event.payload?.spacingOutcome,
  )
);

const isCueingActionFail = (context: SpacingMachineContext, event: SpacingTransition) => (
  isCueingActionCompletedAllowed(context) && isSpacingFail(context, event)
);

const isCueingActionSuccess = (context: SpacingMachineContext, event: SpacingTransition) => (
  isCueingActionCompletedAllowed(context) && isSuccess(context, event)
);

const isPracticeMode = (context: SpacingMachineContext) => context.isPracticeMode;

const cameFromActiveState = (context: SpacingMachineContext) => (
  context.previousState === SpacingState.ACTIVE
);

const cameFromSuccessFeedbackState = (context: SpacingMachineContext) => (
  context.previousState === SpacingState.SHOWING_SUCCESS_FEEDBACK
);

const cameFromErrorFeedbackState = (context: SpacingMachineContext) => (
  context.previousState === SpacingState.SHOWING_ERROR_FEEDBACK
);

const hasNextActivity = (context: SpacingMachineContext) => !!(context.currentActivity?.nextActivityType);

const hasNextItem = (context: SpacingMachineContext) => !!(context.currentSet?.sequenceItems?.length);

const hasNextSet = (context: SpacingMachineContext) => {
  if (!context.currentSet) return false;
  return getNextSpacingSetInOrder(context.currentSet) !== null;
};

const isFirstErrorThreshold = (context: SpacingMachineContext) => context.spacingErrorCount === 2;

const isSecondErrorThreshold = (context: SpacingMachineContext) => context.spacingErrorCount === 3;

const isThirdErrorThreshold = (context: SpacingMachineContext) => context.spacingErrorCount === 5;

const isPlanetGameActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.category === SpacingActivityCategory.PLANET_GAME
);

const isMultiChoiceActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.category === SpacingActivityCategory.MULTI_CHOICE
);

const isFadedWritingActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.type === SpacingActivityType.TRACE_FADED
);

const isChaseShootingStarActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.type === SpacingActivityType.TRACE_SHOOTING_STAR
);

const isIndependentActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.type === SpacingActivityType.SPACE_INDEPENDENT
);

const isDragAndDropActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.type === SpacingActivityType.DRAG_AND_DROP
  || context.currentActivity?.type === SpacingActivityType.DRAG_AND_DROP_INDEPENDENT
);

const isSelfAssessmentActivity = (context: SpacingMachineContext) => (
  context.currentActivity?.type === SpacingActivityType.SELF_ASSESSMENT
);

const hasNextIteration = (context: SpacingMachineContext) => (
  (context.currentActivity?.requiredIterations || 0) > context.completedIterations + 1
);

const hasNextIterationWithoutCue = (context: SpacingMachineContext) => (
  hasNextIteration(context)
  && (isIndependentActivity(context) || isPlanetGameActivity(context))
);

const shouldGoToNextActivityAfterError = (context: SpacingMachineContext) => (
  isMultiChoiceActivity(context)
  || (isPlanetGameActivity(context) && isSecondErrorThreshold(context) && hasNextActivity(context))
);

const shouldSkipItemAfterError = (context: SpacingMachineContext) => (
  // intended for writing activity, fallback for last planet game
  // MC activities should never hit third error
  isThirdErrorThreshold(context) && hasNextItem(context)
);

const shouldResetAfterError = (context: SpacingMachineContext) => (
  isThirdErrorThreshold(context) && !hasNextSet(context)
);

const shouldGoToNextSetAfterError = (context: SpacingMachineContext) => (
  isThirdErrorThreshold(context) && !hasNextItem(context) && hasNextSet(context)
);

const shouldEndSequenceAfterError = (context: SpacingMachineContext) => (
  isPlanetGameActivity(context) && isSecondErrorThreshold(context)
);

const shouldMoveToMultipleChoiceActivity = (context: SpacingMachineContext) => (
  isFadedWritingActivity(context) && isFirstErrorThreshold(context)
);

const shouldStartFromCueAfterError = (context: SpacingMachineContext) => (
  isChaseShootingStarActivity(context)
);

const shouldRepeatFadedLinePracticeAfterError = (context: SpacingMachineContext) => (
  (isChaseShootingStarActivity(context) || isIndependentActivity(context))
  && isSecondErrorThreshold(context)
);

const shouldGoToCharacterFormationPracticeAfterError = (context: SpacingMachineContext) => (
  isDragAndDropActivity(context) && isThirdErrorThreshold(context)
);

const shouldWatchDemoVideo = (context: SpacingMachineContext) => (
  isDragAndDropActivity(context) && isSecondErrorThreshold(context)
);

// effects/actions

const loadSet = assign<SpacingMachineContext, SpacingTransition>({
  currentSet: (context: SpacingMachineContext, event: SpacingTransition) => deepCopy(event.payload),
});

const loadItem = assign<SpacingMachineContext, SpacingTransition>({
  currentSet: (context: SpacingMachineContext) => {
    if (context.isPracticeMode || !context.currentSet) return context.currentSet;
    return {
      ...context.currentSet,
      sequenceItems: deepCopy(context.currentSet?.sequenceItems).slice(1),
    };
  },
  currentItem: (context: SpacingMachineContext, event: SpacingTransition) => deepCopy(event.payload),
  currentActivity: { ...Object.values(spacingActivities)[0] },
});

const incrementSpacingErrorCount = assign<SpacingMachineContext>({
  spacingErrorCount: (context: SpacingMachineContext) => context.spacingErrorCount + 1,
  lastStrokeOutcome: null,
  activitiesProgress: (context: SpacingMachineContext) => {
    if (!context.currentActivity?.nextActivityType) return { ...context.activitiesProgress };
    // if user has been sent back to faded from independent,
    // reset spacing error count to what it was previously for independent.
    if (context.currentActivity?.type === SpacingActivityType.DRAG_AND_DROP_INDEPENDENT) {
      const currentActivityProgress = { ...context.activitiesProgress[context.currentActivity.type] };
      const updatedActivitiesProgress = {
        ...context.activitiesProgress,
        [context.currentActivity.type]: {
          ...currentActivityProgress,
          spacingErrorCount: context.spacingErrorCount + 1,
        },
      };
      return updatedActivitiesProgress;
    }
    return { ...context.activitiesProgress };
  },
});

const incrementCharErrorCount = assign<SpacingMachineContext, SpacingTransition>({
  charFormationErrorCount: (context: SpacingMachineContext) => context.charFormationErrorCount + 1,
  lastStrokeOutcome: (context: SpacingMachineContext, event: SpacingTransition) => event.payload.charStrokeOutcome,
});

const markDemoWatched = assign<SpacingMachineContext>({
  hasWatchedDemo: true,
});

// after any success -> reset error count
const resetSpacingErrorCount = assign<SpacingMachineContext>({
  spacingErrorCount: 0,
});

// after completing activity -> reset completed iterations
const resetIterations = assign<SpacingMachineContext>({
  completedIterations: 0,
});

const resetItemContext = assign<SpacingMachineContext>({
  completedIterations: 0,
  spacingErrorCount: 0,
  charFormationErrorCount: 0,
  lastStrokeOutcome: null,
  currentActivity: null,
  currentItem: null,
});

const resetPreviousState = assign<SpacingMachineContext>({
  previousState: initialContext.previousState,
});

const updatePreviousState = assign<SpacingMachineContext, SpacingTransition>({
  previousState: (context: SpacingMachineContext, event: SpacingTransition) => {
    if (context.currentActivity?.type === SpacingActivityType.MULTI_CHOICE_ALL
      || context.currentActivity?.type === SpacingActivityType.MULTI_CHOICE_CLOSE
      || context.currentActivity?.type === SpacingActivityType.MULTI_CHOICE_WIDE) {
      return context.previousState;
    }
    return event.payload;
  },
});

const incrementCompletedIterations = assign<SpacingMachineContext>({
  completedIterations: (context: SpacingMachineContext) => context.completedIterations + 1,
});

const returnToFadedLineActivity = assign<SpacingMachineContext>({
  completedIterations: 0,
  spacingErrorCount: 0,
  currentActivity: (context: SpacingMachineContext) => {
    if (!context.currentActivity?.type) return null;

    const activity = {
      ...spacingActivities.TRACE_FADED,
      nextActivityType: context.currentActivity.type,
    };

    return activity;
  },
});

const advanceToNextActivity = assign<SpacingMachineContext>({
  completedIterations: (context: SpacingMachineContext) => {
    const nextActivityType = context.currentActivity?.nextActivityType;
    if (nextActivityType) {
      const nextActivity = { ...spacingActivities[nextActivityType] };
      // if an activity has multiple iterations and one or more of the iterations
      // have been completed, return to the next uncompleted iteration.
      if ((nextActivity.requiredIterations > 1)) {
        return context.activitiesProgress[nextActivityType]?.currentIteration || 0;
      }
    }
    return 0;
  },
  spacingErrorCount: (context: SpacingMachineContext) => {
    if (!context.currentActivity?.nextActivityType) return 0;
    // if user has been sent back to faded from chase or independent,
    // reset spacing error count to what it was previously for chase/independent.
    const nextActivity = { ...spacingActivities[context.currentActivity.nextActivityType] };
    if (context.currentActivity?.type === SpacingActivityType.TRACE_FADED
      && (nextActivity.category === SpacingActivityCategory.WRITING)) {
      return context.activitiesProgress[nextActivity.type]?.spacingErrorCount || 0;
    }
    return 0;
  },
  charFormationErrorCount: 0,
  lastStrokeOutcome: null,
  currentActivity: (context: SpacingMachineContext) => {
    if (!context.currentActivity?.nextActivityType) return null;

    const nextActivity = { ...spacingActivities[context.currentActivity.nextActivityType] };
    const currentItemCharLength = context.currentItem?.display?.length || 1;

    if ([SpacingActivityType.PLANET_1_GAME, SpacingActivityType.PLANET_2_GAME].includes(nextActivity.type)
      && currentItemCharLength > 2) {
      nextActivity.requiredIterations = currentItemCharLength - 1;
    }

    return nextActivity;
  },
  activitiesProgress: (context: SpacingMachineContext) => {
    // Do not delete data for MC activities on advance. They will be deleted
    // when the set is finished.
    if (!context.activitiesProgress || !context.currentActivity?.type
      || context.currentActivity.category !== SpacingActivityCategory.WRITING) return { ...context.activitiesProgress };
    const currentActivityProgress = context.activitiesProgress[context.currentActivity.type];
    if (currentActivityProgress) {
      return {
        ...context.activitiesProgress,
        [context.currentActivity.type]: {},
      };
    }
    return { ...context.activitiesProgress };
  },
});

const skipThisItem = assign<SpacingMachineContext>({
  completedIterations: 0,
  spacingErrorCount: 0,
  charFormationErrorCount: 0,
  lastStrokeOutcome: null,
  currentActivity: null,
  activitiesProgress: { ...initialActivitiesProgress },
  currentSet: (context: SpacingMachineContext) => {
    if (!context.currentSet || !context.currentItem) return null;
    const thisItem = deepCopy(context.currentItem);
    return { ...context.currentSet, sequenceItems: [...context.currentSet.sequenceItems, thisItem] };
  },
});

const skipToNextSet = assign<SpacingMachineContext>({
  ...initialContext,
  activitiesProgress: { ...initialActivitiesProgress },
  currentSet: (context: SpacingMachineContext) => {
    if (!context.currentSet) return null;
    const nextSet = getNextSpacingSetInOrder(context.currentSet);
    if (!nextSet) return null;
    return { ...nextSet };
  },
});

const setPracticeMode = assign<SpacingMachineContext>({
  isPracticeMode: () => true,
  currentActivity: null,
  currentItem: null,
  spacingErrorCount: 0,
  charFormationErrorCount: 0,
  lastStrokeOutcome: null,
  completedIterations: 0,
  activitiesProgress: { ...initialActivitiesProgress },
});

const resetToInit = assign<SpacingMachineContext>({
  ...initialContext,
});

const updateDrawnSpacingChars = assign<SpacingMachineContext, SpacingTransition>({
  drawnSpacingChars: (context: SpacingMachineContext, event: SpacingTransition) => {
    if (!isIndependentActivity(context) && !isSelfAssessmentActivity) return context.drawnSpacingChars;
    const newDrawnSpacingChar: DrawnSpacingCharacter | undefined = event.drawnSpacingChar;

    if (newDrawnSpacingChar) {
      if (!context.drawnSpacingChars.find((drawnChar) => drawnChar.order === newDrawnSpacingChar.order)) {
        // used for adding new drawn chars to array
        return [...context.drawnSpacingChars, newDrawnSpacingChar];
      }
      return context.drawnSpacingChars.map((drawnChar) => {
        // used for updating existing drawn chars with new information (ex. isSelected === true)
        if (drawnChar.order === newDrawnSpacingChar.order) {
          return newDrawnSpacingChar;
        }
        return drawnChar;
      });
    }
    return context.drawnSpacingChars;
  },
});

const updateDroppedSpacingChars = assign<SpacingMachineContext, SpacingTransition>({
  droppedSpacingChars: (context: SpacingMachineContext, event: SpacingTransition) => {
    if (!isDragAndDropActivity(context)) return context.drawnSpacingChars;
    const newDroppedSpacingChar: DroppedSpacingCharacter | undefined = event.droppedSpacingChar;

    if (newDroppedSpacingChar) {
      if (!context.droppedSpacingChars.find((droppedChar) => droppedChar.order === newDroppedSpacingChar.order)) {
        // used for adding new dropped chars to array
        return [...context.droppedSpacingChars, newDroppedSpacingChar];
      }
      return context.droppedSpacingChars.map((droppedChar) => {
        // used for updating existing drawn chars with new information (ex. isSelected === true)
        if (droppedChar.order === newDroppedSpacingChar.order) {
          return newDroppedSpacingChar;
        }
        return droppedChar;
      });
    }
    return context.droppedSpacingChars;
  },
});

const updateActivityProgress = (context: SpacingMachineContext, event: SpacingTransition) => {
  const allActivityProgress = { ...context.activitiesProgress };
  const currentActivityType = context.currentActivity?.type;
  if (allActivityProgress && currentActivityType) {
    // @ts-expect-error
    allActivityProgress[currentActivityType] = event.activityProgress;
  }
  return allActivityProgress;
};

const updateAllProgress = assign<SpacingMachineContext, SpacingTransition>({
  activitiesProgress: (context: SpacingMachineContext, event: SpacingTransition) => (
    updateActivityProgress(context, event)),
});

const updateMCProgress = assign<SpacingMachineContext, SpacingTransition>({
  activitiesProgress: (context: SpacingMachineContext, event: SpacingTransition) => {
    if (!context.activitiesProgress || !context.currentActivity?.type
      || context.currentActivity.category !== SpacingActivityCategory.MULTI_CHOICE) {
      return { ...context.activitiesProgress };
    }
    return updateActivityProgress(context, event);
  },
});

const setDragAndDropInitPosition = assign<SpacingMachineContext>({
  dragAndDrop: (context: SpacingMachineContext) => {
    const initPosition = {
      isDragging: false,
      x: 975,
      y: context.currentActivity?.type === SpacingActivityType.DRAG_AND_DROP_INDEPENDENT ? 300 : 420,
    };

    return initPosition;
  },
});

const setDragAndDropPosition = assign<SpacingMachineContext, SpacingTransition>({
  dragAndDrop: (context: SpacingMachineContext, event: SpacingTransition) => {
    const dragAndDropPosition = {
      isDragging: event.dragAndDrop?.isDragging || false,
      x: event.dragAndDrop?.x || 975,
      y: event.dragAndDrop?.y || 420,
    };

    return dragAndDropPosition;
  },
});

// machine config

const spacingMachineConfig: MachineConfig<SpacingMachineContext, SpacingMachineSchema, SpacingTransition> = {
  schema: {
    context: {} as SpacingMachineContext,
    events: {} as SpacingTransition,
  },
  predictableActionArguments: true,
  id: 'spacing',
  initial: SpacingState.INIT,
  context: initialContext,
  states: {
    [SpacingState.INIT]: {
      on: {
        [SpacingEventTypes.LAUNCH_SET]: {
          target: SpacingState.SELECTING_NEXT_ITEM,
          actions: ['loadSet', 'setDragAndDropInitPosition'],
        },
      },
    },
    [SpacingState.SELECTING_NEXT_ITEM]: {
      on: {
        [SpacingEventTypes.SELECT_ITEM]: {
          target: SpacingState.SHOWING_DEMO,
          actions: ['loadItem'],
        },
        [SpacingEventTypes.RESET]: {
          target: SpacingState.INIT,
          actions: 'resetToInit',
        },
      },
      entry: 'resetItemContext',
    },
    [SpacingState.SHOWING_DEMO]: {
      on: {
        [SpacingEventTypes.NEXT]: [
          {
            cond: 'cameFromActiveState',
            target: SpacingState.ACTIVE,
          },
          {
            cond: 'cameFromSuccessFeedbackState',
            target: SpacingState.SHOWING_SUCCESS_FEEDBACK,
          },
          {
            cond: 'cameFromErrorFeedbackState',
            target: SpacingState.SHOWING_ERROR_FEEDBACK,
          },
          {
            target: SpacingState.CUEING_ACTION,
          },
        ],
      },
      exit: ['markDemoWatched', 'resetPreviousState'],
    },
    [SpacingState.CUEING_ACTION]: {
      on: {
        [SpacingEventTypes.NEXT]: SpacingState.ACTIVE,
        [SpacingEventTypes.REWATCH_DEMO]: {
          target: SpacingState.SHOWING_DEMO,
          actions: 'updatePreviousState',
        },
        [SpacingEventTypes.RESET_ITEM]: {
          cond: 'isPracticeMode',
          target: SpacingState.SELECTING_NEXT_ITEM,
          actions: 'setPracticeMode',
        },
        [SpacingEventTypes.ACTION_COMPLETED]: [
          {
            cond: 'isCharFormationFail',
            target: SpacingState.SHOWING_ERROR_FEEDBACK,
            actions: ['incrementCharErrorCount'],
          },
          {
            cond: 'isCueingActionFail',
            target: SpacingState.SHOWING_ERROR_FEEDBACK,
            actions: ['incrementSpacingErrorCount', 'updateMCProgress'],
          },
          {
            cond: 'isSelfAssessmentActivity',
            target: SpacingState.SHOWING_SUCCESS_FEEDBACK,
            actions: 'updateDrawnSpacingChars',
          },
          {
            cond: 'isIndependentActivity',
            target: SpacingState.SHOWING_SUCCESS_FEEDBACK,
            actions: ['updateDrawnSpacingChars', 'updateAllProgress'],
          },
          {
            cond: 'isCueingActionSuccess',
            target: SpacingState.SHOWING_SUCCESS_FEEDBACK,
            actions: ['updateAllProgress'],
          },
        ],
      },
    },
    [SpacingState.ACTIVE]: {
      on: {
        [SpacingEventTypes.ACTION_COMPLETED]: [
          {
            cond: 'isSuccess',
            target: SpacingState.SHOWING_SUCCESS_FEEDBACK,
            actions: ['setDragAndDropPosition', 'updateAllProgress'],
          },
          {
            cond: 'isSpacingFail',
            target: SpacingState.SHOWING_ERROR_FEEDBACK,
            actions: ['incrementSpacingErrorCount', 'updateMCProgress'],
          },
        ],
        [SpacingEventTypes.REWATCH_DEMO]: {
          target: SpacingState.SHOWING_DEMO,
          actions: 'updatePreviousState',
        },
        [SpacingEventTypes.RESET_ITEM]: {
          cond: 'isPracticeMode',
          target: SpacingState.SELECTING_NEXT_ITEM,
          actions: 'setPracticeMode',
        },
      },
    },
    [SpacingState.SHOWING_ERROR_FEEDBACK]: {
      on: {
        [SpacingEventTypes.NEXT]: [
          {
            cond: 'shouldGoToCharacterFormationPracticeAfterError',
            target: SpacingState.INIT,
            actions: 'resetToInit',
          },
          {
            cond: 'shouldWatchDemoVideo',
            target: SpacingState.SHOWING_DEMO,
          },
          {
            cond: 'shouldGoToNextActivityAfterError',
            target: SpacingState.CUEING_ACTION,
            actions: 'advanceToNextActivity',
          },
          {
            target: SpacingState.ACTIVE,
          },
          // [SW-1024] TODO: Delete everything bellow after we have everything ready
          {
            cond: 'shouldRepeatFadedLinePracticeAfterError',
            target: SpacingState.CUEING_ACTION,
            actions: 'returnToFadedLineActivity',
          },
          {
            cond: 'shouldSkipItemAfterError',
            target: SpacingState.SELECTING_NEXT_ITEM,
            actions: 'skipThisItem',
          },
          {
            cond: 'shouldGoToNextSetAfterError',
            target: SpacingState.SELECTING_NEXT_ITEM,
            actions: 'skipToNextSet',
          },
          {
            cond: 'shouldEndSequenceAfterError',
            target: SpacingState.SELECTING_NEXT_ITEM,
            actions: 'setPracticeMode',
          },
          {
            cond: 'shouldMoveToMultipleChoiceActivity',
            target: SpacingState.CUEING_ACTION,
            actions: 'advanceToNextActivity',
          },
          {
            cond: 'shouldResetAfterError',
            target: SpacingState.INIT,
            actions: 'resetToInit',
          },
          {
            cond: 'shouldStartFromCueAfterError',
            target: SpacingState.CUEING_ACTION,
          },
        ],
        [SpacingEventTypes.REWATCH_DEMO]: {
          target: SpacingState.SHOWING_DEMO,
          actions: 'updatePreviousState',
        },
        [SpacingEventTypes.RESET_ITEM]: {
          cond: 'isPracticeMode',
          target: SpacingState.SELECTING_NEXT_ITEM,
          actions: 'setPracticeMode',
        },
      },
    },
    [SpacingState.SHOWING_SUCCESS_FEEDBACK]: {
      on: {
        [SpacingEventTypes.NEXT]: [
          {
            cond: 'hasNextIterationWithoutCue',
            target: SpacingState.ACTIVE,
            actions: ['resetSpacingErrorCount', 'incrementCompletedIterations'],
          },
          {
            cond: 'hasNextIteration',
            target: SpacingState.CUEING_ACTION,
            actions: ['resetSpacingErrorCount', 'incrementCompletedIterations'],
          },
          {
            cond: 'hasNextActivity',
            target: SpacingState.CUEING_ACTION,
            actions: 'advanceToNextActivity',
          },
          {
            cond: 'hasNextItem',
            target: SpacingState.SELECTING_NEXT_ITEM,
          },
          // end of sequence case
          {
            target: SpacingState.SELECTING_NEXT_ITEM,
            actions: 'setPracticeMode',
          },
        ],
        [SpacingEventTypes.REWATCH_DEMO]: {
          target: SpacingState.SHOWING_DEMO,
          actions: 'updatePreviousState',
        },
        [SpacingEventTypes.RESET_ITEM]: {
          cond: 'isPracticeMode',
          target: SpacingState.SELECTING_NEXT_ITEM,
          actions: 'setPracticeMode',
        },
      },
    },
  },
};

const spacingMachineOptions: Partial<MachineOptions<SpacingMachineContext, any>> = {
  guards: {
    isSpacingFail,
    isCharFormationFail,
    isSuccess,
    isCueingActionFail,
    isCueingActionSuccess,
    isPracticeMode,
    cameFromActiveState,
    cameFromSuccessFeedbackState,
    cameFromErrorFeedbackState,
    hasNextIteration,
    hasNextIterationWithoutCue,
    hasNextActivity,
    hasNextItem,
    hasNextSet,
    isMultiChoiceActivity,
    shouldGoToNextActivityAfterError,
    shouldSkipItemAfterError,
    shouldResetAfterError,
    shouldGoToNextSetAfterError,
    shouldEndSequenceAfterError,
    shouldMoveToMultipleChoiceActivity,
    shouldStartFromCueAfterError,
    shouldRepeatFadedLinePracticeAfterError,
    shouldGoToCharacterFormationPracticeAfterError,
    isSelfAssessmentActivity,
    isIndependentActivity,
    isDragAndDropActivity,
    shouldWatchDemoVideo,
  },
  actions: {
    loadSet,
    loadItem,
    incrementSpacingErrorCount,
    incrementCharErrorCount,
    markDemoWatched,
    resetSpacingErrorCount,
    resetIterations,
    resetItemContext,
    resetPreviousState,
    updatePreviousState,
    incrementCompletedIterations,
    advanceToNextActivity,
    skipThisItem,
    skipToNextSet,
    setPracticeMode,
    returnToFadedLineActivity,
    resetToInit,
    updateDrawnSpacingChars,
    updateDroppedSpacingChars,
    updateAllProgress,
    updateMCProgress,
    setDragAndDropInitPosition,
    setDragAndDropPosition,
  },
};

export const spacingMachine:
  StateMachine<SpacingMachineContext, SpacingMachineSchema, SpacingTransition> = createMachine(
    spacingMachineConfig,
    spacingMachineOptions,
  );
