import styled from 'styled-components';

interface PositionWrapperProps {
  top?: number,
  right?: number,
}

export const AbsolutePositionWrapper = styled.div`
  position: absolute;
  top: ${(props: PositionWrapperProps) => (props.top ? `${props.top}px` : 'auto')};
  right: ${(props: PositionWrapperProps) => (props.right ? `${props.right}px` : 'auto')};
`;
