import React, { useMemo } from 'react';
import { Group, Path } from 'react-konva';
import envSettings from '../../data/envSettings';
import {
  Character, DifficultyLevel, Stroke,
} from '../../data/types';
import { SCALE_LARGE } from '../../shared/constants';

type CharacterModelDragNDropType = {
  character: Character
  difficultyLevel: DifficultyLevel,
  stroke: Stroke,
  positioning: { xOffset: number, yOffset: number },
  children?: any,
}

export default function CharacterModelDragNDrop({
  character,
  difficultyLevel,
  stroke,
  positioning,
  children,
}: CharacterModelDragNDropType) {
  const characterPathWidth = useMemo(() => {
    const pathWidth = character.gradingSettings.pathStrokeWidth;
    if (difficultyLevel === DifficultyLevel.EASY) return pathWidth * SCALE_LARGE;
    return pathWidth;
  }, [character, difficultyLevel]);

  return (
    <Group
      id="character-model-path"
      x={positioning.xOffset}
      y={positioning.yOffset}
    >
      <Path
        data={stroke?.paths[difficultyLevel]}
        stroke={envSettings?.devMode ? 'gray' : 'transparent'}
        opacity={envSettings?.devMode ? 0.2 : 0}
        strokeWidth={characterPathWidth}
      />
      { children }
    </Group>
  );
}

CharacterModelDragNDrop.defaultProps = {
  children: null,
};
