import styled from 'styled-components';

const DocumentContainer = styled.div`
    width: 95%;
    height: 95%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${(props) => props.theme.fonts.families.secondary};
    pointer-events: auto;

    span, p, h3, a, li {
      color: ${(props) => props.theme.colors.documents.default};
      font-size: ${(props) => props.theme.fonts.sizes.sm};
      font-weight: normal;
      line-height: 19.6px;
    }

    h1 {
      font-weight: ${(props) => props.theme.fonts.weights.boldest};
      font-size: ${(props) => props.theme.fonts.sizes.md};
      line-height: 110%;
      color: ${(props) => props.theme.colors.documents.h2};
    }

    h2 {
      color: ${(props) => props.theme.colors.documents.default};
      font-size: ${(props) => props.theme.fonts.sizes.sm};
      font-weight: ${(props) => props.theme.fonts.weights.bolder};
    }

    h3 {
      display: inline;
    }

    a {
      text-decoration: underline;
    }

    ol {
      counter-reset: item;
    }

    ol > li {
      counter-increment: item;
    }

    ol ol > li {
      display: block;
    }

    ol ol > li:before {
        content: counters(item, ".") ". ";
        margin-left: -20px;
    }

    ul {
      list-style-type: disc;
    }
`;

export default DocumentContainer;
