import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TextButtonColors } from '../styled';

export const EducatorResourcesLinkContainer = styled.a`
  font-family: ${(props) => props.theme.fonts.families.secondary};
  text-decoration: underline;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fonts.sizes.lg};
  font-weight: ${(props) => props.theme.fonts.weights.boldest};
  color: ${(props) => props.theme.colors.text.tertiary};
`;

type RectangleLinkType = {
  colors: TextButtonColors,
}

export const RectangleLink = styled(Link) <RectangleLinkType>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 170px;
  width: 100%;
  height: 209px;
  margin: 0;
  border: unset;
  border-radius: 20%;
  background-color: ${(props) => props.colors.primary};
  box-shadow: 0px 10px 0px ${(props) => props.colors.secondary};
  color: ${(props) => props.colors.contrastText};
  z-index: 2;
  text-align: center;
  text-decoration: none;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 20px;
  }

  img {
    width: 40px;
    height: auto;
    margin-bottom: 34px;
  }

  span {
    font-family: ${(props) => props.theme.fonts.families.secondary};
    font-weight: ${(props) => props.theme.fonts.weights.boldest};
    font-size: ${(props) => props.theme.fonts.sizes.md};
    text-transform: uppercase;
    line-height: 19px;
  }
`;
