import React from 'react';
import {
  DndContext, closestCenter, TouchSensor, useSensor, useSensors, DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove, SortableContext, horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

type SortableEntity = {
  id: string|number,
};

interface HorizontalListWrapperProps {
  items: SortableEntity[],
  setItems: React.Dispatch<React.SetStateAction<any[]>>,
  children: React.ReactNode,
}

export default function SortableHorizontalListWrapper({ items, setItems, children }: HorizontalListWrapperProps) {
  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 10,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((prevItems: SortableEntity[]) => {
        const oldIndex = prevItems.findIndex((i) => i.id === active?.id);
        const newIndex = prevItems.findIndex((i) => i.id === over?.id);
        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <div style={{ display: 'flex' }}>
          {children}
        </div>
      </SortableContext>
    </DndContext>
  );
}
