import React from 'react';
import { useTheme } from 'styled-components';
import { CircleButton, PillButtonWithSvg } from '../../styles/components/Buttons';
import setsPageGripIcon from '../../assets/images/icons/hand-setscreen.svg';
import activityPageGripIcon from '../../assets/images/icons/hand-practicemode.svg';

export enum GripButtonType {
  PILL = 'pill',
  CIRCLE = 'circle',
}

type GripButtonProps = {
  handleTouch: (() => void) | undefined,
  type: GripButtonType,
};

export default function GripButton({ handleTouch, type }: GripButtonProps) {
  const theme = useTheme();

  if (!handleTouch) return null;

  switch (type) {
    case GripButtonType.PILL:
      return (
        <PillButtonWithSvg
          type="button"
          className="grip-button"
          colors={theme.colors.buttons.sequence}
          aria-label="go to grip demo video page"
          onTouchStart={handleTouch}
          role="link"
        >
          <img src={setsPageGripIcon} alt="" style={{ width: '35%' }} />
        </PillButtonWithSvg>
      );
    case GripButtonType.CIRCLE:
      return (
        <CircleButton
          colors={theme.colors.buttons.grip}
          aria-label="go to grip demo video page"
          onTouchStart={handleTouch}
          role="link"
        >
          <img src={activityPageGripIcon} alt="" style={{ width: '80%' }} />
        </CircleButton>
      );
    default:
      return null;
  }
}
