import { CharacterType } from '../../../data/types';

/** *********************************************************
 * INSTRUCTIONS FOR PREPPING CHARACTERS FOR STAR GAME
 * 1. Backpack will provide an exportable SVG in Figma of the
 *    character with star overlay
 * 2. Save SVG in /src/components/SVG/StarGame directory
 * 3. Edit SVG:
 *    Each star on the character is represented by two path elements
 *    on for its outline and one for its fill. These path elements are siblings, and
 *    devs must group these elements before importing here.
 *    * in the SVG, wrap each star outline path and star fill path in a <g class="star"> tag
 *      ex:
 *          <g class="star">
 *            <path d="path data for star outline" />
 *            <path d="path data for star fill" />
 *          </g>
 * 4. Turn return the svg as a React component
 * 5. Update the the object below with the string version of the component's name
 * 6. Play the star game!
 ********************************************************** */

type StarGameComponents = {
  [key in CharacterType]: string;
}

const starGameComponents: StarGameComponents = {
  [CharacterType.LOWER_A]: 'LowerA',
  [CharacterType.LOWER_B]: 'LowerB',
  [CharacterType.LOWER_C]: 'LowerC',
  [CharacterType.LOWER_D]: 'LowerD',
  [CharacterType.LOWER_E]: 'LowerE',
  [CharacterType.LOWER_F]: 'LowerF',
  [CharacterType.LOWER_G]: 'LowerG',
  [CharacterType.LOWER_H]: 'LowerH',
  [CharacterType.LOWER_I]: 'LowerI',
  [CharacterType.LOWER_J]: 'LowerJ',
  [CharacterType.LOWER_K]: 'LowerK',
  [CharacterType.LOWER_L]: 'LowerL',
  [CharacterType.LOWER_M]: 'LowerM',
  [CharacterType.LOWER_N]: 'LowerN',
  [CharacterType.LOWER_O]: 'LowerO',
  [CharacterType.LOWER_P]: 'LowerP',
  [CharacterType.LOWER_Q]: 'LowerQ',
  [CharacterType.LOWER_R]: 'LowerR',
  [CharacterType.LOWER_S]: 'LowerS',
  [CharacterType.LOWER_T]: 'LowerT',
  [CharacterType.LOWER_U]: 'LowerU',
  [CharacterType.LOWER_V]: 'LowerV',
  [CharacterType.LOWER_W]: 'LowerW',
  [CharacterType.LOWER_X]: 'LowerX',
  [CharacterType.LOWER_Y]: 'LowerY',
  [CharacterType.LOWER_Z]: 'LowerZ',

  [CharacterType.UPPER_A]: 'UpperA',
  [CharacterType.UPPER_B]: 'UpperB',
  [CharacterType.UPPER_C]: 'UpperC',
  [CharacterType.UPPER_D]: 'UpperD',
  [CharacterType.UPPER_E]: 'UpperE',
  [CharacterType.UPPER_F]: 'UpperF',
  [CharacterType.UPPER_G]: 'UpperG',
  [CharacterType.UPPER_H]: 'UpperH',
  [CharacterType.UPPER_I]: 'UpperI',
  [CharacterType.UPPER_J]: 'UpperJ',
  [CharacterType.UPPER_K]: 'UpperK',
  [CharacterType.UPPER_L]: 'UpperL',
  [CharacterType.UPPER_M]: 'UpperM',
  [CharacterType.UPPER_N]: 'UpperN',
  [CharacterType.UPPER_O]: 'UpperO',
  [CharacterType.UPPER_P]: 'UpperP',
  [CharacterType.UPPER_Q]: 'UpperQ',
  [CharacterType.UPPER_R]: 'UpperR',
  [CharacterType.UPPER_S]: 'UpperS',
  [CharacterType.UPPER_T]: 'UpperT',
  [CharacterType.UPPER_U]: 'UpperU',
  [CharacterType.UPPER_V]: 'UpperV',
  [CharacterType.UPPER_W]: 'UpperW',
  [CharacterType.UPPER_X]: 'UpperX',
  [CharacterType.UPPER_Y]: 'UpperY',
  [CharacterType.UPPER_Z]: 'UpperZ',

  [CharacterType.NUMBER_0]: 'Number0',
  [CharacterType.NUMBER_1]: 'Number1',
  [CharacterType.NUMBER_2]: 'Number2',
  [CharacterType.NUMBER_3]: 'Number3',
  [CharacterType.NUMBER_4]: 'Number4',
  [CharacterType.NUMBER_5]: 'Number5',
  [CharacterType.NUMBER_6]: 'Number6',
  [CharacterType.NUMBER_7]: 'Number7',
  [CharacterType.NUMBER_8]: 'Number8',
  [CharacterType.NUMBER_9]: 'Number9',

  [CharacterType.SYMBOL_PLUS]: 'SymbolPlus',
  [CharacterType.SYMBOL_EQUAL]: 'SymbolEqual',
  [CharacterType.SYMBOL_QUESTION]: 'SymbolQuestion',
  [CharacterType.SYMBOL_EXCLAMATION]: 'SymbolExclamation',
};

export default starGameComponents;
