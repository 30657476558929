import { useMemo } from 'react';
import { useGameContext } from '../context/WritingContext/GameContext';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { ActivityType, SpacingActivityType } from '../data/types';

/* Character and Spacing activities occasionally share the same logic.
** This hooks allows for that common logic to check activity types across both.
*/
export default function useActivityTypes() {
  const ctx = useGameContext();
  const { state } = useSpacingGameContext();

  const isChaseActivity = useMemo(
    () => ctx?.currentActivity?.type === ActivityType.CHASE_STAR
      || state.context?.currentActivity?.type === SpacingActivityType.TRACE_SHOOTING_STAR,
    [ctx?.currentActivity?.type, state.context?.currentActivity?.type],
  );

  const isIndependentActivity = useMemo(
    () => ctx?.currentActivity?.type === ActivityType.INDEPENDENT
      || state.context?.currentActivity?.type === SpacingActivityType.SPACE_INDEPENDENT,
    [ctx?.currentActivity?.type, state.context?.currentActivity?.type],
  );

  return {
    isChaseActivity,
    isIndependentActivity,
  };
}
