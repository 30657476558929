import React from 'react';
import starOutlined from '../assets/images/star-yellow/star-yellow-outline.png';
import starFilled from '../assets/images/star-yellow/star-yellow-fill.png';

export default function StarScoreBoard({ gameProgress }: any) {
  const getRoundOutcome = (activityType: string): string => {
    if (gameProgress[activityType as string].totalStarsToCatch === 0) return 'not played';
    return gameProgress[activityType].starsCaught === gameProgress[activityType].totalStarsToCatch
      ? 'won'
      : 'lost';
  };

  const getStarImg = (activityType: string): string => {
    if (gameProgress[activityType].totalStarsToCatch === 0) return starOutlined;
    return gameProgress[activityType].starsCaught === gameProgress[activityType].totalStarsToCatch
      ? starFilled
      : starOutlined;
  };
  return (
    <>
      {Object.keys(gameProgress).map((key, index) => (
        <img key={key} alt={`Planet ${index + 1}: ${getRoundOutcome(key)}`} src={getStarImg(key)} className="star" />
      ))}
    </>
  );
}
