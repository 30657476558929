import React from 'react';
import { Link } from 'react-router-dom';
import tabletIcon from '../assets/images/icons/tablet-icon.svg';
import { Paths } from '../data/types';
import useTouchDevice from '../hooks/useTouchDevice';
import { TouchDeviceRequiredWrapper } from '../styles/components/ContentWrappers';

export default function TouchDeviceRequired() {
  const isTouchDevice = useTouchDevice();

  if (!isTouchDevice) {
    return (
      <TouchDeviceRequiredWrapper>
        <img alt="" src={tabletIcon} />
        <div>
          <h1>Oops!</h1>
          <p>Starwriter is only accessible on a tablet.</p>
        </div>
        <p className="small">
          For more information, go to
          {' '}
          <Link to={`/${Paths.ABOUT}`}>About page</Link>
        </p>
      </TouchDeviceRequiredWrapper>
    );
  }
  return null;
}
