import styled from 'styled-components';

const ProgressIndicatorWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 105px;
  position: absolute;
  bottom: 5%;
`;

export default ProgressIndicatorWrapper;
