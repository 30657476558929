import React, { useMemo } from 'react';
import { Circle } from 'react-konva';
import {
  CIRCLE_POSITION_ADJUSTMENT_LARGE,
  CIRCLE_POSITION_ADJUSTMENT_NORMAL,
  SCALE_LARGE,
} from '../../shared/constants';
import { getIntersectionNodeName, IntersectionType } from '../../shared/getIntersectionNodeName';
import {
  Character, DifficultyLevel, Point, ThresholdRestrictedPoint,
} from '../../data/types';
import envSettings from '../../data/envSettings';

type RequiredPointsType = {
  requiredPoints: (Point | ThresholdRestrictedPoint)[],
  character: Character,
  intersectedPoints: Set<string>,
  difficultyLevel: DifficultyLevel,
}
export default function RequiredPoints({
  requiredPoints, character, intersectedPoints, difficultyLevel,
}: RequiredPointsType) {
  const circlePositionAdjustment = useMemo(() => (difficultyLevel === DifficultyLevel.EASY
    ? CIRCLE_POSITION_ADJUSTMENT_LARGE
    : CIRCLE_POSITION_ADJUSTMENT_NORMAL), [character, difficultyLevel]);

  const getCoordinatesForDifficultyLevel = (point: Point | ThresholdRestrictedPoint) => {
    const coordinates = point.coordinates[difficultyLevel];
    if (!coordinates) return { x: circlePositionAdjustment, y: circlePositionAdjustment };
    return { x: coordinates.x + circlePositionAdjustment, y: coordinates.y + circlePositionAdjustment };
  };

  const getRadiusForDifficultyLevel = (radius: number) => {
    if (difficultyLevel !== DifficultyLevel.EASY) return radius;
    return radius * SCALE_LARGE;
  };

  return (
    <>
      {Array.from(requiredPoints).reverse().map((point) => {
      // only rendered if they haven't yet been intersected
      // so that we can detect intersections when drawn stroke
      // must return over same area
      // e.g. down and up on first vertical of 'm'
        const id = getIntersectionNodeName(IntersectionType.REQUIRED, point.orderId);
        const coordinates = getCoordinatesForDifficultyLevel(point);
        const radius = getRadiusForDifficultyLevel(point.radius || character.gradingSettings.pointRadius);

        const requiredPreviousIntersectionComplete = point.allowIntersectionAfterOrderId !== undefined
          ? intersectedPoints.has(
            getIntersectionNodeName(IntersectionType.REQUIRED, point.allowIntersectionAfterOrderId),
          ) : true;
        if (!intersectedPoints.has(id) && requiredPreviousIntersectionComplete) {
          return (
            <Circle
              id={id}
              key={id}
              x={coordinates.x}
              y={coordinates.y}
              stroke={envSettings?.devMode ? 'green' : 'transparent'}
              fill="transparent"
              radius={radius}
            />
          );
        }
        return null;
      })}
    </>
  );
}
