import styled from 'styled-components';

type SlideInUpAnimationProps = {
  leftPx: string;
  yTransition?: number
}

const SlideInUpAnimation = styled.div<SlideInUpAnimationProps>`
  position: absolute;
  z-index: 5;
  left: ${(props) => props.leftPx};
  
  &.planet-game {
    background-color: transparent;
    border: none;
  transform: translateY(100vh);
    visibility: hidden;
  }

  /* Do not change name of classes that begin with slide.
  ** These are connected to a <CSSTransition /> component.
  */
  &.slide-enter {
    transform: translateY(100vh);
    visibility: visible;
  }

  &.slide-enter-active {
    transform: translateY(${(props) => props.yTransition || 0}px);
    transition: transform 4000ms ease-out;

    &.planet-game {
      transition: transform 3000ms linear;
    }
  }
`;

export default SlideInUpAnimation;
