import React, { useState, useEffect } from 'react';
import { Stage } from 'react-konva';
import {
  Character, PracticeLinesRelationship, DifficultyLevel, SpacingItem, OptionType, SpacingFeedbackType,
} from '../../data/types';
import {
  DRAWABLE_MARGIN,
  TARGET_HEIGHT_NORMAL_EXTENDED,
  TARGET_HEIGHT_NORMAL,
} from '../../shared/constants';
import VisibleTargetZone from './VisibleTargetZone';
import CharacterModelDragNDrop from './CharacterModelDragNDrop';
import SpacingRestrictedAreas from './SpacingRestrictedAreas';
import ModelPathLayer from './ModelPathLayer';
import SpacingStarAnimation from '../Animations/SpacingStarAnimation';
import { spacingItems } from '../../data/spacing/spacing-items';
import { useSpacingGameContext } from '../../context/SpacingGameContext';
import { DragAndDropActionOutcome } from '../../stateMachines/spacingMachine/spacingMachine';

export const chooseSpacingFeedback: {
  [key in OptionType]: SpacingFeedbackType
} = {
  TOO_CLOSE: SpacingFeedbackType.TOO_CLOSE,
  TOO_WIDE: SpacingFeedbackType.TOO_WIDE,
  CORRECT: SpacingFeedbackType.CORRECT,
};
interface DropAreaProps {
  width: number,
  height: number,
  character: Character,
  spacingItem: SpacingItem,
  canDragAndDrop: boolean,
  doReset: boolean,
  showTargetZone: boolean,
}

export default function SpacingDropAreaStage({
  width,
  height,
  character,
  spacingItem,
  canDragAndDrop,
  doReset,
  showTargetZone,
}: DropAreaProps) {
  const { state } = useSpacingGameContext();
  const [currentCharacterDropIndex, setCurrentCharacterDropIndex] = useState<number>(0);
  const currentDrop = character.strokes[currentCharacterDropIndex] || null;
  const { currentItem } = state.context;
  const { activityProgress } = state.event;
  const [dropOutcome, setDropOutcome] = useState<DragAndDropActionOutcome | undefined>();
  const [showAnimationFeedback, setShowAnimationFeedback] = useState<boolean>(false);

  const resetForNextDropAtIndex = (index: number) => {
    setCurrentCharacterDropIndex(index);
  };

  useEffect(() => {
    if (doReset) {
      resetForNextDropAtIndex(0);
    }
  }, [doReset]);

  useEffect(() => {
    if (activityProgress?.dropOutcome) {
      setDropOutcome(activityProgress.dropOutcome);
      setShowAnimationFeedback(true);
    } else {
      setDropOutcome(undefined);
      setShowAnimationFeedback(false);
    }
  }, [activityProgress]);

  if (!currentItem) {
    return null;
  }

  return (
    <div style={{
      position: 'absolute',
    }}
    >
      <Stage
        width={width}
        height={height}
      >
        <ModelPathLayer canWrite={canDragAndDrop}>
          <VisibleTargetZone
            show={showTargetZone}
            positioning={{
              width,
              height: character.practiceLinesRelationship === PracticeLinesRelationship.BELOW_LINES
                ? TARGET_HEIGHT_NORMAL_EXTENDED
                : TARGET_HEIGHT_NORMAL,
              xOffset: 0,
            }}
          />
          <CharacterModelDragNDrop
            character={character}
            difficultyLevel={DifficultyLevel.NORMAL}
            stroke={currentDrop}
            positioning={{
              xOffset: spacingItem.positioning.scaffold.xOffset || 0,
              yOffset: DRAWABLE_MARGIN + (character.positioning[DifficultyLevel.NORMAL]?.scaffold.yOffset || 0),
            }}
          >
            <SpacingRestrictedAreas gradingPositions={spacingItem.positioning.grading} />
          </CharacterModelDragNDrop>
        </ModelPathLayer>
      </Stage>
      {dropOutcome
        && (
        <SpacingStarAnimation
          spacingFeedbackType={chooseSpacingFeedback[dropOutcome]}
          right={spacingItems[currentItem.type].positioning
            .dragAndDrop[chooseSpacingFeedback[dropOutcome]].yOffset ?? 0}
          isVisible={showAnimationFeedback}
          handleComplete={() => {}}
        />
        )}

    </div>
  );
}
