import React from 'react';

export default function TermsOfUse() {
  return (
    <div>
      <h1>Illuminate Literacy: Terms of Use</h1>
      <p><i>Last Updated: April 18, 2023</i></p>
      <p>
        <strong>
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.
        </strong>
      </p>
      <p>
        If you do not agree to these Terms of Use, you may not access or otherwise use the Services.
      </p>
      <p>
        This is a legally binding agreement between you and
        Illuminate Literacy (&ldquo;we&rdquo; or &ldquo;our&rdquo;) regarding your use of the
        {' '}
        <a href="https://starwriter.org/">https://starwriter.org/</a>
        {' '}
        and the services we provide (collectively, &ldquo;Services &rdquo;). &ldquo;You&rdquo;
        refers to the person bound by these Terms of Use, which
        includes a child who accesses or uses the Services and his or her legal parent or guardian.
        By accessing or using our Services, you agree to be bound by these Terms and by our Privacy Policy
        available at
        {' '}
        <a href="https://starwriter.org/">https://starwriter.org/</a>
        , which is incorporated here by reference.
      </p>
      <p>
        We reserve the right, at our discretion, to change these Terms in the future. When we do, we will
        notify you by updating the date of these Terms and posting it here on our website. Your continued
        access or use of the Services following any changes to the Terms constitutes your
        acceptance of those changes.
      </p>
      <ol>
        <li>
          <h2>Definitions</h2>
          <ul>
            <li>
              Illuminate Literacy is an early stage nonprofit focused, in partnership with our fiscal sponsor
              Cambiar Education and our sister R&amp;D organization AERDF Reading Reimagined, on unlocking
              the great reader in every child to end illiteracy in our country, centering
              the experiences of Black, Latino, and Native American students and students living in poverty.
            </li>
            <li>
              Services: Illuminate Literacy&rsquo;s prototypes, software services, and services we
              provide either currently in existence or in the future; the software and source code used by
              Illuminate Literacy to provide such services; user interface layouts, designs, images, text,
              knowledge base articles, program offers; and all other intellectual property protected
              under copyright, trademark, patent, publicity, or any other proprietary right.
            </li>
          </ul>
        </li>
        <li>
          <h2>Use of the Services</h2>
          <ol>
            <li>
              <h3>What are the Services?</h3>
              <ol>
                <li>
                  Illuminate Literacy operates the Starwriter digital handwriting program, a tech-enabled
                  early literacy solution that provides scalable access to evidence-based handwriting instruction.
                </li>
              </ol>
            </li>
            <li>
              <h3>General license to the Services</h3>
              <ol>
                <li>
                  <strong>Ownership:</strong>
                  {' '}
                  Except as expressly licensed to you in these Terms, Illuminate Literacy retains all ownership, right,
                  title, and interest in the Services and to the services,  which are protected by applicable
                  intellectual property laws, including copyright and trademark laws. No portion of the Services,
                  including the trademarks and service marks, may be used, displayed or reproduced without the prior
                  written consent from Illuminate Literacy. You may not share or reproduce any portion of the Services
                  outside of the Services or in any public forum.
                </li>
                <li>
                  <strong>Licenses:</strong>
                  {' '}
                  Illuminate Literacy grants you a limited, personal, non-exclusive, non-transferable, revocable
                  license to access and use the Services for your personal, non-commercial use.
                </li>
              </ol>
            </li>
            <li>
              <h3>Unauthorized use:</h3>
              {' '}
              If you use the Services in an unauthorized way, Illuminate Literacy may terminate your access to the
              Services and pursue other penalties, damages, losses, and profits to which Illuminate Literacy is
              entitled under this agreement or at law or equity. The following uses of the Services are explicitly
              prohibited:
              <ul>
                <li>
                  using the Services in any way for commercial purposes; using, displaying, sharing, or reproducing
                  any part of the Services, including the trademarks and service marks, without the prior written
                  consent of Illuminate Literacy;
                </li>
                <li>
                  obscuring or removing any watermark, copyright, or other proprietary notice from the Services;
                </li>
                <li>
                  mining, hacking, probing, spidering, or scraping the Services, or similarly gathering or extracting
                  data (whether manual or robotic), including by indexing, caching, or aggregation;
                </li>
                <li>
                  decompiling, reverse engineering, or making derivative works;
                </li>
                <li>
                  interfering (or engaging in any activity that may interfere) with any user&rsquo;s experience;
                </li>
                <li>
                  uploading, posting, or otherwise transmitting content that contains personally identifying information
                  (including, but not limited to actual names, phone numbers, mailing addresses or email addresses),
                  derogatory, harmful, abusive, defamatory, or unlawful content, content that is invasive of
                  another&rsquo;s privacy, or otherwise objectionable or commercial in nature; or
                </li>
                <li>
                  testing the Services for technical
                  vulnerabilities or circumventing any security measures or access restrictions.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h2>Limitations of Liability</h2>
          <ol>
            <li>
              Illuminate Literacy reserves the right to modify, suspend, or discontinue the Services at any time
              without notice. Illuminate Literacy further reserves the right, at any time without prior notice,
              to terminate your access and use of the Services for lack of use or if Illuminate Literacy believes
              that you have violated these Terms of Use. You agree that we shall not be liable to you or any third
              party for such actions.
            </li>
            <li>
              You assume all risk for any damage to your computer systems
              or network caused by the Services, including any damages resulting from computer viruses.
            </li>
            <li>
              In no event shall Illuminate Literacy, or any of their respective directors, officers, employees, agents,
              representatives and suppliers (or their respective successors and assigns) be liable to you or any third
              party in contract, tort (including negligence) or otherwise for any direct, indirect, consequential,
              special, incidental or putative damages, including, but not limited to, loss of use, service
              interruptions and loss of data, regardless of whether these parties had advance notice of the
              possibility of any such damages. Illuminate Literacy&rsquo;s total liability to you for any claim
              arising out of or relating to the services shall not exceed $100. Some states do not allow the
              foregoing limitation of liability so they may not apply to you.
            </li>
          </ol>
        </li>
        <li>
          <h2>Indemnity</h2>
          <ol>
            <li>
              To the extent that state law allows, you agree to indemnify and hold Illuminate Literacy and its
              directors, officers, employees, and agents harmless from any loss, damage, liability, or demand, including
              reasonable attorneys&rsquo; fees, arising out of, resulting from, or connected with the use of the
              Services and/or arising from your breach of these Terms of Use. You will cooperate fully and
              reasonably as required by Illuminate Literacy in the defense of any claim.
            </li>
            <li>
              Illuminate Literacy reserves the right to assume the exclusive defense and control of any matter for
              which you are required to indemnify us. You agree to cooperate with any reasonable requests to assist
              us in our defense of such matters.
            </li>
            <li>
              You hereby acknowledge that you understand that by accessing the Services and agreeing to these
              Terms of Use, you may be waiving rights with respect to claims that are at this time unknown or
              unsuspected, and in accordance with such waiver, you acknowledge that you have read and understood,
              and hereby expressly waive, the benefits of Section 1542 of the California Civil Code, and any similar
              law of any state or territory, which says: &ldquo;A general release does not extend to claims which the
              creditor does not know or suspect to exist in his favor at the time of executing the release,
              which if known by him must have materially affected his settlement with the debtor.&rdquo;
            </li>
          </ol>
        </li>
        <li>
          <h2>Representations</h2>
          <ol>
            <li>
              By accessing and using the Services, you represent that:
              <ul>
                <li>
                  you are an adult, parent, or legal guardian, or school or district employee authorized to
                  allow a child&rsquo;s access to and use of the Services;
                </li>
                <li>
                  you will use the Services in compliance with all applicable laws and regulations;
                </li>
                <li>your registration information, if applicable, is accurate;</li>
                <li>
                  you will keep your account credentials, if applicable, including your username and password,
                  confidential and will be responsible for all use of your
                  account. If you suspect any unauthorized use of your account, please contact us at
                  {' '}
                  <a href="mailto:Illuminate@cambiareducation.org">Illuminate@cambiareducation.org</a>
                  ; and
                </li>
                <li>
                  if you create an account on behalf of any child, you represent that you are authorized to accept these
                  Terms of Use on their behalf, and agree to be responsible for ensuring that those child users comply
                  with these Terms of Use.
                </li>
              </ul>
            </li>
            <li>You will not use the Services except pursuant to these Terms of Use.</li>
          </ol>
        </li>
        <li>
          <h2>Links</h2>
          <p>
            We may provide links to other web sites or resources. These links are provided solely as a
            convenience to you and inclusion of such links does not represent an endorsement by
            Illuminate Literacy. We are not responsible for the content of any linked websites or resources,
            and make no representations regarding the accuracy of such websites or resources.
          </p>
        </li>
        <li>
          <h2>Restrictions</h2>
          <ol>
            <li>
              The prototype version of the Services (used in SY22-23) will not collect any personally identifying
              information of students. There will be no individual user logins, and at no point will users have
              an opportunity to input any personally identifying information. The prototype will collect only
              activity-level data (e.g., number of sessions, session duration, device IP addresses, instructional
              videos viewed, letters/numbers practiced). We can collect (and share) data at the device level,
              but will not have the ability to know which users used which devices.
            </li>
            <li>
              If you provide a child with access to the Services, you
              represent and warrant that you are the parent or legal guardian, or the educator or administrator of an
              educational institution, and have obtained the necessary consents from the parent or legal guardian to
              provide such access.
            </li>
          </ol>
        </li>
        <li>
          <h2>No Warranties</h2>
          <p>
            The Services are provided &ldquo;as is&rdquo;. Illuminate Literacy makes no representations or
            warranties about the reliability, quality, suitability, availability, accuracy or completeness
            of the Services, and is not subject to liability for any delays or interruptions, viruses or other
            harmful components. You agree that your use of the Services is at your own risk. We reserve the right
            to modify, suspend or discontinue the Services at any time without notice. We further reserve the right,
            at any time without prior notice, to terminate your account and use of the Services for lack of use or if
            Illuminate Literacy believes that you have violated these Terms of Use. You agree that we shall not be
            liable to you or any third party for such actions.
          </p>
        </li>
        <li>
          <h2>Copyright Infringement</h2>
          <ol>
            <li>
              Illuminate Literacy respects the intellectual property rights of others, and requires that the people who
              use the Services do the same.
              If you believe that your work has been copied in a way that constitutes
              copyright infringement and is accessible on the Services, you may notify us by providing our copyright
              agent with the following information in writing:
            </li>
          </ol>
          <p>
            <ol>
              <li>
                Your name, postal address, telephone number and email address;
              </li>
              <li>
                A description of the copyrighted work that you claim has been infringed;
              </li>
              <li>
                A description of where the alleged infringing material is located, with a link, if possible;
              </li>
              <li>
                A statement by you that you have a good faith belief that the disputed use is not authorized by the
                copyright owner, its agent, or the law;
              </li>
              <li>
                An electronic or physical signature of the person authorized to act on behalf of the owner of the
                copyrighted material; and
              </li>
              <li>
                A statement made under penalty of perjury that the above information in your notice is accurate and that
                you are the copyright owner or are authorized to act on the copyright owner&rsquo;s behalf.
              </li>
            </ol>
          </p>
          <p>
            Our designated agent to receive notification of claimed infringement under the Digital Millennium
            Copyright Act of 1998 (&ldquo;DMCA&rdquo;) is:
          </p>
          <p>
            Illuminate Literacy
            <br />
            4653 Carmel Mountain Rd
            <br />
            San Diego, CA 92130
          </p>
        </li>
        <li>
          <h2>Termination and General Terms</h2>
          <ol>
            <li>
              <h3><strong>Entire agreement:</strong></h3>
              {' '}
              This agreement constitutes the entire agreement between you and Illuminate Literacy. No
              modification to this agreement will be binding, unless in writing and signed by an authorized Illuminate
              Literacy representative.
            </li>
            <li>
              <h3><strong>Termination; Material Breach:</strong></h3>
              {' '}
              Your breach of these Terms of Use or other applicable policies or agreements with Illuminate Literacy may
              result in Illuminate Literacy terminating your access to the Services, without any liability to Illuminate
              Literacy. You may terminate these Terms of Use at any time by ceasing to use the Services. You agree that
              any material breach of these Terms of Use will result in irreparable harm to Illuminate Literacy for which
              damages would be an inadequate remedy and, therefore, in addition to its rights and remedies otherwise
              available at law, Illuminate Literacy will be entitled to equitable relief, including both a preliminary
              and permanent injunction, if such a breach occurs. You waive any requirement for the posting of a
              bond or other security if Illuminate Literacy seeks such an injunction.
            </li>
            <li>
              <h3><strong>Governing law:</strong></h3>
              {' '}
              This agreement is governed by California law, without giving effect to conflict of law principles. Any
              controversy or claim arising out of or relating to these Terms of Use will be settled by binding
              arbitration in accordance with the commercial arbitration rules of the American Arbitration Association.
              Any such controversy or claim will be arbitrated on an individual basis, and will not be consolidated
              in any arbitration with any claim or controversy of any other party. The arbitration will be conducted
              in California, and judgment on the arbitration award may be entered into any court having jurisdiction
              thereof. The award of the arbitrator will be final and binding upon the parties without appeal or
              review except as permitted by California law. Notwithstanding the foregoing, either party may seek any
              interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to
              protect the party&rsquo;s rights or property pending the completion of arbitration. All notices, requests,
              and other communications under this agreement must be in writing (including email).
            </li>
            <li>
              <h3><strong>Severability:</strong></h3>
              {' '}
              If any portion of these terms is held to be unenforceable, the unenforceable portion shall
              be construed in accordance with applicable law to the greatest extent possible and the remainder of the
              provisions shall remain in full force and effect.
            </li>
            <li>
              <h3>Notice</h3>
              <ol>
                <li>
                  By using the Services, you consent to receiving electronic communications from Illuminate Literacy
                  providing notices about your account and information concerning or related to the Services.
                </li>
                <li>
                  You agree that any notice, agreements, disclosure, or
                  other communications that we send to you electronically will satisfy any legal communication
                  requirements, including that such communications be in writing.
                </li>
              </ol>
            </li>
            <li>
              Questions: Please direct any questions regarding these Terms of Use to
              {' '}
              <a href="mailto:Illuminate@cambiareducation.org">Illuminate@cambiareducation.org</a>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
