import React, { useEffect } from 'react';
import GripButton, { GripButtonType } from '../components/Buttons/GripButton';
import { useGameContext, GameFlowStep, ActionType } from '../context/WritingContext/GameContext';
import { SelectionAreaWrapper } from '../styles/components/ContentWrappers';
import { SelectSequenceContainer } from '../styles/components/ScreenContainers';
import selectASequence from '../assets/audio/appIntro/VO0.mp3';
import NoInternetToast from '../components/NoInternet/NoInternetToast';
import { useAudioContext } from '../context/AudioContext';
import useOnlineStatus from '../hooks/useOnlineStatus/useOnlineStatus';
import EducatorResourcesLink from '../components/Links/EducatorResourcesLink';
import SpacingSetsButtons from '../components/SpacingGame/SpacingSetsButtons';
import InstructionalSequenceButtons from '../components/InstructionalSequenceButtons';
import VersionDisplay from '../components/Version Display/VersionDisplay';
import BackButton from '../components/Buttons/BackButton';
import { Paths } from '../data/types';

export default function SelectSequence() {
  const ctx = useGameContext();
  const isOnline = useOnlineStatus();
  const audioContext = useAudioContext();

  useEffect(() => {
    /* Since this page is the first to show on app load, many browsers do not allow
        audio to play until its resumed or after a user gesture: https://goo.gl/7K7WLu.
        So, on load, SelectSequence will not play VO until a user taps on screen.
    */
    audioContext?.handlePlay({ src: selectASequence });
    return () => audioContext?.handleComplete();
  }, []);

  const handleGripDemoButtonTouch = () => {
    ctx?.dispatch({ type: ActionType.GO_TO_GRIP_DEMO_VIDEO });
  };

  const handleLessonButtonTouch = () => {
    audioContext?.handleComplete();
  };

  return (
    <>
      {!isOnline && <NoInternetToast />}
      <BackButton path={Paths.ACTIVITY_SET_SELECTION} placedLeft />
      <SelectionAreaWrapper>
        <h1 className="visually-hidden">Sequence Selection</h1>
        {ctx?.flowStep === GameFlowStep.INIT ? (
          <SelectSequenceContainer>
            <GripButton type={GripButtonType.PILL} handleTouch={handleGripDemoButtonTouch} />
            <InstructionalSequenceButtons onButtonSelection={handleLessonButtonTouch} />
            <SpacingSetsButtons onButtonSelection={handleLessonButtonTouch} />
            <div className="spacer" />
          </SelectSequenceContainer>
        ) : null}
        <VersionDisplay isLanding={false} />
        <EducatorResourcesLink shouldCheckAccess />
      </SelectionAreaWrapper>
    </>
  );
}
