enum Links {
  CAMBIAR_EDUCATION = 'https://www.cambiareducation.org/',
  DR_NELL_DUKE = 'https://www.nellkduke.org/',
  RESOURCES = 'https://sites.google.com/cambiareducation.org/starwriter-resources/',
  DEMO_VIDEO_RESOURCES = 'https://sites.google.com/cambiareducation.org/starwriter-resources/starwriter-video-library',
  EMAIL_CAMBIAR = 'mailto:Illuminate@cambiareducation.org',
  TERMS_OF_SERVICE = 'https://docs.google.com/document/d/1DVY4qEbB3xM3vvYLXtXLEB7J0VlfTQulp_Bh_epiTQA/edit?usp=sharing',
  PRIVACY_POLICY = 'https://docs.google.com/document/d/1iOfO3YGk04ZHB5KWve6bCE8h8gyYLPBzCeLhJZe6-j4/edit?usp=sharing',
  DIRECT_NOTICE = 'https://docs.google.com/document/d/1lYjEN5deOE4cuXhc7X89rudukOY7Uh6LUmPUqnJ_qtQ/edit?usp=sharing',
}

export default Links;
