import React, {
  RefObject, useEffect, useRef, useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import shootingStar from '../../../assets/images/shooting-star-spacing.png';
import SlideInUpAnimation from '../../../styles/components/SlideInUpAnimation';

type ShootingStarProps = {
  leftPosition: number,
  start: boolean,
  handleCaughtStar: () => void,
  handleMissedStar: () => void,
  setYAxis: (y :number) => void;
}

export default function PlanetGameShootingStar({
  leftPosition,
  start,
  handleCaughtStar,
  handleMissedStar,
  setYAxis,
}: ShootingStarProps) {
  const [showStar, setShowStar] = useState(false);
  const ref: RefObject<HTMLButtonElement> = useRef(null);

  useEffect(() => {
    setShowStar(start);
  }, [start]);
  return (
    <CSSTransition
      nodeRef={ref}
      in={showStar}
      appear={showStar}
      timeout={3000}
      unmountOnExit
      classNames="planet-game slide"
      onEntered={() => {
        setShowStar(false);
        handleMissedStar();
      }}
    >
      <SlideInUpAnimation
        as="button"
        className="planet-game"
        leftPx={`${leftPosition}px`}
        yTransition={175}
        ref={ref}
        onTouchStart={() => {
          // Determine the y axis position relative to the shooting star
          const starRect = ref.current?.getBoundingClientRect();
          if (starRect) {
            const clickedY = starRect.top;
            // Set clickedY values to determine the clicked vertical position
            setYAxis(clickedY);
            // Handle the star click
            setShowStar(false);
            handleCaughtStar();
          }
        }}
      >
        <img alt="" src={shootingStar} />
      </SlideInUpAnimation>
    </CSSTransition>
  );
}
