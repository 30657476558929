import React, {
  useState, useMemo, useEffect, useLayoutEffect, useRef,
} from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import {
  useGameContext, ActionType, GameMode,
} from '../context/WritingContext/GameContext';
import activities from '../data/activities';
import {
  Activity,
  ActivityType,
  Character,
  PracticeLinesRelationship,
  Iteration,
  StrokeOutcome,
  CorrectiveFeedback,
  GameScreens,
  DifficultyLevel,
  CharacterType,
  CorrectiveLegibilityTypes,
  StrokeOutcomeType,
  LineType,
  SequenceCategory,
} from '../data/types';
import ActivityScaffold from '../components/ActivityScaffold';
import { DrawingArea, DrawingAreaGrid } from '../styles/components/DrawingArea';
import WritingArea from '../components/Konva/WritingArea';
import { WritingPracticeAreaWrapper } from '../styles/components/ContentWrappers';
import {
  DRAWABLE_WIDTH_NORMAL,
  DRAWABLE_HEIGHT_NORMAL,
  DRAWABLE_HEIGHT_NORMAL_EXTENDED,
  FIRST_ERROR_THRESHOLD,
  SECOND_ERROR_THRESHOLD,
  DRAWABLE_HEIGHT_LARGE_EXTENDED,
  DRAWABLE_WIDTH_LARGE,
} from '../shared/constants';
import { DrawingStroke } from '../components/Konva/types';
import CompletedCharacter from '../components/Konva/CompletedCharacter';
import ActivityWrapper from '../styles/components/ActivityWrapper';
import ActivityRewardsAndNavigation from '../components/ActivityRewardsAndNavigation';
import { AudioCue } from '../components/AudioPlayer';
import yourTurn from '../assets/audio/yourTurn/VO4.mp3';
import activityIntros from '../assets/audio/activityIntros';
import { charInstructions } from '../assets/audio/charInstructions';
import activitySuccessCues from '../assets/audio/activitySuccess';
import watchDemoAgain from '../assets/audio/activityErrors/VO8.mp3';
import skipLetter from '../assets/audio/activityErrors/VO12-13.mp3';
import closeCircle from '../assets/audio/activityCorrectiveFeedback/VO68-closecircle.mp3';
import closeGap from '../assets/audio/activityCorrectiveFeedback/VO69-closegap.mp3';
import goToDottedActivity from '../assets/audio/activityErrors/VO20-21.mp3';
import yourTurnAgain from '../assets/audio/activityErrors/VO11.mp3';
import newPen from '../assets/audio/rewards/VO28.mp3';
import tryAgain from '../assets/audio/activityErrors/VO34.mp3';
import plink from '../assets/audio/activitySuccess/plink.mp3';
import getAnalyticsStrokeOutcomeMessage, { getCorrectiveFeedbackMessage } from '../shared/analyticsMessages';
import TravelingStarAnimation, { TravelingStarPosition } from '../components/Animations/TravelingStarAnimation';
import CloseGapAnimation from '../components/Animations/CloseGapAnimation';
import useTimeout from '../hooks/useTimeout';
import { useAudioContext } from '../context/AudioContext';
import useOnlineStatus from '../hooks/useOnlineStatus/useOnlineStatus';
import NoInternetPersistent from '../components/NoInternet/NoInternetPersistent';
import {
  useActivityProgressContext,
  ActionType as ProgressActionType,
} from '../context/ActivityProgressContext/ActivityProgressContext';
import correctiveFeedback from '../assets/audio/activityCorrectiveFeedback';
import TopNavigation from '../components/TopNavigation';
import envSettings from '../data/envSettings';
import { AnalyticsEventCategory } from '../data/types/analytics';
import ReadySetGoStarAnimation from '../components/Animations/ReadySetGoStarAnimation';
import { FeedbackActionType, useFeedbackContext } from '../context/FeedbackContext';
import useChaseTheShootingStar from '../hooks/useChaseTheShootingStar';
import useDrawnCharacters from '../hooks/useDrawnCharacters';
import set from '../assets/audio/instructions/VO49-readysetgo-set.mp3';
import go from '../assets/audio/instructions/VO49-readysetgo-go.mp3';
import { useRewardsContext } from '../context/RewardsContext';
import { RewardEventTypes, RewardState } from '../stateMachines/rewardsMachine/rewardsMachine';
import ScaffoldModel from '../components/Models/ScaffoldModel';
import useDifficultyLevel from '../hooks/useDifficultyLevel';
import { strokeOutcomes } from '../data/stroke-outcomes';
import { getStrokeErrorVO, getStrokeErrorVOByCharCategory } from '../assets/audio/activityErrors';
import useDirectionalStartDot from '../hooks/useDirectionalStartDot';
import { getPreferredOpacityByPenId } from '../data/pens';

// activity maps over iterations to render either FreeDraw area
// or a drawn character (in Chase the Shooting Star)
// so iterations array must not be empty
const defaultFirstIteration: Iteration = {
  id: 1,
  strokes: [],
};

export default function CharacterDrawingActivity() {
  const ctx = useGameContext();
  const rewards = useRewardsContext();
  const progress = useActivityProgressContext();
  const { trackEvent } = useMatomo();
  const timeout = useTimeout();
  const audioContext = useAudioContext();
  const isOnline = useOnlineStatus();
  const {
    isReadySetGoStarted, isReadySetGoFinished, finishReadySetGoSequence, beginShootingStarAnim,
    startShootingStarAnim, resetChaseStarIteration, startChaseStarIteration, getReadyStarAnimationData,
    stopChaseStarAnimation,
  } = useChaseTheShootingStar();
  const { dispatchDrawnCharacters } = useDrawnCharacters();
  const { difficultyLevel } = useDifficultyLevel();
  const isStartDotDirectional = useDirectionalStartDot();
  const [starMeterFrames, setStarMeterFrames] = useState<[number, number]>([0, 1]);
  const [showTravelingStarAnimation, setShowTravelingStarAnimation] = useState(false);
  const [showCloseGapAnimation, setShowCloseGapAnimation] = useState(false);
  const [activeAudioCue, setActiveAudioCue] = useState<AudioCue|null>(null);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [enablePenSelection, setEnablePenSelection] = useState(true);
  const [iterations, setIterations] = useState<Iteration[]>([defaultFirstIteration]);
  const [currentActivityCycle, setCurrentActivityCycle] = useState(1);
  const completedIterationsCount: number = iterations.filter((i) => i.strokes.length).length;
  const [lastStrokeOutcome, setLastStrokeOutcome] = useState<StrokeOutcome|null>(null);
  const [multipleStrokeLastOutcome, setMultipleStrokeLastOutcome] = useState<StrokeOutcome|null>(null);
  const [showScaffold, setShowScaffold] = useState(false);
  const [strokeCorrectiveFeedback, setStrokeCorrectiveFeedback] = useState<CorrectiveFeedback|null>(null);
  const [charEfficiencyTimer, setCharEfficiencyTimer] = useState<any>(null);
  const [strokeCount, setStrokeCount] = useState(0);
  const [showCorrectiveFeedback, setShowCorrectiveFeedback] = useState(false);
  const [drawnStrokeOpacity, setDrawnStrokeOpacity] = useState<number|null>(null);
  // TODO: May want to remove after stroke outcome warnings have been decided on.
  const feedbackCtx = useFeedbackContext();
  const drawingAreaRef = useRef<HTMLDivElement>(null);
  const readySetGoStarRef = useRef<HTMLDivElement>(null);

  // *********** Memoized Variables
  const canUseExperimentalInstructionalVO = useMemo(() => ctx?.currentActivity?.type === ActivityType.SOLID
    && ctx?.currentCharacter?.type
    && charInstructions[ctx.currentCharacter.type], [ctx?.currentActivity, ctx?.currentCharacter]);

  const drawingAreaHeight = useMemo(() => {
    // easy level always has extended drawable area.
    if (difficultyLevel === DifficultyLevel.EASY) return DRAWABLE_HEIGHT_LARGE_EXTENDED;
    return (ctx?.currentCharacter?.practiceLinesRelationship === PracticeLinesRelationship.BELOW_LINES)
      ? DRAWABLE_HEIGHT_NORMAL_EXTENDED
      : DRAWABLE_HEIGHT_NORMAL;
  }, [ctx?.currentActivity?.type, ctx?.currentCharacter]);

  const drawingAreaWidth = useMemo(() => ((difficultyLevel === DifficultyLevel.EASY)
    ? DRAWABLE_WIDTH_LARGE
    : DRAWABLE_WIDTH_NORMAL), [ctx?.currentActivity?.type, ctx?.currentCharacter]);

  const currentActivityProgress = useMemo(() => {
    if (!ctx || !ctx.currentActivity || !progress) return null;
    const current = progress.activities[ctx.currentActivity.type];
    if (!current) return null;
    return current;
  }, [ctx?.currentActivity?.type, progress?.activities]);

  const canWrite = useMemo(() => {
    if (ctx?.currentActivity?.type === ActivityType.CHASE_STAR) {
      return isReadySetGoFinished;
    }
    return ctx?.gameMode === GameMode.ACTIVE;
  }, [ctx?.gameMode, ctx?.currentActivity, isReadySetGoFinished]);

  const isActivityComplete = useMemo(() => {
    if (!ctx || !ctx.currentCharacter || !ctx.currentActivity) return false;
    return ctx.activityStrokesCompleted === ctx.currentCharacter.strokes.length;
  }, [ctx?.activityStrokesCompleted, ctx?.currentCharacter]);

  const showGuideDots = useMemo(() => {
    if (isActivityComplete) return false;
    if (ctx?.currentActivity?.type === ActivityType.CHASE_STAR) {
      return isReadySetGoFinished;
    }
    return ctx?.currentActivity?.type !== ActivityType.INDEPENDENT;
  }, [ctx?.currentActivity, isReadySetGoFinished]);

  const allowPenReward = useMemo(() => {
    const { rewardsPerItem } = rewards.state.context;
    if (!ctx || !ctx.currentActivity?.type || !ctx.currentSequence?.category || !ctx.currentCharacter?.type
        || !rewardsPerItem) return false;
    return (((ctx.currentActivity.type === ActivityType.DOTTED
        && ctx.currentSequence.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS && !ctx.hasCompletedSequence))
        && !rewardsPerItem[ctx.currentCharacter.type]?.[ctx.currentActivity.type]);
  }, [ctx?.currentSequence, ctx?.currentCharacter, ctx?.currentActivity, rewards.state.context]);

  const requiredIterations = useMemo(() => {
    if (ctx?.currentActivity?.type === ActivityType.INDEPENDENT
        && difficultyLevel === DifficultyLevel.EASY) return 1;
    return ctx?.currentActivity?.requiredIterations || 1;
  }, [ctx?.currentActivity]);

  const showNextButton = useMemo(() => {
    if (enableNextButton) return true;
    if (ctx?.gameMode !== GameMode.FEEDBACK) return false;
    if (!ctx || !ctx.currentActivity) return false;
    if (ctx.currentActivity.currentIteration < requiredIterations) {
      return false;
    }
    return (strokeOutcomes[ctx?.lastStrokeOutcome as StrokeOutcome]?.type === StrokeOutcomeType.SUCCESS);
  }, [enableNextButton, ctx?.gameMode, ctx?.lastStrokeOutcome, ctx?.currentActivity]);

  // *********** Functions
  const hasTracingActivityGeneralError = (
    activityType: ActivityType,
  ): boolean => ctx?.lastStrokeOutcome === StrokeOutcome.ERROR_GENERAL_TRACING
    && (activityType === ActivityType.SOLID || activityType === ActivityType.FADED
      || activityType === ActivityType.DOTTED)
    && ctx.errorCount !== FIRST_ERROR_THRESHOLD && ctx.errorCount !== SECOND_ERROR_THRESHOLD;

  const saveActivityProgress = () => {
    if (ctx && ctx.currentActivity) {
      progress?.dispatch({
        type: ProgressActionType.SAVE_ACTIVITY_PROGRESS,
        payload: {
          activityType: ctx.currentActivity.type,
          activityProgress: {
            starMeterFrames,
            iterations,
            isActivityComplete,
            lastStrokeOutcome,
            errorCount: ctx.errorCount,
            currentIteration: ctx.currentActivity.currentIteration,
          },
          redirectedFrom: ctx.currentActivity.type,
        },
      });
    }
  };

  const handleStrokeOutcome = (
    payload: StrokeOutcome,
    hasMoreStrokes: boolean,
    currentStrokeCount: number,
    strokes: DrawingStroke[],
  ) => {
    setStrokeCount(currentStrokeCount);
    setEnablePenSelection(!hasMoreStrokes);
    if (payload === StrokeOutcome.WARNING_INTERSECTION) {
      if (hasMoreStrokes) {
        // Simulates temporary success to delay the 'close the loop' warning
        ctx?.dispatch({ type: ActionType.DRAW_STROKE, payload: StrokeOutcome.SUCCESS });
      } else {
        // Dispatches the actual result. Whether is success or error
        ctx?.dispatch({ type: ActionType.DRAW_STROKE, payload });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        currentStrokeCount > 1
        && !hasMoreStrokes
        && payload === StrokeOutcome.SUCCESS
        && multipleStrokeLastOutcome === StrokeOutcome.WARNING_INTERSECTION
      ) {
        // If this is the last stroke, was succesfull
        // but on the first stroke the user didn't close the loop, warn them
        // Dispatches the previous result (Close the loop warning)
        ctx?.dispatch({ type: ActionType.DRAW_STROKE, payload: multipleStrokeLastOutcome });
      } else {
        // Dispatches the actual result. Whether is success or error
        ctx?.dispatch({ type: ActionType.DRAW_STROKE, payload });
      }
    }
    setMultipleStrokeLastOutcome(payload);
    setLastStrokeOutcome(payload);
    const currentCharacterType = ctx?.currentCharacter?.type;
    const eventName = (difficultyLevel === DifficultyLevel.EASY)
      ? `${ctx?.currentActivity?.type} - Large Mode`
      : ctx?.currentActivity?.type;
    trackEvent({
      category: `${currentCharacterType}`,
      action: `Stroke ${currentStrokeCount} - ${getAnalyticsStrokeOutcomeMessage(payload)}`,
      name: `${eventName}`,
    });

    if (!canUseExperimentalInstructionalVO) {
      setActiveAudioCue(null);
    }

    // If there are more strokes, do not cancel char efficiency timer
    // so it can continue to keep track of how long a student takes to finish the
    // entire character
    if (hasMoreStrokes) {
      timeout.cancelTimeouts([charEfficiencyTimer]);
    } else {
      timeout.cancelTimeouts();
    }

    if (!hasMoreStrokes) {
      if (strokeOutcomes[payload as StrokeOutcome].type === StrokeOutcomeType.SUCCESS) {
        progress?.dispatch({
          type: ProgressActionType.UPDATE_ACTIVITY_PROGRESS,
          payload: {
            activityType: ctx?.currentActivity?.type,
            strokeProgress: strokes,
            redirectedFrom: null,
          },
        });
      }
    } else if (hasMoreStrokes && ctx?.currentActivity?.type === ActivityType.INDEPENDENT) {
      // remind student to continue writing the character
      const timeouts = [
        {
          delay: 7000,
          func: () => ctx?.dispatch({ type: ActionType.STROKE_FALSE_START, payload: StrokeOutcome.WARNING_REMINDER }),
        },
        { delay: 13000, func: () => handleStrokeOutcome(StrokeOutcome.ERROR_TIME, false, 0, []) },
      ];
      timeout.createTimeouts(timeouts);
    }
  };

  const errorReset = (resetAudio: boolean = true) => {
    ctx?.dispatch({ type: ActionType.RESET_ACTIVITY, payload: GameMode.ACTIVE });
    setEnableNextButton(false);
    setEnablePenSelection(true);
    if (resetAudio) setActiveAudioCue(null);
    feedbackCtx.dispatch({ type: FeedbackActionType.RESET_MESSAGES });
  };

  const saveAndSetNextIteration = (strokes: DrawingStroke[]) => {
    if (!ctx || !ctx.currentActivity) return;
    if (requiredIterations < 2 || !progress) return;

    const currentIteration = ctx.currentActivity.currentIteration || 1;
    const currentIterationIndex = currentIteration - 1;

    const updatedIterations = [...iterations];
    updatedIterations[currentIterationIndex] = { id: currentIteration, strokes };
    if (currentIteration < requiredIterations) {
      updatedIterations.push({ id: currentIteration + 1, strokes: [] });
    }
    setIterations([...updatedIterations]);
    feedbackCtx.dispatch({ type: FeedbackActionType.RESET_MESSAGES });
  };

  const createCharEfficiencyTimer = () => {
    // check if student finishes the full character within a set amount of time
    const timer = timeout.createTimeout({
      delay: 15000,
      func: () => {
        setStrokeCorrectiveFeedback(CorrectiveFeedback.AUTOMATICITY_SPEED);
        trackEvent({
          category: AnalyticsEventCategory.CORRECTIVE_FEEDBACK,
          action: `${getCorrectiveFeedbackMessage(CorrectiveFeedback.AUTOMATICITY_SPEED)}`,
          name: `${ctx?.currentCharacter?.type}`,
        });
      },
    });
    setCharEfficiencyTimer(timer);
  };

  const handleFirstStrokeStart = () => {
    timeout.cancelTimeouts();
    // Sandbox only feature
    if (canUseExperimentalInstructionalVO && ctx?.currentCharacter?.type) {
      setActiveAudioCue({ src: charInstructions[ctx.currentCharacter.type]?.instructionEnd });
    }
    createCharEfficiencyTimer();
  };

  const handleTapOffStart = (currentStrokeIndex: number) => {
    const currentStroke = ctx?.currentCharacter?.strokes[currentStrokeIndex] || null;
    timeout.cancelTimeouts();
    ctx?.dispatch({ type: ActionType.STROKE_FALSE_START, payload: StrokeOutcome.WARNING_INCORRECT_START_POINT });
    setActiveAudioCue({
      src: getStrokeErrorVO(
        StrokeOutcome.WARNING_INCORRECT_START_POINT,
        isStartDotDirectional(currentStroke),
        ctx?.currentActivity?.type,
      ),
      onEnd: () => ctx?.dispatch({ type: ActionType.STROKE_FALSE_START, payload: null }),
    });
  };

  const handleRemainingStrokeStart = () => {
    timeout.cancelTimeouts([charEfficiencyTimer]);
  };

  const viewDemoScreen = (redirectedByError: boolean) => {
    timeout.cancelTimeouts();
    resetChaseStarIteration();
    const canSkip = !redirectedByError;
    ctx?.dispatch({ type: ActionType.GO_TO_CHARACTER_DEMO_VIDEO, payload: canSkip });
    saveActivityProgress();
  };

  const viewGripDemoScreen = () => {
    timeout.cancelTimeouts();
    resetChaseStarIteration();
    ctx?.dispatch({ type: ActionType.GO_TO_GRIP_DEMO_VIDEO });
    saveActivityProgress();
  };

  const skipCharacter = () => {
    setCurrentActivityCycle(1);
    resetChaseStarIteration();
    progress?.dispatch({ type: ProgressActionType.RESET_ALL_PROGRESS });
    ctx?.dispatch({ type: ActionType.SKIP_CHARACTER });
  };

  const goToNextActivity = () => {
    if (!ctx) return;
    feedbackCtx.dispatch({ type: FeedbackActionType.RESET_MESSAGES });

    ctx.dispatch({ type: ActionType.COMPLETE_ACTIVITY_LEVEL });

    if (ctx.currentActivity?.type === ActivityType.INDEPENDENT
      && iterations.length === requiredIterations) {
      ctx.dispatch({ type: ActionType.GO_TO_SELF_ASSESS_PAGE });
    }

    if (ctx.currentActivity?.nextActivityType === ActivityType.STAR_GAME
      && iterations.length === requiredIterations) {
      saveActivityProgress();
    }

    if (ctx.currentActivity
        && (ctx.currentActivity.currentIteration === requiredIterations)) {
      setIterations([defaultFirstIteration]);
    }
    progress?.dispatch({ type: ProgressActionType.RESET_ACTIVITY_PROGRESS, payload: ctx?.currentActivity?.type });
  };

  const prepareToSkip = () => {
    timeout.cancelTimeouts();
    setEnableNextButton(true);
  };

  const skipToDottedActivity = (customNextActivity?: boolean) => {
    resetChaseStarIteration();
    timeout.cancelTimeouts();

    if (ctx && ctx.currentCharacter) {
      saveActivityProgress();
    }

    setIterations([defaultFirstIteration]);

    const nextActivity: Activity = { ...activities.DOTTED };
    if (customNextActivity) {
      nextActivity.nextActivityType = ActivityType.INDEPENDENT;
    }
    ctx?.dispatch({
      type: ActionType.SKIP_TO_ACTIVITY,
      payload: nextActivity,
    });
    setCurrentActivityCycle(currentActivityCycle + 1);
  };

  const handleNextAfterErrorStroke = () => {
    if (!ctx) return;
    if (ctx.currentActivity?.type === ActivityType.CHASE_STAR && ctx.errorCount === FIRST_ERROR_THRESHOLD) {
      if (currentActivityCycle < 2) {
        skipToDottedActivity();
      } else {
        skipCharacter();
      }
    } else if (ctx?.lastStrokeOutcome === StrokeOutcome.ERROR_TIME
      || (ctx.currentActivity?.type === ActivityType.INDEPENDENT && ctx.errorCount === FIRST_ERROR_THRESHOLD)) {
      if (currentActivityCycle < 2) {
        skipToDottedActivity(true);
      } else {
        skipCharacter();
      }
    } else if (ctx.errorCount === FIRST_ERROR_THRESHOLD) {
      // make sure to add to the dispatch that we want to ensure the next button is enabled
      viewDemoScreen(true);
    } else if (ctx.errorCount === SECOND_ERROR_THRESHOLD) {
      skipCharacter();
    }
    if (canUseExperimentalInstructionalVO
        && ctx.errorCount !== FIRST_ERROR_THRESHOLD
        && ctx.errorCount !== SECOND_ERROR_THRESHOLD
        && ctx.currentCharacter) {
      errorReset(false);
      setActiveAudioCue({
        src: charInstructions[ctx.currentCharacter.type]?.instructionStart,
        onEnd: () => {
          setActiveAudioCue(null);
        },
      });
    } else {
      errorReset();
    }
  };

  const setNextStarMeterFramesForActivity = (currentActivity: Activity | null) => {
    if (!currentActivity) return;
    const indexFromIteration = requiredIterations === 1 ? 0 : completedIterationsCount - 1;
    const framesForActivity = currentActivity.starMeterFrames?.[indexFromIteration];
    if (framesForActivity) {
      setStarMeterFrames(framesForActivity);
    }
  };

  const handleNextAfterSuccessStroke = () => {
    // goes to pen reward overlay
    if (allowPenReward && rewards.state.matches(RewardState.IDLE)) {
      rewards.send({
        type: RewardEventTypes.REWARD_PEN,
        payload: {
          itemType: ctx?.currentCharacter?.type,
          activityType: ctx?.currentActivity?.type,
        },
      });
    // leaving pen reward overlay or onto next activity
    } else {
      rewards.send(RewardEventTypes.NEXT);
      setShowScaffold(false);
      goToNextActivity();
    }
  };

  useLayoutEffect(() => {
    setShowScaffold(true);
  }, [ctx?.currentActivity?.type, ctx?.currentActivity?.currentIteration]);

  const createNextIterationTimers = (currentActivity: Activity|null): void => {
    if (!currentActivity || currentActivity.type !== ActivityType.INDEPENDENT) return;
    const currentIteration = currentActivity.currentIteration || 1;
    // Function handleIntroForActivity handles the first iteration timers
    // For subsequent iterations, we want to create "no activity" timers to encourage user to continue writing char
    if (currentIteration < requiredIterations
        || (currentIteration === requiredIterations && ctx?.gameMode === GameMode.ACTIVE)) {
      // If no activity for 7 seconds, cue char specific VO
      // If still no activity 6 seconds after that, error.
      if (!ctx || !ctx.currentCharacter) return;
      const src = activityIntros[ActivityType.INDEPENDENT][ctx.currentCharacter.type];
      const timeouts = [
        { delay: 7000, func: () => setActiveAudioCue({ src }) },
        { delay: 13000, func: () => handleStrokeOutcome(StrokeOutcome.ERROR_TIME, false, 0, []) },
      ];
      timeout.createTimeouts(timeouts);
    }
  };

  useEffect(() => {
    if (activeAudioCue) {
      audioContext?.handlePlay(activeAudioCue);
    }
    return () => audioContext?.handleComplete();
  }, [activeAudioCue]);

  useEffect(() => {
    if (ctx?.errorCount && !currentActivityProgress?.inProgress) {
      setActiveAudioCue({ src: yourTurnAgain });
    }
  }, [ctx?.errorCount]);

  const handleIntroForActivity = (currentActivityType: ActivityType, currentCharacter: Character) => {
    if (currentActivityType === ActivityType.STAR_GAME || !currentCharacter?.type || !currentCharacter?.category) {
      return;
    }

    if (canUseExperimentalInstructionalVO) {
      // TODO: Temporary code block while this feature is still being
      // finalized and not all chars are available. Remove when this moves
      // to production.
      if (charInstructions[currentCharacter.type]) {
        setActiveAudioCue({
          src: yourTurn,
          onEnd: () => setActiveAudioCue({
            src: activityIntros[ActivityType.SOLID],
            onEnd: () => setActiveAudioCue({
              src: charInstructions[currentCharacter.type]?.instructionStart,
            }),
          }),
        });
        return;
      }
      setActiveAudioCue({
        src: yourTurn,
        onEnd: () => setActiveAudioCue({ src: activityIntros[ActivityType.SOLID] }),
      });
    } else if (currentActivityType === ActivityType.CHASE_STAR) {
      setActiveAudioCue({
        src: activityIntros[currentActivityType][currentCharacter.type],
        onEnd: () => startChaseStarIteration(),
      });
    } else if (currentActivityType === ActivityType.INDEPENDENT) {
      const src = activityIntros[currentActivityType][currentCharacter.type];
      const timeouts = [
        { delay: 6000, func: () => setActiveAudioCue({ src }) },
        { delay: 13000, func: () => handleStrokeOutcome(StrokeOutcome.ERROR_TIME, false, 0, []) },
      ];
      // encourage student to start writing character within an acceptable time.
      setActiveAudioCue({ src, onEnd: () => timeout.createTimeouts(timeouts) });
    } else {
      setActiveAudioCue({ src: activityIntros[currentActivityType] });
    }
  };

  const handleSuccessForActivity = (currentActivity: Activity | null) => {
    if (!ctx || !ctx.currentCharacter || !currentActivity) return;
    if (currentActivity.type === ActivityType.STAR_GAME) return;
    setStrokeCorrectiveFeedback(null);

    // cue only plink sound if we're not on the final iteration
    if (requiredIterations > 1
      && (currentActivity.currentIteration || 1) < requiredIterations) {
      if (currentActivity.type === ActivityType.INDEPENDENT && strokeCorrectiveFeedback) {
        const src = correctiveFeedback[strokeCorrectiveFeedback][ctx.currentCharacter.category];
        setActiveAudioCue({
          src: plink,
          onEnd: () => setActiveAudioCue({ src }),
        });
      } else if (currentActivity.type === ActivityType.CHASE_STAR) {
        setActiveAudioCue({
          src: plink,
          onEnd: () => startChaseStarIteration(),
        });
      } else {
        setActiveAudioCue({ src: plink });
      }
      goToNextActivity();
      return;
    }

    let audioSrc = activitySuccessCues[currentActivity.type];
    if (currentActivity.type === ActivityType.INDEPENDENT) {
      // for final iteration, set specific audio for independent.
      audioSrc = activitySuccessCues.greatJob[ctx.currentCharacter.category];
    }
    setActiveAudioCue({
      src: plink,
      onEnd: () => setActiveAudioCue({ src: audioSrc }),
    });
  };

  const handleErrorForActivity = (currentActivity: Activity, errorCount: number, hasSavedProgress = false) => {
    const nextCharacter = ctx?.currentSequence?.characters[0];
    const currentStroke = ctx?.currentCharacter?.strokes[strokeCount - 1] || null;

    if (currentActivity.type === ActivityType.CHASE_STAR && errorCount === FIRST_ERROR_THRESHOLD) {
      if (currentActivityCycle < 2) {
        setActiveAudioCue({ src: goToDottedActivity, onEnd: () => prepareToSkip() });
      } else {
        setActiveAudioCue({ src: skipLetter, onEnd: () => setEnableNextButton(true) });
      }
    } else if (currentActivity.type === ActivityType.INDEPENDENT
        && errorCount === 2
        && currentActivity.currentIteration <= 2
        && (ctx?.lastStrokeOutcome !== StrokeOutcome.ERROR_START
          && ctx?.lastStrokeOutcome !== StrokeOutcome.ERROR_TIME)) {
      setShowCorrectiveFeedback(true);
      timeout.cancelTimeouts();
      setActiveAudioCue({
        src: getStrokeErrorVOByCharCategory(ctx?.currentCharacter?.category),
        onEnd: () => {
          handleNextAfterErrorStroke();
          setShowCorrectiveFeedback(false);
          createNextIterationTimers(currentActivity);
        },
      });
    } else if (ctx?.lastStrokeOutcome === StrokeOutcome.ERROR_TIME
      || (currentActivity.type === ActivityType.INDEPENDENT && errorCount === FIRST_ERROR_THRESHOLD)) {
      if (currentActivityCycle < 2) {
        setActiveAudioCue({ src: goToDottedActivity, onEnd: () => prepareToSkip() });
      } else {
        setActiveAudioCue({ src: skipLetter, onEnd: () => setEnableNextButton(true) });
      }
    } else if (errorCount === FIRST_ERROR_THRESHOLD) {
      if (hasSavedProgress && canUseExperimentalInstructionalVO && ctx?.currentCharacter?.type) {
        const currentCharType = ctx.currentCharacter.type;
        setActiveAudioCue({
          src: tryAgain,
          onEnd: () => setActiveAudioCue({ src: charInstructions[currentCharType]?.instructionStart }),
        });
      } else {
        setActiveAudioCue(
          hasSavedProgress
            ? { src: tryAgain }
            : { src: watchDemoAgain, onEnd: () => handleNextAfterErrorStroke() },
        );
      }
    } else if (errorCount === SECOND_ERROR_THRESHOLD && nextCharacter) {
      setActiveAudioCue({ src: skipLetter, onEnd: () => setEnableNextButton(true) });
    } else if (errorCount === SECOND_ERROR_THRESHOLD && !nextCharacter
        && (currentActivity.type === ActivityType.SOLID || currentActivity.type === ActivityType.FADED
          || currentActivity.type === ActivityType.DOTTED)) {
      setActiveAudioCue({ src: skipLetter, onEnd: () => setEnableNextButton(true) });
    } else if (hasTracingActivityGeneralError(currentActivity.type)) {
      setShowCorrectiveFeedback(true);
      setDrawnStrokeOpacity(getPreferredOpacityByPenId(rewards.state.context.activePen.id));
      const timer = {
        delay: 1000,
        func: () => {
          setShowCorrectiveFeedback(false);
          setDrawnStrokeOpacity(1);
          handleNextAfterErrorStroke();
        },
      };
      setActiveAudioCue({
        src: getStrokeErrorVO(ctx?.lastStrokeOutcome, isStartDotDirectional(currentStroke), currentActivity.type),
        onEnd: () => {
          // keep everything on screen one second after VO ends, so student has time to view.
          timeout.createTimeout(timer);
        },
      });
    } else {
      const errorMessage = hasSavedProgress
        ? tryAgain
        : getStrokeErrorVO(ctx?.lastStrokeOutcome, isStartDotDirectional(currentStroke), currentActivity.type);
      setActiveAudioCue({
        src: errorMessage,
        onEnd: () => {
          if (currentActivity.type === ActivityType.CHASE_STAR) {
            resetChaseStarIteration();
          }
          if (currentActivity.type === ActivityType.INDEPENDENT && hasSavedProgress) {
            createNextIterationTimers(currentActivity);
          }
          handleNextAfterErrorStroke();
          if (currentActivity.type === ActivityType.CHASE_STAR) {
            startChaseStarIteration();
          }
        },
      });
    }
  };

  useEffect(() => {
    if (progress && currentActivityProgress && currentActivityProgress.inProgress) {
      // Make activity look like it did before navigating away
      setLastStrokeOutcome(currentActivityProgress.lastStrokeOutcome || null);
      if (currentActivityProgress.starMeterFrames) setStarMeterFrames([...currentActivityProgress.starMeterFrames]);
      if (currentActivityProgress.iterations) setIterations([...currentActivityProgress.iterations]);

      if (!ctx || !ctx.currentActivity || !ctx.currentCharacter) return;

      if (progress.redirectedFrom === GameScreens.DEMO_VIDEO || progress.redirectedFrom === GameScreens.GRIP_VIDEO) {
        // Jira HWP-324: Allow drawing on canvas when navigating to demo video before canvas resets
        const hasHitAnErrorThreshold = ctx.errorCount === FIRST_ERROR_THRESHOLD
          || ctx.errorCount === SECOND_ERROR_THRESHOLD;
        if (ctx.gameMode === GameMode.FEEDBACK
          && !hasHitAnErrorThreshold && !currentActivityProgress.isActivityComplete
          && currentActivityProgress.lastStrokeOutcome !== StrokeOutcome.ERROR_TIME) {
          errorReset();
        }
        // Play audio
        if (!currentActivityProgress.isActivityComplete && ctx.activityStrokesCompleted === 0) {
          const strokeOutcome = currentActivityProgress?.lastStrokeOutcome;
          if (ctx.errorCount > 0 && strokeOutcome
              && strokeOutcomes[strokeOutcome as StrokeOutcome].type !== StrokeOutcomeType.SUCCESS) {
            // Play error audio if last stroke was an error
            if (ctx.currentActivity.type === ActivityType.CHASE_STAR && !hasHitAnErrorThreshold) {
              const errorMessage = strokeOutcome
                ? getStrokeErrorVO(strokeOutcome, false, ctx.currentActivity?.type)
                : null;
              if (errorMessage) {
                setActiveAudioCue({ src: errorMessage, onEnd: () => startChaseStarIteration() });
              }
            } else {
              handleErrorForActivity(ctx.currentActivity, ctx.errorCount, true);
            }
          } else if (ctx.currentActivity.currentIteration > 1) {
            // Play if in the middle of multiple iterations
            if (ctx.currentActivity.type === ActivityType.CHASE_STAR) {
              setActiveAudioCue({
                src: plink,
                onEnd: () => startChaseStarIteration(),
              });
            } else {
              // Returning to independent from dotted and then watching demo, play intro
              const src = (ctx.currentActivity.type === ActivityType.INDEPENDENT)
                ? activityIntros[ActivityType.INDEPENDENT][ctx.currentCharacter.type]
                : plink;
              setActiveAudioCue({ src, onEnd: () => createNextIterationTimers(ctx.currentActivity) });
            }
          } else {
            // Play intro audio if no strokes were drawn.
            handleIntroForActivity(ctx.currentActivity.type, ctx.currentCharacter);
          }
        } else if (!currentActivityProgress.isActivityComplete && ctx.activityStrokesCompleted > 0) {
          // Did not finish multi-stroke char before navigating away from activity, reset
          errorReset();
          if (ctx.currentActivity.currentIteration === 1) {
            handleIntroForActivity(ctx.currentActivity.type, ctx.currentCharacter);
          } else {
            setActiveAudioCue({ src: tryAgain, onEnd: () => createNextIterationTimers(ctx.currentActivity) });
          }
        } else if (currentActivityProgress.isActivityComplete && ctx.currentActivity) {
          // Play audio if character was successfully completed
          handleSuccessForActivity(ctx.currentActivity);
        }
      } else {
        if (ctx.currentActivity.type === ActivityType.CHASE_STAR) {
          setActiveAudioCue({ src: tryAgain, onEnd: () => startChaseStarIteration() });
        } else {
          const src = (ctx.currentActivity.type === ActivityType.INDEPENDENT)
            ? activityIntros[ActivityType.INDEPENDENT][ctx.currentCharacter.type] // Returning to inde from dotted
            : tryAgain;
          setActiveAudioCue({ src, onEnd: () => createNextIterationTimers(ctx.currentActivity) });
        }
        // for activities with iterations, update game context so it
        // reflects saved progress
        ctx.dispatch({
          type: ActionType.RESUME_GAME_PLAY,
          payload: {
            currentIteration: currentActivityProgress.currentIteration,
          },
        });
      }
      progress.dispatch({ type: ProgressActionType.RESET_ACTIVITY_PROGRESS, payload: ctx.currentActivity.type });
    }
  }, [currentActivityProgress]);

  useEffect(() => {
    if (!ctx || !ctx.currentActivity) return;
    if (!currentActivityProgress?.inProgress) {
      setIterations([defaultFirstIteration]);
      feedbackCtx.dispatch({ type: FeedbackActionType.RESET_MESSAGES });
      if (ctx && ctx.currentActivity && ctx.currentCharacter) {
        handleIntroForActivity(ctx.currentActivity.type, ctx.currentCharacter);
      }
    }
  }, [ctx?.currentActivity?.type, ctx?.currentCharacter]);

  const syncGamePlayFeedback = (
    currentActivity: Activity,
    gameMode: GameMode,
    isRewardingPen: boolean,
    errorCount: number,
    _isActivityComplete: boolean,
  ) => {
    // reset star meter for Chase the Shooting Star
    if (currentActivity.type === ActivityType.CHASE_STAR && currentActivity.currentIteration === 1) {
      setStarMeterFrames([0, 1]);
    }

    // handle new pen reward
    if (isRewardingPen) {
      setActiveAudioCue({ src: newPen });
    // handle success
    } else if (_isActivityComplete) {
      if (currentActivity.type === ActivityType.CHASE_STAR) {
        resetChaseStarIteration();
      }

      // set up the close gap animation to not show on the last iteration of the independent activity
      if (
        currentActivity.type === ActivityType.INDEPENDENT
        && currentActivity.currentIteration === currentActivity.requiredIterations
      ) {
        setShowCloseGapAnimation(false);
      } else {
        setShowCloseGapAnimation(true);
      }

      setShowTravelingStarAnimation(true);
      handleSuccessForActivity(currentActivity);
      createNextIterationTimers(currentActivity);

    // handle errors
    } else if (gameMode === GameMode.FEEDBACK) {
      if (currentActivity.type === ActivityType.CHASE_STAR) {
        // on error, we only want to stop shooting star animation, but leave
        // guide dots on screen for now.
        stopChaseStarAnimation();
      }
      handleErrorForActivity(currentActivity, errorCount);
    }
  };

  useEffect(() => {
    if (!ctx?.currentActivity) return;
    if (ctx.currentActivity.type !== ActivityType.STAR_GAME && !currentActivityProgress?.inProgress) {
      syncGamePlayFeedback(
        ctx?.currentActivity,
        ctx?.gameMode,
        rewards.state.matches(RewardState.REWARDING_PEN),
        ctx?.errorCount,
        isActivityComplete,
      );
    }
  }, [ctx?.gameMode, ctx?.currentActivity, isActivityComplete, rewards.state.value]);

  useEffect(() => {
    if (!ctx) return;
    setLastStrokeOutcome(null);

    if (ctx.lastStrokeOutcome === StrokeOutcome.WARNING_INTERSECTION) {
      const closeTheGapChars = [
        CharacterType.LOWER_B, CharacterType.LOWER_P, CharacterType.NUMBER_6, CharacterType.LOWER_D,
      ];
      const isCloseGapChar = (ctx?.currentCharacter?.type && closeTheGapChars.includes(ctx.currentCharacter.type));

      const src = isCloseGapChar ? closeGap : closeCircle;
      const legibility = isCloseGapChar ? CorrectiveLegibilityTypes.CLOSE_GAP : CorrectiveLegibilityTypes.CLOSE_CIRCLE;

      if (ctx.currentActivity?.type === ActivityType.CHASE_STAR
          && ctx.currentActivity.currentIteration < ctx.currentActivity.requiredIterations) {
        setActiveAudioCue({ src, onEnd: () => startChaseStarIteration() });
        setLastStrokeOutcome(StrokeOutcome.WARNING_INTERSECTION);
      } else {
        setActiveAudioCue({ src });
        setLastStrokeOutcome(StrokeOutcome.WARNING_INTERSECTION);
      }
      trackEvent({
        category: AnalyticsEventCategory.CORRECTIVE_FEEDBACK,
        action: `${getCorrectiveFeedbackMessage(legibility)}`,
        name: `${ctx?.currentCharacter?.type}`,
      });
      return;
    }

    if (ctx.lastStrokeOutcome === StrokeOutcome.WARNING_REMINDER) {
      const src = (ctx.currentCharacter)
        ? correctiveFeedback[CorrectiveFeedback.AUTOMATICITY_MEMORY][ctx.currentCharacter.category]
        : null;
      trackEvent({
        category: AnalyticsEventCategory.CORRECTIVE_FEEDBACK,
        action: `${getCorrectiveFeedbackMessage(CorrectiveFeedback.AUTOMATICITY_MEMORY)}`,
        name: `${ctx?.currentCharacter?.type}`,
      });
      setActiveAudioCue({ src });
    }
  }, [ctx?.lastStrokeOutcome]);

  const getTravelingStarPosition = (index: number) => {
    switch (index + 1) {
      case 1:
        return TravelingStarPosition.START;
      case 2:
        return TravelingStarPosition.MIDDLE;
      case 3:
      default:
        return TravelingStarPosition.END;
    }
  };

  const handleReadyStarAnimFinish = () => {
    beginShootingStarAnim();
    setActiveAudioCue({
      src: set,
      onEnd: () => {
        setActiveAudioCue({ src: go });
        finishReadySetGoSequence();
        ctx?.dispatch({ type: ActionType.RESET_PREV_LAST_STROKE });
      },
    });
  };

  const showScaffoldModel = () => ctx?.currentActivity?.type !== ActivityType.INDEPENDENT
    || showCorrectiveFeedback;

  const showTargetZone = () => {
    if (showCorrectiveFeedback) return false;
    return showGuideDots || requiredIterations > 1
    || difficultyLevel === DifficultyLevel.EASY;
  };

  if (!ctx || !ctx.currentCharacter || !ctx.currentActivity || !ctx.currentSequence) return null;

  const isOnLastSequences = ctx.currentSequence.id === 'SEQUENCE_20'
    || ctx.currentSequence.id === 'SEQUENCE_21'
    || ctx.currentSequence.id === 'SEQUENCE_22';

  return (
    <ActivityWrapper>
      <h1 className="visually-hidden">
        {ctx.currentActivity.type}
        {' '}
        line
        {' '}
        Activity for Character
        {' '}
        {ctx.currentCharacter?.display}
      </h1>

      <TopNavigation
        onDemoButtonTouch={() => viewDemoScreen(false)}
        itemLabel={ctx.currentCharacter.display}
        onHomeButtonTouch={() => ctx?.dispatch({ type: ActionType.RESET })}
        showGrantAccess={!isOnLastSequences}
        showHomeButton
        showGripDemoButton
        onGripDemoButtonTouch={() => viewGripDemoScreen()}
      />
      {(envSettings.enableNewTouchSettings && feedbackCtx.messages.length) ? (
        <ul style={{ margin: '0 10px' }}>{feedbackCtx.messages.map((msg: string) => <li key={msg}>{msg}</li>)}</ul>
      ) : null }
      <WritingPracticeAreaWrapper difficultyLevel={difficultyLevel}>
        {!isOnline && <NoInternetPersistent />}
        <ScaffoldModel
          character={ctx.currentCharacter}
          show={showScaffoldModel()}
          height={ctx.currentActivity.type === ActivityType.CHASE_STAR ? '25%' : '50%'}
          lineType={ctx.currentActivity.type === ActivityType.INDEPENDENT ? LineType.FADED : LineType.SOLID}
        />

        {(ctx?.currentActivity?.type === ActivityType.CHASE_STAR) && (
        <div
          style={{ width: '130px', height: '126px', zIndex: '99' }}
          ref={readySetGoStarRef}
        >
          <ReadySetGoStarAnimation
            animationData={getReadyStarAnimationData(drawingAreaRef, readySetGoStarRef, ctx?.currentCharacter?.type)}
            onAnimComplete={() => handleReadyStarAnimFinish()}
            start={isReadySetGoStarted}
          />
        </div>
        )}
        <DrawingAreaGrid style={{ height: `${drawingAreaHeight}px` }} difficultyLevel={difficultyLevel}>
          {iterations.map((iteration: Iteration, index: number) => (
            <DrawingArea
              key={iteration.id}
              style={{
                width: `${drawingAreaWidth}px`,
                gridColumnStart: requiredIterations > 1 ? index + 1 : 2,
                gridColumnEnd: requiredIterations > 1 ? index + 2 : 3,
              }}
              ref={drawingAreaRef}
            >
              {(index + 1 === ctx.currentActivity?.currentIteration
                && iteration.strokes.length === 0) ? (
                  <>
                    <ActivityScaffold
                      currentActivityType={ctx.currentActivity.type}
                      currentCharacter={ctx.currentCharacter}
                      isVisible={showScaffold}
                      playLottie={startShootingStarAnim}
                      shouldBlink={hasTracingActivityGeneralError(ctx.currentActivity.type)}
                    />
                    <TravelingStarAnimation
                      isVisible={showTravelingStarAnimation && requiredIterations === 1}
                      handleComplete={() => {
                        setNextStarMeterFramesForActivity(ctx?.currentActivity);
                        setShowTravelingStarAnimation(false);
                        setShowCloseGapAnimation(false);
                        setLastStrokeOutcome(null);
                      }}
                      position={TravelingStarPosition.MIDDLE}
                    />
                    {ctx.currentCharacter && (
                    <WritingArea
                      lastStrokeOutcome={lastStrokeOutcome}
                      width={drawingAreaWidth}
                      height={drawingAreaHeight}
                      activePen={rewards.state.context.activePen}
                      character={ctx.currentCharacter}
                      canWrite={canWrite}
                      doReset={ctx.gameMode === GameMode.ACTIVE}
                      isActivityComplete={isActivityComplete}
                      showGuideDots={showGuideDots}
                      showTargetZone={showTargetZone()}
                      saveIteration={(strokes) => {
                        saveAndSetNextIteration(strokes);
                        dispatchDrawnCharacters(strokes);
                      }}
                      handleStrokeOutcome={handleStrokeOutcome}
                      handleFirstStrokeStart={handleFirstStrokeStart}
                      handleTapOffStart={handleTapOffStart}
                      handleRemainingStrokeStart={handleRemainingStrokeStart}
                      activityInProgress={currentActivityProgress?.inProgress ? currentActivityProgress : null}
                      currentIteration={completedIterationsCount}
                      drawnStrokeOpacity={drawnStrokeOpacity}
                      allowFreeDrawGrading={ctx?.currentActivity?.type === ActivityType.INDEPENDENT
                        && ctx?.currentActivity.currentIteration === ctx?.currentActivity.requiredIterations}
                    />
                    )}
                  </>
                ) : (
                  <>
                    <CompletedCharacter
                      width={drawingAreaWidth}
                      height={drawingAreaHeight}
                      strokes={iteration.strokes}
                      difficultyLevel={difficultyLevel}
                    />
                    <CloseGapAnimation
                      isVisible={
                        showCloseGapAnimation
                        && (ctx?.prevActivityLastStrokeOutcome === StrokeOutcome.WARNING_INTERSECTION
                        || ctx?.lastStrokeOutcome === StrokeOutcome.WARNING_INTERSECTION)
                        && index + 1 === completedIterationsCount
                      }
                      currentCharacter={ctx.currentCharacter}
                    />
                    <TravelingStarAnimation
                      isVisible={(
                          showTravelingStarAnimation
                          // for multi-iteration activities, we want to base these settings on:
                          // 1) the position of the display area
                          // 2) whether this is the last completed iteration
                          && index + 1 === completedIterationsCount
                        )}
                      handleComplete={() => {
                        setNextStarMeterFramesForActivity(ctx?.currentActivity);
                        setShowTravelingStarAnimation(false);
                        setShowCloseGapAnimation(false);
                      }}
                      position={getTravelingStarPosition(index)}
                    />
                  </>
                )}
            </DrawingArea>
          ))}
        </DrawingAreaGrid>
      </WritingPracticeAreaWrapper>

      <ActivityRewardsAndNavigation
        starMeterFrames={starMeterFrames}
        hasNewPen={rewards.state.matches(RewardState.REWARDING_PEN)}
        showNextButton={showNextButton}
        onNextButtonTouch={isActivityComplete ? handleNextAfterSuccessStroke : handleNextAfterErrorStroke}
        canChangePenColor={enablePenSelection}
      />
    </ActivityWrapper>
  );
}
