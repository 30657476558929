import React from 'react';
import arrowGreenCircleActive from '../../assets/images/arrow-green-circle-active.svg';
import { BadgeImg } from '../../styles/components/Buttons';
import miniStar from '../../assets/images/mini-star.svg';
import deleteBadge from '../../assets/images/delete-badge.svg';
import addBadge from '../../assets/images/add-badge.svg';

interface CharacterButtonBadgeImgProps {
  isNext?: boolean,
  isUnlockedAfterSequence?: boolean,
  isDeleteable?: boolean,
  isAddable?: boolean,
}

export default function CharacterButtonBadgeImg({
  isNext,
  isUnlockedAfterSequence,
  isDeleteable,
  isAddable,
}: CharacterButtonBadgeImgProps) {
  if (isNext) {
    return <BadgeImg src={arrowGreenCircleActive} alt="" />;
  }

  if (isUnlockedAfterSequence) {
    return <BadgeImg src={miniStar} alt="" />;
  }

  if (isDeleteable) {
    return <BadgeImg src={deleteBadge} alt="" />;
  }

  if (isAddable) {
    return <BadgeImg src={addBadge} alt="" />;
  }

  return null;
}

CharacterButtonBadgeImg.defaultProps = {
  isNext: false,
  isUnlockedAfterSequence: false,
  isDeleteable: false,
  isAddable: false,
};
