import { Character } from '../index';

export enum KinestheticSetType {
  STRAIGHT_LINE_DOWN = 'STRAIGHT_LINE_DOWN',
  CIRCLE_STARTER = 'CIRCLE_STARTER',
  DOWN_AND_CURVE = 'DOWN_AND_CURVE',
  LINE_BACK = 'LINE_BACK',
  MIDDLE_LINE_ACROSS = 'MIDDLE_LINE_ACROSS',
  DOWN_AND_UP_PALS = 'DOWN_AND_UP_PALS',
  UP_AND_DOWN_PALS = 'UP_AND_DOWN_PALS',
  BUMP = 'BUMP',
  UPPER_CRISS_CROSS = 'UPPER_CRISS_CROSS',
  ZIP_FORWARD_BACK = 'ZIP_FORWARD_BACK',
  KICK = 'KICK',
  BUMP_AND_KICK = 'BUMP_AND_KICK',
  SUPER_SQUIGGLY = 'SUPER_SQUIGGLY',
  HOOK = 'HOOK',
  HIGH_STARTERS = 'HIGH_STARTERS',
  LOWER_CIRCLE_STARTER = 'LOWER_CIRCLE_STARTER',
  LINE_COMES_LATER = 'LINE_COMES_LATER',
  DOTS_ON_TOP = 'DOTS_ON_TOP',
  HUMP = 'HUMP',
  CIRCLE_COMES_LATER = 'CIRCLE_COMES_LATER',
  LOWER_DOWN_AND_UP = 'LOWER_DOWN_AND_UP',
  LOWER_CRISS_CROSS = 'LOWER_CRISS_CROSS',
  ZIG_ZAG = 'ZIG_ZAG',
  HIGH_HOOK = 'HIGH_HOOK',
  LOOP_AROUND = 'LOOP_AROUND',
  LOWER_SUPER_SQUIGGLY = 'LOWER_SUPER_SQUIGGLY',
}

export enum NumberSetType {
  NUMBERS_0123 = 'NUMBERS_0123',
  NUMBERS_456 = 'NUMBERS_456',
  NUMBERS_789 = 'NUMBERS_789',
}

export enum SymbolSetType {
  SYMBOLS = 'SYMBOLS',
}

export type CharacterSetType = KinestheticSetType | NumberSetType | SymbolSetType;

export type CharacterSet = {
  type?: CharacterSetType,
  display: string,
  characters: Character[],
}
