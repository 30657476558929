import { DifficultyLevel } from './types';

interface EnvironmentSettings {
  analytics?: string,
  devMode?: boolean,
  enableStylusSettings?: boolean,
  enableLottieTest?: boolean,
  enableNewTouchSettings?: boolean,
  difficultyLevel: DifficultyLevel,
}

const settings: EnvironmentSettings = {
  analytics: process.env.REACT_APP_ANALYTICS,
  // env vars are passed in as strings in GitHub Actions build workflow
  devMode: JSON.parse(process.env.REACT_APP_DEV_MODE || 'false'),
  enableStylusSettings: JSON.parse(process.env.REACT_APP_STYLUS_SETTINGS || 'false'),
  enableNewTouchSettings: JSON.parse(process.env.REACT_APP_NEW_TOUCH_SETTINGS || 'false'),
  difficultyLevel: process.env.REACT_APP_DIFFICULTY?.toLocaleUpperCase() as DifficultyLevel || DifficultyLevel.NORMAL,
};

export default settings;
