/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { TouchInput, useUserSettingsContext } from '../context/UserSettingsContext';
import { FlexRowCenter } from '../styles/components/FlexSpacers';
import envSettings from '../data/envSettings';

export default function ExperimentalWritingSettings() {
  const settings = useUserSettingsContext();

  const toggleStylusSetting = () => {
    if (settings?.acceptedTouchInputType === TouchInput.STYLUS) {
      settings?.setAcceptedTouchInputType(TouchInput.ANY);
    } else {
      settings?.setAcceptedTouchInputType(TouchInput.STYLUS);
    }
  };

  const showExperimentalSettings = envSettings?.enableStylusSettings || envSettings?.enableNewTouchSettings;

  if (!showExperimentalSettings) return null;

  return (
    <div>
      <FlexRowCenter>
        <div>
          <button type="button" style={{ padding: '0.5em ' }} onClick={toggleStylusSetting}>
            Allowed Input:
            {' '}
            {settings?.acceptedTouchInputType === TouchInput.STYLUS ? 'active stylus' : 'any'}
          </button>
        </div>
      </FlexRowCenter>
    </div>
  );
}
