// Method to create a temporary span element to measure the actual width of
// the current letter and position it exactly at the middle of the container.
export const getLetterWidth = (letter: string) => {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.width = 'fit-content';
  span.style.font = 'Starwriter';
  span.style.fontStyle = '300';
  span.style.fontSize = '120px';
  span.textContent = letter;

  document.body.appendChild(span);
  const { width } = span.getBoundingClientRect();
  document.body.removeChild(span);

  return width || 0;
};
