import React from 'react';

export default function DirectNotice() {
  return (
    <div>
      <h1>Illuminate Literacy: COPPA Direct Notice to Parents</h1>
      <p><i>Last Updated: April 18, 2023</i></p>
      <p>
        Illuminate Literacy is committed to protecting your and your child&rsquo;s privacy.
        We adhere to the Children&rsquo;s Online Privacy and Protection Act (&ldquo;COPPA&rdquo;). COPPA requires
        operators of commercial websites and online services to obtain parental consent if the operator collects,
        uses, or shares personal information about a child under the age of 13.
      </p>
      <p>
        Even though the prototype version of our handwriting program (used in SY22-23), located at&nbsp;
        <a href="https://starwriter.org/">http://starwriter.org/</a>
        &nbsp;(the &ldquo;Site&rdquo;) as well as ancillary services and applications
        (collectively, &ldquo;Services&ldquo;), will not collect any personal information,
        we want you to know what information we do collect.
        This notice describes our practices of collecting, using, and sharing information from users of our Services.
      </p>
      <p>
        Please also read our Privacy Policy and Terms of Use, which are incorporated herein by reference.
      </p>
      <ol>
        <li>
          <h2>Information We Collect</h2>
          <ol>
            <li>
              The prototype version of our handwriting program (used in SY22-23) will not collect any personally
              identifiable information children. There will be no individual user logins, and at no point will child
              users have an opportunity to input any personally identifiable information.
            </li>
            <li>
              Illuminate Literacy may collect the following information from a child who accesses
              its prototype:
              <ul>
                <li>
                  activity-level data (e.g., number of sessions, session duration, instructional videos viewed,
                  letters/numbers practiced). We can access data at the device level, but will not
                  have the ability to know which users used which devices.
                </li>
                <li>
                  persistent identifiers (e.g., device IP addresses, cookies, unique device identifiers)
                </li>
              </ul>
            </li>
            <li>
              If you provide a child with access to the Services, you represent and warrant that you are the parent or
              legal guardian, or the educator or administrator of an educational institution and have obtained the
              necessary consents from the parent or legal guardian to provide such access.
            </li>
          </ol>
        </li>
        <li>
          <h2>How We Use Your Information</h2>
          <ol>
            <li>
              Illuminate Literacy uses the information collected solely for the purpose of
              offering and providing educational online services to children.
            </li>
            <li>
              We will not sell, rent, or otherwise use your personal information for a commercial purpose
              unless approved by you. We do not use your personal information to engage in targeted advertising on our
              Services.
            </li>
            <li>
              We may use aggregated, anonymized program information to improve and demonstrate
              the efficacy of our Site and Services.
            </li>
            <li>
              We may use Site use information to test new applications or services. This may mean
              that we partner with third-party researchers or application developers. We will not share your personal
              information with such researchers unless provided for under our Privacy Policy and our Terms of Use.
            </li>
            <li>
              We use Site usage information to administer our Site and Services, including to
              monitor and diagnose any problems and to improve the Services.
            </li>
          </ol>
        </li>
        <li>
          <h2>How We May Share Your Information&zwj;</h2>
          <ol>
            <li>We do not ever sell or rent your personal information.</li>
            <li>
              We may share your information with third parties as permitted by law and as described in our Privacy
              Policy. Our partners and third-party providers are required to maintain reasonable
              security practices, to use information only for our stated purpose, to prevent disclosure of the
              information to unauthorized parties, and to abide by applicable laws and regulations.
            </li>
          </ol>
        </li>
        <li>
          <h2>Your Rights</h2>
          You are in control of the personal information that you provide to us. If you are a parent or
          legal guardian of a minor child, you may have the right to access, correct, or delete certain
          information about your child. To do so, contact
          {' '}
          <a href="mailto:Illuminate@cambiareducation.org">Illuminate@cambiareducation.org</a>
          .
        </li>
        <li>
          <h2>Notice of Changes to Policy</h2>
          If something changes and we do need to collect personal information, we will notify you
          before collecting any such information.
        </li>
        <li>
          <h2>Contact Us</h2>
          If you have any questions about this Notice, please contact us:
          {' '}
          <a href="mailto:Illuminate@cambiareducation.org">
            Illuminate@cambiareducation.org
          </a>
        </li>
      </ol>
    </div>
  );
}
