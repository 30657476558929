import {
  KinestheticSetType, NumberSetType, CharacterSetType, CharacterSet, Character, CharacterType, SymbolSetType,
} from './types';
import { characters } from './characters/characters';

export const characterSets: {
  [key in CharacterSetType]: CharacterSet
} = {
  [KinestheticSetType.STRAIGHT_LINE_DOWN]: {
    type: KinestheticSetType.STRAIGHT_LINE_DOWN,
    display: 'Straight-Line-Down Letters (I, T, L)',
    characters: [
      characters.UPPER_I,
      characters.UPPER_T,
      characters.UPPER_L,
    ],
  },
  [KinestheticSetType.CIRCLE_STARTER]: {
    type: KinestheticSetType.CIRCLE_STARTER,
    display: 'Circle-Starter Letters (O, Q, C, G)',
    characters: [
      characters.UPPER_O,
      characters.UPPER_Q,
      characters.UPPER_C,
      characters.UPPER_G,
    ],
  },
  [NumberSetType.NUMBERS_0123]: {
    display: 'Numbers 0, 1, 2, 3',
    characters: [
      characters.NUMBER_0,
      characters.NUMBER_1,
      characters.NUMBER_2,
      characters.NUMBER_3,
    ],
  },
  [KinestheticSetType.DOWN_AND_CURVE]: {
    display: 'Down-and-Curve Letters (J, U)',
    characters: [
      characters.UPPER_J,
      characters.UPPER_U,
    ],
  },
  [KinestheticSetType.LINE_BACK]: {
    display: 'Line-Back Letters (F, E)',
    characters: [
      characters.UPPER_F,
      characters.UPPER_E,
    ],
  },
  [KinestheticSetType.MIDDLE_LINE_ACROSS]: {
    display: 'Middle-Line-Across Letters (H, A)',
    characters: [
      characters.UPPER_H,
      characters.UPPER_A,
    ],
  },
  [KinestheticSetType.DOWN_AND_UP_PALS]: {
    display: 'Down-and-Up Pals (V, W)',
    characters: [
      characters.UPPER_V,
      characters.UPPER_W,
    ],
  },
  [KinestheticSetType.UP_AND_DOWN_PALS]: {
    display: 'Up-and-Down Pals (M, N)',
    characters: [
      characters.UPPER_M,
      characters.UPPER_N,
    ],
  },
  [KinestheticSetType.UPPER_CRISS_CROSS]: {
    display: 'Criss-Cross Letters (X, Y)',
    characters: [
      characters.UPPER_X,
      characters.UPPER_Y,
    ],
  },
  [KinestheticSetType.ZIP_FORWARD_BACK]: {
    display: 'Zip Forward and Back Letter (Z)',
    characters: [
      characters.UPPER_Z,
    ],
  },
  [NumberSetType.NUMBERS_456]: {
    display: 'Numbers 4, 5, 6',
    characters: [
      characters.NUMBER_4,
      characters.NUMBER_5,
      characters.NUMBER_6,
    ],
  },
  [KinestheticSetType.BUMP]: {
    display: 'Bump Letters (D, P, B)',
    characters: [
      characters.UPPER_D,
      characters.UPPER_P,
      characters.UPPER_B,
    ],
  },
  [NumberSetType.NUMBERS_789]: {
    display: 'Numbers 7, 8, 9',
    characters: [
      characters.NUMBER_7,
      characters.NUMBER_8,
      characters.NUMBER_9,
    ],
  },
  [KinestheticSetType.KICK]: {
    display: 'Kick Letter (K)',
    characters: [
      characters.UPPER_K,
    ],
  },
  [KinestheticSetType.BUMP_AND_KICK]: {
    display: 'Bump-and-kick Letter (R)',
    characters: [
      characters.UPPER_R,
    ],
  },
  [KinestheticSetType.SUPER_SQUIGGLY]: {
    display: 'Super Squiqqly (S)',
    characters: [
      characters.UPPER_S,
    ],
  },
  [KinestheticSetType.HOOK]: {
    display: 'Hook Letters (q, g)',
    characters: [
      characters.LOWER_Q,
      characters.LOWER_G,
    ],
  },
  [KinestheticSetType.HIGH_STARTERS]: {
    display: 'High Starters (l, t, h, b, k)',
    characters: [
      characters.LOWER_L,
      characters.LOWER_T,
      characters.LOWER_H,
      characters.LOWER_B,
      characters.LOWER_K,
    ],
  },
  [KinestheticSetType.LOWER_CIRCLE_STARTER]: {
    display: 'Circle Starters (o, c)',
    characters: [
      characters.LOWER_O,
      characters.LOWER_C,
    ],
  },
  [KinestheticSetType.LINE_COMES_LATER]: {
    display: 'Line Comes Later (a, d)',
    characters: [
      characters.LOWER_A,
      characters.LOWER_D,
    ],
  },
  [KinestheticSetType.DOTS_ON_TOP]: {
    display: 'Dots-on-Top Letters (j, i)',
    characters: [
      characters.LOWER_J,
      characters.LOWER_I,
    ],
  },
  [KinestheticSetType.HUMP]: {
    display: 'Hump Letters (m, n, r)',
    characters: [
      characters.LOWER_M,
      characters.LOWER_N,
      characters.LOWER_R,
    ],
  },
  [KinestheticSetType.CIRCLE_COMES_LATER]: {
    display: 'Circle Comes Later Letter (p)',
    characters: [
      characters.LOWER_P,
    ],
  },
  [KinestheticSetType.LOWER_DOWN_AND_UP]: {
    display: 'Down and Up Letters (u, v, w)',
    characters: [
      characters.LOWER_U,
      characters.LOWER_V,
      characters.LOWER_W,
    ],
  },
  [KinestheticSetType.HIGH_HOOK]: {
    display: 'High Hook (f)',
    characters: [
      characters.LOWER_F,
    ],
  },
  [KinestheticSetType.LOOP_AROUND]: {
    display: 'Loop Around Letter (e)',
    characters: [
      characters.LOWER_E,
    ],
  },
  [KinestheticSetType.LOWER_SUPER_SQUIGGLY]: {
    display: 'Super Squiggly (s)',
    characters: [
      characters.LOWER_S,
    ],
  },
  [KinestheticSetType.LOWER_CRISS_CROSS]: {
    display: 'Criss-Cross Letters (x, y)',
    characters: [
      characters.LOWER_X,
      characters.LOWER_Y,
    ],
  },
  [KinestheticSetType.ZIG_ZAG]: {
    display: 'Zig Zag Letter (z)',
    characters: [
      characters.LOWER_Z,
    ],
  },
  [SymbolSetType.SYMBOLS]: {
    display: 'Symbols (+ = ? !)',
    characters: [
      characters.SYMBOL_PLUS,
      characters.SYMBOL_EQUAL,
      characters.SYMBOL_QUESTION,
      characters.SYMBOL_EXCLAMATION,
    ],
  },
};

type SetsByCharacter = {
  [id: string]: string;
}

function selectSetsByCharacter(): { [id: string]: string } {
  const allCharacters: { [id: string]: string } = {};
  Object.keys(characterSets).forEach((key) => {
    characterSets[key as CharacterSetType].characters.forEach((char) => {
      allCharacters[char.type as CharacterType] = key;
    });
  });
  return allCharacters;
}

export function selectCharactersInSetByCharacterType(charType: CharacterType): Character[] {
  if (!charType) return [];
  const allCharacters: SetsByCharacter = selectSetsByCharacter();
  const setType: CharacterSetType = allCharacters[charType] as CharacterSetType;
  return characterSets[setType].characters;
}
