import { DrawingStroke } from '../../../components/Konva/types';
import { Shape, ShapeType } from './shapes';

export enum PreWritingActivityType {
  PW1 = 'PW1',
  PW2 = 'PW2',
  PW3 = 'PW3',
}

export type PreWritingActivity = {
  type: PreWritingActivityType,
  currentIteration : number,
  shapes: Shape[]
}

export type DrawnModel = {
  id: string,
  order: number,
  shapeId: ShapeType,
  sequenceItemOrder: number,
  drawingStrokes: DrawingStroke[],
}
