import styled from 'styled-components';

type VersionDisplayProps = {
  color: string,
  isLanding: boolean,
}

const VersionDisplayWrapper = styled.div<VersionDisplayProps>`
  color: ${(props) => props.color};
  font-weight: 700;
  font-family: 'Inter';
  ${(props) => props.isLanding && `
      margin: 0 auto;
  `} 
  ${(props) => !props.isLanding && `
      position: absolute;
      bottom: 3%;
      left: 3%;
  `}  
`;

export default VersionDisplayWrapper;
