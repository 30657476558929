export enum Pens {
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  MAGENTA = 'MAGENTA',
  RED = 'RED',
  ORANGE = 'ORANGE',
  TEAL = 'TEAL',
  GRADIENT_BLUE = 'GRADIENT_BLUE',
  GRADIENT_PURPLE = 'GRADIENT_PURPLE',
  GRADIENT_GREEN = 'GRADIENT_GREEN',
  GRADIENT_RED = 'GRADIENT_RED',
  GRADIENT_ORANGE = 'GRADIENT_ORANGE',
}

export type Pen = {
  id: Pens,
  displayName: string,
  colors: string[],
}
