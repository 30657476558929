import React from 'react';
import NoInternetContainer from '../../styles/components/NoInternetContainer';
import TranslucentOverlay from '../../styles/components/TranslucentOverlay';
import NoInternetCircleSvg from './NoInternetCircleSvg';

export default function NoInternetOverlay() {
  return (
    <>
      <TranslucentOverlay />
      <NoInternetContainer role="alert" aria-live="polite">
        <NoInternetCircleSvg />
        <span>No Internet</span>
      </NoInternetContainer>
    </>
  );
}
