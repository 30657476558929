import greatJob from './VO71.mp3';
import youDidIt from './VO45.mp3';
import tryAgainNextTime from './VO73.mp3';
import ohNo from './VO47.mp3';

export const planetGameAudio = {
  caughtAStar: youDidIt,
  caughtAllStars: greatJob,
  missedStar: ohNo,
  missedAllStars: tryAgainNextTime,
};
