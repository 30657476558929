import React from 'react';
import { HexagonSvgColors } from '../../styles/styled';
import { HexagonButtonSizes } from './types';

interface HexagonWithDoubleBorderSvgProps {
  colors: HexagonSvgColors,
  // note we currently have no designs for a small double border hexagon -- small are always single border
  size?: HexagonButtonSizes,
}

export default function HexagonWithDoubleBorderSvg({ colors, size }: HexagonWithDoubleBorderSvgProps) {
  if (size === HexagonButtonSizes.LG) {
    return (
      <svg width="91" height="102" viewBox="0 0 91 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d={`M82.4014 21.6104L52.4043 4.29155C48.0918 1.80174 42.7786 1.80174 38.4662 
            4.29155L8.46908 21.6104C4.1566 24.1002 1.5 28.7016 1.5 33.6812V68.3188C1.5 73.2985 
            4.1566 77.8998 8.46908 80.3896L38.4662 97.7085C42.7786 100.198 48.0918 100.198 
            52.4043 97.7085L82.4014 80.3896C86.7139 77.8998 89.3705 73.2984 89.3705 
            68.3188V33.6812C89.3705 28.7015 86.7139 24.1002 82.4014 21.6104Z`}
          fill={colors.outerStroke}
          stroke={colors.outerStroke}
          strokeWidth="3"
        />
        <path
          d={`M50.9043 6.88963L80.9014 24.2085C84.2857 26.1624 86.3705 29.7733 86.3705 
            33.6812V68.3188C86.3705 72.2267 84.2857 75.8376 80.9014 77.7916L50.9043 
            95.1104C47.52 97.0643 43.3504 97.0643 39.9662 95.1104L9.96908 77.7916C6.5848 
            75.8376 4.5 72.2267 4.5 68.3188V33.6812C4.5 29.7733 6.5848 26.1624 9.96908 
            24.2085L39.9662 6.88963C43.3504 4.93571 47.52 4.93571 50.9043 6.88963Z`}
          fill={colors.fill}
          stroke={colors.innerStroke}
          strokeWidth="3"
        />
      </svg>
    );
  }

  if (HexagonButtonSizes.MD) {
    return (
      <svg width="74" height="82" viewBox="0 0 74 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d={`M65.0809 16.6104L43.7441 4.29155C39.4316 1.80174 34.1184 1.80174 29.8059 4.29155L8.46908 
            16.6104C4.1566 19.1002 1.5 23.7015 1.5 28.6812V53.3188C1.5 58.2984 4.1566 62.8998 8.46908 
            65.3896L29.8059 77.7085C34.1184 80.1983 39.4316 80.1983 43.7441 77.7085L65.0809 65.3896C69.3934 
            62.8998 72.05 58.2985 72.05 53.3188V28.6812C72.05 23.7015 69.3934 19.1002 65.0809 16.6104Z`}
          fill={colors.outerStroke}
          stroke={colors.outerStroke}
          strokeWidth="3"
        />
        <path
          d={`M42.2441 6.88963L63.5809 19.2085C66.9652 21.1624 69.05 24.7733 69.05 28.6812V53.3188C69.05 
            57.2267 66.9652 60.8376 63.5809 62.7916L42.2441 75.1104C38.8598 77.0643 34.6902 77.0643 31.3059 
            75.1104L9.96908 62.7916C6.5848 60.8376 4.5 57.2267 4.5 53.3188V28.6812C4.5 24.7733 6.5848 21.1624 
            9.96908 19.2085L31.3059 6.88962C34.6902 4.93571 38.8598 4.93571 42.2441 6.88963Z`}
          fill={colors.fill}
          stroke={colors.innerStroke}
          strokeWidth="3"
        />
      </svg>
    );
  }

  return null;
}

HexagonWithDoubleBorderSvg.defaultProps = {
  size: HexagonButtonSizes.MD,
};
