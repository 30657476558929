import styled from 'styled-components';
import { DRAWABLE_WIDTH_NORMAL } from '../../shared/constants';

interface ActivityScaffoldPositionWrapperProps {
  top?: number;
  left?: number;
  isVisible?: boolean,
}

const ActivityScaffoldPositionWrapper = styled.div`
  position: absolute;
  left: ${(props: ActivityScaffoldPositionWrapperProps) => props.left}px;
  top: ${(props: ActivityScaffoldPositionWrapperProps) => props.top}px;
  width: ${DRAWABLE_WIDTH_NORMAL}px;
  height: min-content;
  opacity: ${(props: ActivityScaffoldPositionWrapperProps) => (props.isVisible ? '1' : '0')};
`;

ActivityScaffoldPositionWrapper.defaultProps = {
  top: 0,
  left: 0,
};

export default ActivityScaffoldPositionWrapper;
