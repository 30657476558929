import { useEffect, useState } from 'react';

export default function useScreenOrientation() {
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)')?.matches);
  const setPortraitOrientation = () => setIsPortrait(window.matchMedia('(orientation: portrait)').matches);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Safari does not support window's ScreenOrientation API, so window resize is used
    window.addEventListener('resize', setPortraitOrientation);
    return (() => {
      window.removeEventListener('resize', setPortraitOrientation);
    });
  }, []);

  return isPortrait;
}
