import React, { useEffect, useMemo } from 'react';
import NextButton from '../components/Buttons/NextButton';
import { ActionType, useGameContext } from '../context/WritingContext/GameContext';
import { KinestheticGroupAreaWrapper } from '../styles/components/ContentWrappers';
import { characterSets, selectCharactersInSetByCharacterType } from '../data/character-sets';
import { Character } from '../data/types';
import KinestheticGroupCharacterContainer from '../styles/components/KinestheticGroupCharacterContainer';
import { ContainerWithButtonContainer } from '../styles/components/ScreenContainers';
import pencil from '../assets/images/pencil.png';
import starGroup3 from '../assets/images/star-group/star-group-3.png';
import starGroup4 from '../assets/images/star-group/star-group-4.png';
import starGroup5 from '../assets/images/star-group/star-group-5.png';
import dashedSquiggles3 from '../assets/images/backgrounds/dashed-squiggles-3.svg';
import kinestheticImages from '../assets/images/kinesthetic';
import kinestheticAudio from '../assets/audio/kinestheticGroupPage';
import NoInternetToast from '../components/NoInternet/NoInternetToast';
import { useAudioContext } from '../context/AudioContext';
import useOnlineStatus from '../hooks/useOnlineStatus/useOnlineStatus';

export default function CharacterKinestheticGroup() {
  const ctx = useGameContext();
  const audioContext = useAudioContext();
  const isOnline = useOnlineStatus();

  const kinestheticGroupDisplayName: string = ctx?.currentCharacter?.kinestheticGroupType
    ? characterSets[ctx.currentCharacter.kinestheticGroupType]?.display : '';

  const allCharactersInCurrentSet: Character[] = (ctx?.currentCharacter)
    ? selectCharactersInSetByCharacterType(ctx.currentCharacter.type)
    : [];

  const kinestheticGroupAudioSrc = useMemo(() => {
    if (!ctx?.currentCharacter?.type || !ctx?.currentCharacter?.kinestheticGroupType) return null;
    return kinestheticAudio[ctx.currentCharacter.kinestheticGroupType] || null;
  }, [ctx?.currentCharacter?.type]);

  useEffect(() => {
    audioContext?.handlePlay({ src: kinestheticGroupAudioSrc });
    return () => audioContext?.handleComplete();
  }, []);

  const goToWriting = () => {
    ctx?.dispatch({ type: ActionType.GO_TO_CHARACTER_DEMO_VIDEO });
  };

  return (
    <>
      {!isOnline && <NoInternetToast />}
      <ContainerWithButtonContainer>
        <h1 className="visually-hidden">
          Kinesthetic Group
          {' '}
          {kinestheticGroupDisplayName}
        </h1>
        <KinestheticGroupAreaWrapper>
          <img className="decoration pencil" src={pencil} alt="" />
          <img className="decoration star-group-left" src={starGroup5} alt="" />
          <img className="decoration star-group-right" src={starGroup3} alt="" />
          {ctx && ctx.currentCharacter && allCharactersInCurrentSet ? (
            <div className="kinesthetic-group-container">
              {allCharactersInCurrentSet.map((character) => {
                const isSelectedCharacter = character.type === ctx?.currentCharacter?.type;
                return (
                  <KinestheticGroupCharacterContainer
                    key={character.type}
                    large={isSelectedCharacter}
                  >
                    <button
                      type="button"
                      disabled={!isSelectedCharacter}
                      onTouchStart={() => {
                        if (isSelectedCharacter) {
                          goToWriting();
                        }
                      }}
                    >
                      <img
                        src={kinestheticImages[character.type]}
                        alt={`${character.display} is part of the kinesthetic group ${kinestheticGroupDisplayName}`}
                      />
                    </button>
                  </KinestheticGroupCharacterContainer>
                );
              })}
            </div>
          ) : null }
          <img className="decoration star-group-bottom" src={starGroup4} alt="" />
          <img className="decoration squiggles" src={dashedSquiggles3} alt="" />
        </KinestheticGroupAreaWrapper>
        <div className="button-container">
          <NextButton
            isDisabled={false}
            buttonTouch={goToWriting}
          />
        </div>
      </ContainerWithButtonContainer>
    </>
  );
}
