/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import arrowOutlined from '../../assets/images/icons/arrow-outlined.svg';
import starGroup from '../../assets/lotties/star-group.json';
import crossShapeDemo from '../../assets/lotties/PreWriting/PW2/plusSign.json';
import {
  PreWritingHandwritingActivityCrossContainer,
  PreWritingHandwritingActivityCrossShapeWrapper,
} from '../../styles/components/PreWritingWrappers';
import { DrawingArea } from '../../styles/components/DrawingArea';
import PreWritingHandwritingArea from '../Konva/PreWritingHandwritingArea';
import { ShapeType } from '../../data/types/preWriting/shapes';
import LottiePlayer from '../LottiePlayer';

type CrossShapeProps = {
  completed?: boolean,
  canWrite?: boolean,
  iteration: number,
  shouldAutoplayDemo?: boolean,
  correctStroke: (value: boolean) => void,
  onFinish: () => void,
}

export function CrossShape({
  completed, canWrite = true, iteration, shouldAutoplayDemo, correctStroke, onFinish,
}: CrossShapeProps) {
  const [verticalAreaWidth, setVerticalAreaWidth] = useState<number>(0);
  const [verticalAreaheight, setVerticalAreaHeight] = useState<number>(0);
  const [horizontalAreaWidth, setHorizontalAreaWidth] = useState<number>(0);
  const [horizontalAreaHeight, setHorizontalAreaHeight] = useState<number>(0);
  const [finishedFirstStroke, setFinishedFirstStroke] = useState<boolean>(false);
  const [finishedSecondStroke, setFinishedSecondStroke] = useState<boolean>(false);
  const [isFirstStrokeCorrect, setIsFirstStrokeCorrect] = useState(false);
  const [isSecondStrokeCorrect, setIsSecondStrokeCorrect] = useState(false);

  const finishedActivityIteration = useMemo(
    (): boolean => finishedFirstStroke && finishedSecondStroke,
    [finishedFirstStroke, finishedSecondStroke],
  );

  useEffect(() => {
    if (finishedActivityIteration) {
      if (isFirstStrokeCorrect && isSecondStrokeCorrect) {
        correctStroke(true);
      } else {
        correctStroke(false);
      }
      onFinish();
    }
  }, [finishedActivityIteration]);

  useEffect(() => {
    const crossArea = document.getElementById('PreWritingCrossShape');

    setVerticalAreaWidth(crossArea?.clientWidth ? crossArea.clientWidth * 0.15 : 0);
    setVerticalAreaHeight(crossArea?.clientHeight || 0);

    setHorizontalAreaWidth(crossArea?.clientWidth || 0);
    setHorizontalAreaHeight(crossArea?.clientHeight ? crossArea.clientHeight * 0.15 : 0);
  }, []);

  const handleIsFirstStrokeCorrect = (isValid: boolean) => {
    setIsFirstStrokeCorrect(isValid);
  };

  const handleIsSecondStrokeCorrect = (isValid: boolean) => {
    setIsSecondStrokeCorrect(isValid);
  };

  return (
    <PreWritingHandwritingActivityCrossContainer iterationCount={iteration}>
      {finishedActivityIteration || completed
        ? (
          <div style={{ height: 382, display: 'flex', alignItems: 'center' }}>
            <LottiePlayer
              width={382}
              data={starGroup}
              segmentFrames={[0, 100]}
              speed={0.75}
              scale={2}
            />
          </div>
        ) : (
          <PreWritingHandwritingActivityCrossShapeWrapper>
            <div style={{
              position: 'absolute', width: 382, zIndex: 9, marginLeft: 20, marginTop: -16,
            }}
            >
              <LottiePlayer
                data={crossShapeDemo}
                width="100%"
                shouldAutoplay={shouldAutoplayDemo}
                speed={1.25}
              />
            </div>
            <DrawingArea id="PreWritingCrossShape" className="drawing-area-cross">
              <img className="arrow-left" src={arrowOutlined} alt="arrow-left-icon" />
              <img className="arrow-down" src={arrowOutlined} alt="arrow-down-icon" />
              <div style={{ position: 'absolute', left: '166px' }}>
                <PreWritingHandwritingArea // 1st stroke (vertical)
                  isMultiStroke
                  shape={ShapeType.VERTICAL_LINE}
                  width={verticalAreaWidth}
                  height={verticalAreaheight}
                  canWrite={!finishedFirstStroke && canWrite}
                  onFinishDrawing={() => setFinishedFirstStroke(true)}
                  isStrokeCorrect={handleIsFirstStrokeCorrect}
                />
              </div>
              <div style={{ position: 'absolute', top: '166px' }}>
                <PreWritingHandwritingArea // 2nd stroke (horizontal)
                  isMultiStroke
                  shape={ShapeType.HORIZONTAL_LINE}
                  width={horizontalAreaWidth}
                  height={horizontalAreaHeight}
                  canWrite={!finishedSecondStroke && canWrite}
                  onFinishDrawing={() => setFinishedSecondStroke(true)}
                  isStrokeCorrect={handleIsSecondStrokeCorrect}
                />
              </div>
            </DrawingArea>
          </PreWritingHandwritingActivityCrossShapeWrapper>
        )}
    </PreWritingHandwritingActivityCrossContainer>
  );
}
