import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PreWritingSelectionWrapper } from '../styles/components/PreWritingWrappers';
import { Paths, PreWritingActivityType } from '../data/types';
import PreWritingActivityButton from '../components/Buttons/PreWritingActivityButton';
import starGroup1 from '../assets/images/star-group/star-group-1.png';
import starGroup2 from '../assets/images/star-group/star-group-2.png';
import pencil from '../assets/images/pencil.png';
import BackButton from '../components/Buttons/BackButton';
import sequenceIcon from '../assets/images/icons/sequence-icon.svg';

export default function PreWritingSelection() {
  const navigate = useNavigate();
  return (
    <PreWritingSelectionWrapper>
      <img src={starGroup1} alt="" className="star-group-1" />
      <img src={starGroup2} alt="" className="star-group-2" />
      <BackButton
        path={Paths.ACTIVITY_SET_SELECTION}
        icon={sequenceIcon}
        placedLeft={false}
      />
      <PreWritingActivityButton
        isDisabled
        // TO DO : REDIRECT TO THE PROPER PW ACTIVITY ONCE IS IT IMPLEMENTED
        buttonTouch={() => navigate(`/${Paths.PRE_WRITING}`)}
        preWritingActivityType={PreWritingActivityType.PW1}
      />
      <PreWritingActivityButton
        isDisabled={false}
        buttonTouch={() => navigate(`/${Paths.PRE_WRITING_HANDWRITING}`)}
        preWritingActivityType={PreWritingActivityType.PW2}
      />
      <PreWritingActivityButton
        isDisabled
        // TO DO : REDIRECT TO THE PROPER PW ACTIVITY ONCE IS IT IMPLEMENTED
        buttonTouch={() => navigate(`/${Paths.PRE_WRITING}`)}
        preWritingActivityType={PreWritingActivityType.PW3}
      />
      <img src={pencil} alt="" className="pencil" />
    </PreWritingSelectionWrapper>
  );
}
