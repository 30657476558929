import React, { useState } from 'react';
import {
  PreWritingHandwritingActivityWrapper,
} from '../styles/components/PreWritingWrappers';
import dashedSquiggles1 from '../assets/images/backgrounds/dashed-squiggles-1.svg';
import whiteStar from '../assets/images/white-star.svg';
import NextButton from '../components/Buttons/NextButton';
import { CircleShape } from '../components/PreWriting/CircleShape';
import { LineShape } from '../components/PreWriting/LineShape';
import { CrossShapeActivity } from './CrossShapeActivity';
import PW2Reward from '../components/PreWriting/PW2Reward';

export default function PWHandwritingActivity() {
  const [step, setStep] = useState(1);
  const [isActivityFinished, setIsActivityFinished] = useState(false);

  const handleShapeToDisplay = () => {
    if (step === 1) {
      return (
        <LineShape onFinish={() => setIsActivityFinished(true)} />
      );
    }
    if (step === 2) {
      return (
        <CircleShape onFinish={() => setIsActivityFinished(true)} />
      );
    }
    if (step === 3) {
      return (
        <CrossShapeActivity onFinish={() => setIsActivityFinished(true)} />
      );
    }
    if (step === 4) {
      return (
        <PW2Reward />
      );
    }
    return <div />;
  };

  const handleNext = () => {
    setStep(step + 1);
    setIsActivityFinished(false);
  };

  return (
    <PreWritingHandwritingActivityWrapper>
      <div className="shapes-wrapper">
        {handleShapeToDisplay()}
      </div>
      <div className="next-button-wrapper">
        { step <= 3 && isActivityFinished
      && (
      <NextButton
        buttonTouch={handleNext}
        isDisabled={!isActivityFinished}
      />
      )}
      </div>
      <img className="squiggles" src={dashedSquiggles1} alt="" />
      <img className="white-star one" src={whiteStar} alt="" />
      <img className="white-star two" src={whiteStar} alt="" />
    </PreWritingHandwritingActivityWrapper>
  );
}
