import React from 'react';
import { Rect } from 'react-konva';
import { WRITING_LINE_HEIGHT_NORMAL } from '../../shared/constants';
import envSettings from '../../data/envSettings';
import { SpacingGradingPosition } from '../../data/types';

type SpacingRestrictedAreasProps = {
  gradingPositions: SpacingGradingPosition,
}

export default function SpacingRestrictedAreas({ gradingPositions }: SpacingRestrictedAreasProps) {
  return (
    <>
      <Rect
        id="inner-spacing-restricted-area"
        fill={envSettings?.devMode ? 'red' : 'transparent'}
        opacity={0.2}
        width={gradingPositions.inner?.width || 10}
        height={WRITING_LINE_HEIGHT_NORMAL}
        y={0}
        x={gradingPositions.inner?.xOffset || -23}
        listening
      />
      <Rect
        id="outer-spacing-restricted-area"
        fill={envSettings?.devMode ? 'red' : 'transparent'}
        opacity={0.2}
        width={gradingPositions.outer.width || 10}
        height={WRITING_LINE_HEIGHT_NORMAL}
        y={0}
        x={gradingPositions.outer.xOffset}
        listening
      />
    </>
  );
}
