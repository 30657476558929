import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClosePageButton from '../components/Buttons/ClosePageButton';
import InformationContent from '../components/InformationContent';
import EducatorResourcesLink from '../components/Links/EducatorResourcesLink';
import { Paths } from '../data/types';
import useTouchDevice from '../hooks/useTouchDevice';
import { InfoPageAreaWrapper } from '../styles/components/ContentWrappers';

export default function Information() {
  const isTouchDevice = useTouchDevice();
  const navigate = useNavigate();

  const getButtonLabel = () => (isTouchDevice
    ? 'go to instructional sequence page'
    : 'return to error page');

  const handleClosePageButtonTouch = (): void => {
    if (isTouchDevice) {
      navigate(`/${Paths.EDUCATOR_RESOURCES}`, { state: { shouldCheckAccess: false } });
    } else {
      navigate('/');
    }
  };

  return (
    <InfoPageAreaWrapper>
      <ClosePageButton buttonTouch={() => handleClosePageButtonTouch()} ariaLabel={getButtonLabel()} />
      <h1 className="visually-hidden">Information Page for Educators and Caretakers</h1>
      <InformationContent />
      { isTouchDevice && <EducatorResourcesLink /> }
    </InfoPageAreaWrapper>
  );
}
