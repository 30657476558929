import React, { useMemo, useState } from 'react';
import NextButton from '../components/Buttons/NextButton';
import { ActionType, useGameContext } from '../context/WritingContext/GameContext';
import { GameScreens } from '../data/types';
import {
  useActivityProgressContext,
  ActionType as ProgressActionType,
} from '../context/ActivityProgressContext/ActivityProgressContext';
import DemoVideoWrapper from '../components/DemoVideoWrapper';
import VideoPlayer from '../components/Video/VideoPlayer';
import useOnlineStatus from '../hooks/useOnlineStatus/useOnlineStatus';
import envSettings from '../data/envSettings';
import { DEFAULT_VIDEO_PATH } from '../shared/constants';
import getEnvironment from '../shared/getEnvironment';

export default function GripDemo() {
  const ctx = useGameContext();
  const activityProgress = useActivityProgressContext();
  const isOnline = useOnlineStatus();
  const [videoHasFinished, setVideoHasFinished] = useState(false);
  const [videoHasPlayedBefore, setVideoHasPlayedBefore] = useState(false);
  const environment = getEnvironment();
  const videoPlayRequired = useMemo(() => {
    if (envSettings.devMode || videoHasFinished || environment === 'dev') return false;
    if (activityProgress?.redirectedFrom === null) return true;
    return false;
  }, [activityProgress?.redirectedFrom, videoHasFinished]);

  const nextButtonIsEnabled = useMemo(
    () => (!isOnline || videoHasFinished || videoHasPlayedBefore || !videoPlayRequired || envSettings.devMode),
    [isOnline, videoHasFinished, videoHasPlayedBefore, videoPlayRequired],
  );

  if (!ctx) return null;

  const handleContinue = (): void => {
    if (!ctx.currentCharacter) {
      ctx.dispatch({ type: ActionType.RESET });
    }

    if (ctx.currentCharacter && activityProgress) {
      switch (activityProgress.redirectedFrom) {
        case GameScreens.SELF_ASSESSMENT:
          ctx.dispatch({ type: ActionType.GO_TO_SELF_ASSESS_PAGE });
          break;
        case GameScreens.DRAWING_ACTIVITIES:
        default:
          ctx.dispatch({ type: ActionType.START_WRITING });
          break;
      }
      activityProgress.dispatch({
        type: ProgressActionType.UPDATE_ACTIVITY_PROGRESS,
        payload: {
          redirectedFrom: GameScreens.GRIP_VIDEO,
        },
      });
    }
  };

  return (
    <DemoVideoWrapper
      headerText="Demo Video for Grip"
      nextButton={(
        <NextButton
          buttonTouch={handleContinue}
          isDisabled={!nextButtonIsEnabled}
        />
      )}
    >
      <VideoPlayer
        path={`${DEFAULT_VIDEO_PATH}/Grip-Demo.mp4`}
        ariaLabelPostfix="grip"
        hasPlayedBefore={!videoPlayRequired}
        onFinish={() => { setVideoHasFinished(true); setVideoHasPlayedBefore(true); }}
      />
    </DemoVideoWrapper>
  );
}
