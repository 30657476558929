// New shapes can be added here
export enum ShapeType {
  CIRCLE = 'CIRCLE',
  CROSS = 'CROSS',
  VERTICAL_LINE = 'VERTICAL_LINE',
  HORIZONTAL_LINE = 'HORIZONTAL_LINE'
}

type XYCoords = {
  x: number,
  y: number,
}

type XYMinMax = {
  xMin?: number,
  xMax?: number,
  yMin?: number,
  yMax?: number,
}

export type Point = {
  // coordinates are positioned from top left of character position
  coordinates: XYCoords
  // set x, y coords before setting rotation and offsets for start point
  rotationDegrees?: number,
  rotationOffsetX?: number,
  rotationOffsetY?: number,
  // necessary for visible midpoints
  visible?: boolean,
  // circle radius of zone used to capture intersections
  // or distance allowed if start or end point
  // if not specified, a default value is used
  radius?: number,
  // index of intersection order; start point = 0
  orderId?: number,
  // supports characters with overlapping start/stop points or retraced strokes, e.g. 'O' or 'm'
  // point will only track intersection after specified orderId point is intersected
  allowIntersectionAfterOrderId?: number,
  // supports showing mid/end points only after a stroke passes a certain point to reduce
  // confusion in users about where and in what direction to bring the stroke.
  visibleAfterOrderId?: number,
}

export interface ThresholdRestrictedPoint extends Point {
  // supports strokes with start/endpoints that must not pass a certain threshold, e.g. 'g' or 'q'
  // coordinates are positioned from top left of yellow guide box
  minMax?: XYMinMax
}

export enum RestrictedAreaType {
  MID_STROKE = 'MID_STROKE',
  END_STROKE = 'END_STROKE',
}

export type RestrictedAreaShape = {
  id: string,
  type: RestrictedAreaType,
  coordinates: XYCoords,
  points: number[],
  tension?: number,
}

export enum StrokeIntersectionType {
  END_POINT_INTERSECTION = 'END_POINT_INTERSECTION',
  START_POINT_INTERSECTION = 'START_POINT_INTERSECTION',
}

export type Stroke = {
  id: string,
  order: number,
  paths: string,
  // dot strokes will have only one point
  points: (Point | ThresholdRestrictedPoint)[],
  restrictedAreas?: RestrictedAreaShape[],
  requireIntersection?: StrokeIntersectionType,
};

export enum PracticeLinesRelationship {
  WITHIN_LINES = 'WITHIN_LINES',
  BELOW_LINES = 'BELOW_LINES',
}

export type GradingSettings = {
  pointRadius: number,
  pathStrokeWidth: number,
  // percentage of drawn points that must intersect with model path
  strokePathAccuracyThreshold: number,
};

export type ShapePosition = {
  // used to position whole model within target writing area from top left
  // should be set based on writing lines and scaled up for large model lines at top
  xOffset?: number,
  yOffset?: number,
  // default to line height for upper chars, but variable for lower chars
  height?: number,
  width?: number,
}

export type Shape = {
  type: ShapeType,
  display: string,
  requiredIterations: number,
  // relationship to lower line. Default is within
  practiceLinesRelationship?: PracticeLinesRelationship,
  positioning: ShapePosition
  strokes: Stroke[],
  gradingSettings: GradingSettings,
};
