import styled from 'styled-components';

type EmptyCharacterSpacerProps = {
  width: string,
  height: string
}

const EmptyCharacterSpacer = styled.div<EmptyCharacterSpacerProps>`
  visibility: hidden;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export default EmptyCharacterSpacer;
