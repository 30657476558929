import styled from 'styled-components';

type CharacterContainerProps = {
  large: boolean;
}

const KinestheticGroupCharacterContainer = styled.div`
  button {
    background: transparent;
    border: none;
    min-width: 85px;
    min-height: 98px;
    width: ${(props: CharacterContainerProps) => (props.large ? '156' : '85')}px;
    height: ${(props: CharacterContainerProps) => (props.large ? '180' : '98')}px;
    padding: 0;
    margin: 0;
  }
  img {
    min-width: 85px;
    min-height: 98px;
    width: ${(props: CharacterContainerProps) => (props.large ? '156' : '85')}px;
    height: ${(props: CharacterContainerProps) => (props.large ? '180' : '98')}px;
  }
`;

export default KinestheticGroupCharacterContainer;
