import { SpacingActionOutcome } from '../../../stateMachines/spacingMachine/spacingMachine';
import { SpacingActivityType } from '../writing/activities';

export enum OptionType {
  CORRECT = 'CORRECT',
  TOO_CLOSE = 'TOO_CLOSE',
  TOO_WIDE = 'TOO_WIDE',
}

export type MultiChoiceOption = {
  spacingActionOutcome: SpacingActionOutcome.PASS
  | SpacingActionOutcome.FAIL,
  optionType: OptionType,
  isSelected: boolean,
}

export type MultiChoiceOptions = {
  [key in SpacingActivityType.MULTI_CHOICE_ALL
  | SpacingActivityType.MULTI_CHOICE_CLOSE
  | SpacingActivityType.MULTI_CHOICE_WIDE]: MultiChoiceOption[]
}
