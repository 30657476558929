import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../data/types';

export default function PolicyLinkContainer({ returnPage }: { returnPage: Paths }) {
  const getAbsolutePath = (path: Paths) => `/${path}`;

  return (
    <div className="link-container">
      <Link to={getAbsolutePath(Paths.TOS)} state={{ returnPage }}>Terms of Use</Link>
      <Link to={getAbsolutePath(Paths.PRIVACY)} state={{ returnPage }}>Privacy Policy</Link>
      <Link to={getAbsolutePath(Paths.DIRECT_NOTICE)} state={{ returnPage }}>Direct Notice</Link>
    </div>
  );
}
