import React, { useEffect, useState } from 'react';
import ReactHowler from 'react-howler';
import { useAudioContext } from '../context/AudioContext';

export interface AudioCue {
  src: any,
  onEnd?: () => void,
}

export default function AudioPlayer() {
  const [played, setPlayed] = useState(false);
  const [shouldTriggerEnding, setShouldTriggerEnding] = useState(false);
  const audioContext = useAudioContext();

  useEffect(() => {
    setPlayed(false);
  }, [audioContext?.audioCue]);

  useEffect(() => {
    if (shouldTriggerEnding && audioContext?.audioCue && audioContext?.audioCue.onEnd) {
      audioContext?.audioCue.onEnd();
    }
    setShouldTriggerEnding(false);
  }, [shouldTriggerEnding]);

  if (!audioContext || !audioContext.audioCue || !audioContext.audioCue.src) return null;

  return (
    <ReactHowler
      src={audioContext.audioCue.src}
      onEnd={() => {
        setShouldTriggerEnding(true);
        setPlayed(true);
      }}
      playing={!played && audioContext.isPlaying}
    />
  );
}

AudioPlayer.defaultProps = {
  playing: false,
};
