/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import VersionDisplayWrapper from '../../styles/components/VersionDisplayWrapper';
import colors from '../../styles/colors';

type VersionDisplayProps = {
  isLanding: boolean;
}

export default function VersionDisplay({
  isLanding,
}: VersionDisplayProps) {
  return (
    <VersionDisplayWrapper
      color={isLanding ? colors.orange : colors.black}
      isLanding={isLanding}
    >
      v4.0
    </VersionDisplayWrapper>
  );
}
