import styled from 'styled-components';

type ProgressCircleProps = {
  isSelected: boolean,
}
const ProgressCircle = styled.div<ProgressCircleProps>`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: ${(props) => (props.isSelected
    ? props.theme.colors.progressIndicator.selected
    : props.theme.colors.progressIndicator.default
  )};
`;

export default ProgressCircle;
