import React from 'react';
import { useTheme } from 'styled-components';
import arrowIcon from '../../assets/images/icons/arrow-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';

type NextButtonProps = {
  isDisabled: boolean,
  buttonTouch: () => void,
}

export default function NextButton({ isDisabled, buttonTouch }: NextButtonProps) {
  const theme = useTheme();

  return (
    <CircleButton
      className="next"
      disabled={isDisabled}
      aria-disabled={isDisabled}
      colors={isDisabled ? theme.colors.buttons.next.disabled : theme.colors.buttons.next.active}
      aria-label="go to next activity"
      onTouchStart={buttonTouch}
      role="link"
    >
      <img
        alt=""
        style={{ width: '40px', height: '39px' }}
        src={arrowIcon}
      />
    </CircleButton>
  );
}
