import React, { useMemo } from 'react';
import { Group, Path } from 'react-konva';
import RequiredPoints from './RequiredPoints';
import RestrictedAreas from './RestrictedAreas';
import envSettings from '../../data/envSettings';
import {
  Character, DifficultyLevel, Point, RestrictedAreaShape, Stroke, ThresholdRestrictedPoint,
} from '../../data/types';
import { SCALE_LARGE } from '../../shared/constants';

type CharacterModelPathType = {
  character: Character
  difficultyLevel: DifficultyLevel,
  requiredPoints: (Point | ThresholdRestrictedPoint)[],
  intersectedPoints: Set<string>,
  restrictedAreas: RestrictedAreaShape[] | [],
  stroke: Stroke,
  positioning: { xOffset: number, yOffset: number },
  children?: any,
}

export default function CharacterModelPath({
  character,
  difficultyLevel,
  requiredPoints,
  intersectedPoints,
  restrictedAreas,
  stroke,
  positioning,
  children,
}: CharacterModelPathType) {
  const characterPathWidth = useMemo(() => {
    const pathWidth = character.gradingSettings.pathStrokeWidth;
    if (difficultyLevel === DifficultyLevel.EASY) return pathWidth * SCALE_LARGE;
    return pathWidth;
  }, [character, difficultyLevel]);

  return (
    <Group
      id="character-model-path"
      x={positioning.xOffset}
      y={positioning.yOffset}
    >
      <Path
        id={stroke?.id}
        key={stroke?.id}
        data={stroke?.paths[difficultyLevel]}
        stroke={envSettings?.devMode ? 'gray' : 'transparent'}
        opacity={envSettings?.devMode ? 0.2 : 0}
        strokeWidth={characterPathWidth}
        strokeOrder={stroke?.order}
      />
      <RequiredPoints
        requiredPoints={requiredPoints}
        intersectedPoints={intersectedPoints}
        character={character}
        difficultyLevel={difficultyLevel}
      />
      <RestrictedAreas restrictedAreas={restrictedAreas || []} difficultyLevel={difficultyLevel} />
      { children }
    </Group>
  );
}

CharacterModelPath.defaultProps = {
  children: null,
};
