import styled from 'styled-components';

type PlanetGameWrapperProps = {
  wordHeight: number | null,
}

const PlanetGameWrapper = styled.div<PlanetGameWrapperProps>`
  background: radial-gradient(72.21% 72.21% at 32.62% 12.43%, #502D7E 0%, #281B5D 100%);
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 216px;
      height: 216px;

      img.planet {
        height: 180px;
        position: absolute;
      }
    }

    .middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 40px;
      height: 100px;
      align-self: flex-start;
      margin-left: 40px;
    }

    .bottom {
      width: auto;

      img.word {
        height: ${(props) => props.wordHeight || 180}px;
      }

      button.next {
        position: absolute;
        right: 5%;
        bottom: 7%;
      }
    }
  }
`;

export default PlanetGameWrapper;
