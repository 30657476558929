import styled from 'styled-components';

type ZoomOutAnimationProps = {
  tranlsatePx: [string, string];
}

const ZoomOutAnimation = styled.div<ZoomOutAnimationProps>`
  /* Do not change name of classes that begin with zoomOut.
  ** These are connected to a <CSSTransition /> component.
  */
  &.zoomOut-enter {
    opacity: 1;
    visibility: visible;
  }

  &.zoomOut-enter-active {
    transform: translate(
        ${(props) => props.tranlsatePx[0] || '0px'},
        ${(props) => props.tranlsatePx[1] || '0px'}
      ) scale(0);
    visibility: hidden;
    opacity: 1;
    transition: transform 1200ms ease-in-out 500ms,
                visibility 500ms ease-out 1200ms;
  }

  &.zoomOut-enter-done {
    opacity: 0;
    visibility: hidden;
  }

  &.zoomOut-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  &.zoomOut-exit {
    opacity: 0;
    visibility: hidden;
  }
`;

export default ZoomOutAnimation;
