import React, { createContext, useContext } from 'react';
import { ActorRefFrom } from 'xstate';
import { useActor, useInterpret } from '@xstate/react';
import { rewardsMachine } from '../stateMachines/rewardsMachine/rewardsMachine';

interface RewardsGlobalStateContextType {
  service: ActorRefFrom<typeof rewardsMachine>;
}

const RewardsContext = createContext({} as RewardsGlobalStateContextType);

type RewardsContextProviderProps = {
  children: React.ReactNode,
}

export default function RewardsContextProvider({ children }: RewardsContextProviderProps) {
  const service = useInterpret(rewardsMachine);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RewardsContext.Provider value={{ service }}>
      {children}
    </RewardsContext.Provider>
  );
}

export function useRewardsContext() {
  const globalRewardsServices = useContext(RewardsContext);

  if (globalRewardsServices === undefined) {
    throw new Error('useRewardsContext must be used within RewardsContextProvider');
  }

  const [state, send] = useActor(globalRewardsServices.service);

  return { state, send };
}
