import React from 'react';
import ProgressCircle from '../styles/components/ProgressCircle';
import ProgressIndicatorWrapper from '../styles/components/ProgressIndicatorWrapper';

type ProgressIndicatorProps = {
  pageCount: number,
  currentIndex: number,
}

export default function ProgressIndicator({ pageCount, currentIndex }: ProgressIndicatorProps) {
  return (
    <ProgressIndicatorWrapper aria-label="independent activity progress">
      {(() => {
        const pages = [];
        for (let i = 0; i < pageCount; i++) {
          pages.push(
            <li key={i} aria-current={currentIndex === i}>
              <ProgressCircle isSelected={currentIndex === i} />
            </li>,
          );
        }
        return pages;
      })()}
    </ProgressIndicatorWrapper>
  );
}
