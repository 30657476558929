import { CharacterType } from '../../../data/types';
import letterA from './Letter_A.mp3';
import letterB from './Letter_B.mp3';
import letterC from './Letter_C.mp3';
import letterD from './Letter_D.mp3';
import letterE from './Letter_E.mp3';
import letterF from './Letter_F.mp3';
import letterG from './Letter_G.mp3';
import letterH from './Letter_H.mp3';
import letterI from './Letter_I.mp3';
import letterJ from './Letter_J.mp3';
import letterK from './Letter_K.mp3';
import letterL from './Letter_L.mp3';
import letterM from './Letter_M.mp3';
import letterN from './Letter_N.mp3';
import letterO from './Letter_O.mp3';
import letterP from './Letter_P.mp3';
import letterQ from './Letter_Q.mp3';
import letterR from './Letter_R.mp3';
import letterS from './Letter_S.mp3';
import letterT from './Letter_T.mp3';
import letterU from './Letter_U.mp3';
import letterV from './Letter_V.mp3';
import letterW from './Letter_W.mp3';
import letterX from './Letter_X.mp3';
import letterY from './Letter_Y.mp3';
import letterZ from './Letter_Z.mp3';
import instrumental1 from './Instrumental1.mp3';
import instrumental2 from './Instrumental2.mp3';

const phonicsSongs = {
  [CharacterType.LOWER_A]: letterA,
  [CharacterType.LOWER_B]: letterB,
  [CharacterType.LOWER_C]: letterC,
  [CharacterType.LOWER_D]: letterD,
  [CharacterType.LOWER_E]: letterE,
  [CharacterType.LOWER_F]: letterF,
  [CharacterType.LOWER_G]: letterG,
  [CharacterType.LOWER_H]: letterH,
  [CharacterType.LOWER_I]: letterI,
  [CharacterType.LOWER_J]: letterJ,
  [CharacterType.LOWER_K]: letterK,
  [CharacterType.LOWER_L]: letterL,
  [CharacterType.LOWER_M]: letterM,
  [CharacterType.LOWER_N]: letterN,
  [CharacterType.LOWER_O]: letterO,
  [CharacterType.LOWER_P]: letterP,
  [CharacterType.LOWER_Q]: letterQ,
  [CharacterType.LOWER_R]: letterR,
  [CharacterType.LOWER_S]: letterS,
  [CharacterType.LOWER_T]: letterT,
  [CharacterType.LOWER_U]: letterU,
  [CharacterType.LOWER_V]: letterV,
  [CharacterType.LOWER_W]: letterW,
  [CharacterType.LOWER_X]: letterX,
  [CharacterType.LOWER_Y]: letterY,
  [CharacterType.LOWER_Z]: letterZ,

  [CharacterType.NUMBER_0]: instrumental2,
  [CharacterType.NUMBER_1]: instrumental1,
  [CharacterType.NUMBER_2]: instrumental2,
  [CharacterType.NUMBER_3]: instrumental1,
  [CharacterType.NUMBER_4]: instrumental2,
  [CharacterType.NUMBER_5]: instrumental1,
  [CharacterType.NUMBER_6]: instrumental2,
  [CharacterType.NUMBER_7]: instrumental1,
  [CharacterType.NUMBER_8]: instrumental2,
  [CharacterType.NUMBER_9]: instrumental1,

  [CharacterType.UPPER_A]: letterA,
  [CharacterType.UPPER_B]: letterB,
  [CharacterType.UPPER_C]: letterC,
  [CharacterType.UPPER_D]: letterD,
  [CharacterType.UPPER_E]: letterE,
  [CharacterType.UPPER_F]: letterF,
  [CharacterType.UPPER_G]: letterG,
  [CharacterType.UPPER_H]: letterH,
  [CharacterType.UPPER_I]: letterI,
  [CharacterType.UPPER_J]: letterJ,
  [CharacterType.UPPER_K]: letterK,
  [CharacterType.UPPER_L]: letterL,
  [CharacterType.UPPER_M]: letterM,
  [CharacterType.UPPER_N]: letterN,
  [CharacterType.UPPER_O]: letterO,
  [CharacterType.UPPER_P]: letterP,
  [CharacterType.UPPER_Q]: letterQ,
  [CharacterType.UPPER_R]: letterR,
  [CharacterType.UPPER_S]: letterS,
  [CharacterType.UPPER_T]: letterT,
  [CharacterType.UPPER_U]: letterU,
  [CharacterType.UPPER_V]: letterV,
  [CharacterType.UPPER_W]: letterW,
  [CharacterType.UPPER_X]: letterX,
  [CharacterType.UPPER_Y]: letterY,
  [CharacterType.UPPER_Z]: letterZ,

  [CharacterType.SYMBOL_PLUS]: instrumental1,
  [CharacterType.SYMBOL_EQUAL]: instrumental2,
  [CharacterType.SYMBOL_QUESTION]: instrumental1,
  [CharacterType.SYMBOL_EXCLAMATION]: instrumental2,
};

export default phonicsSongs;
