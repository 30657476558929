import styled from 'styled-components';
import { Pen } from '../../data/types';
import { IconButtonColors, TextButtonColors } from '../styled';
import colorNames from '../colors';

/* Base button - use for all buttons
pointer-events is used so touch events do not trigger disabled buttons

Previously, an issue was identified where click events were not registering
on buttons while using a passive stylus.

-------------------
Ticket Url: https://atomicsoftware.atlassian.net/browse/HWP-215
Ticket Description:
  Stylus used: My First Stylus
  There are times when you click the next button with the stylus and it doesn’t
  register and the user needs to click the button a few times before the action is performed.
-------------------

onTouchStart event is now used for all buttons in Starwriter to better support
touch/stylus events.

Additionally, onClick event fires after onTouchStart and onTouchEnd and creates the
perception of a delay.
*/
const Button = styled.button`
  border: unset;
  pointer-events: auto;

  &:disabled {
    pointer-events: none;
  }
`;

const ButtonWithShadow = styled(Button)`
  box-shadow: 0px 10px 0px;
`;

export const ButtonWithSvg = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: ${(props) => props.theme.fonts.families.primary};
  font-size: ${(props) => props.theme.fonts.sizes.xl};
  background-color: unset;
  padding: 0;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
  transform-origin: bottom;

  span {
    position: absolute;
  }
`;

export const BadgeImg = styled.img`
  position: absolute;
  width: 24px;
  top: 13%;
  right: -10%;
`;

export interface CircleButtonProps {
  colors: IconButtonColors;
  width?: string;
  height?: string;
}

export const CircleButton = styled(ButtonWithShadow) <CircleButtonProps>`
  width: ${(props) => props.width || '80px'};
  height: ${(props) => props.height || '80px'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.colors.primary};
  box-shadow: 0px 10px 0px ${(props) => props.colors.secondary};
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

export interface ActivitySetButtonProps {
  color: string;
  width?: string;
  height?: string;
  isCircle: boolean;
  borderColor: string;
  disabled?: boolean;
}

export const ActivitySetButton = styled(ButtonWithSvg) <ActivitySetButtonProps>`
  width: ${(props) => props.width || '80px'};
  height: ${(props) => props.height || '80px'};
  border-radius: ${(props) => (props.isCircle ? '50%' : '0%')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  z-index: 2;
  box-shadow: 0 0 0 5px white, 0 0 0 10px ${(props) => props.borderColor};

  //If the ellipse, line and polygon are condensed into one icon, this class could be deleted.
  .pre-writing-icons{
    width: 64px;
    height: 64px;
  }
  
  img {
    width: 100%;
    height: 130px;
    width: 130px;
  }
  ${(props) => props.disabled && `
      opacity: 0.5;
  `}
`;

export interface PreWritingActivityButtonWrapperProps {
  width?: string;
  height?: string;
  shape:string;
}
export const PreWritingActivityButtonWrapper = styled(ButtonWithSvg)<PreWritingActivityButtonWrapperProps>`
  width: ${(props) => props.width || '80px'};
  height: ${(props) => props.height || '80px'};
  ${(props) => props.shape === 'square' && `
    margin-bottom: 9%;
  `}
  /* the circle and triangle buttons images have been unsaturated to display they are unavailable */
  ${(props) => props.shape === 'circle' && `
    margin: 0 3% 1% 3%;
    opacity: 0.6;
  `}
  ${(props) => props.shape === 'triangle' && `
    margin: 1% 0 0 0;
    opacity: 0.6;
  `}
  img {
    width: ${(props) => (props.shape === 'circle' ? '88%' : '100%')};
    height: auto;
  }
  .green-star{
    position:absolute;

    &.left{
      width:24px;
      bottom:0%;
      right:30%;
    }

    &.right{
      width:20px;
      top:35%;
      right:5%;
    }
  }
`;

interface PillButtonProps {
  readonly colors: TextButtonColors;
  readonly autoWidth?: boolean;
}

export const PillButton = styled(Button) <PillButtonProps>`
  width: ${(props) => (props.autoWidth ? 'max-content' : 'clamp(175px, 197px, 203px)')};
  height: clamp(55px, 62px, 64px);
  border-radius: 140px;
  border: none;
  text-align: center;
  background-color: ${(props) => props.colors.primary};
  box-shadow: 0px 7px 0px ${(props) => props.colors.secondary};
  color: ${(props) => props.colors.contrastText};
  font-size: ${(props) => props.theme.fonts.sizes.xl};
  padding: ${(props) => (props.autoWidth ? '0.7em 1.5em' : '0.7em')};
  font-family: ${(props) => props.theme.fonts.families.primary};

  span.access-button-text, span.launch-text {
    font-family: ${(props) => props.theme.fonts.families.secondary};
    font-weight: ${(props) => props.theme.fonts.weights.boldest};
    font-size: ${(props) => props.theme.fonts.sizes.lg};
    line-height: 24px;
  }

  > div {
    display: flex;
    position: relative;

    &:first-child {
      align-self: center;
      justify-content: center;
    }

    .circle-container {
      position: absolute;
      bottom: -37%;
      right: -5%;

      > .circle-with-text {
        border-radius: 50%;
        height: 22px;
        width: 22px;
        padding: 1px;
        line-height: 14px;
        background-color: ${(props) => props.theme.colors.white};
        border-width: 1px;
        border-style: solid;

        span {
          font-family: ${(props) => props.theme.fonts.families.secondary};
          font-size: ${(props) => props.theme.fonts.sizes.xs};
          font-weight: ${(props) => props.theme.fonts.weights.bold};
        }
      }

      > .circle-with-text::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }
`;

interface PillButtonWithSvgProps {
  readonly colors: IconButtonColors;
  readonly autoWidth?: boolean;
}

export const PillButtonWithSvg = styled(Button) <PillButtonWithSvgProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.autoWidth ? 'max-content' : 'clamp(175px, 197px, 203px)')};
  height: clamp(55px, 62px, 64px);
  border-radius: 140px;
  border: none;
  background-color: ${(props) => props.colors.primary};
  box-shadow: 0px 7px 0px ${(props) => props.colors.secondary};
  padding: ${(props) => (props.autoWidth ? '0.7em 1.5em' : '0.7em')};

  img {
    height: auto;
  }
`;

export const LargePillButton = styled(PillButton)`
  width: 302px;
  height: 56px;
`;

export const TextButton = styled(Button)`
  font-family: ${(props) => props.theme.fonts.families.secondary};
  font-size: ${(props) => props.theme.fonts.sizes.sm};
  font-weight: ${(props) => props.theme.fonts.weights.bolder};
  text-transform: uppercase;
  background: transparent;
  text-decoration: underline;
  color: ${colorNames.navyBlue};
`;

interface PenColorButtonProps {
  pen: Pen,
  isSelected?: boolean,
}

export const PenColorButton = styled(Button)`
  width: 40px;
  height: 40px;
  max-height: 40px;
  border-radius: 50%;
  margin-left: 1em;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 46px 46px;

  ${({ pen }: PenColorButtonProps) => (pen.colors.length > 1 ? (
    `background-image: linear-gradient(${pen.colors.join(', ')});`
  ) : (
    `background: ${pen.colors[0]};`
  ))}

  ${({ isSelected, pen }: PenColorButtonProps) => (isSelected ? (
    `box-shadow: 0 0 0 5px ${pen.colors?.[0]};
      border: 3px solid #fff;`
  ) : (
    'border: 3px solid transparent;'
  ))}

  &:disabled {
    opacity: 0.5;
  }
`;
