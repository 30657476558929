import styled from 'styled-components';

interface ZIndexWrapperProps {
  value: number;
}

const ZIndexWrapper = styled.div`
  z-index: ${(props: ZIndexWrapperProps) => props.value};
`;

export default ZIndexWrapper;
