import React from 'react';
import { useTheme } from 'styled-components';
import { InstructionalSequence, InstructionalSequenceType, SequenceCategory } from '../data/types';
import { TextButtonStateColors, TextButtonWithAdditionalColors } from '../styles/styled';
import { instructionalSequenceTypeList, instructionalSequences } from '../data/sequences';
import { ActionType, useGameContext } from '../context/WritingContext/GameContext';
import SequenceButton from './Buttons/SequenceButton';

export default function InstructionalSequenceButtons({ onButtonSelection }: { onButtonSelection: () => void}) {
  const theme = useTheme();
  const ctx = useGameContext();

  const isButtonDisabled = (sequence: InstructionalSequence): boolean => (
    sequence.characters.length === 0 && sequence.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS
  );

  const getCharacterSequenceColors = (sequence: InstructionalSequence): TextButtonWithAdditionalColors => {
    let colorObject: TextButtonStateColors;

    if (sequence.id === InstructionalSequenceType.SEQUENCE_23) {
      // Symbol sequence has different coloring than other Instructional sequences
      colorObject = theme.colors.buttons.practiceSequence;
    } else {
      switch (sequence.category) {
        case SequenceCategory.INSTRUCTIONAL_CHARACTERS:
          colorObject = theme.colors.buttons.instructionalCharacterSequence;
          break;
        case SequenceCategory.PRACTICE:
          colorObject = theme.colors.buttons.practiceSequence;
          break;
        default:
          colorObject = theme.colors.buttons.instructionalDigraphSequence;
          break;
      }
    }

    return (isButtonDisabled(sequence))
      ? colorObject.disabled
      : colorObject.active;
  };

  const handleCharacterSequenceButtonTouch = (sequence: InstructionalSequence): void => {
    /* If user does not tap on screen, VO remains loaded in Audio Player and
        plays briefly right before SelectCharacters VO. This stops that from happening.
    */
    onButtonSelection();
    ctx?.dispatch({
      type: (sequence?.category === SequenceCategory.PRACTICE)
        ? ActionType.SELECT_PRACTICE_SEQUENCE
        : ActionType.SELECT_SEQUENCE,
      payload: sequence,
    });
  };

  return (
    <>
      {instructionalSequenceTypeList
        .map((key) => {
          if (key === InstructionalSequenceType.CUSTOM_1 || !instructionalSequences[key]) return null;
          const display = instructionalSequences[key].display || '';
          return (
            <SequenceButton
              key={key}
              colors={getCharacterSequenceColors(instructionalSequences[key])}
              isDisabled={isButtonDisabled(instructionalSequences[key])}
              ariaLabel={`start practicing ${display}`}
              sequenceOrder={instructionalSequences[key].order}
              buttonTouch={() => handleCharacterSequenceButtonTouch(instructionalSequences[key])}
            >
              {display}
            </SequenceButton>
          );
        })}
    </>
  );
}
