import styled from 'styled-components';
import { FlexColumnCenter } from './FlexSpacers';

const NoInternetContainer = styled(FlexColumnCenter)`
  position: absolute;
  z-index: 5;
  font-family: ${(props) => props.theme.fonts.families.primary};
  font-weight: ${(props) => props.theme.fonts.weights.bold};
  font-size: ${(props) => props.theme.fonts.sizes.mdLg};
  color: ${(props) => props.theme.colors.white};

  & > span {
    margin-top: 12px;
  }
`;

export default NoInternetContainer;
