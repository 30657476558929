import styled from 'styled-components';
import { CircleButton, CircleButtonProps } from './Buttons';
import { FlexColumnCenter, FlexColumnCenter100Percent } from './FlexSpacers';

type ModalProps = {
  columnWidth: string | undefined
}

export const ModalWrapper = styled.div<ModalProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  outline: 0;

  display: grid;
  grid-template-columns: ${(props) => props.columnWidth || '927px'} 32px;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 400;
`;

type ModalBodyProps = {
  width: string | undefined,
  height: string | undefined,
}

export const ModalBody = styled(FlexColumnCenter) <ModalBodyProps>`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 54px;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '524px'};
`;

export const ModalCloseButton = styled(CircleButton) <CircleButtonProps>`
  position: relative;
  top: -5%;
  left: -29%;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const ModalContent = styled(FlexColumnCenter100Percent)`
  width: 100%;
  height: 80%;
  padding: 10px;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;

  text-align: center;

  h1 {
    font-family: ${(props) => props.theme.fonts.families.primary};
    font-size: ${(props) => props.theme.fonts.sizes.display};
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    margin: 0;
  }

  p {
    font-family: ${(props) => props.theme.fonts.families.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.lg};
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    line-height: 1.5;
  }
`;

export const ExternalLinkModalContainer = styled(FlexColumnCenter)`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) => props.theme.fonts.weights.bold};
  margin: 24px;

  & > * {
    margin-top: 0;
    margin-bottom: 24px;
  }

  & :last-child {
    margin-bottom: 0;
  }

  h1 {
    font-family: ${(props) => props.theme.fonts.families.primary};
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }

  p {
    font-family: ${(props) => props.theme.fonts.families.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.sm};
    line-height: 17px;
    text-align: center;
    max-width: 325px;
  }

  button {
    text-transform: uppercase;
    margin-top: 12px;
  }
`;
