import styled from 'styled-components';

const PageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 720px;
  width: 100%;
  max-width: 1024px;
  margin: 15px 20px 20px 20px;
  user-select: none; /* prevents users from accidentally selecting text if their palm rests on screen */
`;

export default PageWrapper;
