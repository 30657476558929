import { CharacterCategory, CorrectiveFeedback } from '../../../data/types';
import finishLetter from './VO34.mp3';
import finishNumber from './VO35.mp3';
import finishSymbol from './VO36-symbol.mp3';
import fasterLetter from './VO37-letter.mp3';
import fasterNumber from './VO38-number.mp3';
import fasterSymbol from './VO39-symbol.mp3';

type CorrectiveFeedbackAudio = {
  [CorrectiveFeedback.AUTOMATICITY_MEMORY]: any,
  [CorrectiveFeedback.AUTOMATICITY_SPEED]: any,
  [CorrectiveFeedback.LEGIBILITY]: null,
}

const correctiveFeedback: CorrectiveFeedbackAudio = {
  [CorrectiveFeedback.AUTOMATICITY_MEMORY]: {
    [CharacterCategory.NUMBER]: finishNumber,
    [CharacterCategory.LETTER_LOWER]: finishLetter,
    [CharacterCategory.LETTER_UPPER]: finishLetter,
    [CharacterCategory.SYMBOL]: finishSymbol,
  },
  [CorrectiveFeedback.AUTOMATICITY_SPEED]: {
    [CharacterCategory.NUMBER]: fasterNumber,
    [CharacterCategory.LETTER_LOWER]: fasterLetter,
    [CharacterCategory.LETTER_UPPER]: fasterLetter,
    [CharacterCategory.SYMBOL]: fasterSymbol,
  },
  [CorrectiveFeedback.LEGIBILITY]: null,
};

export default correctiveFeedback;
