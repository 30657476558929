import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GrantAccess from '../components/GrantAccess';
import Modal from '../components/Modal';
import { ResourcesAreaWrapper } from '../styles/components/ContentWrappers';
import books from '../assets/images/icons/books-icon.svg';
import customSet from '../assets/images/icons/dashboard-customize-icon.svg';
import info from '../assets/images/icons/info-icon.svg';
import play from '../assets/images/icons/play-2-icon.svg';
import ResourceLink from '../components/Links/ResourceLink';
import SetsLink from '../components/Links/SetsLink';
import Links from '../data/links';
import { GameFlowStep, useGameContext } from '../context/WritingContext/GameContext';
import { Paths } from '../data/types';
import PolicyLinkContainer from '../components/PolicyLinkContainer';
import VersionDisplay from '../components/Version Display/VersionDisplay';

enum ViewState {
  ACCESS = 'ACCESS',
  RESOURCES = 'RESOURCES',
  NONE = 'NONE',
}

enum LinkType {
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  INTERAL_LINK = 'INTERNAL_LINK',
}

type Resource = {
  title: string,
  icon: string,
  link: Links|string,
  action?: () => void,
  type: LinkType,
}

export default function EducatorResources() {
  const [view, setView] = useState(ViewState.NONE);
  const ctx = useGameContext();
  const navigate = useNavigate();
  const location = useLocation();

  const resources: Resource[] = [
    {
      title: 'About Starwriter', icon: info, link: `/${Paths.ABOUT}`, type: LinkType.INTERAL_LINK,
    },
    {
      title: 'Guides and How-To\'s', icon: books, link: Links.RESOURCES, type: LinkType.EXTERNAL_LINK,
    },
    {
      title: 'Demo Video Repository',
      icon: play,
      link: Links.DEMO_VIDEO_RESOURCES,
      type: LinkType.EXTERNAL_LINK,
    },
    {
      title: 'Create a Custom Set',
      icon: customSet,
      action: () => ctx?.dispatch({ type: GameFlowStep.CREATE_CUSTOM_SET }),
      link: `/${Paths.WRITING}`,
      type: LinkType.INTERAL_LINK,
    },
  ];

  useEffect(() => {
    const state = location.state as { shouldCheckAccess: boolean };
    if (!state || state.shouldCheckAccess) return setView(ViewState.ACCESS);
    return setView(ViewState.RESOURCES);
  }, [location]);

  const goToSplashScreen = () => {
    navigate('/');
  };

  const renderViewState = () => {
    switch (view) {
      case ViewState.ACCESS:
        return (
          <Modal
            title="Answer the math problem to continue to the educator experience"
            isShown
            onClose={goToSplashScreen}
          >
            <GrantAccess
              buttonTouch={() => setView(ViewState.RESOURCES)}
              buttonText="GO"
            />
          </Modal>
        );
      case ViewState.RESOURCES:
        return (
          <>
            <div className="text-container">
              <h1 className="visually-hidden">Educator Resources</h1>
              <span className="title">Welcome!</span>
              <h2 className="sub-title">
                Hello, Educator! If it&apos;s your first time here, we recommend
                {' '}
                starting with &lsquo;Guides and How-To&apos;s&rsquo;
              </h2>
            </div>
            <div className="resource-container">
              {resources.map((resource) => (
                <ResourceLink
                  key={resource.title}
                  imgSrc={resource.icon}
                  text={resource.title}
                  link={resource.link}
                  action={resource.action}
                  isExternal={resource.type === LinkType.EXTERNAL_LINK}
                />
              ))}
            </div>
            <SetsLink />
            <VersionDisplay isLanding={false} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ResourcesAreaWrapper>
      <>
        {renderViewState()}
        <PolicyLinkContainer returnPage={Paths.EDUCATOR_RESOURCES} />
      </>
    </ResourcesAreaWrapper>
  );
}
