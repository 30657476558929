import React from 'react';
import { useTheme } from 'styled-components';
import { LargePillButton, TextButton } from '../styles/components/Buttons';
import Modal from './Modal';
import { ExternalLinkModalContainer } from '../styles/components/Modals';

type ExternalLinkModalProps = {
  linkTitle: string,
  linkPath: string,
  handleModalClose: () => void,
}

export default function ExternalLinkModal({ linkTitle, linkPath, handleModalClose }: ExternalLinkModalProps) {
  const theme = useTheme();
  const modalTitle = 'You\'re now leaving Starwriter.org';

  return (
    <Modal
      title={modalTitle}
      width="495px"
      height="402px"
      columnWidth="500px"
      isShown
      onClose={handleModalClose}
    >
      <ExternalLinkModalContainer>
        <div><h1>{modalTitle}</h1></div>
        <p>
          To access
          {' '}
          { linkTitle }
          {' '}
          you’ll be directed to our Starwriter Google site, which will open a new page on your browser.
        </p>
        <a
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LargePillButton
            disabled={false}
            aria-disabled={false}
            colors={theme.colors.buttons.ok}
            aria-label={`Navigate to ${linkTitle}`}
            onTouchStart={() => setTimeout(() => handleModalClose(), 300)}
          >
            <span className="access-button-text">Continue to Site</span>
          </LargePillButton>
        </a>
        <TextButton className="cancel-button" onClick={() => handleModalClose()}>Cancel</TextButton>
      </ExternalLinkModalContainer>
    </Modal>
  );
}
