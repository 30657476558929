import styled, { css } from 'styled-components';
import colors from '../colors';

export const AnimatedOuterSpaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: white;

  > .top {
    position: relative;

    .divisor {
      position: absolute;
      width: 100%;
      height: 10px;
      background-color: ${colors.white};
      margin-top: -10px;
      z-index: 0;
    }

    .text-and-stars {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 75px;

      .left, .right {
        .star {
          position: absolute;
        }
      }

      .left {
        .star.top {
          top: 27%;
          left: 22%;
        }

        .star.bottom {
          bottom: 1%;
          left: 30%;
          width: 18px;
          transform: rotate(45deg);
        }
      }

      .right {
        .star.top {
          top: 53%;
          right: 30%;
          width: 18px;
          transform: rotate(30deg);
        }

        .star.bottom {
          bottom: 14%;
          right: 35%;
        }
      }
    }
  }

  > .bottom {
    position: absolute;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    > div {
      margin: 0 1.5em;
    }

    > .next-activity-navigation {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 2em 1.5em;
    }
  }
`;

type EndOfSetDisplayContainerProps = {
  numberOfCharacters: number,
}

export const EndOfSetDisplayContainer = styled(AnimatedOuterSpaceWrapper)`
  .cards-container {
      margin-top: 3.5rem;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr;
      column-gap: 1.5em;
      row-gap: 1.5em;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;
      grid-template-areas:
        ". . spacer"
        ". . .";
      ${(props: EndOfSetDisplayContainerProps) => (props.numberOfCharacters === 3
    || props.numberOfCharacters === 4) && css`
        grid-template-areas:
          ". . ."
          ". . .";
      `}
      z-index: 200;

      .practice-card {
        grid-column: span 2;
        ${(props) => props.numberOfCharacters === 4 && css`
          grid-column: span 3;
        `}
      }

      .spacer {
        grid-area: spacer;
        grid-column: span 1;
      }
    }
`;

export const LandingScreenContainer = styled(AnimatedOuterSpaceWrapper)<{ $breakPoint?: boolean; }>`
  width: ${(props) => (props.$breakPoint ? '90%' : '100%')};
  height: ${(props) => (props.$breakPoint ? '90%' : '100%')};
  > .top {
    top: 0;
    scale: 1.25;

    .text-and-stars {
      position: absolute;
      top: 17%;
      flex-direction: column;

      .title {
        font-family: ${(props) => props.theme.fonts.families.primary};
        color: ${(props) => props.theme.colors.white};
        font-size: 84px;
        font-weight: ${(props) => props.theme.fonts.weights.bold};
      }

      button {
        margin-top: 36px;
      }

      .star {
        width: 24px;
        height: auto;
        position: absolute;

        &.left {
          top: -7%;
          left: 28%;
          transform: rotate(45deg);
        }

        &.right {
          top: 33%;
          right: 30%;
        }
      }
    }
  }

  > .bottom {
    margin-bottom: 2%;
    bottom: 0%;
    justify-content: center;

    .star {
      position: absolute;

      &.left {
        bottom: 13%;
        left: 4%;
      }
    }
  }

`;
