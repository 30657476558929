import { useMemo } from 'react';
import { useGameContext } from '../context/WritingContext/GameContext';
import { StrokeOutcome } from '../data/types';
import { FIRST_ERROR_THRESHOLD, SECOND_ERROR_THRESHOLD } from '../shared/constants';
import useActivityTypes from './useActivityTypes';

export default function useCanvasVisibleCorrectiveFeedback() {
  const ctx = useGameContext();
  const { isChaseActivity, isIndependentActivity } = useActivityTypes();

  const removeStartDotAfterAnimation = useMemo(() => {
    // after animation, make the start dot disappear only on
    // independent activity and after a WARNING_REMINDER or an ERROR_START
    // action.
    if (!isIndependentActivity) return true;
    return ctx?.lastStrokeOutcome === StrokeOutcome.ERROR_START
      || ctx?.lastStrokeOutcome === StrokeOutcome.WARNING_REMINDER;
  }, [ctx?.currentActivity?.currentIteration, ctx?.lastStrokeOutcome]);

  const beginStartDotAnimation = () => {
    // do not animate start dot for Chase the Shooting Star activity.
    if (!ctx || isChaseActivity) return false;
    /* do not animate start dot if
    **  1) first error threshold - user will be taken to demo video or dotted line
    **  2) second error threshold - user will skip letter */
    if (ctx.errorCount === FIRST_ERROR_THRESHOLD || ctx.errorCount === SECOND_ERROR_THRESHOLD) return false;
    /* animate start dot for
    **  1) start dot errors,
    **  2) when user begins stroke outside of start dot radius,
    **  3) If user receives a reminder to finish the character */
    return ctx.lastStrokeOutcome === StrokeOutcome.ERROR_START
      || ctx.lastStrokeOutcome === StrokeOutcome.WARNING_INCORRECT_START_POINT
      || ctx.lastStrokeOutcome === StrokeOutcome.WARNING_REMINDER;
  };

  const beginStopDotAnimation = useMemo(() => {
    // do not animate stop dot for Chase the Shooting Star or Independent activities.
    if (!ctx || isChaseActivity || isIndependentActivity) return false;
    /* do not animate stop dot if
    **  1) first error threshold - user will be taken to demo video or dotted line
    **  2) second error threshold - user will skip letter */
    if (ctx.errorCount === FIRST_ERROR_THRESHOLD || ctx.errorCount === SECOND_ERROR_THRESHOLD) return false;
    /* animate stop dot for
    **  1) stop dot errors */
    return ctx.lastStrokeOutcome === StrokeOutcome.ERROR_END;
  }, [ctx?.lastStrokeOutcome]);

  const shouldShowOnlyStartDotOnLoad = () => (isIndependentActivity && ctx?.currentActivity?.currentIteration === 1);

  return {
    beginStartDotAnimation,
    removeStartDotAfterAnimation,
    beginStopDotAnimation,
    shouldShowOnlyStartDotOnLoad,
  };
}
