import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FlexColumn = styled(FlexWrapper)`
  flex-direction: column;
`;

export const FlexRowSpaceBetween = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FlexRowCenter = styled(FlexWrapper)`
  justify-content: center;
  align-items: center;
`;

export const FlexColumnCenter = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
`;

export const FlexColumnCenter100Percent = styled(FlexColumnCenter)`
  width: 100%;
  height: 100%;
`;

export const FlexColumnCenterTop = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowCenterTop = styled(FlexWrapper)`
  justify-content: center;
  align-items: flex-start;
`;

export const FlexRowLeftCenter = styled(FlexWrapper)`
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowBottomSpaced = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: flex-end;
`;
