import React from 'react';
import { PreWritingActivityButtonWrapper } from '../../styles/components/Buttons';
import { PreWritingActivityType } from '../../data/types';
import pw1 from '../../assets/images/pre-writing/PWIcon-1.svg';
import pw2 from '../../assets/images/pre-writing/PWIcon-2.svg';
import pw3 from '../../assets/images/pre-writing/PWIcon-3.svg';
import greenStar from '../../assets/images/star-green.svg';

type PreWritingActivityButtonProps = {
  isDisabled: boolean,
  buttonTouch: () => void,
  preWritingActivityType: string
}

export default function PreWritingActivityButton({
  isDisabled,
  buttonTouch,
  preWritingActivityType,
}: PreWritingActivityButtonProps) {
  const handleImageSrc = () => {
    if (preWritingActivityType === PreWritingActivityType.PW1) {
      return pw1;
    }
    if (preWritingActivityType === PreWritingActivityType.PW2) {
      return pw2;
    }
    if (preWritingActivityType === PreWritingActivityType.PW3) {
      return pw3;
    }
    if (isDisabled) return pw1;
    return pw1;
  };

  const handleShape = () => {
    if (preWritingActivityType === PreWritingActivityType.PW1) {
      return 'circle';
    }
    if (preWritingActivityType === PreWritingActivityType.PW2) {
      return 'square';
    }
    if (preWritingActivityType === PreWritingActivityType.PW3) {
      return 'triangle';
    }
    if (isDisabled) return 'square';
    return 'square';
  };

  return (
    <PreWritingActivityButtonWrapper
      disabled={isDisabled}
      width="260px"
      height="260px"
      aria-disabled={isDisabled}
      onTouchStart={buttonTouch}
      shape={handleShape()}
      role="link"
    >
      <img alt="" src={handleImageSrc()} />
      { preWritingActivityType === PreWritingActivityType.PW3
      && (
      <>
        <img src={greenStar} alt="" className="green-star left" />
        <img src={greenStar} alt="" className="green-star right" />
      </>
      )}
    </PreWritingActivityButtonWrapper>
  );
}
