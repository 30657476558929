import colors from '../styles/colors';
import { Pen, Pens } from './types';

export const initialPens: Pen[] = [
  { id: Pens.BLUE, displayName: 'blue', colors: [colors.vividDarkBlue] },
];

export const solidPens: Pen[] = [
  { id: Pens.PURPLE, displayName: 'purple', colors: [colors.purple] },
  { id: Pens.MAGENTA, displayName: 'magenta', colors: [colors.magenta] },
  { id: Pens.RED, displayName: 'red', colors: [colors.red] },
  { id: Pens.ORANGE, displayName: 'orange', colors: [colors.penOrange] },
  { id: Pens.TEAL, displayName: 'green', colors: [colors.teal] },
];

export const gradientPens: Pen[] = [
  { id: Pens.GRADIENT_BLUE, displayName: 'waterfall', colors: [colors.vividLightBlue, colors.vividMediumBlue] },
  { id: Pens.GRADIENT_PURPLE, displayName: 'berry', colors: [colors.darkMagenta, colors.darkerBlue] },
  { id: Pens.GRADIENT_GREEN, displayName: 'lime', colors: [colors.green, colors.limeGreen] },
  { id: Pens.GRADIENT_RED, displayName: 'lava', colors: [colors.mediumRed, colors.mediumYellow] },
  { id: Pens.GRADIENT_ORANGE, displayName: 'sunset', colors: [colors.mediumOrange, colors.brightMagenta] },
];

export const getPreferredOpacityByPenId = (penId: Pens | null): number => {
  switch (penId) {
    case Pens.ORANGE:
    case Pens.TEAL:
    case Pens.GRADIENT_GREEN:
    case Pens.GRADIENT_ORANGE:
      return 0.5;
    default:
      return 0.4;
  }
};
