import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './global';
import defaultTheme from './themes';

type ThemeProps = {
  children?: React.ReactNode,
}

export default function Theme({ children }: ThemeProps) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}

Theme.defaultProps = {
  children: null,
};
