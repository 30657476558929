/* eslint-disable max-len */
import React from 'react';

// Used as a component instead of an svg file so it can be used offline
export default function NoInternetBWSvg() {
  return (
    <svg width="60" height="49" viewBox="0 0 60 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2394_25545)">
        <path d="M55.1181 16.0789C52.1466 13.3415 48.7571 11.1537 45.0065 9.55734C40.5164 7.64659 35.7024 6.65933 30.7002 6.62635C25.7155 6.59337 20.7899 7.51026 16.0634 9.34845C12.0787 10.8986 8.37632 13.0534 5.02402 15.7667C3.78989 16.7672 3.65203 18.6076 4.72862 19.7773L4.76582 19.8191C5.74394 20.8833 7.37413 20.9976 8.49667 20.0895C14.884 14.9202 22.663 12.1299 30.663 12.1805C38.5536 12.2311 45.8183 15.0521 51.3807 20.1709C52.4267 21.134 54.0372 21.123 55.0787 20.1555L55.1203 20.1181C56.2932 19.0297 56.2954 17.163 55.1203 16.0789H55.1181Z" fill="#5A5A5A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M55.1202 20.1181L55.0786 20.1555C54.0371 21.123 52.4265 21.134 51.3806 20.1709C45.8182 15.0521 38.5535 12.2311 30.6629 12.1805C22.6629 12.1299 14.8839 14.9202 8.49657 20.0895C7.37403 20.9976 5.74383 20.8833 4.76571 19.8191L4.72852 19.7773C3.65193 18.6076 3.78978 16.7672 5.02392 15.7667C8.37622 13.0534 12.0786 10.8986 16.0633 9.34845C20.7898 7.51026 25.7154 6.59337 30.7001 6.62635C35.7023 6.65933 40.5163 7.64659 45.0064 9.55734C48.757 11.1537 52.1465 13.3415 55.118 16.0789H55.1202C56.2953 17.163 56.2931 19.0297 55.1202 20.1181ZM56.2621 13.0789C53.2549 10.476 49.8769 8.3699 46.1813 6.79696C41.3171 4.72704 36.1095 3.66195 30.7198 3.62641C25.3493 3.59088 20.0485 4.57965 14.9759 6.55246C10.6985 8.21651 6.7266 10.5291 3.13652 13.4348L3.13474 13.4363C0.568489 15.5166 0.269813 19.3386 2.50149 21.7875L2.54092 21.8318L2.55693 21.8492C4.61404 24.0874 8.03843 24.3189 10.3834 22.4219C16.2519 17.6724 23.363 15.1345 30.6436 15.1804C37.8159 15.2264 44.3553 17.7831 49.3485 22.3779C51.5506 24.4055 54.9207 24.3767 57.0993 22.373L57.1435 22.3332L57.1609 22.3172C59.6075 20.0468 59.6226 16.1509 57.1544 13.8739L56.2926 13.0789H56.2621Z" fill="white" />
        <path d="M48.2933 23.0138C46.2079 21.2086 43.86 19.7508 41.2867 18.6646C37.9082 17.2376 34.2867 16.5076 30.523 16.4966C30.4946 16.4966 30.4662 16.4966 30.4377 16.4966C26.7222 16.4966 23.0504 17.198 19.5209 18.5788C16.7594 19.6606 14.1795 21.1272 11.8163 22.95C10.5515 23.9263 10.4049 25.7887 11.488 26.965L11.5252 27.0046C12.4771 28.038 14.057 28.181 15.1686 27.3234C19.6259 23.8823 24.9607 22.0155 30.4377 22.0155C30.4618 22.0155 30.4837 22.0155 30.5077 22.0155C35.8797 22.0309 40.8338 23.8515 44.6981 27.1871C45.7484 28.093 47.3064 28.071 48.3239 27.1255L48.3655 27.0882C49.5559 25.9822 49.5274 24.0758 48.2977 23.0138H48.2933Z" fill="#5A5A5A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M44.6981 27.1871C40.8338 23.8515 35.8797 22.0309 30.5077 22.0155H30.4377C24.9607 22.0155 19.6259 23.8823 15.1686 27.3234C14.057 28.181 12.4771 28.038 11.5252 27.0046L11.488 26.965C10.4049 25.7887 10.5515 23.9263 11.8163 22.95C14.1795 21.1272 16.7594 19.6606 19.5208 18.5788C23.0504 17.198 26.7222 16.4966 30.4377 16.4966H30.523C34.2867 16.5076 37.9081 17.2376 41.2867 18.6646C43.86 19.7508 46.2079 21.2086 48.2933 23.0138H48.2976C49.5274 24.0758 49.5559 25.9822 48.3655 27.0882L48.3239 27.1255C47.3064 28.071 45.7484 28.093 44.6981 27.1871ZM50.3447 29.3429C48.1974 31.3184 44.9299 31.3487 42.7387 29.4589L42.7378 29.4581C39.4404 26.6119 35.1883 25.0299 30.5033 25.0155H30.4378C25.6509 25.0155 20.9555 26.6458 17.0018 29.6981L17.001 29.6988C14.6476 31.5142 11.325 31.202 9.33077 29.0502L9.29148 29.0084L9.28108 28.9971C7.02224 26.5439 7.30678 22.6411 9.98316 20.5752L9.98401 20.5746C12.5687 18.5809 15.3965 16.9726 18.4266 15.7855L18.4278 15.785C22.2977 14.271 26.3397 13.4966 30.4377 13.4966H30.5318C34.6831 13.5087 38.699 14.3151 42.4533 15.9007M50.3447 29.3429L50.3895 29.3027L50.4075 29.2859C52.8979 26.9721 52.8501 22.9814 50.2585 20.7433L49.4137 20.0138H49.3772C47.2773 18.3353 44.958 16.958 42.4533 15.9007" fill="white" />
        <path d="M41.162 29.9535C40.0395 29.1246 38.8141 28.4364 37.5012 27.8933C35.2561 26.9654 32.8469 26.5058 30.3437 26.5234C26.2846 26.5542 22.3174 27.8405 18.9082 30.1954C17.5275 31.1497 17.3021 33.1088 18.4421 34.3467L18.4793 34.3885C19.4049 35.3956 20.9235 35.5627 22.0504 34.7887C27.0811 31.3278 33.4771 31.2003 37.8951 34.4589C38.9498 35.2372 40.4071 35.1427 41.3677 34.25L41.4093 34.2126C42.6785 33.0341 42.5559 30.987 41.1642 29.9601L41.162 29.9535Z" fill="#5A5A5A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M43.769 28.2838L43.71 28.1059L42.9441 27.5402C41.6194 26.5621 40.1798 25.7548 38.6478 25.1211C36.0198 24.0349 33.2116 23.5032 30.3225 23.5235L30.3208 23.5235C25.638 23.559 21.0883 25.0433 17.2031 27.727C14.3399 29.7059 13.8557 33.7694 16.2153 36.3572L16.2544 36.4012L16.2705 36.4186C18.2133 38.5324 21.3989 38.8755 23.7488 37.2616L23.7507 37.2603C27.8808 34.4189 32.8676 34.479 36.1136 36.8727C38.3418 38.5171 41.3896 38.3062 43.3884 36.4674L43.4329 36.4274L43.4506 36.4109C45.8278 34.2035 45.8638 30.5564 43.769 28.2838ZM41.3676 34.25L41.4092 34.2126C42.6783 33.0341 42.5558 30.987 41.1641 29.9601L41.1619 29.9535C40.0394 29.1246 38.814 28.4364 37.5011 27.8933C35.256 26.9654 32.8468 26.5058 30.3435 26.5234C26.2845 26.5542 22.3173 27.8405 18.9081 30.1954C17.5274 31.1497 17.302 33.1088 18.442 34.3467L18.4792 34.3885C19.4048 35.3956 20.9234 35.5627 22.0503 34.7887C27.081 31.3278 33.477 31.2003 37.895 34.4589C38.9497 35.2372 40.407 35.1427 41.3676 34.25Z" fill="white" />
        <path d="M34.2952 42.9963C35.3818 40.6126 34.3396 37.7952 31.9675 36.7034C29.5953 35.6116 26.7915 36.6588 25.7049 39.0424C24.6184 41.4261 25.6606 44.2435 28.0327 45.3353C30.4048 46.4271 33.2087 45.3799 34.2952 42.9963Z" fill="#5A5A5A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M33.2118 33.9737C37.0972 35.762 38.7905 40.3676 37.0205 44.2507C35.2504 48.1338 30.6737 49.8535 26.7884 48.0652C22.9031 46.2769 21.2097 41.6714 22.9798 37.7882C24.7498 33.9051 29.3265 32.1854 33.2118 33.9737ZM31.9675 36.7035C34.3396 37.7953 35.3818 40.6128 34.2953 42.9964C33.2087 45.38 30.4049 46.4273 28.0327 45.3354C25.6606 44.2436 24.6184 41.4262 25.705 39.0426C26.7915 36.6589 29.5954 35.6117 31.9675 36.7035Z" fill="white" />
        <path d="M46.1971 40.7255L10.1981 4.55204C9.45077 3.80112 8.23917 3.80112 7.49187 4.55204C6.74458 5.30295 6.74458 6.52043 7.49187 7.27135L43.4909 43.4449C44.2382 44.1958 45.4498 44.1958 46.1971 43.4449C46.9444 42.6939 46.9444 41.4765 46.1971 40.7255Z" fill="#5A5A5A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3143 2.42555L48.3133 38.5991C50.2293 40.5244 50.2293 43.6459 48.3133 45.5713C46.3972 47.4966 43.2907 47.4966 41.3747 45.5713L5.37569 9.39775C3.45966 7.47243 3.45966 4.35087 5.37569 2.42555C7.29172 0.500233 10.3982 0.500232 12.3143 2.42555ZM10.1981 4.55199L46.1971 40.7255C46.9444 41.4764 46.9444 42.6939 46.1971 43.4448C45.4498 44.1957 44.2382 44.1957 43.4909 43.4448L7.49187 7.2713C6.74458 6.52039 6.74458 5.30291 7.49187 4.55199C8.23917 3.80108 9.45077 3.80108 10.1981 4.55199Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2394_25545">
          <rect width="60" height="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
