import React from 'react';
import CardWrapper, { CardWrapperSize, CardWrapperType } from '../../styles/components/CardWrapper';
import starIcon from '../../assets/images/icons/status-marker-icon.svg';
import { DifficultyLevel, DrawnCharacter as DrawnCharacterType } from '../../data/types';
import { DrawnSpacingCharacter } from '../../stateMachines/spacingMachine/spacingMachine';
import DrawnCharacter from '../DrawnCharacter';

type DrawnCharCardButtonProps = {
  onTouch: () => void,
  isDisabled: boolean,
  drawnCharacter: DrawnCharacterType|DrawnSpacingCharacter,
  partialWord?: React.ReactNode|null,
  hasAdditionalCharPositioning?: boolean,
  difficultyLevel: DifficultyLevel,
}

export default function DrawnCharCardButton({
  onTouch, isDisabled, drawnCharacter, partialWord, hasAdditionalCharPositioning, difficultyLevel,
}: DrawnCharCardButtonProps) {
  const isEasyLevel = difficultyLevel === DifficultyLevel.EASY;

  const handleCardSelection = () => {
    if (isDisabled) return;
    onTouch();
  };

  const getTransformValue = () => {
    if (partialWord) return 'translate(26, -7)';
    if (isEasyLevel) return 'translate(0, -22)';
    return 'translate(60, -6)';
  };

  return (
    <CardWrapper
      size={isEasyLevel ? CardWrapperSize.LG : CardWrapperSize.MD}
      type={CardWrapperType.SELECTED}
      className="practice-card"
      isSelected={drawnCharacter.isSelected}
      hasAdditionalCharPositioning={hasAdditionalCharPositioning}
    >
      <button
        type="button"
        disabled={isDisabled}
        aria-disabled={isDisabled}
        onTouchStart={() => handleCardSelection()}
      >
        <div className="lines">
          { partialWord }
          <DrawnCharacter
            drawnCharacter={drawnCharacter}
            isEasyLevel={isEasyLevel}
            positioning={{
              pathTransform: getTransformValue(),
            }}
          />
          {drawnCharacter.isSelected && (
            <img className="selected-marker" alt="selected practice character" src={starIcon} />
          )}
        </div>
      </button>
    </CardWrapper>
  );
}

DrawnCharCardButton.defaultProps = {
  partialWord: null,
  hasAdditionalCharPositioning: false,
};
