import React from 'react';
import { Rect } from 'react-konva';
import { DRAWABLE_MARGIN } from '../../shared/constants';

type VisibleTargetZoneProps = {
  show: boolean,
  positioning: {
    width: number,
    height: number,
    xOffset: number,
  }
}

export default function VisibleTargetZone({ show, positioning }: VisibleTargetZoneProps) {
  const { width, height, xOffset } = positioning;
  return (
    <Rect
      id="visible-target-zone"
      fill={show ? '#FBC400' : 'transparent'}
      opacity={0.2}
      width={width}
      height={height}
      y={DRAWABLE_MARGIN}
      x={xOffset}
      listening={false}
    />
  );
}
