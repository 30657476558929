import React from 'react';
import { AnimatedOuterSpaceWrapper } from '../styles/components/AnimatedOuterSpaceWrapper';
import LottiePlayer from './LottiePlayer';
import outerSpace from '../assets/lotties/outer-space.json';
import starYellow from '../assets/images/star-yellow/star-yellow.svg';
import starGameSuccessImages from '../assets/images/star-game-success';
import { Character } from '../data/types';

interface StarGameSuccessProps {
  character: Character,
  showNavigation: boolean,
  children: any,
}

export default function StarGameSuccess({ character, showNavigation, children }: StarGameSuccessProps) {
  return (
    <AnimatedOuterSpaceWrapper>
      <div className="top">
        <LottiePlayer
          data={outerSpace}
          width="100%"
          handleComplete={() => {}}
          shouldAutoplay
          shouldLoop
        />
        <div className="divisor" />
        <div className="text-and-stars">
          <div className="left">
            <img
              alt=""
              className="star top"
              src={starYellow}
            />
            <img
              alt=""
              className="star bottom"
              src={starYellow}
            />
          </div>
          {character ? (
            <img
              alt={`character ${character.display}`}
              src={starGameSuccessImages[character.type]}
            />
          ) : null}
          <div className="right">
            <img
              alt=""
              className="star top"
              src={starYellow}
            />
            <img
              alt=""
              className="star bottom"
              src={starYellow}
            />
          </div>
        </div>
      </div>
      {showNavigation && (
        <div className="bottom">
          { children }
        </div>
      )}
    </AnimatedOuterSpaceWrapper>
  );
}
