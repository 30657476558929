import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import shootingStar from '../../assets/images/shooting-star-spacing.png';
import SlideInUpAnimation from '../../styles/components/SlideInUpAnimation';

type ShootingStarProps = {
  start: boolean,
  animationData: { left: number },
}
export default function ShootingStarAnimation({
  start, animationData,
}: ShootingStarProps) {
  const [showStar, setShowStar] = useState(false);
  const { left } = animationData;
  const ref = useRef(null);

  useEffect(() => {
    setShowStar(start);
  }, [start]);

  return (
    <CSSTransition
      nodeRef={ref}
      in={showStar}
      appear={showStar}
      timeout={4000}
      classNames="slide"
      mountOnEnter={start}
      onExit={() => setShowStar(false)}
    >
      <SlideInUpAnimation leftPx={`${left || 0}px`} ref={ref}>
        <img alt="" src={shootingStar} />
      </SlideInUpAnimation>
    </CSSTransition>
  );
}
