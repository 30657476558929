import styled from 'styled-components';
import { FlexRowSpaceBetween } from './FlexSpacers';

const Toast = styled(FlexRowSpaceBetween)`
  width: 225px;
  position: absolute;
  top: 3%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  z-index: 10;
  padding: 17px 16px;
  background: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.families.primary};
  font-weight: ${(props) => props.theme.fonts.weights.bold};
  font-size: ${(props) => props.theme.fonts.sizes.mdLg};
  color: ${(props) => props.theme.colors.black};

  & > img {
    margin-right: 8px;
    width: auto;
    height: 44px;
  }

  & > div {
    margin-right: 20px;
    line-height: 25px;
  }

  & > button {
    width: 44px;
    height: 44px;
    background: transparent;

    img {
      width: 24px;
      height: auto;
    }
  }
`;

export default Toast;
