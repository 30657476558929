import React, { useEffect, useState } from 'react';
import Toast from '../../styles/components/Toast';
import NoInternetColorSvg from './NoInternetColorSvg';
import CloseXSvg from '../SVG/CloseXSvg';

export default function NoInternetToast() {
  const [show, setShow] = useState(true);

  const closeToast = () => {
    setShow(false);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => closeToast(), 5000);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <Toast role="alert" aria-live="assertive" className={show ? 'slideInDown' : 'slideOutUp'}>
      <NoInternetColorSvg />
      <div>No Internet</div>
      <button aria-label="close" type="button" onClick={() => closeToast()}>
        <CloseXSvg />
      </button>
    </Toast>
  );
}
