/* eslint-disable max-len */
import React from 'react';

// Used as a component instead of an svg file so it can be used offline
export default function NoInternetCircleSvg() {
  return (
    <svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="53" cy="53" r="53" fill="white" />
      <g clipPath="url(#clip0_2300_2950)">
        <path d="M85.8638 43.2385C82.0351 39.6922 77.6679 36.858 72.8354 34.79C67.05 32.3147 60.8474 31.0358 54.4022 30.9931C47.9797 30.9503 41.6332 32.1381 35.5433 34.5194C30.4092 36.5276 25.6388 39.319 21.3195 42.834C19.7294 44.13 19.5518 46.5142 20.9389 48.0295L20.9868 48.0837C22.2471 49.4623 24.3475 49.6104 25.7939 48.434C34.0237 41.7373 44.0466 38.1227 54.3543 38.1882C64.521 38.2537 73.8814 41.9083 81.0483 48.5394C82.396 49.787 84.471 49.7728 85.8131 48.5195L85.8666 48.471C87.3778 47.0611 87.3807 44.6427 85.8666 43.2385H85.8638Z" fill="#E80041" />
        <path d="M77.0701 52.2224C74.3832 49.8839 71.358 47.9953 68.0424 46.5882C63.6893 44.7396 59.0232 43.7939 54.1738 43.7797C54.1372 43.7797 54.1005 43.7797 54.0639 43.7797C49.2766 43.7797 44.5456 44.6883 39.9979 46.4771C36.4399 47.8786 33.1158 49.7785 30.0709 52.1398C28.4413 53.4045 28.2524 55.8171 29.648 57.3411L29.6959 57.3923C30.9223 58.7311 32.9579 58.9162 34.3902 57.8053C40.1333 53.3475 47.0069 50.9292 54.0639 50.9292C54.0949 50.9292 54.1231 50.9292 54.1541 50.9292C61.0757 50.9492 67.4588 53.3077 72.4378 57.6287C73.7912 58.8023 75.7986 58.7738 77.1096 57.549L77.1631 57.5006C78.6969 56.0678 78.6602 53.5982 77.0757 52.2224H77.0701Z" fill="#E80041" />
        <path d="M67.8818 61.2121C66.4354 60.1383 64.8565 59.2467 63.1649 58.5431C60.2722 57.3411 57.1681 56.7458 53.9427 56.7686C48.7127 56.8085 43.6012 58.4748 39.2085 61.5255C37.4295 62.7617 37.1391 65.2996 38.608 66.9033L38.6559 66.9574C39.8485 68.262 41.8052 68.4785 43.2572 67.4758C49.739 62.9924 57.98 62.8272 63.6724 67.0486C65.0313 68.0569 66.9091 67.9344 68.1468 66.778L68.2003 66.7295C69.8356 65.2028 69.6777 62.5509 67.8846 61.2207L67.8818 61.2121Z" fill="#E80041" />
        <path d="M59.0343 78.1089C60.4343 75.021 59.0915 71.3711 56.0351 69.9567C52.9787 68.5423 49.366 69.899 47.966 72.9869C46.5661 76.0748 47.9089 79.7246 50.9653 81.139C54.0217 82.5534 57.6343 81.1968 59.0343 78.1089Z" fill="#E80041" />
        <path d="M77.6437 75.3817L27.7736 24.9978L21.2265 31.6123L71.0967 81.9961L77.6437 75.3817Z" fill="white" />
        <path d="M76.1126 76.9285L26.2424 26.5447L22.7556 30.0674L72.6258 80.4513L76.1126 76.9285Z" fill="#E80041" />
      </g>
      <defs>
        <clipPath id="clip0_2300_2950">
          <rect width="67" height="57" fill="white" transform="translate(20 25)" />
        </clipPath>
      </defs>
    </svg>
  );
}
