import styled from 'styled-components';
import colors from '../colors';
import crossShape from '../../assets/images/cross-shape.svg';

export const PreWritingSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: radial-gradient(${colors.darkPurple}, ${colors.veryDarkPurple});
  width: 98%;
  height: 92%;
  border-radius: 54px;
  .star-group-1 {
    position: absolute;
    top: 1%;
    left: 0%;
  }
  .star-group-2 {
    position: absolute;
    top: -2%;
    right: 13%;
  }
  .pencil {
    position: absolute;
    bottom: -11%;
    right: 10%;
    transform: rotate(100deg);
  }
`;

export const PreWritingHandwritingActivityWrapper = styled.div`
  display: flex;
  background: radial-gradient(${colors.darkPurple}, ${colors.veryDarkPurple});
  width: 98%;
  height: 92%;
  border-radius: 54px;
  .squiggles {
    position: absolute;
    top: 1%;
    left: 0%;
  }
  .next-button-wrapper{
    position: absolute;
    right: 7%;
    bottom: 14%;
  }
  .shapes-wrapper{
    margin: 10%;
    width: 100%;
  }
  .white-star {
    position: absolute;
    &.one{
      top:13%;
      left:10%;
    }
    &.two{
      top:18%;
      left:6%;
    }
  }
`;

type PreWritingHandwritingActivityLineShapeWrapperProps = {
  iterationCount: number,
}

export const
  PreWritingHandwritingActivityLineShapeWrapper = styled.div <PreWritingHandwritingActivityLineShapeWrapperProps>`
  display: flex;
  ${(props) => (props.iterationCount <= 3) && `
    align-items: center;
    justify-content: flex-start;
  `}
  ${(props) => (props.iterationCount > 3) && `
    align-items: center;
    justify-content: space-between;
  `}
  width: 100%;
  height: 100%;
`;

type PreWritingHandwritingActivityLineShapeProps = {
  url: string,
  iterationCount: number,
}

export const
  PreWritingHandwritingActivityLineShape = styled.div <PreWritingHandwritingActivityLineShapeProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30%;
    ${(props) => (props.iterationCount <= 3) && `
      padding-left: 5%;
      width: 30%;
      height: 100%;
      .vertical-line-lottie {
        position: absolute;
        top: 8%;
        z-index: 999;
        width: 128px;
        height: 386px;
      }
      .drawing-area-vertical-1 {
        position: absolute;
        background: url(${props.url});
        background-repeat: no-repeat;
        width: 20%;
        height: 82%;
        margin-top: 10%;
      }
      .drawing-area-vertical-2 {
        position: absolute;
        background: url(${props.url});
        background-repeat: no-repeat;
        width: 20%;
        height: 82%;
        margin-top: 10%;
        left: 60%;
      }
    `}
    ${(props) => (props.iterationCount > 3) && `
      flex-direction: column;
      width: 60%;
      height: 50%;
      .horizontal-line-lottie {
        position: absolute;
        z-index: 999;
        top: -35%;
        left: 5%;
        width: 465px;
      }
      .drawing-area-horizontal {
        background: url(${props.url});
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 31%;
      }
    `}
  `;

export const PreWritingHandwritingActivityVerticalLineSuccess = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  .shooting-star-vertical-lottie-1 {
    position: absolute;
    width: 50px;
    height: 372px;
    display: flex;
    justify-content: center;
    top: 13%;
  }
  .shooting-star-vertical-lottie-2 {
    position: absolute;
    width: 50px;
    height: 372px;
    display: flex;
    justify-content: center;
    top: 13%;
    left: 100%;
  }
`;

export const PreWritingHandwritingActivityHorizontalLineSuccess = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 31%;
  .shooting-star-horizontal-lottie-1 {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    width: 482px;
    height: 81px;
  }
  .shooting-star-horizontal-lottie-2 {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 170%;
    width: 482px;
    height: 81px;
  }
`;

type PreWritingHandwritingActivityCrossContainerProps = {
  iterationCount: number,
}

export const
  PreWritingHandwritingActivityCrossContainer = styled.div <PreWritingHandwritingActivityCrossContainerProps>`
    ${(props) => props.iterationCount === 1 && `
      margin-top: -5%;
      margin-left: -2%
    `}
    ${(props) => props.iterationCount === 2 && `
      margin-top: 144px;
      margin-left: -10%
  `}
    ${(props) => props.iterationCount === 3 && `
      margin-top: -5%;
      margin-left: -10%
  `}
`;

export const PreWritingHandwritingActivityCrossShapeWrapper = styled.div`
  background: url(${crossShape});
  background-repeat: no-repeat;
  width: 382px;
  height: 382px;
  .arrow-down {
    width: 44px;
    rotate: 90deg;
    margin-top: 16px;
    align-self: baseline;
  }
  .arrow-left {
    width: 44px;
    padding-left: 16px;
  }
  .drawing-area-cross {
    display: flex;
    justify-content: flex-start;
    column-gap: 110px;
    width: 100%;
    height: 100%;
  }
`;

type PreWritingHandwritingActivityCircleShapeProps = {
  url: string,
  iterationCount: number,
}

export const PreWritingHandwritingActivityCircleShape = styled.div <PreWritingHandwritingActivityCircleShapeProps>`
  background: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 300px;
  height: 300px;
  ${(props) => props.iterationCount === 1 && `
    margin-top: -30px;
    margin-left: -424px;
  `}
   ${(props) => props.iterationCount === 2 && `
     margin-left: -330px;
     margin-top: 235px;
  `}
   ${(props) => props.iterationCount === 3 && `
     margin-left: -320px;
     margin-top: -20px;
  `}
  .drawing-area-circle {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
`;

export const PreWritingHandwritingActivityCircleShapeWrapper = styled.div`
  display: flex;
`;

export const PreWritingHandwritingRewardWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: -6%;
  margin-left: -5%;
  .red-planet{
    position: absolute;
  }
  .blue-planet{
    position: absolute;
    margin-top: 30px;
    margin-left: 600px;
  }
  .yellow-planet{
    position: absolute;
    rotate: calc(40deg);
    margin-left: 140px;
    margin-top: 140px;
  }
  .stars-1{
    position: absolute;
    margin-left: 440px;
    margin-top: 80px;
  }
  .stars-2{
    position: absolute;
    margin-left: 60px;
    margin-top: 360px;
  }
  .stars-3{
    position: absolute;
    margin-top: 480px;
    margin-left: 720px;
  }
  .shooting-stars-1{
    position: absolute;
    margin-left: 400px;
  }
  .shooting-stars-2{
    position: absolute;
    rotate: calc(180deg);
    margin-left: 140px;
    margin-top: 540px;
  }
  .next-button-wrapper{
    position: absolute;
    right: 40px;
    bottom: 40px;
  }
`;
