import React from 'react';
import Links from '../data/links';
import replayIcon from '../assets/images/icons/replay-icon.svg';
// This page has its own styles so that app error boundary
// can sit outside of styled-components and theme provider
import './ErrorFallback.css';

export default function ErrorFallback() {
  return (
    <div className="error-fallback-container">
      <main className="error-fallback">
        <button
          type="button"
          onTouchStart={() => window.location.reload()}
          onClick={() => window.location.reload()}
          aria-label="Reload the page"
        >
          <img alt="" src={replayIcon} />
        </button>
        <div>
          <h1>Oops!</h1>
          <p>Something went wrong.</p>
          <p>
            We&apos;re looking into this.
            <br />
            Try refreshing the page.
          </p>
        </div>
        <p className="small">
          For any issues or questions,
          <br />
          you can reach us at
          {' '}
          <a href={Links.EMAIL_CAMBIAR}>Illuminate@cambiareducation.org</a>
          .
        </p>
      </main>
    </div>
  );
}
