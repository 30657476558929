import { StrokeOutcome, StrokeOutcomeType } from './types';

export const strokeOutcomes = {
  [StrokeOutcome.SUCCESS]: {
    type: StrokeOutcomeType.SUCCESS,
  },
  [StrokeOutcome.ERROR_GENERAL_TRACING]: {
    type: StrokeOutcomeType.ERROR,
  },
  [StrokeOutcome.ERROR_START]: {
    type: StrokeOutcomeType.ERROR,
  },
  [StrokeOutcome.ERROR_END]: {
    type: StrokeOutcomeType.ERROR,
  },
  [StrokeOutcome.ERROR_TIME]: {
    type: StrokeOutcomeType.ERROR,
  },
  [StrokeOutcome.WARNING_INTERSECTION]: {
    type: StrokeOutcomeType.SUCCESS,
  },
  [StrokeOutcome.WARNING_INCORRECT_START_POINT]: {
    type: StrokeOutcomeType.WARN,
  },
  [StrokeOutcome.WARNING_REMINDER]: {
    type: StrokeOutcomeType.WARN,
  },
};
