import React from 'react';
import { Character, CharacterCategory } from '../../data/types';
import LottiePlayer from '../LottiePlayer';
import { AbsolutePositionWrapper } from '../../styles/components/PositionWrappers';
import closeGap from '../../assets/lotties/close-gap.json';

interface CloseGapAnimationProps {
  isVisible: boolean,
  currentCharacter: Character|null,
}

export default function CloseGapAnimation({
  isVisible,
  currentCharacter,
}: CloseGapAnimationProps) {
  if (!isVisible) return null;

  return (
    <AbsolutePositionWrapper
      top={currentCharacter?.category === CharacterCategory.LETTER_LOWER ? 70 : 20}
      right={55}
    >
      <LottiePlayer
        data={closeGap}
        width={35}
        shouldAutoplay
        speed={2}
      />
    </AbsolutePositionWrapper>
  );
}
