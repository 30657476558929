import { Stroke } from '../data/types';
import useActivityTypes from './useActivityTypes';

export default function useDirectionalStartDot() {
  const { isIndependentActivity } = useActivityTypes();

  const isStartDotDirectional = (currentStroke: Stroke | null) => {
    if (isIndependentActivity || !currentStroke) return false;
    return currentStroke.points.length > 1;
  };

  return isStartDotDirectional;
}
