import React, { useEffect, useState } from 'react';
import outerSpace from '../assets/lotties/outer-space.json';
import starYellow from '../assets/images/star-yellow/star-yellow.svg';
import { CharacterType, DifficultyLevel, DrawnCharacter } from '../data/types';
import { ActionType, useGameContext } from '../context/WritingContext/GameContext';
import useTimeout from '../hooks/useTimeout';
import { useAudioContext } from '../context/AudioContext';
import { EndOfSetDisplayContainer } from '../styles/components/AnimatedOuterSpaceWrapper';
import LottiePlayer from '../components/LottiePlayer';
import DrawnCharCard from '../components/DrawnCharCard';
import NextButton from '../components/Buttons/NextButton';
import greatWriting from '../assets/audio/rewards/VO50.mp3';
import { characters } from '../data/characters/characters';
import envSettings from '../data/envSettings';

export default function CharacterEndOfSetDisplay() {
  const timeout = useTimeout();
  const [showNextButton, setShowNextButton] = useState(false);
  const audioContext = useAudioContext();

  useEffect(() => {
    audioContext?.handlePlay({ src: greatWriting });
    return () => audioContext?.handleComplete();
  }, []);

  useEffect(() => {
    timeout.createTimeout({
      delay: 3000,
      func: () => {
        setShowNextButton(true);
      },
    });
  }, []);

  const ctx = useGameContext();
  if (!ctx) return null;

  const selectedChars: DrawnCharacter[] = ctx?.drawnCharacters.filter((drawnChar) => (drawnChar.isSelected === true));

  const goToNextActivity = () => {
    ctx.dispatch({ type: ActionType.LEAVE_END_OF_SET_PAGE });
  };

  /* TODO: This is temporary while Easy/Normal modes are unchangeable
  ** and only available in certain envs.
  ** Added here because End of Set display must deal with a mixture of chars
  ** completed in Easy or Normal modes */
  const getDifficultyLevelForChar = (
    characterType: CharacterType | null = null,
  ) => {
    if (envSettings.difficultyLevel !== DifficultyLevel.EASY) return DifficultyLevel.NORMAL;
    const currentCharType = characterType || ctx?.currentCharacter?.type;
    const hasEasyLevel = (currentCharType)
      ? !!characters[currentCharType].positioning[DifficultyLevel.EASY]
      : false;
    return (hasEasyLevel)
      ? DifficultyLevel.EASY
      : DifficultyLevel.NORMAL;
  };

  return (
    <EndOfSetDisplayContainer numberOfCharacters={selectedChars.length}>
      <div className="top">
        <LottiePlayer
          data={outerSpace}
          width="100%"
          handleComplete={() => {}}
          shouldAutoplay
          shouldLoop
        />
        <div className="text-and-stars">
          <div className="left">
            <img
              alt=""
              className="star top"
              src={starYellow}
            />
            <img
              alt=""
              className="star bottom"
              src={starYellow}
            />
          </div>
          <div className="cards-container">
            {selectedChars.length > 0
              ? selectedChars.map((drawnCharacter) => (
                <DrawnCharCard
                  key={`${drawnCharacter.characterId}-${drawnCharacter.order}`}
                  drawnCharacter={drawnCharacter}
                  difficultyLevel={getDifficultyLevelForChar(drawnCharacter.characterId)}
                />
              ))
              : null}
            <div className="spacer" />
          </div>
          <div className="right">
            <img
              alt=""
              className="star top"
              src={starYellow}
            />
            <img
              alt=""
              className="star bottom"
              src={starYellow}
            />
          </div>
        </div>
      </div>
      {showNextButton && (
        <div className="bottom">
          <div className="next-activity-navigation">
            <NextButton isDisabled={false} buttonTouch={goToNextActivity} />
          </div>
        </div>
      )}
    </EndOfSetDisplayContainer>
  );
}
