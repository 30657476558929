import React, { useRef, useEffect } from 'react';
import useImage from 'use-image';
import Konva from 'konva';
import { Image } from 'react-konva';

type URLImageProps = {
  id?: string,
  url: string,
  x?: number,
  y?: number,
  width?: number,
  height?: number,
  rotation?: number,
  rotationOffsetX?: number,
  rotationOffsetY?: number,
  animateEntrance?: boolean,
  handleAnimationEnd?: () => void,
  handleAnimationStart?: () => void,
}

export default function URLImage({
  id,
  url,
  x,
  y,
  width,
  height,
  rotation,
  rotationOffsetX,
  rotationOffsetY,
  animateEntrance,
  handleAnimationStart,
  handleAnimationEnd,
}: URLImageProps) {
  const imageRef = useRef<any>(null);
  const [image] = useImage(url);

  useEffect(() => {
    if (!animateEntrance) return;
    if (handleAnimationStart) handleAnimationStart();
    const scaleUpTween = new Konva.Tween({
      node: imageRef.current,
      duration: 0.5,
      scaleX: 2,
      scaleY: 2,
      easing: Konva.Easings.EaseInOut,
      onFinish: () => {
        scaleUpTween.reverse();
        // Wait for reverse to end so that animation is smooth.
        setTimeout(() => {
          if (handleAnimationEnd) handleAnimationEnd();
        }, 500);
      },
    });

    scaleUpTween.play();
  }, [animateEntrance]);

  return (
    <Image
      ref={imageRef}
      id={id}
      image={image}
      height={height}
      width={width}
      x={x}
      y={y}
      rotation={rotation}
      offsetX={rotationOffsetX}
      offsetY={rotationOffsetY}
    />
  );
}

URLImage.defaultProps = {
  id: '',
  x: 0,
  y: 0,
  width: undefined,
  height: undefined,
  rotation: undefined,
  rotationOffsetX: undefined,
  rotationOffsetY: undefined,
  animateEntrance: false,
  handleAnimationEnd: undefined,
  handleAnimationStart: undefined,
};
