const getEnvironment = () => {
  if (window && window.location && window.location.host) {
    const { host } = window.location;
    if (
      host.includes('0.0.0.0')
      || host.includes('localhost')
      || process.env.NODE_ENV === 'development') return 'local';
    if (host.includes('dev')) return 'dev';
    if (host.includes('staging')) return 'staging';
    if (host.includes('sandbox')) return 'sandbox';
    if (host.includes('demo')) return 'demo';
    return 'production';
  }
  return 'unknown';
};

export default getEnvironment;
