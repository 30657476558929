/* eslint-disable max-len */
import React from 'react';

// Used as a component instead of an svg file so it can be used offline
export default function NoInternetColorSvg() {
  return (
    <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2278_2932)">
        <path d="M44.2369 12.9789C41.6653 10.5525 38.7321 8.61334 35.4865 7.19841C31.6008 5.5048 27.4348 4.62973 23.106 4.60049C18.7923 4.57126 14.5298 5.38396 10.4395 7.01327C6.99126 8.38726 3.78725 10.2972 0.886227 12.7022C-0.181776 13.5889 -0.301074 15.2202 0.630588 16.257L0.662779 16.2941C1.50923 17.2373 2.91998 17.3387 3.8914 16.5338C9.41888 11.9518 16.1507 9.47866 23.0738 9.52349C29.9022 9.56831 36.189 12.0688 41.0026 16.6059C41.9077 17.4595 43.3014 17.4498 44.2028 16.5922L44.2388 16.5591C45.2538 15.5944 45.2557 13.9398 44.2388 12.9789H44.2369Z" fill="#E80041" />
        <path d="M38.3307 19.1259C36.5261 17.5258 34.4942 16.2337 32.2673 15.2709C29.3436 14.006 26.2096 13.359 22.9526 13.3492C22.928 13.3492 22.9034 13.3492 22.8788 13.3492C19.6634 13.3492 16.4859 13.971 13.4315 15.1949C11.0417 16.1538 8.80915 17.4537 6.76404 19.0693C5.66953 19.9347 5.54266 21.5854 6.48 22.6281L6.51219 22.6632C7.33591 23.5792 8.70311 23.7058 9.66507 22.9458C13.5224 19.8957 18.139 18.2411 22.8788 18.2411C22.8996 18.2411 22.9185 18.2411 22.9393 18.2411C27.5882 18.2547 31.8753 19.8684 35.2195 22.8249C36.1284 23.6279 37.4767 23.6084 38.3572 22.7704L38.3932 22.7372C39.4233 21.7569 39.3987 20.0672 38.3345 19.1259H38.3307Z" fill="#E80041" />
        <path d="M32.1594 25.2767C31.188 24.542 30.1275 23.932 28.9914 23.4506C27.0485 22.6281 24.9636 22.2208 22.7973 22.2364C19.2847 22.2637 15.8515 23.4038 12.9013 25.4911C11.7064 26.3369 11.5113 28.0734 12.4979 29.1707L12.5301 29.2077C13.3311 30.1003 14.6453 30.2484 15.6205 29.5624C19.9739 26.4948 25.509 26.3818 29.3322 29.2701C30.2449 29.96 31.5061 29.8762 32.3374 29.0849L32.3734 29.0518C33.4717 28.0072 33.3656 26.1927 32.1613 25.2826L32.1594 25.2767Z" fill="#E80041" />
        <path d="M26.217 36.8376C27.1573 34.7248 26.2554 32.2275 24.2026 31.2598C22.1498 30.292 19.7234 31.2203 18.7831 33.3331C17.8428 35.4458 18.7447 37.9431 20.7975 38.9108C22.8503 39.8786 25.2767 38.9504 26.217 36.8376Z" fill="#E80041" />
        <path d="M38.7158 34.9717L5.22095 0.498535L0.823698 5.02422L34.3186 39.4974L38.7158 34.9717Z" fill="white" />
        <path d="M37.6875 36.0301L4.19263 1.55688L1.85073 3.96718L35.3456 38.4404L37.6875 36.0301Z" fill="#E80041" />
      </g>
      <defs>
        <clipPath id="clip0_2278_2932">
          <rect width="45" height="39" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
