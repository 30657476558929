import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Pen } from '../../data/types';

type SvgGradientDefsProps = {
  gradientId: string,
  pen: Pen,
}

export default function SvgGradientDefs({ gradientId, pen }:SvgGradientDefsProps) {
  const isGradient = pen.colors.length > 1;

  return (
    isGradient ? (
      <defs>
        <linearGradient
          id={gradientId}
          x1="0"
          y1="0"
          x2="200"
          y2="0"
          gradientTransform="rotate(45)"
          gradientUnits="userSpaceOnUse"
        >
          {pen.colors.map((color, i) => (
            <stop key={uuidv4()} offset={i} stopColor={color} />
          ))}
        </linearGradient>
      </defs>
    ) : null
  );
}
