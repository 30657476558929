import React from 'react';
import { useTheme } from 'styled-components';
import replayIcon from '../../assets/images/icons/replay-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';

type ReplayButtonProps = {
  ariaLabel: string,
  buttonTouch: () => void,
}

export default function ReplayButton({ ariaLabel, buttonTouch }: ReplayButtonProps) {
  const theme = useTheme();

  return (
    <CircleButton
      className="replay"
      colors={theme.colors.buttons.replay}
      aria-label={ariaLabel}
      onTouchStart={buttonTouch}
      width="106px"
      height="106px"
    >
      <img
        alt=""
        src={replayIcon}
        style={{ width: '52px', height: '55px' }}
      />
    </CircleButton>
  );
}
