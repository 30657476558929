import React from 'react';
import { useTheme } from 'styled-components';
import sequenceIcon from '../../assets/images/icons/sequence-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';

type SequencePageButtonProps = {
  buttonTouch: () => void,
}
export default function SequencePageButton({ buttonTouch }: SequencePageButtonProps) {
  const theme = useTheme();

  return (
    <CircleButton
      colors={theme.colors.buttons.sequence}
      aria-label="go to instructional sequence page"
      onTouchStart={buttonTouch}
      role="link"
    >
      <img
        alt=""
        style={{ width: '36px', height: '36px' }}
        src={sequenceIcon}
      />
    </CircleButton>
  );
}
