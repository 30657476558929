import React from 'react';
import { useTheme } from 'styled-components';
import playIcon from '../../assets/images/icons/play-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';

type PlayButtonProps = {
  ariaLabel: string,
  buttonTouch: () => void,
}

export default function PlayButton({ ariaLabel, buttonTouch }: PlayButtonProps) {
  const theme = useTheme();

  return (
    <CircleButton
      colors={theme.colors.buttons.demo}
      aria-label={ariaLabel}
      onTouchStart={buttonTouch}
      width="106px"
      height="106px"
    >
      <img
        alt=""
        src={playIcon}
        style={{ width: '52px', height: '55px' }}
      />
    </CircleButton>
  );
}
