import { useState, useMemo } from 'react';
import { TouchInput, useUserSettingsContext } from '../context/UserSettingsContext';
import { EVAL_DISTANCE_FROM_LAST_STROKE_POINT } from '../shared/constants';
import pointIsWithinAcceptableDistance from '../shared/pointIsWithinAcceptableDistance';
import envSettings from '../data/envSettings';
import { DrawingStroke } from '../components/Konva/types';
import { getIntersectionNodeName, IntersectionType } from '../shared/getIntersectionNodeName';

// Manages pointer (touch) selection logic for the Konva stage
// based on app settings (type of touch allowed), expected start point, and previous touch points within stage

export default function useKonvaPalmRejection() {
  const [touchInterferenceDetected, setTouchInterferenceDetected] = useState(false);
  const settings = useUserSettingsContext();

  const isPointInTargetZone = (konvaStage: any, point: any, targetId: string) => {
    const intersections = konvaStage.getAllIntersections(point);
    return !!intersections?.find((shape: any) => shape.attrs?.id === targetId);
  };

  const findStartZonePointer = (konvaStage: any) => {
    const allPointerPositions = konvaStage.getPointersPositions();
    if (envSettings?.enableNewTouchSettings && allPointerPositions?.length > 1) {
      setTouchInterferenceDetected(true);
    }
    const id = getIntersectionNodeName(IntersectionType.REQUIRED, 0);
    return allPointerPositions?.find(
      (pos: any) => isPointInTargetZone(konvaStage, pos, id),
    ) || null;
  };

  const findChangedTouchFromStylus = (nativeEvent: any): any => {
    const { changedTouches } = nativeEvent;

    for (let i = 0; i < changedTouches.length; i++) {
      const touchEvt = changedTouches.item(i);
      if (touchEvt?.touchType === TouchInput.STYLUS) return touchEvt;
    }

    return null;
  };

  const findActiveStylusPointer = (konvaStage: any, e: any) => {
    const stylusTouch = findChangedTouchFromStylus(e.evt);

    if (!stylusTouch?.identifier) return null;
    const allPointerPositions = konvaStage.getPointersPositions();
    return allPointerPositions?.find((pos: any) => pos.id === stylusTouch.identifier);
  };

  const findChangedTouchById = (nativeEvent: any, id: number): any => {
    const { changedTouches } = nativeEvent;

    for (let i = 0; i < changedTouches.length; i++) {
      const touchEvt = changedTouches.item(i);
      if (touchEvt?.identifier === id) return touchEvt;
    }

    return null;
  };

  const isTouchTypeAllowed = (nativeEvent: any, targetPointerId: number): boolean => {
    let allow = true;
    if (envSettings?.enableStylusSettings && settings?.acceptedTouchInputType !== TouchInput.ANY) {
      const nativeTouchEvt = findChangedTouchById(nativeEvent, targetPointerId);
      allow = nativeTouchEvt?.touchType === settings?.acceptedTouchInputType;
    }

    return allow;
  };

  const allowActiveStylusOnly = useMemo(
    () => {
      if (envSettings?.enableStylusSettings && settings?.acceptedTouchInputType === TouchInput.STYLUS) {
        return true;
      }
      return false;
    },
    [settings?.acceptedTouchInputType],
  );

  // prefer activePointer from pointerId in close proximity
  // or allow close point (fallback for shaky stroke not recognized as continuation of touch)
  const getActivePointerFromKonva = (e: any, konvaStage: any, lastStroke: DrawingStroke): any => {
    if (envSettings?.enableNewTouchSettings) {
      const allPointerPositions = konvaStage?.getPointersPositions();
      if (allPointerPositions?.length > 1) {
        setTouchInterferenceDetected(true);
      }
      const lastPointX = lastStroke?.points[lastStroke.points.length - 2];
      const lastPointY = lastStroke?.points[lastStroke.points.length - 1];
      const activePointer = allPointerPositions.find((pos: any) => pointIsWithinAcceptableDistance(
        [pos.x, pos.y],
        [lastPointX, lastPointY],
        EVAL_DISTANCE_FROM_LAST_STROKE_POINT * 2,
      ));

      return activePointer || null;
    }

    let activePointer;
    const allPointerPositions = konvaStage?.getPointersPositions();
    if (envSettings?.enableNewTouchSettings && allPointerPositions?.length > 1) {
      setTouchInterferenceDetected(true);
    }
    const lastPointX = lastStroke?.points[lastStroke.points.length - 2];
    const lastPointY = lastStroke?.points[lastStroke.points.length - 1];
    if (e.pointerId === lastStroke?.touchId) {
      activePointer = allPointerPositions.find((pos: any) => {
        const matchesId = pos.id === lastStroke.touchId;
        // checking distance to prevent streaks off the edge of the drawing surface
        // occasionally these touches have the same pointId but are far off the stroke
        const closeToLastPoint = pointIsWithinAcceptableDistance(
          [pos.x, pos.y],
          [lastPointX, lastPointY],
          EVAL_DISTANCE_FROM_LAST_STROKE_POINT * 2,
        );
        return matchesId && closeToLastPoint;
      });
    } else {
      activePointer = allPointerPositions.find((pos: any) => pointIsWithinAcceptableDistance(
        [pos.x, pos.y],
        [lastPointX, lastPointY],
        EVAL_DISTANCE_FROM_LAST_STROKE_POINT,
      ));
    }

    return activePointer || null;
  };

  // when active stylus is required, stroke can begin anywhere on canvas (preferred design)
  // if all touch types/styli are allowed, stroke must begin in start zone
  // to support palm rejection for passive stylus
  const getAllowedStartingPointerFromKonva = (konvaStage: any, e: any) => {
    const requireStrokeFromStartZone = !allowActiveStylusOnly;
    return requireStrokeFromStartZone ? findStartZonePointer(konvaStage) : findActiveStylusPointer(konvaStage, e);
  };

  return {
    isTouchTypeAllowed,
    allowActiveStylusOnly,
    getActivePointerFromKonva,
    getAllowedStartingPointerFromKonva,
    touchInterferenceDetected,
    setTouchInterferenceDetected,
  };
}
