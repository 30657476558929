import React, { useMemo, useState, useEffect } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { SpacingState, SpacingEventTypes, SpacingActionOutcome } from '../stateMachines/spacingMachine/spacingMachine';
import SpacingWritingActivity from '../components/SpacingGame/SpacingWritingActivity';
import SpacingMCActivity from '../components/SpacingGame/SpacingMCActivity';
import ActivityWrapper from '../styles/components/ActivityWrapper';
import TopNavigation from '../components/TopNavigation';
import ActivityRewardsAndNavigation from '../components/ActivityRewardsAndNavigation';
import useOnlineStatus from '../hooks/useOnlineStatus/useOnlineStatus';
import NoInternetPersistent from '../components/NoInternet/NoInternetPersistent';
import { SpacingActivityCategory, SpacingActivityType } from '../data/types';
import SpacingSelfAssessment from '../components/SpacingGame/SpacingSelfAssessment';
import { useRewardsContext } from '../context/RewardsContext';
import { RewardEventTypes, RewardState } from '../stateMachines/rewardsMachine/rewardsMachine';
import TravelingStarAnimation, { TravelingStarPosition } from '../components/Animations/TravelingStarAnimation';
import { getAnalyticsSpacingOutcomeMessage } from '../shared/analyticsMessages';
import SpacingDragAndDropActivity from '../components/SpacingGame/SpacingDragAndDropActivity';
import VersionDisplay from '../components/Version Display/VersionDisplay';

export default function SpacingActivityWrapper() {
  const isOnline = useOnlineStatus();
  const { state, send } = useSpacingGameContext();
  const rewards = useRewardsContext();
  const { trackEvent } = useMatomo();
  const [canChangePen, setCanChangePen] = useState(true);
  const [currentStarMeterFrames, setCurrentStarMeterFrames] = useState([1, 1]);
  const [showTravelingStarAnimation, setShowTravelingStarAnimation] = useState(false);
  const [isCueingErrorFeedback, setIsCueingErrorFeedback] = useState(false);
  const visuallyHiddenTitle = `
    ${state.context.currentActivity?.title} activity for ${state.context.currentItem?.display}
  `;

  const showNextButton = useMemo(() => {
    if (!state.can(SpacingEventTypes.NEXT)) return false;
    if (state.matches(SpacingState.CUEING_ACTION)) return false;
    if (state.context?.currentActivity?.type === SpacingActivityType.TRACE_FADED) {
      if (state.matches(SpacingState.SHOWING_ERROR_FEEDBACK) && state.context?.spacingErrorCount < 2) return false;
    }

    // user should only hit next button to move on if they've hit a threshold to go to another activity
    // otherwise next action should be triggered automatically.
    if (state.matches(SpacingState.SHOWING_ERROR_FEEDBACK)
      && (state.context?.currentActivity?.type === SpacingActivityType.TRACE_SHOOTING_STAR
          || state.context?.currentActivity?.type === SpacingActivityType.SPACE_INDEPENDENT)
      && (state.context?.spacingErrorCount < 3 || state.context?.spacingErrorCount === 4)) {
      return false;
    }

    if (state.context?.currentActivity?.type === SpacingActivityType.TRACE_SHOOTING_STAR
      && state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)
      && (state.context?.completedIterations || 0) + 1 < state.context?.currentActivity?.requiredIterations) {
      return false;
    }

    return true;
  }, [state.value]);

  const nextButtonDisabled = useMemo(() => {
    if (state.context?.currentActivity?.type
      && [SpacingActivityType.DRAG_AND_DROP,
        SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]
        .includes(state.context.currentActivity.type)) {
      if (isCueingErrorFeedback) return true;
    }
    return false;
  }, [state.value, isCueingErrorFeedback]);

  const showRewards = useMemo(() => {
    if (state.context?.currentActivity?.category === SpacingActivityCategory.WRITING) return true;
    if (state.context?.currentActivity?.type === SpacingActivityType.SELF_ASSESSMENT
      && rewards.state.matches(RewardState.REWARDING_PEN)) {
      return true;
    }
    return false;
  }, [state.context?.currentActivity, rewards.state.value]);

  const travelingStarPosition = useMemo(() => {
    if (state.context?.currentActivity?.type === SpacingActivityType.TRACE_SHOOTING_STAR) {
      if (state.context?.completedIterations < 1) return TravelingStarPosition.START;
      return TravelingStarPosition.END;
    }
    return TravelingStarPosition.MIDDLE;
  }, [state.context?.currentActivity?.type, state.context?.completedIterations]);

  const updateStarBankAnimationFrames = () => {
    setCurrentStarMeterFrames(state.context?.currentActivity?.starMeterFrames
      ? state.context.currentActivity.starMeterFrames[state.context.completedIterations]
      : [1, 1]);
  };

  const handleAnalytics = () => {
    const currentItemType = state.context?.currentItem?.type;
    const { activitiesProgress } = state.context;
    if (activitiesProgress) {
      let score = 0;
      Object.entries(activitiesProgress).forEach(([key, value]) => {
        if (key === SpacingActivityType.MULTI_CHOICE_CLOSE
          || key === SpacingActivityType.MULTI_CHOICE_WIDE
          || key === SpacingActivityType.MULTI_CHOICE_ALL) {
          const outcome = value?.outcome || SpacingActionOutcome.FAIL;
          if (outcome as SpacingActionOutcome !== SpacingActionOutcome.FAIL) {
            score++;
          }

          trackEvent({
            category: `${currentItemType}`,
            action: `${getAnalyticsSpacingOutcomeMessage(outcome as SpacingActionOutcome)}`,
            name: `${key}`,
          });
        }
      });

      trackEvent({
        category: `${currentItemType}`,
        action: `${score}`,
        name: 'Final 3 Activity Multi Choice Score',
      });
    }
  };

  useEffect(() => {
    if (state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)) {
      if (state.context.currentActivity?.category === SpacingActivityCategory.WRITING) {
        setShowTravelingStarAnimation(true);
      }
      // uses a timer instead of traveling star's handleComplete callback
      // because when multiple iterations are visible on screen the animation
      // is unmounted when the next iteration becomes active
      setTimeout(() => updateStarBankAnimationFrames(), 800);
    }
  }, [state.value]);

  return (
    <ActivityWrapper>
      <h1 className="visually-hidden">{visuallyHiddenTitle}</h1>
      <TopNavigation
        onDemoButtonTouch={() => send({ type: SpacingEventTypes.REWATCH_DEMO, payload: state.value })}
        itemLabel={state.context?.currentItem?.display || ''}
        showHomeButton
        showGrantAccess
        onHomeButtonTouch={() => send({ type: SpacingEventTypes.RESET })}
      />

      {!isOnline && <NoInternetPersistent />}

      {state.context?.currentActivity?.category === SpacingActivityCategory.WRITING
        && (
        <SpacingWritingActivity
          activePen={rewards.state.context.activePen}
          setCanChangePen={setCanChangePen}
          travelingStarChild={(
            <TravelingStarAnimation
              isVisible={showTravelingStarAnimation}
              handleComplete={() => setShowTravelingStarAnimation(false)}
              position={travelingStarPosition}
            />
          )}
        />
        )}
      {/* [SW-983] Render spacing activity component */}
      {state.context?.currentActivity?.category === SpacingActivityCategory.DRAG_AND_DROP
        && (
        <SpacingDragAndDropActivity onCueingErrorFeedbackChange={(e) => setIsCueingErrorFeedback(e)} />
        )}

      {state.context?.currentActivity?.type === SpacingActivityType.SELF_ASSESSMENT
        && (<SpacingSelfAssessment />)}

      {state.context?.currentActivity?.category === SpacingActivityCategory.MULTI_CHOICE
        && state.context?.currentActivity?.type !== SpacingActivityType.SELF_ASSESSMENT
        && (<SpacingMCActivity />)}

      <ActivityRewardsAndNavigation
        starMeterFrames={currentStarMeterFrames as [number, number]}
        hasNewPen={rewards.state.matches(RewardState.REWARDING_PEN)}
        showRewards={showRewards}
        showNextButton={showNextButton}
        nextButtonDisabled={nextButtonDisabled}
        onNextButtonTouch={() => {
          if (state.context.currentActivity?.type === SpacingActivityType.MULTI_CHOICE_ALL) {
            /* Multi choice activities are different from writing activities when it comes to Matomo in two major ways:
              1) they have to be re-played if a user watches the demo video.
              2) cumulatively, a score is calculated.
              3) their final outcomes cannot be known until their respective Next buttons are pressed
              Because of these points, the only time in the flow that we can be sure all selections are final and the
              score can be calculated and sent to Matomo is when a user presses the Next button on the MC All activity.
            */
            handleAnalytics();
          }
          send({ type: SpacingEventTypes.NEXT });
          rewards.send(RewardEventTypes.NEXT);
        }}
        canChangePenColor={canChangePen}
      />
      <VersionDisplay isLanding={false} />
    </ActivityWrapper>
  );
}
