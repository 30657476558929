import { SpacingActivityCategory, SpacingActivityType, SpacingItemType } from '../../../data/types';

import amFadedIntro from './VO55-AM.mp3';
import amDragIntro from './VO87-AM.m4a';
import amShootingStarIntro from './VO59-AM.mp3';
import amIndeIntro from './VO63-AM.mp3';

import atFadedIntro from './VO55-AT.mp3';
import atDragIntro from './VO87-AT.m4a';
import atShootingStarIntro from './VO59-AT.mp3';
import atIndeIntro from './VO63-AT.mp3';

import bagFadedIntro from './VO55-BAG.mp3';
import bagDragIntro from './VO90-BAG.m4a';
import bagShootingStarIntro from './VO59-BAG.mp3';
import bagIndeIntro from './VO63-BAG.mp3';

import chFadedIntro from './VO56-CH.mp3';
import chDragIntro from './VO87-CH.m4a';
import chShootingStarIntro from './VO60-CH.mp3';
import chIndeIntro from './VO64-CH.mp3';

import ifFadedIntro from './VO55-IF.mp3';
import ifDragIntro from './VO87-IF.m4a';
import ifShootingStarIntro from './VO59-IF.mp3';
import ifIndeIntro from './VO63-IF.mp3';

import inFadedIntro from './VO55-IN.mp3';
import inDragIntro from './VO87-IN.m4a';
import inShootingStarIntro from './VO59-IN.mp3';
import inIndeIntro from './VO63-IN.mp3';

import itFadedIntro from './VO55-IT.mp3';
import itDragIntro from './VO87-IT.m4a';
import itShootingStarIntro from './VO59-IT.mp3';
import itIndeIntro from './VO63-IT.mp3';

import noFadedIntro from './VO55-NO.mp3';
import noDragIntro from './VO87-NO.m4a';
import noShootingStarIntro from './VO59-NO.mp3';
import noIndeIntro from './VO63-NO.mp3';

import redFadedIntro from './VO55-RED.mp3';
import redDragIntro from './VO90-RED.m4a';
import redShootingStarIntro from './VO59-RED.mp3';
import redIndeIntro from './VO63-RED.mp3';

import shFadedIntro from './VO56-SH.mp3';
import shDragIntro from './VO87-SH.m4a';
import shShootingStarIntro from './VO60-SH.mp3';
import shIndeIntro from './VO64-SH.mp3';

import thFadedIntro from './VO56-TH.mp3';
import thDragIntro from './VO87-TH.m4a';
import thShootingStarIntro from './VO60-TH.mp3';
import thIndeIntro from './VO64-TH.mp3';

import upFadedIntro from './VO55-UP.mp3';
import upDragIntro from './VO87-UP.m4a';
import upShootingStarIntro from './VO59-UP.mp3';
import upIndeIntro from './VO63-UP.mp3';

import usFadedIntro from './VO55-US.mp3';
import usDragIntro from './VO87-US.m4a';
import usShootingStarIntro from './VO59-US.mp3';
import usIndeIntro from './VO63-US.mp3';

import yesFadedIntro from './VO55-YES.mp3';
import yesDragIntro from './VO90-YES.m4a';
import yesShootingStarIntro from './VO59-YES.mp3';
import yesIndeIntro from './VO63-YES.mp3';

import multiChoiceIntro from './VO51.mp3';

import planet1Intro from './VO44.mp3';
import planet2Intro from './VO46.mp3';

type SpacingActivityIntros = {
  [typeKey in SpacingActivityType | SpacingActivityCategory]?: any
}

const spacingActivityIntros: SpacingActivityIntros = {
  [SpacingActivityType.TRACE_FADED]: {
    [SpacingItemType.AM]: amFadedIntro,
    [SpacingItemType.AT]: atFadedIntro,
    [SpacingItemType.BAG]: bagFadedIntro,
    [SpacingItemType.CH]: chFadedIntro,
    [SpacingItemType.IF]: ifFadedIntro,
    [SpacingItemType.IN]: inFadedIntro,
    [SpacingItemType.IT]: itFadedIntro,
    [SpacingItemType.NO]: noFadedIntro,
    [SpacingItemType.RED]: redFadedIntro,
    [SpacingItemType.SH]: shFadedIntro,
    [SpacingItemType.TH]: thFadedIntro,
    [SpacingItemType.UP]: upFadedIntro,
    [SpacingItemType.US]: usFadedIntro,
    [SpacingItemType.YES]: yesFadedIntro,
  },

  // TODO: Consider multiple iteration words with different intros like "bag", "red", "yes"
  // [SpacingItemType.RED]: { e: redDragIntroE, d: redDragIntroD }
  [SpacingActivityType.DRAG_AND_DROP]: {
    [SpacingItemType.AM]: amDragIntro,
    [SpacingItemType.AT]: atDragIntro,
    [SpacingItemType.BAG]: bagDragIntro,
    [SpacingItemType.CH]: chDragIntro,
    [SpacingItemType.IF]: ifDragIntro,
    [SpacingItemType.IN]: inDragIntro,
    [SpacingItemType.IT]: itDragIntro,
    [SpacingItemType.NO]: noDragIntro,
    [SpacingItemType.RED]: redDragIntro,
    [SpacingItemType.SH]: shDragIntro,
    [SpacingItemType.TH]: thDragIntro,
    [SpacingItemType.UP]: upDragIntro,
    [SpacingItemType.US]: usDragIntro,
    [SpacingItemType.YES]: yesDragIntro,
  },

  [SpacingActivityCategory.MULTI_CHOICE]: multiChoiceIntro,

  [SpacingActivityType.TRACE_SHOOTING_STAR]: {
    [SpacingItemType.AM]: amShootingStarIntro,
    [SpacingItemType.AT]: atShootingStarIntro,
    [SpacingItemType.BAG]: bagShootingStarIntro,
    [SpacingItemType.CH]: chShootingStarIntro,
    [SpacingItemType.IF]: ifShootingStarIntro,
    [SpacingItemType.IN]: inShootingStarIntro,
    [SpacingItemType.IT]: itShootingStarIntro,
    [SpacingItemType.NO]: noShootingStarIntro,
    [SpacingItemType.RED]: redShootingStarIntro,
    [SpacingItemType.SH]: shShootingStarIntro,
    [SpacingItemType.TH]: thShootingStarIntro,
    [SpacingItemType.UP]: upShootingStarIntro,
    [SpacingItemType.US]: usShootingStarIntro,
    [SpacingItemType.YES]: yesShootingStarIntro,
  },

  [SpacingActivityType.SPACE_INDEPENDENT]: {
    [SpacingItemType.AM]: amIndeIntro,
    [SpacingItemType.AT]: atIndeIntro,
    [SpacingItemType.BAG]: bagIndeIntro,
    [SpacingItemType.CH]: chIndeIntro,
    [SpacingItemType.IF]: ifIndeIntro,
    [SpacingItemType.IN]: inIndeIntro,
    [SpacingItemType.IT]: itIndeIntro,
    [SpacingItemType.NO]: noIndeIntro,
    [SpacingItemType.RED]: redIndeIntro,
    [SpacingItemType.SH]: shIndeIntro,
    [SpacingItemType.TH]: thIndeIntro,
    [SpacingItemType.UP]: upIndeIntro,
    [SpacingItemType.US]: usIndeIntro,
    [SpacingItemType.YES]: yesIndeIntro,
  },

  [SpacingActivityType.PLANET_1_GAME]: planet1Intro,
  [SpacingActivityType.PLANET_2_GAME]: planet2Intro,
};

export default spacingActivityIntros;
