export const pointsToPath = (points: number[]) => {
  let path = '';
  for (let i = 0; i < points.length; i += 2) {
    switch (i) {
      case 0:
        path = `${path}M ${points[i]},${points[i + 1]} `;
        break;
      default:
        path = `${path}L ${points[i]},${points[i + 1]} `;
        break;
    }
  }
  return path;
};

const getCirclePath = (points: number[], radius: number): string => `
  M ${points[0]} ${points[1]}
  m -${radius}, 0
  a ${radius},${radius} 0 1,0 ${radius * 2},0
  a ${radius},${radius} 0 1,0 -${radius * 2},0
`;

export const pointsToCircle = (points: number[], radius: number): string => getCirclePath(points, radius);

export const pointsToDotPath = (points: number[]): string => {
  const radius = 2;
  return getCirclePath(points, radius);
};
