import styled from 'styled-components';
import { ContentAreaWrapper } from './ContentWrappers';

const TranslucentOverlay = styled(ContentAreaWrapper)`
  /* Use rgba instead of opacity because Safari has issues with the opacity attribute.
      Officially, Safari supports opacity: https://caniuse.com/?search=opacity
      But, there's disucssion indicating this is a regression starting with iOS 15:
      https://twitter.com/adamwathan/status/1505165679749701637?lang=en
  */
  background: rgba(0, 0, 0, 80%);
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export default TranslucentOverlay;
