import React from 'react';
import { Layer } from 'react-konva';

type ModelPathLayerProps = {
  children: React.ReactNode,
  canWrite: boolean,
}

export default function ModelPathLayer({ children, canWrite }: ModelPathLayerProps) {
  return (
    <Layer
      id="model-path"
      listening={canWrite}
    >
      {children}
    </Layer>
  );
}
