/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useSpacingGameContext } from '../../context/SpacingGameContext';
import { useAudioContext } from '../../context/AudioContext';
import {
  SpacingActivityType,
  PracticeLinesRelationship,
  DragAndDropIteration,
  DifficultyLevel,
  SpacingItemSize,
  SpacingItemType,
  SpacingFeedbackType,
  OptionType,
} from '../../data/types';
import { SpacingPracticeAreaWrapper } from '../../styles/components/ContentWrappers';
import { SpacingDrawingLinesFlex, SpacingDrawingArea } from '../../styles/components/DrawingArea';
import ModelSpacingItem from '../Models/ModelSpacingItem';
import { DRAWABLE_HEIGHT_NORMAL, DRAWABLE_HEIGHT_NORMAL_EXTENDED } from '../../shared/constants';
import { characters } from '../../data/characters/characters';
import { FlexRowSpaceBetween } from '../../styles/components/FlexSpacers';
import { DraggableLetter } from './DraggableLetter';
import {
  SpacingState,
  SpacingEventTypes,
  DragAndDropActionOutcome,
  DragAndDropState,
} from '../../stateMachines/spacingMachine/spacingMachine';
import plink from '../../assets/audio/activitySuccess/plink.mp3';
import youDidIt from '../../assets/audio/spacingActivitySuccess/VO58.mp3';
import spacingActivityIntros from '../../assets/audio/spacingActivityIntros';
import spacingActivitySuccess from '../../assets/audio/spacingActivitySuccess';
import errorTryAgain from '../../assets/audio/activityErrors/VO11.mp3';
import errorTrySomethingElse from '../../assets/audio/activityErrors/VO12-13.mp3';
import { dragAndDropSpacingErrors } from '../../assets/audio/activityErrors';
import watchDemoAgain from '../../assets/audio/activityErrors/VO8.mp3';
import SpacingActivityScaffold from './SpacingActivityScaffold';
import RocketAnimation from '../Animations/RocketAnimation';
import SpacingDropAreaStage from '../Konva/SpacingDropArea';
import { getAnalyticsSpacingDragAndAdropOutcomeMessage } from '../../shared/analyticsMessages';

interface SpacingWritingActivityProps {
  onCueingErrorFeedbackChange: (e: boolean) => void,
}

export const chooseSpacingFeedback: {
  [key in OptionType]: SpacingFeedbackType
} = {
  TOO_CLOSE: SpacingFeedbackType.TOO_CLOSE,
  TOO_WIDE: SpacingFeedbackType.TOO_WIDE,
  CORRECT: SpacingFeedbackType.CORRECT,
};

export default function SpacingWritingActivity({
  onCueingErrorFeedbackChange,
}: SpacingWritingActivityProps) {
  const { state, send } = useSpacingGameContext();
  const { activityProgress } = state.event;
  const { trackEvent } = useMatomo();
  const audioContext = useAudioContext();

  const {
    currentActivity,
    currentItem,
    activitiesProgress,
    spacingErrorCount,
    completedIterations,
    currentSet,
    dragAndDrop,
  } = state.context;

  const defaultFirstIteration: DragAndDropIteration = {
    id: 1,
    drop: dragAndDrop,
  };

  const [iterations, setIterations] = useState<DragAndDropIteration>(defaultFirstIteration);

  const drawingAreaHeight = useMemo(() => {
    if (!currentItem?.traceCharacterType) return DRAWABLE_HEIGHT_NORMAL;

    const traceCharacter = characters[currentItem.traceCharacterType];
    return (traceCharacter.practiceLinesRelationship === PracticeLinesRelationship.BELOW_LINES)
      ? DRAWABLE_HEIGHT_NORMAL_EXTENDED
      : DRAWABLE_HEIGHT_NORMAL;
  }, [currentItem?.traceCharacterType]);

  const showFadedIntroSequence = useMemo(() => {
    if (currentActivity?.type === SpacingActivityType.DRAG_AND_DROP
      && state.matches(SpacingState.CUEING_ACTION)) return true;
    return false;
  }, [state]);

  const currentActivityProgress = useMemo(() => {
    if (!activitiesProgress || !currentActivity) return null;
    const current = activitiesProgress[currentActivity.type] || null;
    if (!current) return null;
    return current;
  }, [currentActivity?.type, activitiesProgress, iterations]);

  const canDragAndDrop = useMemo(() => {
    if (currentActivity?.type === SpacingActivityType.DRAG_AND_DROP
        && state.matches(SpacingState.ACTIVE)) return true;
    return state.matches(SpacingState.ACTIVE);
  }, [currentActivity, state]);

  const cueActivityIntro = (
    currentActivityType: SpacingActivityType,
    currentItemType: SpacingItemType,
    errorCount: number,
  ) => {
    switch (currentActivityType) {
      case SpacingActivityType.DRAG_AND_DROP:
      case SpacingActivityType.DRAG_AND_DROP_INDEPENDENT:
        if (errorCount === 0) {
          audioContext?.handlePlay({
            src: spacingActivityIntros.DRAG_AND_DROP[currentItemType],
            onEnd: () => send(SpacingEventTypes.NEXT),
          });
        } else {
          audioContext?.handlePlay({
            src: errorTryAgain,
            onEnd: () => send(SpacingEventTypes.NEXT),
          });
        }
        break;
      default:
        break;
    }
  };

  const cueActivityError = (
    currentActivityType: SpacingActivityType,
    errorCount: number,
    outcome: DragAndDropActionOutcome,
  ) => {
    switch (currentActivityType) {
      case SpacingActivityType.DRAG_AND_DROP:
      case SpacingActivityType.DRAG_AND_DROP_INDEPENDENT:
        if (errorCount < 3 || errorCount === 4) {
          onCueingErrorFeedbackChange(true);
          audioContext?.handlePlay({
            src: dragAndDropSpacingErrors[outcome],
            onEnd: () => onCueingErrorFeedbackChange(false),
          });
          break;
        }
        if (errorCount === 3) {
          audioContext?.handlePlay({ src: watchDemoAgain, onEnd: () => send(SpacingEventTypes.NEXT) });
          break;
        }
        if (spacingErrorCount === 5) {
          audioContext?.handlePlay({ src: errorTrySomethingElse });
          break;
        }
        break;
      default:
        break;
    }
  };

  const cueActivitySuccess = (
    currentActivityType: SpacingActivityType,
    currentItemType: SpacingItemType,
  ) => {
    switch (currentActivityType) {
      case SpacingActivityType.DRAG_AND_DROP:
        audioContext?.handlePlay({
          src: plink,
          onEnd: () => audioContext?.handlePlay({
            src: spacingActivitySuccess[currentItemType],
          }),
        });
        break;
      case SpacingActivityType.DRAG_AND_DROP_INDEPENDENT:
        audioContext?.handlePlay({
          src: plink,
          onEnd: () => audioContext?.handlePlay({
            src: youDidIt,
          }),
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!state.context || !currentItem || !currentActivity) return;
    if (state.matches(SpacingState.CUEING_ACTION)) {
      cueActivityIntro(
        currentActivity.type,
        currentItem.type,
        spacingErrorCount,
      );
    } else if (state.matches(SpacingState.SHOWING_ERROR_FEEDBACK) && activityProgress?.dropOutcome) {
      cueActivityError(
        currentActivity.type,
        spacingErrorCount,
        activityProgress.dropOutcome,
      );
    } else if (state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)) {
      cueActivitySuccess(
        currentActivity.type,
        currentItem.type,
      );
    }
  }, [state.value, currentActivity?.type]);

  useEffect(() => {
    const requiredIterations = currentActivity?.requiredIterations || 1;
    const activityIterations = { id: requiredIterations, drop: dragAndDrop };

    setIterations(activityIterations);
  }, [currentActivity?.type]);

  const handleSpacingOutcome = (outcome: DragAndDropActionOutcome, dragAndDropValue: DragAndDropState) => {
    const analyticsMessage = `Drop - ${getAnalyticsSpacingDragAndAdropOutcomeMessage(outcome)}`;

    trackEvent({
      category: `${currentItem?.type}`,
      action: analyticsMessage,
      name: `${currentActivity?.type}`,
    });

    let optionalItems: { [key: string]: any } = { };

    if (
      currentActivity?.type === SpacingActivityType.DRAG_AND_DROP
      || currentActivity?.type === SpacingActivityType.DRAG_AND_DROP_INDEPENDENT
    ) {
      optionalItems = {
        ...optionalItems,
        droppedSpacingChar: {
          order: completedIterations,
          spacingItemType: currentItem?.type,
          setType: currentSet?.type,
          isSelected: false,
        },
      };
    }

    if (outcome !== DragAndDropActionOutcome.TOO_CLOSE || DragAndDropActionOutcome.TOO_WIDE) {
      const updatedIterations = iterations;

      optionalItems = {
        ...optionalItems,
        activityProgress: {
          currentIteration: completedIterations + 1,
          iterations: updatedIterations,
          dropOutcome: outcome,
        },
      };

      send({
        type: SpacingEventTypes.ACTION_COMPLETED,
        payload: { spacingOutcome: outcome },
        dragAndDrop: dragAndDropValue,
        ...optionalItems,
      });
    }

    setIterations((prevState) => prevState);

    // If the drop has been spaced incorrectly, send failed outcome
    if (outcome === DragAndDropActionOutcome.TOO_CLOSE || outcome === DragAndDropActionOutcome.TOO_WIDE) {
      send({
        type: SpacingEventTypes.ACTION_COMPLETED,
        payload: { spacingOutcome: outcome },
        dragAndDrop: dragAndDropValue,
        activityProgress: { dropOutcome: outcome },
      });
    }
  };

  const getWritingAreaWidth = (): number => {
    if (!state.context || !currentItem) return 0;
    if (currentItem.size === SpacingItemSize.sm) {
      return 250;
    }
    return 300;
  };

  const renderDropAreaForResetOn = (resetState: SpacingState) => {
    if (!currentItem) return null;

    return (
      <div id="SpacingDropAreaStage" style={{ display: 'contents' }}>
        <SpacingDropAreaStage
          width={getWritingAreaWidth()}
          height={drawingAreaHeight}
          character={characters[currentItem.traceCharacterType]}
          spacingItem={currentItem}
          canDragAndDrop={canDragAndDrop}
          doReset={state.matches(resetState) || state.matches(SpacingState.CUEING_ACTION)}
          showTargetZone={canDragAndDrop}
        />
        {state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)}
      </div>
    );
  };

  useEffect(() => {
    if (currentActivityProgress) {
      // Make activity look like it did before navigating away
      if (currentActivityProgress?.dragAndDropIterations) setIterations(currentActivityProgress.dragAndDropIterations);
    }
  }, [currentActivityProgress]);

  if (!currentActivity || !currentItem) {
    return null;
  }

  return (
    <SpacingPracticeAreaWrapper difficultyLevel={DifficultyLevel.NORMAL} id="dragAndDropAreaWrapper">
      <ModelSpacingItem
        currentItem={currentItem}
        showItem={currentActivity.type !== SpacingActivityType.DRAG_AND_DROP_INDEPENDENT}
      />

      {[SpacingActivityType.DRAG_AND_DROP, SpacingActivityType.DRAG_AND_DROP_INDEPENDENT]
        .includes(currentActivity.type) ? (
          <FlexRowSpaceBetween>
            <SpacingDrawingLinesFlex width={70} style={{ height: `${drawingAreaHeight}px` }}>
              <SpacingDrawingArea>
                <SpacingActivityScaffold
                  currentActivityType={currentActivity.type}
                  currentItem={currentItem}
                  showIntroSequence={showFadedIntroSequence}
                />
                {renderDropAreaForResetOn(SpacingState.ACTIVE)}
              </SpacingDrawingArea>
              <RocketAnimation
                start={currentActivity.type === SpacingActivityType.DRAG_AND_DROP_INDEPENDENT
                  && state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK) && state.context?.completedIterations < 2}
                startPositionX={(currentItem?.display?.length || 0) <= 2 ? 75 : 0}
              />
            </SpacingDrawingLinesFlex>
            <DraggableLetter
              canDragAndDrop={canDragAndDrop}
              handleSpacingOutcome={handleSpacingOutcome}
            />
          </FlexRowSpaceBetween>
        ) : null}
    </SpacingPracticeAreaWrapper>
  );
}
