import React from 'react';
import {
  Stage,
} from 'react-konva';
import { DrawingStroke } from './types';
import LineLayer from './LineLayer';
import { DifficultyLevel } from '../../data/types';

interface CompletedCharacterProps {
  width: number,
  height: number,
  strokes: DrawingStroke[],
  difficultyLevel: DifficultyLevel,
}

export default function CompletedCharacter({
  width, height, strokes, difficultyLevel,
}: CompletedCharacterProps) {
  return (
    <div style={{ position: 'absolute' }}>
      <Stage width={width} height={height} listening={false}>
        <LineLayer id="previous-iteration" strokes={strokes} difficultyLevel={difficultyLevel} strokeOpacity={1} />
      </Stage>
    </div>
  );
}
