import React from 'react';
import LottiePlayer from '../LottiePlayer';
import smallStar from '../../assets/lotties/small-star.json';
import smallStarStart from '../../assets/lotties/small-star-start.json';
import smallStarEnd from '../../assets/lotties/small-star-end.json';
import { AbsolutePositionWrapper } from '../../styles/components/PositionWrappers';
import { DRAWABLE_HEIGHT_NORMAL } from '../../shared/constants';

export enum TravelingStarPosition {
  START = 'START',
  MIDDLE = 'MIDDLE',
  END = 'END'
}

type TravelingStarTypes = {
  [key in TravelingStarPosition]: {
    lottie: any,
    width: number,
    yOffset: number,
  };
}

const travelingStarTypes: TravelingStarTypes = {
  [TravelingStarPosition.START]: {
    lottie: smallStarStart,
    width: 200,
    yOffset: DRAWABLE_HEIGHT_NORMAL - 30,
  },
  [TravelingStarPosition.MIDDLE]: {
    lottie: smallStar,
    width: 430,
    yOffset: DRAWABLE_HEIGHT_NORMAL - 55,
  },
  [TravelingStarPosition.END]: {
    lottie: smallStarEnd,
    width: 655,
    yOffset: DRAWABLE_HEIGHT_NORMAL - 55,
  },
};

interface TravelingStarAnimationProps {
  isVisible: boolean,
  handleComplete: () => void,
  position: TravelingStarPosition,
}

export default function TravelingStarAnimation({
  isVisible,
  handleComplete,
  position,
}: TravelingStarAnimationProps) {
  if (!isVisible) return null;

  return (
    <AbsolutePositionWrapper
      top={travelingStarTypes[position].yOffset}
      right={85}
    >
      <LottiePlayer
        data={travelingStarTypes[position].lottie}
        width={travelingStarTypes[position].width}
        shouldAutoplay
        speed={1.5}
        handleComplete={handleComplete}
      />
    </AbsolutePositionWrapper>
  );
}
