import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivitySetSelectionWrapper } from '../styles/components/ActivitySetSelection';
import ActivitySetSelectionButton from '../components/Buttons/ActivitySetSelectionButton';
import { ActivitySetType } from '../data/types/writing/activitySet';
import { Paths } from '../data/types';
import blueStar from '../assets/images/star-blue/blue-star.svg';
import yellowStar from '../assets/images/star-yellow/star-yellow.svg';
import getEnvironment from '../shared/getEnvironment';

export default function ActivitySetSelection() {
  const navigate = useNavigate();
  const environment = getEnvironment();
  return (
    <ActivitySetSelectionWrapper>
      <img src={blueStar} alt="" className="blue-star left" />
      <ActivitySetSelectionButton
        isDisabled={!['local', 'dev'].includes(environment)}
        buttonTouch={() => navigate(`/${Paths.PRE_WRITING}`)}
        activitySetType={ActivitySetType.PRE_WRITING}
      />
      <img src={blueStar} alt="" className="blue-star right" />
      <img src={blueStar} alt="" className="small-blue-star" />
      <img src={yellowStar} alt="" className="yellow-star left" />
      <ActivitySetSelectionButton
        isDisabled={false}
        buttonTouch={() => navigate(`/${Paths.WRITING}`)}
        activitySetType={ActivitySetType.WRITING}
      />
      <img src={yellowStar} alt="" className="yellow-star right" />
      <img src={yellowStar} alt="" className="small-yellow-star" />
    </ActivitySetSelectionWrapper>
  );
}
