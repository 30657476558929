import React from 'react';
import { HexagonButtonSizes } from '../SVG/types';
import { ButtonWithSvg } from '../../styles/components/Buttons';
import HexagonPlaceholder from '../SVG/HexagonPlaceholder';
import CharacterButtonBadgeImg from './CharacterButtonBadgeImg';

interface CharacterPlaceholderButtonProps {
  isDisabled?: boolean,
  size: HexagonButtonSizes,
  label: string,
  buttonTouch: (event: any) => void,
}

export default function CharacterPlaceholderButton({
  isDisabled, size, label, buttonTouch,
}: CharacterPlaceholderButtonProps) {
  return (
    <ButtonWithSvg
      disabled={isDisabled}
      aria-disabled={isDisabled}
      type="button"
      aria-label={label}
      onTouchStart={buttonTouch}
    >
      <HexagonPlaceholder size={size} />
      <CharacterButtonBadgeImg isAddable />
    </ButtonWithSvg>
  );
}

CharacterPlaceholderButton.defaultProps = {
  isDisabled: false,
};
