import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import rocket from '../../assets/images/rocket.png';
import SlideRightOutAnimation from '../../styles/components/SlideRightOutAnimation';

type RocketAnimationProps = {
  start: boolean,
  startPositionX: number,
}

export default function RocketAnimation({ start, startPositionX }: RocketAnimationProps) {
  const [show, setShow] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    setShow(start);
  }, [start]);

  return (
    <CSSTransition
      nodeRef={ref}
      in={show}
      timeout={1500}
      classNames="slide-right"
      onEntered={() => setShow(false)}
    >
      <SlideRightOutAnimation ref={ref} rightPosition={startPositionX}>
        <img src={rocket} alt="" width="75px" style={{ transform: 'rotate(90deg)' }} />
      </SlideRightOutAnimation>
    </CSSTransition>
  );
}
