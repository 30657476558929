import React, {
  createContext, useContext, useReducer, useMemo,
} from 'react';

type FeedbackState = {
  messages: string[],
}

export enum FeedbackActionType {
  ADD_MESSAGES = 'ADD_MESSAGES',
  RESET_MESSAGES = 'RESET_MESSAGE',
}

type FeedbackAction =
  { type: FeedbackActionType.ADD_MESSAGES, payload: string[] }
  | { type: FeedbackActionType.RESET_MESSAGES }

const initialState = {
  messages: [],
};

interface FeedbackContextInterface extends FeedbackState {
  dispatch: React.Dispatch<any>,
}

const FeedbackContext = createContext<FeedbackContextInterface>({ ...initialState, dispatch: () => {} });

type FeedbackContextProviderProps = {
  children: React.ReactNode,
}

export const reducer = (currentState: FeedbackState, action: FeedbackAction): FeedbackState => {
  switch (action.type) {
    case FeedbackActionType.ADD_MESSAGES:
      return {
        ...currentState,
        messages: [...currentState.messages, ...action.payload],
      };
    case FeedbackActionType.RESET_MESSAGES:
      return initialState;
    default:
      return currentState;
  }
};

export default function FeedbackContextProvider({ children }: FeedbackContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const providerValue = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return (
    <FeedbackContext.Provider value={providerValue}>
      {children}
    </FeedbackContext.Provider>
  );
}

export function useFeedbackContext() {
  const context = useContext(FeedbackContext);

  if (context === undefined) {
    throw new Error('useFeedbackContext must be used within FeedbackContextProvider');
  }

  return context;
}
