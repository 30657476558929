import React from 'react';
import { chooseSpacingScaffolds } from '../../assets/spacing-scaffolds';
import CardWrapper, { CardWrapperSize, CardWrapperType } from '../../styles/components/CardWrapper';
import greenCheck from '../../assets/images/checkmark-green-circle.svg';
import redX from '../../assets/images/x-mark-red-circle.svg';
import { OptionType, SpacingItem } from '../../data/types';

type SpacingCardButtonProps = {
  type: CardWrapperType.PASS | CardWrapperType.FAIL,
  spacingItem: SpacingItem | null,
  optionType: OptionType
  onTouch: () => void,
  isDisabled: boolean,
  isSelected: boolean,
}

type SpacingCardButtonTypes = {
   [key in CardWrapperType.PASS | CardWrapperType.FAIL]: {
    marker: string,
    alt: string,
   };
}

const spacingCardButtonTypes: SpacingCardButtonTypes = {
  [CardWrapperType.PASS]: {
    marker: greenCheck,
    alt: 'correct spacing',
  },
  [CardWrapperType.FAIL]: {
    marker: redX,
    alt: 'incorrect spacing',
  },
};

export default function SpacingCardButton({
  type,
  spacingItem,
  optionType,
  onTouch,
  isDisabled,
  isSelected,
}: SpacingCardButtonProps) {
  const handleCardSelection = () => {
    if (isDisabled) return;
    onTouch();
  };

  if (spacingItem) {
    return (
      <CardWrapper
        type={type}
        size={CardWrapperSize.MD}
        className="practice-card"
        isSelected={isSelected}
      >
        <button
          type="button"
          disabled={isDisabled}
          aria-disabled={isDisabled}
          onTouchStart={() => handleCardSelection()}
        >
          <div className="lines">
            <div className="content">
              <img
                src={chooseSpacingScaffolds[spacingItem.type][optionType] || ''}
                alt=""
                height={spacingItem?.positioning.multi.height || 0}
                style={{ position: 'absolute', top: `${spacingItem?.positioning.multi.yOffset || 0}px` }}
              />
            </div>
            {isSelected && (
              <img
                src={spacingCardButtonTypes[type].marker}
                alt={spacingCardButtonTypes[type].alt}
                className="selected-marker"
              />
            )}
          </div>
        </button>
      </CardWrapper>
    );
  }

  return null;
}
