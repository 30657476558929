import React from 'react';
import { useTheme } from 'styled-components';
import HexagonWithBorderSvg from '../SVG/HexagonWithBorderSvg';
import HexagonWithDoubleBorderSvg from '../SVG/HexagonWithDoubleBorderSvg';
import CharacterButtonBadgeImg from './CharacterButtonBadgeImg';
import { ButtonWithSvg } from '../../styles/components/Buttons';
import { HexagonButtonSizes } from '../SVG/types';

type SpacingItemButtonProps = {
  displayLabel: string,
  isDisabled?: boolean,
  isCurrentItem?: boolean,
  isItemInCurrentSequence?: boolean,
  isItemInUnlockedSet?: boolean,
  size?: HexagonButtonSizes,
  buttonTouch: () => void,
}

export default function SpacingItemButton({
  displayLabel,
  isDisabled,
  isCurrentItem,
  isItemInCurrentSequence,
  isItemInUnlockedSet,
  size,
  buttonTouch,
}: SpacingItemButtonProps) {
  const theme = useTheme();

  const getHexagonButtonColorsKey = () => {
    if (isCurrentItem || isItemInCurrentSequence
      || (isItemInUnlockedSet && !isDisabled)) return 'selected';
    if (isDisabled && isItemInUnlockedSet) return 'disabled';
    return 'spacingDefault';
  };

  const shouldShowDoubleBorder = size !== HexagonButtonSizes.SM
    && (isCurrentItem || isItemInCurrentSequence || isItemInUnlockedSet);

  return (
    <ButtonWithSvg
      disabled={isDisabled}
      aria-disabled={isDisabled}
      type="button"
      aria-label={`start working on spacing for ${displayLabel}`}
      className={isCurrentItem ? 'bounce' : ''}
      onTouchStart={buttonTouch}
    >
      {shouldShowDoubleBorder
        ? (
          <HexagonWithDoubleBorderSvg
            colors={theme.colors.svgs.hexagonButton[getHexagonButtonColorsKey()]}
            size={HexagonButtonSizes.LG}
          />
        ) : (
          <HexagonWithBorderSvg
            colors={theme.colors.svgs.hexagonButton[getHexagonButtonColorsKey()]}
            size={HexagonButtonSizes.LG}
          />
        )}

      <CharacterButtonBadgeImg
        isNext={isCurrentItem}
        isUnlockedAfterSequence={isItemInUnlockedSet && !isItemInCurrentSequence && isDisabled}
        isDeleteable={false}
      />

      <span
        style={{
          color: theme.colors.svgs.hexagonButton[getHexagonButtonColorsKey()].text,
        }}
      >
        {displayLabel}
      </span>
    </ButtonWithSvg>
  );
}

SpacingItemButton.defaultProps = {
  isDisabled: false,
  isCurrentItem: false,
  isItemInCurrentSequence: false,
  isItemInUnlockedSet: false,
  size: HexagonButtonSizes.MD,
};
