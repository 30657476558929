import styled from 'styled-components';
import {
  FlexColumnCenter100Percent, FlexRowCenter, FlexColumnCenterTop, FlexColumnCenter,
} from './FlexSpacers';

export const RotateScreenContainer = styled(FlexRowCenter)`
@media screen and (orientation: landscape) {
  display: none;
}

@media screen and (orientation: portrait) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.black};
  z-index: 600;

  img {
    max-width: 346px;
    height: auto;
  }
}
`;

export const AccessFormContainer = styled(FlexColumnCenter100Percent)`
  font-family: ${(props) => props.theme.fonts.families.primary};

  form {
    display: flex;
    align-items: center;
    line-height: 1.5;

    label {
      font-size: ${(props) => props.theme.fonts.sizes.display};
      margin-right: 0.15em;
    }

    input {
      font-size: ${(props) => props.theme.fonts.sizes.display};
      width: 150px;
      height: auto;
      line-height: 1.6;
      text-indent: 0.2em;
    }
  }

  button {
    margin-top: ${(props) => props.theme.fonts.sizes.xxxl};
  }
`;

export const SelectCharactersContainer = styled(FlexColumnCenter)`
  width: 100%;
  height: 100%;

  > h1 {
    font-family: ${(props) => props.theme.fonts.families.primary};
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    line-height: 110%;
    font-size: ${(props) => props.theme.fonts.sizes.xxl};
    margin-top: 0;
  }
`;

export const SelectSequenceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 20px;
  row-gap: 21.6px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 40px 82px 64px 82px;

  .grip-button,
  .sequence-button {
    grid-column: span 2;
  }
`;

export const SelectCustomSetContainer = styled(FlexColumnCenterTop)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1em;

  h1 {
    font-family: ${(props) => props.theme.fonts.families.primary};
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    line-height: 110%;
    font-size: ${(props) => props.theme.fonts.sizes.xxl};
    margin-top: 0;
  }

  p {
    font-family: ${(props) => props.theme.fonts.families.primary};
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    line-height: 110%;
    font-size: ${(props) => props.theme.fonts.sizes.md};
    margin-top: 0;
  }

  .text-wrapper {
    box-sizing: border-box;
    padding: 0 2.5em 0.5em;
  }

  .selection-wrapper {
    box-sizing: border-box;
    margin: 1em 0;
    padding: 0.8em 2em;

    button:not(:last-child), svg.placeholder {
      margin-right: 1rem;
    }
  }
`;

export const ContainerWithButtonContainer = styled(FlexColumnCenter100Percent)`
  .button-container {
    align-self: end;
    -webkit-align-self: flex-end; /* Older versions of Safari */
    padding: 1em 2em;
  }
`;

export const StarGameContainer = styled(FlexColumnCenterTop)`
  /* Do not add position rules or change width/height without
  checking how the change may affect the animated stars in the star game. */
  width: 100%;
  height: 100%;
  margin-top: 45px;

  .animated-star-container {
    z-index: 5;
    position: absolute;
  }

  .star-timer {
    display: flex;
    margin-top: 20px;
  }
`;
