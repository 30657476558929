export enum Paths {
  EDUCATOR_RESOURCES = 'resources',
  ACTIVITY_SET_SELECTION = 'activity-set-selection',
  ABOUT = 'info',
  LANDING = '',
  WRITING = 'writing',
  PRE_WRITING = 'pre-writing',
  PRE_WRITING_HANDWRITING = 'pre-writing-handwriting',
  TOS = 'terms-of-use',
  PRIVACY = 'privacy-policy',
  DIRECT_NOTICE = 'direct-notice',
}
