import React from 'react';
import NoInternetBWSvg from './NoInternetBWSvg';

export default function NoInternetPersistent() {
  return (
    <div
      className="no-internet-container"
      role="alert"
      aria-live="polite"
      aria-label="no internet connection"
    >
      <NoInternetBWSvg />
    </div>

  );
}
