import React, { useState, useEffect, useMemo } from 'react';
import { useSpacingGameContext } from '../../context/SpacingGameContext';
import {
  SpacingActivityCategory, SpacingFeedbackType, MultiChoiceOption, OptionType, SpacingActivityType,
} from '../../data/types';
import {
  SpacingActionOutcome,
  SpacingEventTypes,
  SpacingState,
} from '../../stateMachines/spacingMachine/spacingMachine';
import SpacingCardButton from '../Buttons/SpacingCardButton';
import { useAudioContext } from '../../context/AudioContext';
import spacingActivityIntros from '../../assets/audio/spacingActivityIntros';
import spacingActivitySuccess from '../../assets/audio/spacingActivitySuccess';
import { multiChoiceSpacingErrors } from '../../assets/audio/activityErrors';
import { ChooseSpacingAreaWrapper } from '../../styles/components/ContentWrappers';
import { CardWrapperType } from '../../styles/components/CardWrapper';
import { multiChoiceOptions } from '../../data/spacing/spacing-choose-options';
import { spacingItems } from '../../data/spacing/spacing-items';
import SpacingStarAnimation from '../Animations/SpacingStarAnimation';
import deepCopy from '../../shared/deepCopy';
import shuffle from '../../shared/shuffle';

export type MultiChoiceActivity = SpacingActivityType.MULTI_CHOICE_CLOSE
  | SpacingActivityType.MULTI_CHOICE_WIDE
  | SpacingActivityType.MULTI_CHOICE_ALL;

export const chooseSpacingFeedback: {
  [key in OptionType]: SpacingFeedbackType
} = {
  TOO_CLOSE: SpacingFeedbackType.TOO_CLOSE,
  TOO_WIDE: SpacingFeedbackType.TOO_WIDE,
  CORRECT: SpacingFeedbackType.CORRECT,
};

export default function SpacingMCActivity() {
  const { state, send } = useSpacingGameContext();
  const { currentActivity, currentItem } = state.context;
  const audioContext = useAudioContext();
  const [selected, setSelected] = useState<MultiChoiceOption | null>(null);
  const [activeStarAnimationIndex, setActiveStarAnimationIndex] = useState<number>(-1);

  const options = useMemo(
    () => ((currentActivity && [
      SpacingActivityType.MULTI_CHOICE_CLOSE,
      SpacingActivityType.MULTI_CHOICE_WIDE,
      SpacingActivityType.MULTI_CHOICE_ALL].includes(currentActivity.type))
      ? shuffle(multiChoiceOptions[currentActivity?.type as MultiChoiceActivity], deepCopy)
      : []),
    [currentActivity?.type],
  );

  const playFeedbackAnimation = () => {
    const selectedCardIndex = options
      .findIndex((o: MultiChoiceOption) => o.optionType === selected?.optionType);
    setActiveStarAnimationIndex(selectedCardIndex);
  };

  const handleErrorFeedbackAnimationComplete = () => {
    // if the student chooses a card showing incorrect spacing (too_close or too_wide)
    // we play the success animation after the error feedback animation
    if (selected?.spacingActionOutcome === SpacingActionOutcome.FAIL) {
      const correctCardIndex = options
        .findIndex((option) => option.spacingActionOutcome === SpacingActionOutcome.PASS);
      setActiveStarAnimationIndex(correctCardIndex);
    }
  };

  useEffect(() => {
    if (!state.context || !state.context.currentItem) return;
    // eslint-disable-next-line default-case
    switch (state.value) {
      case SpacingState.CUEING_ACTION:
        audioContext?.handlePlay({
          src: spacingActivityIntros[SpacingActivityCategory.MULTI_CHOICE],
          onEnd: () => send(SpacingEventTypes.NEXT),
        });
        break;
      case SpacingState.SHOWING_ERROR_FEEDBACK:
        if (selected) {
          audioContext?.handlePlay({
            src: multiChoiceSpacingErrors[selected.optionType],
          });
        }
        playFeedbackAnimation();
        break;
      case SpacingState.SHOWING_SUCCESS_FEEDBACK:
        audioContext?.handlePlay({
          src: spacingActivitySuccess[SpacingActivityCategory.MULTI_CHOICE],
        });
        playFeedbackAnimation();
        break;
    }
  }, [state.value]);

  useEffect(() => {
    setSelected(null);
    setActiveStarAnimationIndex(-1);
  }, [currentActivity?.type]);

  if (currentActivity?.category !== SpacingActivityCategory.MULTI_CHOICE || !currentItem) {
    return null;
  }

  const handleSelection = (
    key: OptionType,
    spacingActionOutcome: SpacingActionOutcome,
  ): void => {
    const selectedIndex = options.findIndex((option) => option.optionType === key);
    options[selectedIndex].isSelected = true;
    setSelected(options[selectedIndex]);
    send({
      type: SpacingEventTypes.ACTION_COMPLETED,
      payload: { spacingOutcome: spacingActionOutcome },
      activityProgress: { outcome: spacingActionOutcome },
    });
  };

  const getSpacingCardButtonType = (spacingActionOutcome: SpacingActionOutcome) => (
    spacingActionOutcome === SpacingActionOutcome.PASS ? CardWrapperType.PASS : CardWrapperType.FAIL);

  const getSpacingCardButtons = (optionsIn: MultiChoiceOption[]) => (
    <>
      { optionsIn.map((option: MultiChoiceOption, index: number) => {
        const {
          spacingActionOutcome, optionType, isSelected,
        } = option;

        return (
          <div className="practice-card-wrapper" key={`${optionType}-wrap`}>
            <SpacingCardButton
              spacingItem={currentItem}
              optionType={optionType}
              type={getSpacingCardButtonType(spacingActionOutcome)}
              onTouch={() => handleSelection(optionType, spacingActionOutcome)}
              isDisabled={!!selected}
              isSelected={isSelected}
            />
            <SpacingStarAnimation
              spacingFeedbackType={chooseSpacingFeedback[optionType]}
              right={spacingItems[currentItem.type].positioning
                .chooseStar[chooseSpacingFeedback[optionType]].yOffset ?? 0}
              isTall={index === 0 && optionsIn.length === 3}
              isVisible={index === activeStarAnimationIndex}
              handleComplete={() => { handleErrorFeedbackAnimationComplete(); }}
            />
          </div>
        );
      })}
    </>
  );

  switch (currentActivity?.type) {
    case (SpacingActivityType.MULTI_CHOICE_CLOSE):
    case (SpacingActivityType.MULTI_CHOICE_WIDE):
      return (
        <ChooseSpacingAreaWrapper>
          {getSpacingCardButtons(options)}
        </ChooseSpacingAreaWrapper>
      );
    case (SpacingActivityType.MULTI_CHOICE_ALL):
      return (
        <ChooseSpacingAreaWrapper hasRows>
          {getSpacingCardButtons(options)}
          <div className="spacer" />
        </ChooseSpacingAreaWrapper>
      );
    default:
      return null;
  }
}
