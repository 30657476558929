import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { useTheme } from 'styled-components';
import {
  ModalWrapper, ModalBackdrop, ModalBody, ModalCloseButton, ModalContent,
} from '../styles/components/Modals';
import close from '../assets/images/close.svg';

interface ModalProps {
  title: string,
  isShown: boolean,
  onClose: () => void,
  width?: string|undefined,
  height?: string|undefined,
  columnWidth?: string|undefined,
  children: React.ReactNode,
}

export default function Modal({
  title, isShown, onClose, children, width, height, columnWidth,
}: ModalProps) {
  const theme = useTheme();
  const modal = (
    <>
      <ModalBackdrop />
      <FocusLock>
        <ModalWrapper
          aria-modal
          aria-label={title}
          tabIndex={-1}
          role="dialog"
          columnWidth={columnWidth}
        >
          <ModalBody width={width} height={height}>
            <ModalContent>
              {children}
            </ModalContent>
          </ModalBody>
          <ModalCloseButton
            type="button"
            aria-label="Close"
            onClick={onClose}
            colors={theme.colors.buttons.access.active}
            width="34px"
            height="34px"
          >
            <img src={close} alt="" />
          </ModalCloseButton>
        </ModalWrapper>
      </FocusLock>
    </>
  );

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isShown) {
      onClose();
    }
  };

  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [isShown]);

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
}

Modal.defaultProps = {
  width: undefined,
  height: undefined,
  columnWidth: undefined,
};
