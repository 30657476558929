import React, { useEffect, useState } from 'react';
import {
  PreWritingHandwritingActivityCircleShape,
  PreWritingHandwritingActivityCircleShapeWrapper,
} from '../../styles/components/PreWritingWrappers';
import { DrawingArea } from '../../styles/components/DrawingArea';
import PreWritingHandwritingArea from '../Konva/PreWritingHandwritingArea';
import { ShapeType } from '../../data/types/preWriting/shapes';
import LottiePlayer from '../LottiePlayer';
import redPlanetLottie from '../../assets/lotties/PreWriting/PW2/redplanet.json';
import bluePlanetLottie from '../../assets/lotties/PreWriting/PW2/blueplanet.json';
import yellowPlanetLottie from '../../assets/lotties/PreWriting/PW2/yellowplanet.json';
import circleForwardsLottie from '../../assets/lotties/PreWriting/PW2/CircleForward.json';
import circleBackwardsLottie from '../../assets/lotties/PreWriting/PW2/CircleBackwards.json';
import circle1 from '../../assets/images/pre-writing/pw-2/PW2-circle1.svg';
import circle2 from '../../assets/images/pre-writing/pw-2/PW2-circle2.svg';
import circle3 from '../../assets/images/pre-writing/pw-2/PW2-circle3.svg';
import plink from '../../assets/audio/activitySuccess/plink.mp3';
import errorVO from '../../assets/audio/preWriting/PW2/VO103V2.m4a';
import circleBackwardsIntroVO from '../../assets/audio/preWriting/PW2/VO104.m4a';
import nowItsYourTurnVO from '../../assets/audio/preWriting/PW2/VO105.m4a';
import youMadeACircleVO from '../../assets/audio/preWriting/PW2/VO106.m4a';
import itsAPlanetVO from '../../assets/audio/preWriting/PW2/VO107.m4a';
import makeAnotherCircleVO from '../../assets/audio/preWriting/PW2/VO108.m4a';
import lookAtAllThePlanetsVO from '../../assets/audio/preWriting/PW2/VO109.m4a';
import { useAudioContext } from '../../context/AudioContext';
import { ACCEPTABLE_ERROR_OFFSET } from '../../shared/constants';

type CircleShapeProps = {
  onFinish: () => void,
}

export function CircleShape({
  onFinish,
}: CircleShapeProps) {
  const [circleAreaHeight, setCircleAreaHeight] = useState<number>(0);
  const [circleAreaWidth, setCircleAreaWidth] = useState<number>(0);
  const [finishedIteration, setFinishedIteration] = useState(false);
  const [iterationCount, setIterationCount] = useState(1);
  const [isStrokeCorrect, setIsStrokeCorrect] = useState(false);
  const [isPlayingMedia, setIsPlayingMedia] = useState<boolean>(true);
  const audioContext = useAudioContext();
  const [displayNextIteration, setDisplayNextIteration] = useState<boolean>(true);

  useEffect(() => {
    const circleArea = document.getElementById('PreWritingCircleShape');
    setCircleAreaHeight(circleArea?.clientHeight || 0);
    setCircleAreaWidth(circleArea?.clientWidth || 0);
  }, []);

  useEffect(() => {
    if (iterationCount === 1) {
      setDisplayNextIteration(true);
      setIsPlayingMedia(true);
      const playNextAudio = () => {
        audioContext?.handlePlay({ src: nowItsYourTurnVO, onEnd: () => setIsPlayingMedia(false) });
      };
      const playCircleMadeVO = () => {
        audioContext?.handlePlay({
          src: circleBackwardsIntroVO,
          onEnd: () => playNextAudio(),
        });
      };
      playCircleMadeVO();
    } else if (iterationCount > 1 || iterationCount < 4) {
      setIsPlayingMedia(true);
      const playThirdAudio = () => {
        audioContext?.handlePlay({
          src: makeAnotherCircleVO,
          onEnd: () => {
            setIsPlayingMedia(false);
            setDisplayNextIteration(true);
          },
        });
      };
      const playSecondAudio = () => {
        audioContext?.handlePlay({
          src: itsAPlanetVO,
          onEnd: () => playThirdAudio(),
        });
      };
      const playCircleBackwardsVO = () => {
        audioContext?.handlePlay({
          src: youMadeACircleVO,
          onEnd: () => playSecondAudio(),
        });
      };
      const playFeedbackVO = () => {
        audioContext?.handlePlay({
          src: isStrokeCorrect ? plink : errorVO,
          onEnd: () => (isStrokeCorrect ? playCircleBackwardsVO() : playSecondAudio()),
        });
      };
      playFeedbackVO();
    }
    // When iterationCount reaches 4, it means the user has already gone through all 3 planets
    // therefore they can now move on to the plus shape.
    if (iterationCount === 4) {
      const playFinalVO = () => {
        audioContext?.handlePlay({ src: lookAtAllThePlanetsVO });
      };
      const playFeedbackVO = () => {
        audioContext?.handlePlay({
          src: isStrokeCorrect ? plink : errorVO,
          onEnd: () => playFinalVO(),
        });
      };
      playFeedbackVO();
      onFinish();
    }
  }, [iterationCount]);

  const handleFinishCircle = () => {
    setFinishedIteration(true);
    setDisplayNextIteration(false);
    setIterationCount(iterationCount + 1);
  };

  const handleIsStrokeCorrect = (isValid: boolean) => {
    setIsStrokeCorrect(isValid);
  };

  // Shapes get displayed dinamically according to the iterationCount number.
  // The final status (4) is where all 3 planets are being displayed.
  const handleShapesToDisplay = () => {
    switch (iterationCount) {
      case 1:
        return (
          finishedIteration
            ? (
              <div style={{
                marginTop: '-50px', marginLeft: '13px', zIndex: 9,
              }}
              >
                <LottiePlayer
                  data={redPlanetLottie}
                  shouldLoop
                  width="60%"
                  shouldAutoplay
                />
              </div>
            )
            : (
              <>
                <div style={{
                  marginTop: '-50px', marginLeft: '13px', zIndex: 9,
                }}
                >
                  <LottiePlayer
                    data={circleBackwardsLottie}
                    width="75%"
                    shouldAutoplay
                    speed={0.6}
                  />
                </div>
                { displayNextIteration
                && (
                <PreWritingHandwritingActivityCircleShape url={circle1} iterationCount={iterationCount}>
                  <DrawingArea id="PreWritingCircleShape" className="drawing-area-circle">
                    <PreWritingHandwritingArea
                      shape={ShapeType.CIRCLE}
                      width={circleAreaWidth + ACCEPTABLE_ERROR_OFFSET}
                      height={circleAreaHeight + ACCEPTABLE_ERROR_OFFSET}
                      borderRadius="50%"
                      onFinishDrawing={handleFinishCircle}
                      canWrite={!isPlayingMedia}
                      isStrokeCorrect={handleIsStrokeCorrect}
                    />
                  </DrawingArea>
                </PreWritingHandwritingActivityCircleShape>
                )}
              </>
            )
        );
      case 2:
        return (
          <>
            <div style={{
              marginTop: '-25px', marginLeft: '15px', zIndex: 9,
            }}
            >
              <LottiePlayer
                data={redPlanetLottie}
                shouldLoop
                width="200px"
                shouldAutoplay
              />
            </div>
            { displayNextIteration
            && (
              <>
                <div style={{
                  marginTop: '200px', marginLeft: '25px', zIndex: 9,
                }}
                >
                  <LottiePlayer
                    data={circleForwardsLottie}
                    width="340px"
                    shouldAutoplay
                  />
                </div>
                <PreWritingHandwritingActivityCircleShape url={circle2} iterationCount={iterationCount}>
                  <DrawingArea id="PreWritingCircleShape" className="drawing-area-circle">
                    <PreWritingHandwritingArea
                      shape={ShapeType.CIRCLE}
                      width={circleAreaWidth + ACCEPTABLE_ERROR_OFFSET}
                      height={circleAreaHeight + ACCEPTABLE_ERROR_OFFSET}
                      borderRadius="50%"
                      onFinishDrawing={handleFinishCircle}
                      canWrite={!isPlayingMedia}
                      isStrokeCorrect={handleIsStrokeCorrect}
                    />
                  </DrawingArea>
                </PreWritingHandwritingActivityCircleShape>
              </>
            )}
          </>
        );
      case 3:
        return (
          <>
            <div style={{
              marginTop: '-25px', marginLeft: '15px', zIndex: 9,
            }}
            >
              <LottiePlayer
                data={redPlanetLottie}
                shouldLoop
                width="200px"
                shouldAutoplay
              />
            </div>
            <div style={{
              marginTop: '200px', marginLeft: '-70px', zIndex: 9,
            }}
            >
              <LottiePlayer
                data={yellowPlanetLottie}
                shouldLoop
                width="500px"
                shouldAutoplay
              />
            </div>
            {displayNextIteration
            && (
              <>
                <div style={{
                  marginTop: '-40px', marginLeft: '-100px', zIndex: 9,
                }}
                >
                  <LottiePlayer
                    data={circleBackwardsLottie}
                    width="330px"
                    shouldAutoplay
                  />
                </div>
                <PreWritingHandwritingActivityCircleShape url={circle3} iterationCount={iterationCount}>
                  <DrawingArea id="PreWritingCircleShape" className="drawing-area-circle">
                    <PreWritingHandwritingArea
                      shape={ShapeType.CIRCLE}
                      width={circleAreaWidth + ACCEPTABLE_ERROR_OFFSET}
                      height={circleAreaHeight + ACCEPTABLE_ERROR_OFFSET}
                      borderRadius="50%"
                      onFinishDrawing={handleFinishCircle}
                      canWrite={!isPlayingMedia}
                      isStrokeCorrect={handleIsStrokeCorrect}
                    />
                  </DrawingArea>
                </PreWritingHandwritingActivityCircleShape>
              </>
            )}
          </>
        );
      case 4:
        return (
          <>
            <div style={{
              marginTop: '-25px', marginLeft: '15px', zIndex: 9,
            }}
            >
              <LottiePlayer
                data={redPlanetLottie}
                shouldLoop
                width="200px"
                shouldAutoplay
              />
            </div>
            <div style={{
              marginTop: '200px', marginLeft: '-70px', zIndex: 9,
            }}
            >
              <LottiePlayer
                data={yellowPlanetLottie}
                shouldLoop
                width="500px"
                shouldAutoplay
              />
            </div>
            <div style={{
              marginTop: '-40px', marginLeft: '-100px', zIndex: 9,
            }}
            >
              <LottiePlayer
                data={bluePlanetLottie}
                shouldLoop
                width="250px"
                shouldAutoplay
              />
            </div>
          </>
        );
      default:
        return 'An issue has been encountered. Please contact support.';
    }
  };

  return (
    <PreWritingHandwritingActivityCircleShapeWrapper>
      { handleShapesToDisplay() }
    </PreWritingHandwritingActivityCircleShapeWrapper>
  );
}
