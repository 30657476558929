import React, { useState } from 'react';
import { AccessFormContainer } from '../styles/components/ScreenContainers';
import { TextButton } from '../styles/components/Buttons';
import TeacherConfirmButton from './Buttons/TeacherConfirmButton';

type GrantAccessProps = {
  buttonTouch: () => void,
  buttonText: string,
  onCancel?: () => void,
}

const mathProblems: { question: string, answer: string }[] = [
  // \u00D7 = multiplication symbol
  { question: '3 \u00D7 3', answer: '9' },
  { question: '4 \u00D7 5', answer: '20' },
  { question: '2 \u00D7 25', answer: '50' },
  { question: '6 \u00D7 10', answer: '60' },
  // \u00F7 = divide symbol
  { question: '0 \u00F7 3', answer: '0' },
  { question: '10 \u00F7 5', answer: '2' },
  { question: '6 \u00F7 2', answer: '3' },
  { question: '16 \u00F7 4', answer: '4' },
  { question: '25 \u00F7 5', answer: '5' },
];

function getRandomMathProblem() {
  const min = 0;
  const max = mathProblems.length;
  const randomIndex = Math.floor(Math.random() * (max - min) + min);
  return mathProblems[randomIndex];
}

export default function GrantAccess({ buttonTouch, buttonText, onCancel }: GrantAccessProps) {
  const [inputValue, setInputValue] = useState('');
  const [hasCorrectAnswer, setHasCorrectAnswer] = useState(false);
  const [mathProblem] = useState(getRandomMathProblem());

  const handleChange = (value: string) => {
    setInputValue(value);
    if (value === mathProblem.answer) {
      setHasCorrectAnswer(true);
    }
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    if (hasCorrectAnswer) buttonTouch();
  };

  return (
    <>
      <p>
        Child lock is enabled, please answer the following
        <br />
        math problem to continue:
      </p>
      <AccessFormContainer>
        <form onSubmit={submitForm}>
          <label htmlFor="answer">{`${mathProblem.question} =`}</label>
          <input
            id="answer"
            type="text"
            value={inputValue}
            onChange={(event) => handleChange(event.target.value)}
          />
        </form>
        <TeacherConfirmButton
          isDisabled={!hasCorrectAnswer}
          buttonTouch={buttonTouch}
          buttonText={buttonText}
        />
        {onCancel ? <TextButton onClick={onCancel}>Cancel</TextButton> : null}
      </AccessFormContainer>
    </>
  );
}

GrantAccess.defaultProps = {
  onCancel: null,
};
