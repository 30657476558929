import lowerASolid from './NORMAL/SOLID/LOWER_A.svg';
import lowerAFaded from './NORMAL/FADED/LOWER_A.svg';
import lowerADotted from './NORMAL/DOTTED/LOWER_A.svg';
import lowerCSolid from './NORMAL/SOLID/LOWER_C.svg';
import lowerCFaded from './NORMAL/FADED/LOWER_C.svg';
import lowerCDotted from './NORMAL/DOTTED/LOWER_C.svg';
import lowerDSolid from './NORMAL/SOLID/LOWER_D.svg';
import lowerDFaded from './NORMAL/FADED/LOWER_D.svg';
import lowerDDotted from './NORMAL/DOTTED/LOWER_D.svg';
import lowerESolid from './NORMAL/SOLID/LOWER_E.svg';
import lowerEDotted from './NORMAL/DOTTED/LOWER_E.svg';
import lowerFSolid from './NORMAL/SOLID/LOWER_F.svg';
import lowerFFaded from './NORMAL/FADED/LOWER_F.svg';
import lowerFDotted from './NORMAL/DOTTED/LOWER_F.svg';
import lowerEFaded from './NORMAL/FADED/LOWER_E.svg';
import lowerGSolid from './NORMAL/SOLID/LOWER_G.svg';
import lowerGFaded from './NORMAL/FADED/LOWER_G.svg';
import lowerGDotted from './NORMAL/DOTTED/LOWER_G.svg';
import lowerISolid from './NORMAL/SOLID/LOWER_I.svg';
import lowerIFaded from './NORMAL/FADED/LOWER_I.svg';
import lowerIDotted from './NORMAL/DOTTED/LOWER_I.svg';
import lowerJSolid from './NORMAL/SOLID/LOWER_J.svg';
import lowerJFaded from './NORMAL/FADED/LOWER_J.svg';
import lowerJDotted from './NORMAL/DOTTED/LOWER_J.svg';
import lowerMSolid from './NORMAL/SOLID/LOWER_M.svg';
import lowerMFaded from './NORMAL/FADED/LOWER_M.svg';
import lowerMDotted from './NORMAL/DOTTED/LOWER_M.svg';
import lowerNSolid from './NORMAL/SOLID/LOWER_N.svg';
import lowerNFaded from './NORMAL/FADED/LOWER_N.svg';
import lowerNDotted from './NORMAL/DOTTED/LOWER_N.svg';
import lowerSSolid from './NORMAL/SOLID/LOWER_S.svg';
import lowerSFaded from './NORMAL/FADED/LOWER_S.svg';
import lowerSDotted from './NORMAL/DOTTED/LOWER_S.svg';
import lowerOSolid from './NORMAL/SOLID/LOWER_O.svg';
import lowerOFaded from './NORMAL/FADED/LOWER_O.svg';
import lowerODotted from './NORMAL/DOTTED/LOWER_O.svg';
import lowerPSolid from './NORMAL/SOLID/LOWER_P.svg';
import lowerPFaded from './NORMAL/FADED/LOWER_P.svg';
import lowerPDotted from './NORMAL/DOTTED/LOWER_P.svg';
import lowerQSolid from './NORMAL/SOLID/LOWER_Q.svg';
import lowerQFaded from './NORMAL/FADED/LOWER_Q.svg';
import lowerQDotted from './NORMAL/DOTTED/LOWER_Q.svg';
import lowerRSolid from './NORMAL/SOLID/LOWER_R.svg';
import lowerRFaded from './NORMAL/FADED/LOWER_R.svg';
import lowerRDotted from './NORMAL/DOTTED/LOWER_R.svg';
import lowerUSolid from './NORMAL/SOLID/LOWER_U.svg';
import lowerUFaded from './NORMAL/FADED/LOWER_U.svg';
import lowerUDotted from './NORMAL/DOTTED/LOWER_U.svg';
import lowerVSolid from './NORMAL/SOLID/LOWER_V.svg';
import lowerVFaded from './NORMAL/FADED/LOWER_V.svg';
import lowerVDotted from './NORMAL/DOTTED/LOWER_V.svg';
import lowerWSolid from './NORMAL/SOLID/LOWER_W.svg';
import lowerWFaded from './NORMAL/FADED/LOWER_W.svg';
import lowerWDotted from './NORMAL/DOTTED/LOWER_W.svg';
import lowerLSolid from './NORMAL/SOLID/LOWER_L.svg';
import lowerLFaded from './NORMAL/FADED/LOWER_L.svg';
import lowerLDotted from './NORMAL/DOTTED/LOWER_L.svg';
import lowerTSolid from './NORMAL/SOLID/LOWER_T.svg';
import lowerTFaded from './NORMAL/FADED/LOWER_T.svg';
import lowerTDotted from './NORMAL/DOTTED/LOWER_T.svg';
import lowerHSolid from './NORMAL/SOLID/LOWER_H.svg';
import lowerHFaded from './NORMAL/FADED/LOWER_H.svg';
import lowerHDotted from './NORMAL/DOTTED/LOWER_H.svg';
import lowerBSolid from './NORMAL/SOLID/LOWER_B.svg';
import lowerBFaded from './NORMAL/FADED/LOWER_B.svg';
import lowerBDotted from './NORMAL/DOTTED/LOWER_B.svg';
import lowerKSolid from './NORMAL/SOLID/LOWER_K.svg';
import lowerKFaded from './NORMAL/FADED/LOWER_K.svg';
import lowerKDotted from './NORMAL/DOTTED/LOWER_K.svg';
import lowerXSolid from './NORMAL/SOLID/LOWER_X.svg';
import lowerXFaded from './NORMAL/FADED/LOWER_X.svg';
import lowerXDotted from './NORMAL/DOTTED/LOWER_X.svg';
import lowerYSolid from './NORMAL/SOLID/LOWER_Y.svg';
import lowerYFaded from './NORMAL/FADED/LOWER_Y.svg';
import lowerYDotted from './NORMAL/DOTTED/LOWER_Y.svg';
import lowerZSolid from './NORMAL/SOLID/LOWER_Z.svg';
import lowerZFaded from './NORMAL/FADED/LOWER_Z.svg';
import lowerZDotted from './NORMAL/DOTTED/LOWER_Z.svg';
import upperASolid from './NORMAL/SOLID/UPPER_A.svg';
import upperAFaded from './NORMAL/FADED/UPPER_A.svg';
import upperADotted from './NORMAL/DOTTED/UPPER_A.svg';
import upperCSolid from './NORMAL/SOLID/UPPER_C.svg';
import upperCFaded from './NORMAL/FADED/UPPER_C.svg';
import upperCDotted from './NORMAL/DOTTED/UPPER_C.svg';
import upperESolid from './NORMAL/SOLID/UPPER_E.svg';
import upperEFaded from './NORMAL/FADED/UPPER_E.svg';
import upperEDotted from './NORMAL/DOTTED/UPPER_E.svg';
import upperFSolid from './NORMAL/SOLID/UPPER_F.svg';
import upperFFaded from './NORMAL/FADED/UPPER_F.svg';
import upperFDotted from './NORMAL/DOTTED/UPPER_F.svg';
import upperGSolid from './NORMAL/SOLID/UPPER_G.svg';
import upperGFaded from './NORMAL/FADED/UPPER_G.svg';
import upperGDotted from './NORMAL/DOTTED/UPPER_G.svg';
import upperHSolid from './NORMAL/SOLID/UPPER_H.svg';
import upperHFaded from './NORMAL/FADED/UPPER_H.svg';
import upperHDotted from './NORMAL/DOTTED/UPPER_H.svg';
import upperISolid from './NORMAL/SOLID/UPPER_I.svg';
import upperIFaded from './NORMAL/FADED/UPPER_I.svg';
import upperIDotted from './NORMAL/DOTTED/UPPER_I.svg';
import upperJSolid from './NORMAL/SOLID/UPPER_J.svg';
import upperJFaded from './NORMAL/FADED/UPPER_J.svg';
import upperJDotted from './NORMAL/DOTTED/UPPER_J.svg';
import upperLSolid from './NORMAL/SOLID/UPPER_L.svg';
import upperLFaded from './NORMAL/FADED/UPPER_L.svg';
import upperLDotted from './NORMAL/DOTTED/UPPER_L.svg';
import upperOSolid from './NORMAL/SOLID/UPPER_O.svg';
import upperOFaded from './NORMAL/FADED/UPPER_O.svg';
import upperODotted from './NORMAL/DOTTED/UPPER_O.svg';
import upperQSolid from './NORMAL/SOLID/UPPER_Q.svg';
import upperQFaded from './NORMAL/FADED/UPPER_Q.svg';
import upperQDotted from './NORMAL/DOTTED/UPPER_Q.svg';
import upperUSolid from './NORMAL/SOLID/UPPER_U.svg';
import upperUFaded from './NORMAL/FADED/UPPER_U.svg';
import upperUDotted from './NORMAL/DOTTED/UPPER_U.svg';
import upperTSolid from './NORMAL/SOLID/UPPER_T.svg';
import upperTFaded from './NORMAL/FADED/UPPER_T.svg';
import upperTDotted from './NORMAL/DOTTED/UPPER_T.svg';
import upperXSolid from './NORMAL/SOLID/UPPER_X.svg';
import upperXFaded from './NORMAL/FADED/UPPER_X.svg';
import upperXDotted from './NORMAL/DOTTED/UPPER_X.svg';
import upperYSolid from './NORMAL/SOLID/UPPER_Y.svg';
import upperYFaded from './NORMAL/FADED/UPPER_Y.svg';
import upperYDotted from './NORMAL/DOTTED/UPPER_Y.svg';
import upperZSolid from './NORMAL/SOLID/UPPER_Z.svg';
import upperZFaded from './NORMAL/FADED/UPPER_Z.svg';
import upperZDotted from './NORMAL/DOTTED/UPPER_Z.svg';
import number1Solid from './NORMAL/SOLID/NUMBER_1.svg';
import number1Faded from './NORMAL/FADED/NUMBER_1.svg';
import number1Dotted from './NORMAL/DOTTED/NUMBER_1.svg';
import number2Solid from './NORMAL/SOLID/NUMBER_2.svg';
import number2Faded from './NORMAL/FADED/NUMBER_2.svg';
import number2Dotted from './NORMAL/DOTTED/NUMBER_2.svg';
import number3Solid from './NORMAL/SOLID/NUMBER_3.svg';
import number3Faded from './NORMAL/FADED/NUMBER_3.svg';
import number3Dotted from './NORMAL/DOTTED/NUMBER_3.svg';
import number4Solid from './NORMAL/SOLID/NUMBER_4.svg';
import number4Faded from './NORMAL/FADED/NUMBER_4.svg';
import number4Dotted from './NORMAL/DOTTED/NUMBER_4.svg';
import number5Solid from './NORMAL/SOLID/NUMBER_5.svg';
import number5Faded from './NORMAL/FADED/NUMBER_5.svg';
import number5Dotted from './NORMAL/DOTTED/NUMBER_5.svg';
import number6Solid from './NORMAL/SOLID/NUMBER_6.svg';
import number6Faded from './NORMAL/FADED/NUMBER_6.svg';
import number6Dotted from './NORMAL/DOTTED/NUMBER_6.svg';
import upperVSolid from './NORMAL/SOLID/UPPER_V.svg';
import upperVFaded from './NORMAL/FADED/UPPER_V.svg';
import upperVDotted from './NORMAL/DOTTED/UPPER_V.svg';
import upperWSolid from './NORMAL/SOLID/UPPER_W.svg';
import upperWFaded from './NORMAL/FADED/UPPER_W.svg';
import upperWDotted from './NORMAL/DOTTED/UPPER_W.svg';
import upperMSolid from './NORMAL/SOLID/UPPER_M.svg';
import upperMFaded from './NORMAL/FADED/UPPER_M.svg';
import upperMDotted from './NORMAL/DOTTED/UPPER_M.svg';
import upperNSolid from './NORMAL/SOLID/UPPER_N.svg';
import upperNFaded from './NORMAL/FADED/UPPER_N.svg';
import upperNDotted from './NORMAL/DOTTED/UPPER_N.svg';
import number7Solid from './NORMAL/SOLID/NUMBER_7.svg';
import number7Faded from './NORMAL/FADED/NUMBER_7.svg';
import number7Dotted from './NORMAL/DOTTED/NUMBER_7.svg';
import upperKSolid from './NORMAL/SOLID/UPPER_K.svg';
import upperKFaded from './NORMAL/FADED/UPPER_K.svg';
import upperKDotted from './NORMAL/DOTTED/UPPER_K.svg';
import upperRSolid from './NORMAL/SOLID/UPPER_R.svg';
import upperRFaded from './NORMAL/FADED/UPPER_R.svg';
import upperRDotted from './NORMAL/DOTTED/UPPER_R.svg';
import upperSSolid from './NORMAL/SOLID/UPPER_S.svg';
import upperSFaded from './NORMAL/FADED/UPPER_S.svg';
import upperSDotted from './NORMAL/DOTTED/UPPER_S.svg';
import upperBSolid from './NORMAL/SOLID/UPPER_B.svg';
import upperBFaded from './NORMAL/FADED/UPPER_B.svg';
import upperBDotted from './NORMAL/DOTTED/UPPER_B.svg';
import upperDSolid from './NORMAL/SOLID/UPPER_D.svg';
import upperDFaded from './NORMAL/FADED/UPPER_D.svg';
import upperDDotted from './NORMAL/DOTTED/UPPER_D.svg';
import upperPSolid from './NORMAL/SOLID/UPPER_P.svg';
import upperPFaded from './NORMAL/FADED/UPPER_P.svg';
import upperPDotted from './NORMAL/DOTTED/UPPER_P.svg';
import number8Solid from './NORMAL/SOLID/NUMBER_8.svg';
import number8Faded from './NORMAL/FADED/NUMBER_8.svg';
import number8Dotted from './NORMAL/DOTTED/NUMBER_8.svg';
import number9Solid from './NORMAL/SOLID/NUMBER_9.svg';
import number9Faded from './NORMAL/FADED/NUMBER_9.svg';
import number9Dotted from './NORMAL/DOTTED/NUMBER_9.svg';
import number0Solid from './NORMAL/SOLID/NUMBER_0.svg';
import number0Faded from './NORMAL/FADED/NUMBER_0.svg';
import number0Dotted from './NORMAL/DOTTED/NUMBER_0.svg';

import symbolPlusSolid from './NORMAL/SOLID/SYMBOL_PLUS.svg';
import symbolPlusFaded from './NORMAL/FADED/SYMBOL_PLUS.svg';
import symbolPlusDotted from './NORMAL/DOTTED/SYMBOL_PLUS.svg';
import symbolEqualSolid from './NORMAL/SOLID/SYMBOL_EQUAL.svg';
import symbolEqualFaded from './NORMAL/FADED/SYMBOL_EQUAL.svg';
import symbolEqualDotted from './NORMAL/DOTTED/SYMBOL_EQUAL.svg';
import symbolQuestionSolid from './NORMAL/SOLID/SYMBOL_QUESTION.svg';
import symbolQuestionFaded from './NORMAL/FADED/SYMBOL_QUESTION.svg';
import symbolQuestionDotted from './NORMAL/DOTTED/SYMBOL_QUESTION.svg';
import symbolExclamationSolid from './NORMAL/SOLID/SYMBOL_EXCLAMATION.svg';
import symbolExclamationFaded from './NORMAL/FADED/SYMBOL_EXCLAMATION.svg';
import symbolExclamationDotted from './NORMAL/DOTTED/SYMBOL_EXCLAMATION.svg';

// Large
import lowerELargeSolid from './LARGE/SOLID/LOWER_E.svg';
import lowerELargeFaded from './LARGE/FADED/LOWER_E.svg';
import lowerELargeDotted from './LARGE/DOTTED/LOWER_E.svg';
import lowerJLargeSolid from './LARGE/SOLID/LOWER_J.svg';
import lowerJLargeFaded from './LARGE/FADED/LOWER_J.svg';
import lowerJLargeDotted from './LARGE/DOTTED/LOWER_J.svg';
import lowerMLargeSolid from './LARGE/SOLID/LOWER_M.svg';
import lowerMLargeFaded from './LARGE/FADED/LOWER_M.svg';
import lowerMLargeDotted from './LARGE/DOTTED/LOWER_M.svg';

import upperFLargeSolid from './LARGE/SOLID/UPPER_F.svg';
import upperFLargeFaded from './LARGE/FADED/UPPER_F.svg';
import upperFLargeDotted from './LARGE/DOTTED/UPPER_F.svg';
import upperMLargeSolid from './LARGE/SOLID/UPPER_M.svg';
import upperMLargeFaded from './LARGE/FADED/UPPER_M.svg';
import upperMLargeDotted from './LARGE/DOTTED/UPPER_M.svg';
import upperOLargeSolid from './LARGE/SOLID/UPPER_O.svg';
import upperOLargeFaded from './LARGE/FADED/UPPER_O.svg';
import upperOLargeDotted from './LARGE/DOTTED/UPPER_O.svg';
import upperWLargeSolid from './LARGE/SOLID/UPPER_W.svg';
import upperWLargeFaded from './LARGE/FADED/UPPER_W.svg';
import upperWLargeDotted from './LARGE/DOTTED/UPPER_W.svg';

import { ActivityType, CharacterType, DifficultyLevel } from '../../data/types';

type Scaffolds = {
  [DifficultyLevel.EASY]: {
    [charKey in CharacterType]?: {
      [ActivityType.SOLID]: string;
      [ActivityType.FADED]: string;
      [ActivityType.DOTTED]: string;
    };
  },
  [DifficultyLevel.NORMAL]: {
    [charKey in CharacterType]: {
      [ActivityType.SOLID]: string;
      [ActivityType.FADED]: string;
      [ActivityType.DOTTED]: string;
    };
  },
  [DifficultyLevel.HARD]: null,
}

const scaffolds: Scaffolds = {
  [DifficultyLevel.NORMAL]: {
    [CharacterType.LOWER_A]: {
      [ActivityType.SOLID]: lowerASolid,
      [ActivityType.FADED]: lowerAFaded,
      [ActivityType.DOTTED]: lowerADotted,
    },
    [CharacterType.LOWER_B]: {
      [ActivityType.SOLID]: lowerBSolid,
      [ActivityType.FADED]: lowerBFaded,
      [ActivityType.DOTTED]: lowerBDotted,
    },
    [CharacterType.LOWER_C]: {
      [ActivityType.SOLID]: lowerCSolid,
      [ActivityType.FADED]: lowerCFaded,
      [ActivityType.DOTTED]: lowerCDotted,
    },
    [CharacterType.LOWER_D]: {
      [ActivityType.SOLID]: lowerDSolid,
      [ActivityType.FADED]: lowerDFaded,
      [ActivityType.DOTTED]: lowerDDotted,
    },
    [CharacterType.LOWER_E]: {
      [ActivityType.SOLID]: lowerESolid,
      [ActivityType.FADED]: lowerEFaded,
      [ActivityType.DOTTED]: lowerEDotted,
    },
    [CharacterType.LOWER_F]: {
      [ActivityType.SOLID]: lowerFSolid,
      [ActivityType.FADED]: lowerFFaded,
      [ActivityType.DOTTED]: lowerFDotted,
    },
    [CharacterType.LOWER_G]: {
      [ActivityType.SOLID]: lowerGSolid,
      [ActivityType.FADED]: lowerGFaded,
      [ActivityType.DOTTED]: lowerGDotted,
    },
    [CharacterType.LOWER_H]: {
      [ActivityType.SOLID]: lowerHSolid,
      [ActivityType.FADED]: lowerHFaded,
      [ActivityType.DOTTED]: lowerHDotted,
    },
    [CharacterType.LOWER_I]: {
      [ActivityType.SOLID]: lowerISolid,
      [ActivityType.FADED]: lowerIFaded,
      [ActivityType.DOTTED]: lowerIDotted,
    },
    [CharacterType.LOWER_J]: {
      [ActivityType.SOLID]: lowerJSolid,
      [ActivityType.FADED]: lowerJFaded,
      [ActivityType.DOTTED]: lowerJDotted,
    },
    [CharacterType.LOWER_K]: {
      [ActivityType.SOLID]: lowerKSolid,
      [ActivityType.FADED]: lowerKFaded,
      [ActivityType.DOTTED]: lowerKDotted,
    },
    [CharacterType.LOWER_L]: {
      [ActivityType.SOLID]: lowerLSolid,
      [ActivityType.FADED]: lowerLFaded,
      [ActivityType.DOTTED]: lowerLDotted,
    },
    [CharacterType.LOWER_M]: {
      [ActivityType.SOLID]: lowerMSolid,
      [ActivityType.FADED]: lowerMFaded,
      [ActivityType.DOTTED]: lowerMDotted,
    },
    [CharacterType.LOWER_N]: {
      [ActivityType.SOLID]: lowerNSolid,
      [ActivityType.FADED]: lowerNFaded,
      [ActivityType.DOTTED]: lowerNDotted,
    },
    [CharacterType.LOWER_O]: {
      [ActivityType.SOLID]: lowerOSolid,
      [ActivityType.FADED]: lowerOFaded,
      [ActivityType.DOTTED]: lowerODotted,
    },
    [CharacterType.LOWER_P]: {
      [ActivityType.SOLID]: lowerPSolid,
      [ActivityType.FADED]: lowerPFaded,
      [ActivityType.DOTTED]: lowerPDotted,
    },
    [CharacterType.LOWER_Q]: {
      [ActivityType.SOLID]: lowerQSolid,
      [ActivityType.FADED]: lowerQFaded,
      [ActivityType.DOTTED]: lowerQDotted,
    },
    [CharacterType.LOWER_R]: {
      [ActivityType.SOLID]: lowerRSolid,
      [ActivityType.FADED]: lowerRFaded,
      [ActivityType.DOTTED]: lowerRDotted,
    },
    [CharacterType.LOWER_S]: {
      [ActivityType.SOLID]: lowerSSolid,
      [ActivityType.FADED]: lowerSFaded,
      [ActivityType.DOTTED]: lowerSDotted,
    },
    [CharacterType.LOWER_T]: {
      [ActivityType.SOLID]: lowerTSolid,
      [ActivityType.FADED]: lowerTFaded,
      [ActivityType.DOTTED]: lowerTDotted,
    },
    [CharacterType.LOWER_U]: {
      [ActivityType.SOLID]: lowerUSolid,
      [ActivityType.FADED]: lowerUFaded,
      [ActivityType.DOTTED]: lowerUDotted,
    },
    [CharacterType.LOWER_V]: {
      [ActivityType.SOLID]: lowerVSolid,
      [ActivityType.FADED]: lowerVFaded,
      [ActivityType.DOTTED]: lowerVDotted,
    },
    [CharacterType.LOWER_W]: {
      [ActivityType.SOLID]: lowerWSolid,
      [ActivityType.FADED]: lowerWFaded,
      [ActivityType.DOTTED]: lowerWDotted,
    },
    [CharacterType.LOWER_X]: {
      [ActivityType.SOLID]: lowerXSolid,
      [ActivityType.FADED]: lowerXFaded,
      [ActivityType.DOTTED]: lowerXDotted,
    },
    [CharacterType.LOWER_Y]: {
      [ActivityType.SOLID]: lowerYSolid,
      [ActivityType.FADED]: lowerYFaded,
      [ActivityType.DOTTED]: lowerYDotted,
    },
    [CharacterType.LOWER_Z]: {
      [ActivityType.SOLID]: lowerZSolid,
      [ActivityType.FADED]: lowerZFaded,
      [ActivityType.DOTTED]: lowerZDotted,
    },

    [CharacterType.UPPER_A]: {
      [ActivityType.SOLID]: upperASolid,
      [ActivityType.FADED]: upperAFaded,
      [ActivityType.DOTTED]: upperADotted,
    },
    [CharacterType.UPPER_B]: {
      [ActivityType.SOLID]: upperBSolid,
      [ActivityType.FADED]: upperBFaded,
      [ActivityType.DOTTED]: upperBDotted,
    },
    [CharacterType.UPPER_C]: {
      [ActivityType.SOLID]: upperCSolid,
      [ActivityType.FADED]: upperCFaded,
      [ActivityType.DOTTED]: upperCDotted,
    },
    [CharacterType.UPPER_D]: {
      [ActivityType.SOLID]: upperDSolid,
      [ActivityType.FADED]: upperDFaded,
      [ActivityType.DOTTED]: upperDDotted,
    },
    [CharacterType.UPPER_E]: {
      [ActivityType.SOLID]: upperESolid,
      [ActivityType.FADED]: upperEFaded,
      [ActivityType.DOTTED]: upperEDotted,
    },
    [CharacterType.UPPER_F]: {
      [ActivityType.SOLID]: upperFSolid,
      [ActivityType.FADED]: upperFFaded,
      [ActivityType.DOTTED]: upperFDotted,
    },
    [CharacterType.UPPER_G]: {
      [ActivityType.SOLID]: upperGSolid,
      [ActivityType.FADED]: upperGFaded,
      [ActivityType.DOTTED]: upperGDotted,
    },
    [CharacterType.UPPER_H]: {
      [ActivityType.SOLID]: upperHSolid,
      [ActivityType.FADED]: upperHFaded,
      [ActivityType.DOTTED]: upperHDotted,
    },
    [CharacterType.UPPER_I]: {
      [ActivityType.SOLID]: upperISolid,
      [ActivityType.FADED]: upperIFaded,
      [ActivityType.DOTTED]: upperIDotted,
    },
    [CharacterType.UPPER_J]: {
      [ActivityType.SOLID]: upperJSolid,
      [ActivityType.FADED]: upperJFaded,
      [ActivityType.DOTTED]: upperJDotted,
    },
    [CharacterType.UPPER_K]: {
      [ActivityType.SOLID]: upperKSolid,
      [ActivityType.FADED]: upperKFaded,
      [ActivityType.DOTTED]: upperKDotted,
    },
    [CharacterType.UPPER_L]: {
      [ActivityType.SOLID]: upperLSolid,
      [ActivityType.FADED]: upperLFaded,
      [ActivityType.DOTTED]: upperLDotted,
    },
    [CharacterType.UPPER_M]: {
      [ActivityType.SOLID]: upperMSolid,
      [ActivityType.FADED]: upperMFaded,
      [ActivityType.DOTTED]: upperMDotted,
    },
    [CharacterType.UPPER_N]: {
      [ActivityType.SOLID]: upperNSolid,
      [ActivityType.FADED]: upperNFaded,
      [ActivityType.DOTTED]: upperNDotted,
    },
    [CharacterType.UPPER_O]: {
      [ActivityType.SOLID]: upperOSolid,
      [ActivityType.FADED]: upperOFaded,
      [ActivityType.DOTTED]: upperODotted,
    },
    [CharacterType.UPPER_P]: {
      [ActivityType.SOLID]: upperPSolid,
      [ActivityType.FADED]: upperPFaded,
      [ActivityType.DOTTED]: upperPDotted,
    },
    [CharacterType.UPPER_Q]: {
      [ActivityType.SOLID]: upperQSolid,
      [ActivityType.FADED]: upperQFaded,
      [ActivityType.DOTTED]: upperQDotted,
    },
    [CharacterType.UPPER_R]: {
      [ActivityType.SOLID]: upperRSolid,
      [ActivityType.FADED]: upperRFaded,
      [ActivityType.DOTTED]: upperRDotted,
    },
    [CharacterType.UPPER_S]: {
      [ActivityType.SOLID]: upperSSolid,
      [ActivityType.FADED]: upperSFaded,
      [ActivityType.DOTTED]: upperSDotted,
    },
    [CharacterType.UPPER_T]: {
      [ActivityType.SOLID]: upperTSolid,
      [ActivityType.FADED]: upperTFaded,
      [ActivityType.DOTTED]: upperTDotted,
    },
    [CharacterType.UPPER_U]: {
      [ActivityType.SOLID]: upperUSolid,
      [ActivityType.FADED]: upperUFaded,
      [ActivityType.DOTTED]: upperUDotted,
    },
    [CharacterType.UPPER_V]: {
      [ActivityType.SOLID]: upperVSolid,
      [ActivityType.FADED]: upperVFaded,
      [ActivityType.DOTTED]: upperVDotted,
    },
    [CharacterType.UPPER_W]: {
      [ActivityType.SOLID]: upperWSolid,
      [ActivityType.FADED]: upperWFaded,
      [ActivityType.DOTTED]: upperWDotted,
    },
    [CharacterType.UPPER_X]: {
      [ActivityType.SOLID]: upperXSolid,
      [ActivityType.FADED]: upperXFaded,
      [ActivityType.DOTTED]: upperXDotted,
    },
    [CharacterType.UPPER_Y]: {
      [ActivityType.SOLID]: upperYSolid,
      [ActivityType.FADED]: upperYFaded,
      [ActivityType.DOTTED]: upperYDotted,
    },
    [CharacterType.UPPER_Z]: {
      [ActivityType.SOLID]: upperZSolid,
      [ActivityType.FADED]: upperZFaded,
      [ActivityType.DOTTED]: upperZDotted,
    },

    [CharacterType.NUMBER_0]: {
      [ActivityType.SOLID]: number0Solid,
      [ActivityType.FADED]: number0Faded,
      [ActivityType.DOTTED]: number0Dotted,
    },
    [CharacterType.NUMBER_1]: {
      [ActivityType.SOLID]: number1Solid,
      [ActivityType.FADED]: number1Faded,
      [ActivityType.DOTTED]: number1Dotted,
    },
    [CharacterType.NUMBER_2]: {
      [ActivityType.SOLID]: number2Solid,
      [ActivityType.FADED]: number2Faded,
      [ActivityType.DOTTED]: number2Dotted,
    },
    [CharacterType.NUMBER_3]: {
      [ActivityType.SOLID]: number3Solid,
      [ActivityType.FADED]: number3Faded,
      [ActivityType.DOTTED]: number3Dotted,
    },
    [CharacterType.NUMBER_4]: {
      [ActivityType.SOLID]: number4Solid,
      [ActivityType.FADED]: number4Faded,
      [ActivityType.DOTTED]: number4Dotted,
    },
    [CharacterType.NUMBER_5]: {
      [ActivityType.SOLID]: number5Solid,
      [ActivityType.FADED]: number5Faded,
      [ActivityType.DOTTED]: number5Dotted,
    },
    [CharacterType.NUMBER_6]: {
      [ActivityType.SOLID]: number6Solid,
      [ActivityType.FADED]: number6Faded,
      [ActivityType.DOTTED]: number6Dotted,
    },
    [CharacterType.NUMBER_7]: {
      [ActivityType.SOLID]: number7Solid,
      [ActivityType.FADED]: number7Faded,
      [ActivityType.DOTTED]: number7Dotted,
    },
    [CharacterType.NUMBER_8]: {
      [ActivityType.SOLID]: number8Solid,
      [ActivityType.FADED]: number8Faded,
      [ActivityType.DOTTED]: number8Dotted,
    },
    [CharacterType.NUMBER_9]: {
      [ActivityType.SOLID]: number9Solid,
      [ActivityType.FADED]: number9Faded,
      [ActivityType.DOTTED]: number9Dotted,
    },

    [CharacterType.SYMBOL_PLUS]: {
      [ActivityType.SOLID]: symbolPlusSolid,
      [ActivityType.FADED]: symbolPlusFaded,
      [ActivityType.DOTTED]: symbolPlusDotted,
    },
    [CharacterType.SYMBOL_EQUAL]: {
      [ActivityType.SOLID]: symbolEqualSolid,
      [ActivityType.FADED]: symbolEqualFaded,
      [ActivityType.DOTTED]: symbolEqualDotted,
    },
    [CharacterType.SYMBOL_QUESTION]: {
      [ActivityType.SOLID]: symbolQuestionSolid,
      [ActivityType.FADED]: symbolQuestionFaded,
      [ActivityType.DOTTED]: symbolQuestionDotted,
    },
    [CharacterType.SYMBOL_EXCLAMATION]: {
      [ActivityType.SOLID]: symbolExclamationSolid,
      [ActivityType.FADED]: symbolExclamationFaded,
      [ActivityType.DOTTED]: symbolExclamationDotted,
    },
  },
  [DifficultyLevel.EASY]: {
    [CharacterType.LOWER_E]: {
      [ActivityType.SOLID]: lowerELargeSolid,
      [ActivityType.FADED]: lowerELargeFaded,
      [ActivityType.DOTTED]: lowerELargeDotted,
    },
    [CharacterType.LOWER_J]: {
      [ActivityType.SOLID]: lowerJLargeSolid,
      [ActivityType.FADED]: lowerJLargeFaded,
      [ActivityType.DOTTED]: lowerJLargeDotted,
    },
    [CharacterType.LOWER_M]: {
      [ActivityType.SOLID]: lowerMLargeSolid,
      [ActivityType.FADED]: lowerMLargeFaded,
      [ActivityType.DOTTED]: lowerMLargeDotted,
    },
    [CharacterType.UPPER_F]: {
      [ActivityType.SOLID]: upperFLargeSolid,
      [ActivityType.FADED]: upperFLargeFaded,
      [ActivityType.DOTTED]: upperFLargeDotted,
    },
    [CharacterType.UPPER_M]: {
      [ActivityType.SOLID]: upperMLargeSolid,
      [ActivityType.FADED]: upperMLargeFaded,
      [ActivityType.DOTTED]: upperMLargeDotted,
    },
    [CharacterType.UPPER_O]: {
      [ActivityType.SOLID]: upperOLargeSolid,
      [ActivityType.FADED]: upperOLargeFaded,
      [ActivityType.DOTTED]: upperOLargeDotted,
    },
    [CharacterType.UPPER_W]: {
      [ActivityType.SOLID]: upperWLargeSolid,
      [ActivityType.FADED]: upperWLargeFaded,
      [ActivityType.DOTTED]: upperWLargeDotted,
    },
  },
  [DifficultyLevel.HARD]: null,
};

export default scaffolds;
