import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import homeIcon from '../../assets/images/icons/home-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';
import GrantAccess from '../GrantAccess';
import { ActionType, useGameContext } from '../../context/WritingContext/GameContext';
import Modal from '../Modal';

type HomeButtonProps = {
  showGrantAccess?: boolean
  handleTouch: any
}

export default function HomeButton({ handleTouch, showGrantAccess }: HomeButtonProps) {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const ctx = useGameContext();

  const handleViewHomeButtonTouch = () => {
    ctx?.dispatch({ type: ActionType.RESET });
    window.location.reload();
  };

  if (showGrantAccess && showModal) {
    return (
      <Modal
        title="Answer the math problem to continue to the home page"
        isShown={showModal}
        onClose={() => setShowModal(false)}
      >
        <GrantAccess
          buttonTouch={handleViewHomeButtonTouch}
          buttonText="GO"
        />
      </Modal>
    );
  }

  return (
    <CircleButton
      colors={theme.colors.buttons.home}
      aria-label="go to home page"
      onTouchStart={() => (showGrantAccess ? setShowModal(true) : handleTouch())}
      role="link"
    >
      <img
        alt=""
        src={homeIcon}
      />
    </CircleButton>
  );
}

HomeButton.defaultProps = {
  showGrantAccess: false,
};
