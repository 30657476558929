/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable no-restricted-properties */
const distanceBetweenTwoPoints = (points1: number[], points2: number[]) => {
  const xSquared = Math.pow((points2[0] - points1[0]), 2);
  const ySquared = Math.pow((points2[1] - points1[1]), 2);
  const distance = Math.floor(Math.sqrt(xSquared + ySquared));
  return distance;
};

export default distanceBetweenTwoPoints;
