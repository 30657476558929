import { CharacterType } from '../../../data/types';
import lowerA from './LOWER_A.svg';
import lowerB from './LOWER_B.svg';
import lowerC from './LOWER_C.svg';
import lowerD from './LOWER_D.svg';
import lowerE from './LOWER_E.svg';
import lowerF from './LOWER_F.svg';
import lowerG from './LOWER_G.svg';
import lowerH from './LOWER_H.svg';
import lowerI from './LOWER_I.svg';
import lowerJ from './LOWER_J.svg';
import lowerK from './LOWER_K.svg';
import lowerL from './LOWER_L.svg';
import lowerM from './LOWER_M.svg';
import lowerN from './LOWER_N.svg';
import lowerO from './LOWER_O.svg';
import lowerP from './LOWER_P.svg';
import lowerQ from './LOWER_Q.svg';
import lowerR from './LOWER_R.svg';
import lowerS from './LOWER_S.svg';
import lowerT from './LOWER_T.svg';
import lowerU from './LOWER_U.svg';
import lowerV from './LOWER_V.svg';
import lowerW from './LOWER_W.svg';
import lowerX from './LOWER_X.svg';
import lowerY from './LOWER_Y.svg';
import lowerZ from './LOWER_Z.svg';
import upperA from './UPPER_A.svg';
import upperB from './UPPER_B.svg';
import upperC from './UPPER_C.svg';
import upperD from './UPPER_D.svg';
import upperE from './UPPER_E.svg';
import upperF from './UPPER_F.svg';
import upperG from './UPPER_G.svg';
import upperH from './UPPER_H.svg';
import upperI from './UPPER_I.svg';
import upperJ from './UPPER_J.svg';
import upperK from './UPPER_K.svg';
import upperL from './UPPER_L.svg';
import upperM from './UPPER_M.svg';
import upperN from './UPPER_N.svg';
import upperO from './UPPER_O.svg';
import upperP from './UPPER_P.svg';
import upperQ from './UPPER_Q.svg';
import upperR from './UPPER_R.svg';
import upperS from './UPPER_S.svg';
import upperT from './UPPER_T.svg';
import upperU from './UPPER_U.svg';
import upperV from './UPPER_V.svg';
import upperW from './UPPER_W.svg';
import upperX from './UPPER_X.svg';
import upperY from './UPPER_Y.svg';
import upperZ from './UPPER_Z.svg';
import number0 from './NUMBER_0.svg';
import number1 from './NUMBER_1.svg';
import number2 from './NUMBER_2.svg';
import number3 from './NUMBER_3.svg';
import number4 from './NUMBER_4.svg';
import number5 from './NUMBER_5.svg';
import number6 from './NUMBER_6.svg';
import number7 from './NUMBER_7.svg';
import number8 from './NUMBER_8.svg';
import number9 from './NUMBER_9.svg';
import symbolPlus from './SYMBOL_PLUS.svg';
import symbolEqual from './SYMBOL_EQUAL.svg';
import symbolQuestion from './SYMBOL_QUESTION.svg';
import symbolExclamation from './SYMBOL_EXCLAMATION.svg';

type StarGameSuccessImages = {
  [key in CharacterType]: string;
}

const starGameSuccessImages: StarGameSuccessImages = {
  [CharacterType.LOWER_A]: lowerA,
  [CharacterType.LOWER_B]: lowerB,
  [CharacterType.LOWER_C]: lowerC,
  [CharacterType.LOWER_D]: lowerD,
  [CharacterType.LOWER_E]: lowerE,
  [CharacterType.LOWER_F]: lowerF,
  [CharacterType.LOWER_G]: lowerG,
  [CharacterType.LOWER_H]: lowerH,
  [CharacterType.LOWER_I]: lowerI,
  [CharacterType.LOWER_J]: lowerJ,
  [CharacterType.LOWER_K]: lowerK,
  [CharacterType.LOWER_L]: lowerL,
  [CharacterType.LOWER_M]: lowerM,
  [CharacterType.LOWER_N]: lowerN,
  [CharacterType.LOWER_O]: lowerO,
  [CharacterType.LOWER_P]: lowerP,
  [CharacterType.LOWER_Q]: lowerQ,
  [CharacterType.LOWER_R]: lowerR,
  [CharacterType.LOWER_S]: lowerS,
  [CharacterType.LOWER_T]: lowerT,
  [CharacterType.LOWER_U]: lowerU,
  [CharacterType.LOWER_V]: lowerV,
  [CharacterType.LOWER_W]: lowerW,
  [CharacterType.LOWER_X]: lowerX,
  [CharacterType.LOWER_Y]: lowerY,
  [CharacterType.LOWER_Z]: lowerZ,

  [CharacterType.UPPER_A]: upperA,
  [CharacterType.UPPER_B]: upperB,
  [CharacterType.UPPER_C]: upperC,
  [CharacterType.UPPER_D]: upperD,
  [CharacterType.UPPER_E]: upperE,
  [CharacterType.UPPER_F]: upperF,
  [CharacterType.UPPER_G]: upperG,
  [CharacterType.UPPER_H]: upperH,
  [CharacterType.UPPER_I]: upperI,
  [CharacterType.UPPER_J]: upperJ,
  [CharacterType.UPPER_K]: upperK,
  [CharacterType.UPPER_L]: upperL,
  [CharacterType.UPPER_M]: upperM,
  [CharacterType.UPPER_N]: upperN,
  [CharacterType.UPPER_O]: upperO,
  [CharacterType.UPPER_P]: upperP,
  [CharacterType.UPPER_Q]: upperQ,
  [CharacterType.UPPER_R]: upperR,
  [CharacterType.UPPER_S]: upperS,
  [CharacterType.UPPER_T]: upperT,
  [CharacterType.UPPER_U]: upperU,
  [CharacterType.UPPER_V]: upperV,
  [CharacterType.UPPER_W]: upperW,
  [CharacterType.UPPER_X]: upperX,
  [CharacterType.UPPER_Y]: upperY,
  [CharacterType.UPPER_Z]: upperZ,

  [CharacterType.NUMBER_0]: number0,
  [CharacterType.NUMBER_1]: number1,
  [CharacterType.NUMBER_2]: number2,
  [CharacterType.NUMBER_3]: number3,
  [CharacterType.NUMBER_4]: number4,
  [CharacterType.NUMBER_5]: number5,
  [CharacterType.NUMBER_6]: number6,
  [CharacterType.NUMBER_7]: number7,
  [CharacterType.NUMBER_8]: number8,
  [CharacterType.NUMBER_9]: number9,

  [CharacterType.SYMBOL_PLUS]: symbolPlus,
  [CharacterType.SYMBOL_EQUAL]: symbolEqual,
  [CharacterType.SYMBOL_QUESTION]: symbolQuestion,
  [CharacterType.SYMBOL_EXCLAMATION]: symbolExclamation,
};

export default starGameSuccessImages;
