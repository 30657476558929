export enum IntersectionType {
  RESTRICTED = 'RESTRICTED',
  REQUIRED = 'REQUIRED',
}

export const getIntersectionNodeName = (
  intersectionType: IntersectionType,
  id?: number|string,
) => {
  if (id === undefined) return '';
  return `${intersectionType}-${intersectionType === IntersectionType.REQUIRED ? 'point' : 'area'}-${id}`;
};
