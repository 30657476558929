import styled, { css } from 'styled-components';
import {
  DRAWABLE_MARGIN, DRAWABLE_WIDTH_NORMAL, WRITING_LINE_HEIGHT_LARGE, WRITING_LINE_HEIGHT_NORMAL,
} from '../../shared/constants';
import lines from '../../assets/images/lines.svg';
import linesLarge from '../../assets/images/lines-large.svg';
import { FlexRowCenter } from './FlexSpacers';
import { DifficultyLevel } from '../../data/types';

export const DrawingArea = styled.div`
  height: 100%;
  position: relative;
`;

export const SpacingDrawingArea = styled(DrawingArea)`
  display: flex;
  justify-content: center;
  height: 100%;
  width: ${DRAWABLE_WIDTH_NORMAL}px;
`;

type DrawingAreaGridProps = {
  difficultyLevel: DifficultyLevel,
}

export const DrawingAreaGrid = styled.div<DrawingAreaGridProps>`
  width: 75%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-image: ${(props) => (props.difficultyLevel === DifficultyLevel.EASY
    ? `url(${linesLarge})`
    : `url(${lines})`)};
  ${(props) => props.difficultyLevel === DifficultyLevel.EASY && css`
    position: relative;
    top: 11%;
  `};
  background-repeat: repeat-x;
  background-position: 0 ${DRAWABLE_MARGIN}px;
  background-size: auto ${(props) => (props.difficultyLevel === DifficultyLevel.EASY
    ? WRITING_LINE_HEIGHT_LARGE
    : WRITING_LINE_HEIGHT_NORMAL)}px;
  ${(props) => props.difficultyLevel === DifficultyLevel.EASY && css`
    position: relative;
    top: 11%;
  `};

  > * {
    justify-self: center;
  }
`;

type SpacingDrawingLinesFlexProps = {
  width?: number,
}

export const SpacingDrawingLinesFlex = styled(FlexRowCenter)`
  width: ${(props: SpacingDrawingLinesFlexProps) => (
    props.width || 100)}%;
  background-image: url(${lines});
  background-repeat: repeat-x;
  background-position: 0 ${DRAWABLE_MARGIN}px;
  background-size: auto ${WRITING_LINE_HEIGHT_NORMAL}px;
  position: relative;
`;
