/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import DemoButton from './Buttons/DemoButton';
import HomeButton from './Buttons/HomeButton';
import GripButton, { GripButtonType } from './Buttons/GripButton';
import ExperimentalWritingSettings from './ExperimentalWritingSettings';
import { TopNavigationWrapper } from '../styles/components/ContentWrappers';

type TopNavigationProps = {
  onDemoButtonTouch: () => void,
  itemLabel: string,
  showHomeButton?: boolean,
  onHomeButtonTouch: () => void,
  showGripDemoButton?: boolean,
  onGripDemoButtonTouch?: () => void,
  showGrantAccess?: boolean
}

export default function TopNavigation({
  onDemoButtonTouch,
  itemLabel,
  showHomeButton,
  onHomeButtonTouch,
  showGripDemoButton,
  onGripDemoButtonTouch,
  showGrantAccess,
}: TopNavigationProps) {
  return (
    <TopNavigationWrapper>
      <ul role="list" className="demo-buttons">
        <li role="listitem">
          <DemoButton
            ariaItemLabel={itemLabel}
            buttonTouch={onDemoButtonTouch}
          />
        </li>
        {
          showGripDemoButton && (
            <li role="listitem">
              <GripButton type={GripButtonType.CIRCLE} handleTouch={onGripDemoButtonTouch} />
            </li>
          )
        }
      </ul>
      <ExperimentalWritingSettings />
      {showHomeButton
        && <HomeButton handleTouch={onHomeButtonTouch} showGrantAccess={showGrantAccess} />}
    </TopNavigationWrapper>
  );
}

TopNavigation.defaultProps = {
  showHomeButton: true,
  showGrantAccess: false,
  showGripDemoButton: false,
  onGripDemoButtonTouch: () => { },
};
