import { SpacingActionOutcome } from '../../stateMachines/spacingMachine/spacingMachine';
import { SpacingActivityType } from '../types';
import { MultiChoiceOptions, OptionType } from '../types/spacing/spacing-choose-options';

export const multiChoiceOptions: MultiChoiceOptions = {
  [SpacingActivityType.MULTI_CHOICE_CLOSE]: [
    {
      optionType: OptionType.TOO_CLOSE,
      spacingActionOutcome: SpacingActionOutcome.FAIL,
      isSelected: false,
    },
    {
      optionType: OptionType.CORRECT,
      spacingActionOutcome: SpacingActionOutcome.PASS,
      isSelected: false,
    },
  ],
  [SpacingActivityType.MULTI_CHOICE_WIDE]: [
    {
      optionType: OptionType.TOO_WIDE,
      spacingActionOutcome: SpacingActionOutcome.FAIL,
      isSelected: false,
    },
    {
      optionType: OptionType.CORRECT,
      spacingActionOutcome: SpacingActionOutcome.PASS,
      isSelected: false,
    },
  ],
  [SpacingActivityType.MULTI_CHOICE_ALL]: [
    {
      optionType: OptionType.CORRECT,
      spacingActionOutcome: SpacingActionOutcome.PASS,
      isSelected: false,
    },
    {
      optionType: OptionType.TOO_CLOSE,
      spacingActionOutcome: SpacingActionOutcome.FAIL,
      isSelected: false,
    },
    {
      optionType: OptionType.TOO_WIDE,
      spacingActionOutcome: SpacingActionOutcome.FAIL,
      isSelected: false,
    },
  ],
};
