import { SpacingActivityCategory, SpacingItemType } from '../../../data/types';
import amSuccess from './VO62-AM.mp3';
import atSuccess from './VO62-AT.mp3';
import bagSuccess from './VO62-BAG.mp3';
import chSuccess from './VO61-CH.mp3';
import ifSuccess from './VO62-IF.mp3';
import inSuccess from './VO62-IN.mp3';
import itSuccess from './VO62-IT.mp3';
import noSuccess from './VO62-NO.mp3';
import redSuccess from './VO62-RED.mp3';
import shSuccess from './VO61-SH.mp3';
import thSuccess from './VO61-TH.mp3';
import upSuccess from './VO62-UP.mp3';
import usSuccess from './VO62-US.mp3';
import yesSuccess from './VO62-YES.mp3';

import multiSuccess from './VO52.mp3';

const spacingActivitySuccess = {
  [SpacingItemType.AM]: amSuccess,
  [SpacingItemType.AT]: atSuccess,
  [SpacingItemType.BAG]: bagSuccess,
  [SpacingItemType.CH]: chSuccess,
  [SpacingItemType.IF]: ifSuccess,
  [SpacingItemType.IN]: inSuccess,
  [SpacingItemType.IT]: itSuccess,
  [SpacingItemType.NO]: noSuccess,
  [SpacingItemType.RED]: redSuccess,
  [SpacingItemType.SH]: shSuccess,
  [SpacingItemType.TH]: thSuccess,
  [SpacingItemType.UP]: upSuccess,
  [SpacingItemType.US]: usSuccess,
  [SpacingItemType.YES]: yesSuccess,

  [SpacingActivityCategory.MULTI_CHOICE]: multiSuccess,
};

export default spacingActivitySuccess;
