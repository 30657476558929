import React from 'react';
import CardWrapper, { CardWrapperSize, CardWrapperType } from '../styles/components/CardWrapper';
import { DifficultyLevel, DrawnCharacter as DrawnCharacterType } from '../data/types';
import DrawnCharacter from './DrawnCharacter';

type DrawnCharCardProps = {
  drawnCharacter: DrawnCharacterType,
  difficultyLevel: DifficultyLevel
}

export default function DrawnCharCard({ drawnCharacter, difficultyLevel }: DrawnCharCardProps) {
  const isEasyLevel = difficultyLevel === DifficultyLevel.EASY;
  const getTransformValue = () => {
    // TODO: For testing purposes only, End of Set display will need more dev/design
    // work to prep for larger letters in prod.
    if (isEasyLevel) return 'translate(110, 30) scale(0.2, 0.2)';
    return 'translate(100, 9) scale(0.6, 0.6)';
  };

  return (
    <CardWrapper
      type={CardWrapperType.DEFAULT}
      size={CardWrapperSize.SM}
      className="practice-card"
      isSelected={false}
    >
      <div className="lines">
        <DrawnCharacter
          drawnCharacter={drawnCharacter}
          isEasyLevel={isEasyLevel}
          positioning={{
            pathTransform: getTransformValue(),
          }}
        />
      </div>
    </CardWrapper>
  );
}
