import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LandingScreenContainer } from '../styles/components/AnimatedOuterSpaceWrapper';
import starGroup from '../assets/images/star-group/star-group-6.png';
import starYellow from '../assets/images/star-yellow/star-yellow.svg';
import NextButton from '../components/Buttons/NextButton';
import EducatorResourcesLink from '../components/Links/EducatorResourcesLink';
import LottiePlayer from '../components/LottiePlayer';
import outerSpace from '../assets/lotties/outer-space.json';
import { Paths } from '../data/types';
import VersionDisplay from '../components/Version Display/VersionDisplay';

export default function Landing() {
  const navigate = useNavigate();
  const breakPoint = window.innerHeight === 768 && window.innerWidth === 1366;

  return (
    <LandingScreenContainer $breakPoint={breakPoint}>
      <div className="top">
        <LottiePlayer
          data={outerSpace}
          width="100%"
          handleComplete={() => {}}
          shouldAutoplay
          shouldLoop
        />
        <div className="text-and-stars">
          <img src={starYellow} alt="" className="star left" />
          <div className="title">Starwriter</div>
          <VersionDisplay isLanding />
          <NextButton
            isDisabled={false}
            buttonTouch={() => navigate(`/${Paths.ACTIVITY_SET_SELECTION}`)}
          />
          <img src={starYellow} alt="" className="star right" />
        </div>
      </div>
      <div className="bottom">
        <img src={starGroup} alt="" className="star left" />
        <EducatorResourcesLink shouldCheckAccess />
      </div>
    </LandingScreenContainer>
  );
}
