import styled from 'styled-components';

const CloseButtonContainer = styled.div`
  position: absolute;
  top: -2%;
  right: -5%;

  img.close-icon {
    width: 18px;
    height: auto;
  }
`;

export default CloseButtonContainer;
