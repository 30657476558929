import React, { useEffect, useMemo, useState } from 'react';
import {
  ActivityType, Character, DifficultyLevel,
} from '../data/types';
import { DRAWABLE_WIDTH_NORMAL, DRAWABLE_MARGIN } from '../shared/constants';
import ActivityScaffoldPositionWrapper from '../styles/components/ActivityScaffoldWrapper';
import scaffolds from '../assets/scaffolds';
import ChaseTheShootingStarLottieLoader from './ChaseTheStarLottieLoader';
import useDifficultyLevel from '../hooks/useDifficultyLevel';

interface ActivityScaffoldProps {
  currentActivityType?: ActivityType,
  currentCharacter?: Character | null,
  isVisible: boolean,
  playLottie?: boolean,
  shouldBlink?: boolean,
}

export default function ActivityScaffold({
  currentActivityType,
  currentCharacter,
  isVisible,
  playLottie,
  shouldBlink,
}: ActivityScaffoldProps) {
  const { difficultyLevel } = useDifficultyLevel();
  const [isBlinking, setIsBlinking] = useState(false);
  const difficultyLevelPositioning = useMemo(() => currentCharacter?.positioning[difficultyLevel], [difficultyLevel]);

  const positionX = useMemo(() => (
    (difficultyLevelPositioning?.scaffold.xOffset || 0) + DRAWABLE_MARGIN
  ), [currentCharacter]);

  const positionY = useMemo(() => (
    (difficultyLevelPositioning?.scaffold.yOffset || 0) + DRAWABLE_MARGIN
  ), [currentCharacter]);

  const height = useMemo(() => (
    difficultyLevelPositioning?.scaffold.height || 0
  ), [currentCharacter]);

  const imageSrc = useMemo(() => {
    if (!currentCharacter) return '';
    const scaffold = scaffolds[difficultyLevel] || scaffolds[DifficultyLevel.NORMAL];
    // @ts-expect-error
    return scaffold[currentCharacter.type]?.[isBlinking ? ActivityType.SOLID : currentActivityType];
  }, [currentActivityType, currentCharacter, difficultyLevel, isBlinking]);

  useEffect(() => {
    setIsBlinking(shouldBlink || false);
  }, [shouldBlink]);

  if (!currentCharacter?.type || !currentActivityType) return null;

  switch (currentActivityType) {
    case (ActivityType.CHASE_STAR):
      return (
        <ActivityScaffoldPositionWrapper
          top={currentCharacter.lottieSettings?.[currentActivityType]?.positionY}
          left={currentCharacter.lottieSettings?.[currentActivityType]?.positionX}
          isVisible={isVisible}
        >
          <ChaseTheShootingStarLottieLoader
            shouldStart={playLottie || false}
            width={DRAWABLE_WIDTH_NORMAL + 10}
            characterType={currentCharacter.type}
            lottieFrames={currentCharacter.lottieSettings?.[currentActivityType]?.frames}
          />
        </ActivityScaffoldPositionWrapper>
      );
    case (ActivityType.SOLID):
    case (ActivityType.DOTTED):
    case (ActivityType.FADED):
      return (
        <ActivityScaffoldPositionWrapper top={positionY} isVisible={isVisible}>
          <img
            src={imageSrc}
            height={height}
            alt=""
            style={{ marginLeft: positionX }}
            className={isBlinking ? 'blink' : ''}
            onAnimationEnd={() => setIsBlinking(false)}
          />
        </ActivityScaffoldPositionWrapper>
      );
    default:
      return null;
  }
}

ActivityScaffold.defaultProps = {
  currentActivityType: undefined,
  currentCharacter: undefined,
  playLottie: false,
  shouldBlink: false,
};
