import styled from 'styled-components';
import colors from '../colors';

export const ActivitySetSelectionWrapper = styled.div`
  display: flex;
  background: radial-gradient(${colors.darkPurple}, ${colors.veryDarkPurple});
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .blue-star {
    width: 21px;
    height: auto;
    position: absolute;

    &.left {
      top: 34%;
      left: 15%;
      transform: rotate(45deg);
    }

    &.right {
      bottom: 33%;
      left: 39%;
    }
  }
  .small-blue-star {
    width: 14px;
    height: auto;
    position: absolute;
    bottom: 31%;
    left: 38%;
  }
  .yellow-star {
    width: 21px;
    height: auto;
    position: absolute;

    &.left {
      bottom: 27%;
      right: 44%;
      transform: rotate(45deg);
    }

    &.right {
      top: 29%;
      right: 12%;
    }
  }
  .small-yellow-star {
    width: 14px;
    height: auto;
    position: absolute;
    top: 27%;
    right: 14%;
  }
`;
