import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../data/types';
import { EducatorResourcesLinkContainer } from '../../styles/components/Links';

type ResourcesLinkType = {
  shouldCheckAccess?: boolean,
  handleLinkTouch?: () => void | null,
}
export default function EducatorResourcesLink({ shouldCheckAccess, handleLinkTouch }: ResourcesLinkType) {
  const navigate = useNavigate();
  const handleTouch = () => {
    if (handleLinkTouch) {
      handleLinkTouch();
    }
    navigate(`/${Paths.EDUCATOR_RESOURCES}`, { state: { shouldCheckAccess } });
  };
  return (
    <EducatorResourcesLinkContainer onTouchStart={() => handleTouch()} className="educator-resources-link-container">
      <span>Access Educator Experience</span>
    </EducatorResourcesLinkContainer>
  );
}

EducatorResourcesLink.defaultProps = {
  shouldCheckAccess: false,
  handleLinkTouch: null,
};
