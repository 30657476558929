import { useState } from 'react';
import {
  ThresholdRestrictedPoint,
  Point,
  Character,
  Pen,
  StrokeOutcome,
  Stroke,
  DifficultyLevel,
  RestrictedAreaType,
  CharacterType,
} from '../data/types';
import { DrawingStroke } from '../components/Konva/types';
import {
  ACCEPTABLE_DOT_STROKE_LENGTH,
  ACCEPTABLE_DRAG_AND_DROP_OFFSET,
  DRAWABLE_MARGIN,
  VERTICAL_DRAWABLE_MARGIN,
  DRAWN_STROKE_WIDTH,
  SCALE_LARGE,
} from '../shared/constants';
import pointIsWithinAcceptableDistance from '../shared/pointIsWithinAcceptableDistance';
import calculatePercent from '../shared/calculatePercent';
import { getIntersectionNodeName, IntersectionType } from '../shared/getIntersectionNodeName';
import useSingleStrokeIntersection from './useSingleStrokeIntersection/useSingleStrokeIntersection';
import envSettings from '../data/envSettings';
import { DragAndDropActionOutcome, SpacingActionOutcome } from '../stateMachines/spacingMachine/spacingMachine';
import getPassFailEmoji from '../shared/getPassFailEmoji';
import useDifficultyLevel from './useDifficultyLevel';

interface KonvaGradingProps {
  width: number,
  height: number,
}

interface Coordinates {
  x: number,
  y: number,
}

type Drop = {
  isDragging: boolean,
  x: number,
  y: number
}

type SpacingIntersections = {
  inner: boolean,
  outer: boolean
}

// For state and logic involved in grading accuracy of strokes in Konva
// Grading is based on various types of intersections in the Konva stage between drawn stroke and model data

export default function useKonvaGrading({ width, height }: KonvaGradingProps) {
  const { difficultyLevel, getRadiusForDifficultyLevel } = useDifficultyLevel();
  const [strokes, setStrokes] = useState<DrawingStroke[]>([]);
  const [drop, setDrop] = useState<Drop>();
  const [intersectionCounts, setIntersectionCounts] = useState<{ success: number, fail: number }>(
    { success: 0, fail: 0 },
  );
  const [spacingIntersections, setSpacingIntersections] = useState<SpacingIntersections>(
    { inner: false, outer: false },
  );
  const [intersectedPoints, setIntersectedPoints] = useState<Set<string>>(new Set());
  const [restrictedIntersections, setRestrictedIntersections] = useState<Set<string>>(new Set());
  const [strokeEndedAtEndPoint, setStrokeEndedAtEndPoint] = useState(false);
  const doesStrokeIntersect = useSingleStrokeIntersection(difficultyLevel === DifficultyLevel.EASY
    ? DRAWN_STROKE_WIDTH * SCALE_LARGE
    : DRAWN_STROKE_WIDTH);

  const resetGradingForNextStroke = () => {
    setIntersectedPoints(new Set());
    setRestrictedIntersections(new Set());
    setIntersectionCounts({ success: 0, fail: 0 });
    setStrokeEndedAtEndPoint(false);
    setSpacingIntersections({ inner: false, outer: false });
  };

  const isWithinMinMax = (actualPoint: Coordinates, modelPoint: ThresholdRestrictedPoint): boolean => {
    const minMaxPoints = (modelPoint?.minMax) ? (modelPoint.minMax[difficultyLevel] || null) : null;

    const xMin = minMaxPoints?.xMin || -width;
    const yMin = minMaxPoints?.yMin || -height;
    const xMax = minMaxPoints?.xMax || width;
    const yMax = minMaxPoints?.yMax || height;

    return (
      actualPoint.x >= xMin
      && actualPoint.x <= xMax
      && actualPoint.y >= yMin
      && actualPoint.y <= yMax
    );
  };

  const didHitStartPoint = (
    requiredPoints: Point[] | ThresholdRestrictedPoint[],
    stroke: DrawingStroke,
  ) => {
    // does start point exist in list of intersected points?
    const intersectedPointsList = Array.from(intersectedPoints);
    const startPoint = requiredPoints.find(
      (point: Point, i: number) => (
        intersectedPointsList[i] === getIntersectionNodeName(IntersectionType.REQUIRED, 0)
      ),
    );

    // if start point does not exist, return false, otherwise move to other logic
    if (!startPoint) {
      return false;
    }

    const isStartPointWithinMinMax = isWithinMinMax(
      // positions from top left of yellow box to match min max coordinates
      {
        x: stroke.points[0] - DRAWABLE_MARGIN,
        y: stroke.points[1] - DRAWABLE_MARGIN,
      },
      startPoint,
    );

    return isStartPointWithinMinMax;
  };

  const didHitEndPoint = (
    requiredPoints: Point[] | ThresholdRestrictedPoint[],
    character: Character,
    stroke: DrawingStroke,
  ) => {
    // does stop point exist in list of intersected points?
    const intersectedPointsList = Array.from(intersectedPoints);
    const endPoint = requiredPoints.find(
      (point: Point, i: number) => (
        intersectedPointsList[i] === getIntersectionNodeName(
          IntersectionType.REQUIRED,
          requiredPoints[requiredPoints.length - 1].orderId,
        )
      ),
    );

    const closedCircleChars = [
      CharacterType.UPPER_O, CharacterType.UPPER_Q, CharacterType.LOWER_O, CharacterType.NUMBER_0,
    ];
    const isClosedCircleChar = (character?.type && closedCircleChars.includes(character.type));

    // if stop point does not exist, return false, otherwise move to other logic
    if (!endPoint) {
      return false;
    }

    // actual position in canvas
    const drawnEndPointPositionX = stroke.points[stroke.points.length - 2];
    const drawnEndPointPositionY = stroke.points[stroke.points.length - 1];

    const isEndPointWithinMinMax = isWithinMinMax(
      // positions from top left of yellow box to match min max coordinates
      {
        x: stroke.points[stroke.points.length - 2] - DRAWABLE_MARGIN,
        y: stroke.points[stroke.points.length - 1] - DRAWABLE_MARGIN,
      },
      endPoint,
    );

    // Check for end stroke distance from end point because strokes can pass end points.
    const acceptableEndPointDistance = getRadiusForDifficultyLevel(
      endPoint?.radius || character.gradingSettings.pointRadius,
    );

    const positionX = (character.positioning[difficultyLevel]?.scaffold.xOffset || 0);
    const positionY = (character.positioning[difficultyLevel]?.scaffold.yOffset || 0);

    const endPointCoordinates = endPoint?.coordinates[difficultyLevel] || { x: 0, y: 0 };
    const isEndPointWithinAcceptableDistance = pointIsWithinAcceptableDistance(
      [
        // positions from top left of character position to match point coordinates
        drawnEndPointPositionX - positionX - DRAWABLE_MARGIN,
        isClosedCircleChar
          ? drawnEndPointPositionY - positionY - VERTICAL_DRAWABLE_MARGIN + endPointCoordinates.y
          : drawnEndPointPositionY - positionY - DRAWABLE_MARGIN,
      ],
      [endPointCoordinates.x, endPointCoordinates.y],
      acceptableEndPointDistance,
    );

    return isEndPointWithinAcceptableDistance && isEndPointWithinMinMax;
  };

  const didHitPercentage = (character: Character) => {
    // did trace along stroke path an acceptable percentage
    const pathIntersectionPercentage = calculatePercent(
      intersectionCounts.success,
      intersectionCounts.success + intersectionCounts.fail,
    );

    const acceptablePathIntersectionPercentage = character?.gradingSettings.strokePathAccuracyThreshold;

    return (pathIntersectionPercentage >= acceptablePathIntersectionPercentage);
  };

  const didHitRequiredPointsInOrder = (requiredPoints: (Point | ThresholdRestrictedPoint)[]) => {
    const intersectedPointsList = Array.from(intersectedPoints);
    return requiredPoints.every(
      (point: Point, i: number) => {
        // return true for start and end points because they are graded individually elsewhere.
        if (i === 0 || i === requiredPoints.length - 1) return true;
        return intersectedPointsList[i] === getIntersectionNodeName(IntersectionType.REQUIRED, point.orderId);
      },
    );
  };

  const calculateDotStrokeOutcome = (
    stroke: DrawingStroke,
    endPoint: ThresholdRestrictedPoint,
    character: Character,
    characterStroke: Stroke,
  ): StrokeOutcome => {
    if (!stroke) return StrokeOutcome.ERROR_GENERAL_TRACING;

    const isDotWithinMinMax = isWithinMinMax(
      // positions from top left of yellow box to match min max coordinates
      {
        x: stroke.points[stroke.points.length - 2] - DRAWABLE_MARGIN,
        y: stroke.points[stroke.points.length - 1] - DRAWABLE_MARGIN,
      },
      endPoint,
    );

    // is stroke a dot?
    const isCorrectLength = stroke.points.length <= ACCEPTABLE_DOT_STROKE_LENGTH;

    if (envSettings.devMode) {
      /* eslint-disable no-console */
      console.group(`****** Dot Grading for ${character.display} / Stroke #${characterStroke.order} ******`);
      console.log(`Is dot at right height?\t\t\t${getPassFailEmoji(isDotWithinMinMax)}`);
      console.log(`Is mark just a dot?\t\t\t\t${getPassFailEmoji(isCorrectLength)}`);
      console.log('**************************************');
      console.groupEnd();
      /* eslint-enable no-console */
    }

    if (!isCorrectLength || !isDotWithinMinMax) {
      return StrokeOutcome.ERROR_GENERAL_TRACING;
    }

    return StrokeOutcome.SUCCESS;
  };

  const calculateMultiPointStrokeOutcome = (
    stroke: DrawingStroke,
    requiredPoints: (Point | ThresholdRestrictedPoint)[],
    character: Character,
    characterStroke: Stroke,
  ): StrokeOutcome => {
    if (!stroke || !requiredPoints) return StrokeOutcome.ERROR_GENERAL_TRACING;

    const hasHitStartPoint = didHitStartPoint(requiredPoints, stroke);
    const hasHitEndPoint = didHitEndPoint(requiredPoints, character, stroke);
    const hasHitRequiredPointsInOrder = didHitRequiredPointsInOrder(requiredPoints);
    const hasHitRestrictedZones = restrictedIntersections.size > 0;
    let hasHitEndStrokeRestrictedZone = false; // stroke outcome is different if restricted zone type === END_STROKE
    if (hasHitRestrictedZones) {
      // if user has hit restricted zones, check if any are END_STROKE zones
      hasHitEndStrokeRestrictedZone = character.strokes
        .find((charStroke) => charStroke.id === characterStroke.id)?.restrictedAreas?.filter(
          (area) => restrictedIntersections.has(getIntersectionNodeName(IntersectionType.RESTRICTED, area.id)),
        )?.some((zone) => zone.type === RestrictedAreaType.END_STROKE) || false;
    }
    const hasHitPercentage = didHitPercentage(character);
    const hasIntersectedStroke = (
      characterStroke?.requireIntersection)
      && doesStrokeIntersect(stroke.points, characterStroke.requireIntersection, character.type);
    if (hasHitEndPoint) {
      setStrokeEndedAtEndPoint(true);
    }

    if (envSettings.devMode) {
      /* eslint-disable no-console */
      console.group(`****** Grading for ${character.display} / Stroke #${characterStroke.order} ******`);
      console.log(`Touched start point?\t\t\t${getPassFailEmoji(hasHitStartPoint)}`);
      console.log(`Touched end point?\t\t\t\t${getPassFailEmoji(hasHitEndPoint)}`);
      console.log(`Drew stroke in correct order?\t${getPassFailEmoji(hasHitRequiredPointsInOrder)}`);
      console.log(`Avoided restricted area?\t\t${getPassFailEmoji(!hasHitRestrictedZones)}`);
      if (characterStroke.requireIntersection) {
        console.log(`Closed loop/circle?\t\t\t\t${getPassFailEmoji(hasIntersectedStroke || false)}`);
      }
      console.group(`Drew along path?\t\t\t\t${getPassFailEmoji(hasHitPercentage)}`);
      const acceptablePathIntersectionPercentage = character?.gradingSettings.strokePathAccuracyThreshold;
      const pathIntersectionPercentage = calculatePercent(
        intersectionCounts.success,
        intersectionCounts.success + intersectionCounts.fail,
      );
      console.log(`Path coverage needed to pass: ${acceptablePathIntersectionPercentage}%`);
      console.log(`Calculated path coverage: ${pathIntersectionPercentage}%`);
      console.log(`Intersections on path -\ntotal: ${intersectionCounts.success + intersectionCounts.fail}, `
        + `successful: ${intersectionCounts.success}, missed: ${intersectionCounts.fail}`);
      console.groupEnd();
      console.log('**************************************');
      console.groupEnd();
      /* eslint-enable no-console */
    }

    if (!hasHitStartPoint) {
      return StrokeOutcome.ERROR_START;
    }

    if (!hasHitRequiredPointsInOrder
      || !hasHitPercentage
      || (hasHitRestrictedZones && !hasHitEndStrokeRestrictedZone)) {
      return StrokeOutcome.ERROR_GENERAL_TRACING;
    }

    if (!hasHitEndPoint || (hasHitRestrictedZones && hasHitEndStrokeRestrictedZone)) {
      setStrokeEndedAtEndPoint(false);
      return StrokeOutcome.ERROR_END;
    }

    if (characterStroke.requireIntersection && !hasIntersectedStroke) {
      return StrokeOutcome.WARNING_INTERSECTION;
    }
    return StrokeOutcome.SUCCESS;
  };

  const calculateStrokeOutcome = (
    stroke: DrawingStroke,
    isDotStroke: boolean,
    requiredPoints: (Point | ThresholdRestrictedPoint)[],
    character: Character,
    characterStroke: Stroke,
  ) => (isDotStroke
    ? calculateDotStrokeOutcome(
      stroke,
      requiredPoints[requiredPoints.length - 1],
      character,
      characterStroke,
    )
    : calculateMultiPointStrokeOutcome(
      stroke,
      requiredPoints,
      character,
      characterStroke,
    ));

  const calculateFreeDrawStrokeOutcome = (
    requiredPoints: (Point | ThresholdRestrictedPoint)[],
  ) => ((didHitRequiredPointsInOrder(requiredPoints)) ? StrokeOutcome.SUCCESS : StrokeOutcome.ERROR_GENERAL_TRACING);

  const calculateSpacingCharFormationStrokeOutcome = (
    character: Character,
    requiredPoints: (Point | ThresholdRestrictedPoint)[],
  ) => {
    const intersectedPointsList = Array.from(intersectedPoints);
    const hitPoints = requiredPoints.every(
      (point: Point, i: number) => (
        intersectedPointsList[i] === getIntersectionNodeName(IntersectionType.REQUIRED, point.orderId)
      ),
    );
    const hasHitPercentage = didHitPercentage(character);
    return (hasHitPercentage && hitPoints) ? StrokeOutcome.SUCCESS : StrokeOutcome.ERROR_GENERAL_TRACING;
  };
  const updateIntersectionsFromActivePointer = (
    konvaStage: any,
    activePointer: any,
    lastPointOrderId: number,
    checkSpacing = false,
  ) => {
    // check for point intersection
    const intersectionNode = konvaStage.getIntersection(activePointer);
    const intersectionNodeParentId = intersectionNode?.getParent()?.getId();
    const intersectionNodeId = intersectionNode?.getId();
    if (intersectionNodeId
      && !intersectedPoints.has(intersectionNodeId)
      && intersectionNodeId.includes(IntersectionType.REQUIRED)) {
      const updatedSet = new Set(intersectedPoints);
      updatedSet.add(intersectionNodeId);
      setIntersectedPoints(updatedSet);
    } else if (intersectionNodeId
      && !restrictedIntersections.has(intersectionNodeId)
      && intersectionNodeId.includes(IntersectionType.RESTRICTED)) {
      const updatedRestrictedSet = new Set(restrictedIntersections);
      updatedRestrictedSet.add(intersectionNodeId);
      setRestrictedIntersections(updatedRestrictedSet);
    }

    const isTerminalPoint = [
      getIntersectionNodeName(IntersectionType.REQUIRED, 0),
      getIntersectionNodeName(IntersectionType.REQUIRED, lastPointOrderId),
    ].includes(intersectionNodeId);

    // check for model path or terminal point intersection
    if (['character-model-path', 'points'].includes(intersectionNodeParentId) || isTerminalPoint) {
      setIntersectionCounts({ ...intersectionCounts, success: intersectionCounts.success + 1 });
    } else {
      setIntersectionCounts({ ...intersectionCounts, fail: intersectionCounts.fail + 1 });
    }

    if (checkSpacing && (!spacingIntersections.inner || !spacingIntersections.outer)) {
      if (!spacingIntersections.inner && intersectionNodeId === 'inner-spacing-restricted-area') {
        setSpacingIntersections({ ...spacingIntersections, inner: true });
      }

      if (!spacingIntersections.outer && intersectionNodeId === 'outer-spacing-restricted-area') {
        setSpacingIntersections({ ...spacingIntersections, outer: true });
      }
    }
  };

  const updateLastStrokeFromActivePointer = (lastStroke: DrawingStroke, activePointer: any, e: any) => {
    // add point and update id
    const updatedStroke = { ...lastStroke };
    updatedStroke.points = updatedStroke.points.concat([activePointer.x, activePointer.y]);
    updatedStroke.touchId = e.pointerId;

    if (envSettings?.enableNewTouchSettings) {
      const updatedTouches = { ...updatedStroke.touches };
      if (!updatedTouches[e.pointerId]) {
        updatedTouches[e.pointerId] = [];
      }
      updatedTouches[e.pointerId] = [...updatedTouches[e.pointerId], activePointer.x, activePointer.y];
      updatedStroke.touches = updatedTouches;
    }

    // replace last stroke points
    const updatedStrokes = [...strokes];
    updatedStrokes.splice(updatedStrokes.length - 1, 1, updatedStroke);
    setStrokes(updatedStrokes);
  };

  const initializeStroke = (activePointer: any, isDotStroke: boolean, activePen: Pen) => {
    // if stroke is a dot, add two more points to the array because Konva will not
    // draw if there are only two points.
    const initialPoints = isDotStroke
      ? [activePointer.x, activePointer.y, activePointer.x + 0.25, activePointer.y + 0.25]
      : [activePointer.x, activePointer.y];

    setStrokes([...strokes, {
      points: [...initialPoints],
      pen: activePen,
      touchId: activePointer.id,
      touches: {
        [activePointer.id]: [...initialPoints],
      },
    }]);
  };

  const calculateSpacingOutcome = (): SpacingActionOutcome => {
    const hitRestrictedArea = spacingIntersections.inner || spacingIntersections.outer;
    return hitRestrictedArea
      ? SpacingActionOutcome.FAIL
      : SpacingActionOutcome.PASS;
  };

  const calculateDragAndDropOutcome = (
    dropCoordinates: Drop,
    letterWith: number,
    modelCoordinates: any,
    currentCharacter: string,
  ): DragAndDropActionOutcome => {
    const droppedLetterRight = currentCharacter === 'f'
      // Letter f is very specific to it's right position, so, we need a bit different calcualtions for it
      ? dropCoordinates.x + letterWith + 16
      : dropCoordinates.x + letterWith - 2;

    if (droppedLetterRight > (modelCoordinates.right - ACCEPTABLE_DRAG_AND_DROP_OFFSET)
      && droppedLetterRight > (modelCoordinates.right + ACCEPTABLE_DRAG_AND_DROP_OFFSET)) {
      return DragAndDropActionOutcome.TOO_WIDE;
    }
    if (droppedLetterRight < (modelCoordinates.right + ACCEPTABLE_DRAG_AND_DROP_OFFSET)
      && droppedLetterRight < (modelCoordinates.right - ACCEPTABLE_DRAG_AND_DROP_OFFSET)) {
      return DragAndDropActionOutcome.TOO_CLOSE;
    }
    return DragAndDropActionOutcome.CORRECT;
  };

  const spacingIntersectionErrorsToString = (): string => {
    const errorLocations = Object.keys(spacingIntersections)
      .reduce((allLocations, currentLocation) => {
        let updatedLocations = allLocations;
        if (spacingIntersections[currentLocation as 'inner' | 'outer']) {
          if (allLocations.length > 0) {
            updatedLocations += ` and ${currentLocation}`;
          } else {
            updatedLocations = currentLocation;
          }
        }
        return updatedLocations;
      }, '');
    return errorLocations;
  };

  return {
    strokes,
    setStrokes,
    drop,
    setDrop,
    intersectedPoints,
    strokeEndedAtEndPoint,
    resetGradingForNextStroke,
    calculateStrokeOutcome,
    updateIntersectionsFromActivePointer,
    updateLastStrokeFromActivePointer,
    initializeStroke,
    calculateSpacingOutcome,
    spacingIntersectionErrorsToString,
    calculateFreeDrawStrokeOutcome,
    calculateSpacingCharFormationStrokeOutcome,
    calculateDragAndDropOutcome,
  };
}
