import { CharacterType } from '../../../data/types';

// introduction start VOs
import startAtTheTop from './start-at-the-top.mp3';
import startNearTheTop from './start-near-the-top.mp3';
import startAtTheBottom from './start-at-the-bottom.mp3';
import startJustAboveMiddle from './start-just-above-middle.mp3';
import startAtTheMiddle from './start-at-the-middle.mp3';
import startNearTheMiddle from './start-near-the-middle.mp3';
import startinBottomArea from './start-in-bottom-area.mp3';

// instruction end VOs
import upperA from './UPPER_A.mp3';
import upperB from './UPPER_B.mp3';
import upperC from './UPPER_C.mp3';
import upperD from './UPPER_D.mp3';
import upperE from './UPPER_E.mp3';
import upperF from './UPPER_F.mp3';
import upperG from './UPPER_G.mp3';
import upperH from './UPPER_H.mp3';
import upperI from './UPPER_I.mp3';
import upperJ from './UPPER_J.mp3';
import upperK from './UPPER_K.mp3';
import upperL from './UPPER_L.mp3';
import upperM from './UPPER_M.mp3';
import upperN from './UPPER_N.mp3';
import upperO from './UPPER_O.mp3';
import upperP from './UPPER_P.mp3';
import upperQ from './UPPER_Q.mp3';
import upperR from './UPPER_R.mp3';
import upperS from './UPPER_S.mp3';
import upperT from './UPPER_T.mp3';
import upperU from './UPPER_U.mp3';
import upperV from './UPPER_V.mp3';
import upperW from './UPPER_W.mp3';
import upperX from './UPPER_X.mp3';
import upperY from './UPPER_Y.mp3';
import upperZ from './UPPER_Z.mp3';
import lowerA from './LOWER_A.mp3';
import lowerB from './LOWER_B.mp3';
import lowerC from './LOWER_C.mp3';
import lowerD from './LOWER_D.mp3';
import lowerE from './LOWER_E.mp3';
import lowerF from './LOWER_F.mp3';
import lowerG from './LOWER_G.mp3';
import lowerH from './LOWER_H.mp3';
import lowerI from './LOWER_I.mp3';
import lowerJ from './LOWER_J.mp3';
import lowerK from './LOWER_K.mp3';
import lowerL from './LOWER_L.mp3';
import lowerM from './LOWER_M.mp3';
import lowerN from './LOWER_N.mp3';
import lowerO from './LOWER_O.mp3';
import lowerP from './LOWER_P.mp3';
import lowerQ from './LOWER_Q.mp3';
import lowerR from './LOWER_R.mp3';
import lowerS from './LOWER_S.mp3';
import lowerT from './LOWER_T.mp3';
import lowerU from './LOWER_U.mp3';
import lowerV from './LOWER_V.mp3';
import lowerW from './LOWER_W.mp3';
import lowerX from './LOWER_X.mp3';
import lowerY from './LOWER_Y.mp3';
import lowerZ from './LOWER_Z.mp3';

import symbolEqual from './SYMBOL_EQUAL.mp3';
import symbolExclamation from './SYMBOL_EXCLAMATION.mp3';
import symbolPlus from './SYMBOL_PLUS.mp3';
import symbolQuestion from './SYMBOL_QUESTION.mp3';

import number0 from './NUMBER_0.mp3';
import number1 from './NUMBER_1.mp3';
import number2 from './NUMBER_2.mp3';
import number3 from './NUMBER_3.mp3';
import number4 from './NUMBER_4.mp3';
import number5 from './NUMBER_5.mp3';
import number6 from './NUMBER_6.mp3';
import number7 from './NUMBER_7.mp3';
import number8 from './NUMBER_8.mp3';
import number9 from './NUMBER_9.mp3';

type CharInstructions = {
  [key in CharacterType]?: {
    instructionStart: any,
    instructionEnd: any,
  };
}

export const charInstructions: CharInstructions = {
  [CharacterType.LOWER_A]: { instructionStart: startNearTheMiddle, instructionEnd: lowerA },
  [CharacterType.LOWER_B]: { instructionStart: startAtTheTop, instructionEnd: lowerB },
  [CharacterType.LOWER_C]: { instructionStart: startNearTheMiddle, instructionEnd: lowerC },
  [CharacterType.LOWER_D]: { instructionStart: startNearTheMiddle, instructionEnd: lowerD },
  [CharacterType.LOWER_E]: { instructionStart: startinBottomArea, instructionEnd: lowerE },
  [CharacterType.LOWER_F]: { instructionStart: startNearTheTop, instructionEnd: lowerF },
  [CharacterType.LOWER_G]: { instructionStart: startNearTheMiddle, instructionEnd: lowerG },
  [CharacterType.LOWER_H]: { instructionStart: startAtTheTop, instructionEnd: lowerH },
  [CharacterType.LOWER_I]: { instructionStart: startAtTheMiddle, instructionEnd: lowerI },
  [CharacterType.LOWER_J]: { instructionStart: startAtTheMiddle, instructionEnd: lowerJ },
  [CharacterType.LOWER_K]: { instructionStart: startAtTheTop, instructionEnd: lowerK },
  [CharacterType.LOWER_L]: { instructionStart: startAtTheTop, instructionEnd: lowerL },
  [CharacterType.LOWER_M]: { instructionStart: startAtTheMiddle, instructionEnd: lowerM },
  [CharacterType.LOWER_N]: { instructionStart: startAtTheMiddle, instructionEnd: lowerN },
  [CharacterType.LOWER_O]: { instructionStart: startAtTheMiddle, instructionEnd: lowerO },
  [CharacterType.LOWER_P]: { instructionStart: startAtTheMiddle, instructionEnd: lowerP },
  [CharacterType.LOWER_Q]: { instructionStart: startNearTheMiddle, instructionEnd: lowerQ },
  [CharacterType.LOWER_R]: { instructionStart: startAtTheMiddle, instructionEnd: lowerR },
  [CharacterType.LOWER_S]: { instructionStart: startNearTheMiddle, instructionEnd: lowerS },
  [CharacterType.LOWER_T]: { instructionStart: startAtTheTop, instructionEnd: lowerT },
  [CharacterType.LOWER_U]: { instructionStart: startAtTheMiddle, instructionEnd: lowerU },
  [CharacterType.LOWER_V]: { instructionStart: startAtTheMiddle, instructionEnd: lowerV },
  [CharacterType.LOWER_W]: { instructionStart: startAtTheMiddle, instructionEnd: lowerW },
  [CharacterType.LOWER_X]: { instructionStart: startAtTheMiddle, instructionEnd: lowerX },
  [CharacterType.LOWER_Y]: { instructionStart: startAtTheMiddle, instructionEnd: lowerY },
  [CharacterType.LOWER_Z]: { instructionStart: startAtTheMiddle, instructionEnd: lowerZ },

  [CharacterType.NUMBER_0]: { instructionStart: startAtTheTop, instructionEnd: number0 },
  [CharacterType.NUMBER_1]: { instructionStart: startNearTheTop, instructionEnd: number1 },
  [CharacterType.NUMBER_2]: { instructionStart: startNearTheTop, instructionEnd: number2 },
  [CharacterType.NUMBER_3]: { instructionStart: startNearTheTop, instructionEnd: number3 },
  [CharacterType.NUMBER_4]: { instructionStart: startAtTheTop, instructionEnd: number4 },
  [CharacterType.NUMBER_5]: { instructionStart: startAtTheTop, instructionEnd: number5 },
  [CharacterType.NUMBER_6]: { instructionStart: startAtTheTop, instructionEnd: number6 },
  [CharacterType.NUMBER_7]: { instructionStart: startAtTheTop, instructionEnd: number7 },
  [CharacterType.NUMBER_8]: { instructionStart: startAtTheTop, instructionEnd: number8 },
  [CharacterType.NUMBER_9]: { instructionStart: startNearTheTop, instructionEnd: number9 },

  [CharacterType.UPPER_A]: { instructionStart: startAtTheBottom, instructionEnd: upperA },
  [CharacterType.UPPER_B]: { instructionStart: startAtTheTop, instructionEnd: upperB },
  [CharacterType.UPPER_C]: { instructionStart: startNearTheTop, instructionEnd: upperC },
  [CharacterType.UPPER_D]: { instructionStart: startAtTheTop, instructionEnd: upperD },
  [CharacterType.UPPER_E]: { instructionStart: startAtTheTop, instructionEnd: upperE },
  [CharacterType.UPPER_F]: { instructionStart: startAtTheTop, instructionEnd: upperF },
  [CharacterType.UPPER_G]: { instructionStart: startNearTheTop, instructionEnd: upperG },
  [CharacterType.UPPER_H]: { instructionStart: startAtTheTop, instructionEnd: upperH },
  [CharacterType.UPPER_I]: { instructionStart: startAtTheTop, instructionEnd: upperI },
  [CharacterType.UPPER_J]: { instructionStart: startAtTheTop, instructionEnd: upperJ },
  [CharacterType.UPPER_K]: { instructionStart: startAtTheTop, instructionEnd: upperK },
  [CharacterType.UPPER_L]: { instructionStart: startAtTheTop, instructionEnd: upperL },
  [CharacterType.UPPER_M]: { instructionStart: startAtTheBottom, instructionEnd: upperM },
  [CharacterType.UPPER_N]: { instructionStart: startAtTheBottom, instructionEnd: upperN },
  [CharacterType.UPPER_O]: { instructionStart: startAtTheTop, instructionEnd: upperO },
  [CharacterType.UPPER_P]: { instructionStart: startAtTheTop, instructionEnd: upperP },
  [CharacterType.UPPER_Q]: { instructionStart: startAtTheTop, instructionEnd: upperQ },
  [CharacterType.UPPER_R]: { instructionStart: startAtTheTop, instructionEnd: upperR },
  [CharacterType.UPPER_S]: { instructionStart: startNearTheTop, instructionEnd: upperS },
  [CharacterType.UPPER_T]: { instructionStart: startAtTheTop, instructionEnd: upperT },
  [CharacterType.UPPER_U]: { instructionStart: startAtTheTop, instructionEnd: upperU },
  [CharacterType.UPPER_V]: { instructionStart: startAtTheTop, instructionEnd: upperV },
  [CharacterType.UPPER_W]: { instructionStart: startAtTheTop, instructionEnd: upperW },
  [CharacterType.UPPER_X]: { instructionStart: startAtTheTop, instructionEnd: upperX },
  [CharacterType.UPPER_Y]: { instructionStart: startAtTheTop, instructionEnd: upperY },
  [CharacterType.UPPER_Z]: { instructionStart: startAtTheTop, instructionEnd: upperZ },

  [CharacterType.SYMBOL_EQUAL]: { instructionStart: startJustAboveMiddle, instructionEnd: symbolEqual },
  [CharacterType.SYMBOL_PLUS]: { instructionStart: startJustAboveMiddle, instructionEnd: symbolPlus },
  [CharacterType.SYMBOL_QUESTION]: { instructionStart: startNearTheTop, instructionEnd: symbolQuestion },
  [CharacterType.SYMBOL_EXCLAMATION]: { instructionStart: startAtTheTop, instructionEnd: symbolExclamation },
};
