import React from 'react';
import { useTheme } from 'styled-components';
import { spacingSetTypeList, spacingSets } from '../../data/spacing/spacing-sets';
import SequenceButton from '../Buttons/SequenceButton';
import { SpacingSet } from '../../data/types';
import { useSpacingGameContext } from '../../context/SpacingGameContext';
import { SpacingEventTypes } from '../../stateMachines/spacingMachine/spacingMachine';

export default function SpacingSetsButtons({ onButtonSelection }: { onButtonSelection: () => void}) {
  const theme = useTheme();
  const { send } = useSpacingGameContext();

  const handleSpacingSetButtonTouch = (payload: SpacingSet): void => {
    onButtonSelection();
    send({ type: SpacingEventTypes.LAUNCH_SET, payload });
  };

  return (
    <>
      {spacingSetTypeList.map((key) => {
        if (!spacingSets[key]) return null;
        const display = spacingSets[key].display || '';
        return (
          <SequenceButton
            key={key}
            colors={theme.colors.buttons.instructionalDigraphSequence.active}
            ariaLabel={`start practicing spacing for ${display}`}
            sequenceOrder={spacingSets[key].displayOrder}
            buttonTouch={() => handleSpacingSetButtonTouch(spacingSets[key])}
          >
            {display}
          </SequenceButton>
        );
      })}
    </>
  );
}
